import React, { useEffect, useState } from "react"
import { Link, useLocation, useOutletContext, useParams } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import NoData from "../../common/NoData"
import clientService from "../../services/service"
import TrainerTextLesson from "./LessonTypes/TextLesson"
import TrainerVideoLesson from "./LessonTypes/VideoLesson"
import TrainerVideUploadLesson from "./LessonTypes/VideoUploadLesson"
import TrainerWalkThrough from "./LessonTypes/TrainerWalkThrough"

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const TestLesson = (props) => {
  const { setExtraClass } = useOutletContext()
  let query = useQuery();
  const guideLessonId = query.get("lessonId");
  const [currentIndex, setCurrentIndex] = useState(0)
  const [guideLessons, setGuideLessons] = useState({})
  const [selecetedLesson, setSelecetedLesson] = useState({})
  const { courseId, guideId } = useParams()
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  // const guideId = localStorage.getItem("guideId")

  useEffect(() => {
    setExtraClass(props.extraClass)
    return () => {
      setExtraClass("")
    }
  }, [])

  useEffect(() => {
    if (uuidRegex.test(guideId)) {
      getGuideLessons()
    }
  }, [guideId])

  const getGuideLessons = async () => {
    const res = await clientService.get(
      `TrainerCourse/GetCourseGuideLessons?CourseGuideId=${guideId}`
    )
    setGuideLessons(res.data);
    var lessonId = 0;
    for (let index = 0; index < res.data?.trainerLesson.length; index++) {
      if (res.data?.trainerLesson[index].courseGuideLessonId == guideLessonId) {
        lessonId = index;
      }
    }
    setSelecetedLesson(res.data?.trainerLesson[lessonId])
  }

  useEffect(() => {
    if (
      guideLessons?.trainerLesson &&
      guideLessons?.trainerLesson[currentIndex]
    ) {
      setSelecetedLesson(guideLessons?.trainerLesson[currentIndex])
    }
  }, [currentIndex])

  const prev = () => {
    if (currentIndex == 0) {
      return
    }
    setCurrentIndex(currentIndex - 1)
  }

  const next = () => {
    if (currentIndex < guideLessons?.trainerLesson?.length) {
      setCurrentIndex(currentIndex + 1)
    }
  }
  return (
    <>
      <div className="lesson_sidebar">
        <ul>
          {guideLessons?.trainerLesson?.map((lesson, index) => {
            const time = lesson.time.split(":")
            return (
              <li
                onClick={() => {
                  setCurrentIndex(index) // setSelecetedLesson(lesson)
                }}
                className={
                  lesson.iscompleted ? "pointer" : "hide_check pointer"
                }
                key={`lesson_${index}`}
              >
                <div className="lesson_sidebar_info">
                  {lesson.type == 1 || lesson.type == 6 ?
                    <span dangerouslySetInnerHTML={{ __html: CustomIcons.small_document }}></span>
                    : lesson.type == 2 || lesson.type == 4 ?
                      <span dangerouslySetInnerHTML={{ __html: CustomIcons.small_video }}></span>
                      :
                      <span dangerouslySetInnerHTML={{ __html: CustomIcons.quiz }}></span>
                  }

                  {lesson.lessonName} <br />
                  <small>
                    {time[0]}h {time[1]}m
                  </small>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="aside-content-wrapper white_body">
        <div className="container-fluid">
          <div className="model_content_fit_screen">
            <div className="modal-content">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Learning</li>
                    <li className="breadcrumb-item">
                      <Link to={`/trainer/course/detail/${courseId}`}>
                        {guideLessons?.courseName}
                      </Link>{" "}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {guideLessons.courseGuideName}
                    </li>
                  </ol>
                </nav>
                {selecetedLesson?.type === 4 ? null :
                  <a href="#discussion" className="icon_message">
                    <span dangerouslySetInnerHTML={{ __html: CustomIcons.message }}></span>
                  </a>}
              </div>
              <div className="modal-body">

                <div className="model_content_fit_screen">
                  {selecetedLesson && selecetedLesson?.type === 1 ? (
                    <TrainerTextLesson
                      prev={prev}
                      next={next}
                      lesson={selecetedLesson}
                    />
                  ) : selecetedLesson && selecetedLesson?.type === 2 ? (
                    <TrainerVideoLesson
                      prev={prev}
                      next={next}
                      lesson={selecetedLesson}
                    />
                  ) : selecetedLesson && selecetedLesson?.type === 4 ? (
                    <TrainerVideUploadLesson
                      prev={prev}
                      next={next}
                      lesson={selecetedLesson}
                    />
                  ) : selecetedLesson && selecetedLesson?.type === 6 ? (
                    <TrainerWalkThrough
                      prev={prev}
                      next={next}
                      lesson={selecetedLesson}
                    />
                  ) : <NoData title={"No Lesson Selected"} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestLesson

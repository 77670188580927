import { Modal } from "react-bootstrap"

const DeleteQuestion = ({
  onClose,
  guide,
  title = "Delete a question",
  desciption = "Are you sure you want to delete a question?",
}) => {
  const handleSubmit = ({ setErrors }) => {
    onClose(true)
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="delete_user"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{__html:desciption}}  className="white_color"></p>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Yes, I'm sure
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteQuestion

import React, { useState } from "react"
import { useAlert } from "react-alert"
import { Link, useNavigate, useParams } from "react-router-dom"
import clientService from "../../services/service"
import BookSession from "../models/BookSession"

const LearningGuideResultNotGoodJob = ({ evalutionInfo, getFinalEvalution }) => {
  const [showBookModel, setShowBookModel] = useState(false)
  const { guidId, courseId } = useParams()
  const alert = useAlert();
  const navigate = useNavigate();

  
  // const getFinalEvalution = async () => {
  //   const res = await clientService.get(
  //     `StudentCourse/GetFinalEvaluation?courseGuideId=${guidId}`
  //   )
  // }
  const closeModel = async (e) => {
    if (e === true) {
      getFinalEvalution()
      // navigate(`/course/final-evalution/${courseId}/${guidId}`)

    }
    // navigate(`/course/final-evalution/${courseId}/${guidId}`)
    setShowBookModel(false)
  }
  return (
    <>
      <div className="col-md-12 minus_blank_space mt-3">
        <div className="full text-center mt-3">
          <div className="icon">
            <img src="/img/not_good_job.png" alt="#" />
          </div>
        </div>
        <div className="full text-center">
          <h3 className="white_color mt-4">
            <b>You could have done better!</b>
          </h3>
          <p className="d-flex justify-content-center">
            <Link
              className="main_btn_default mt-4"
              data-bs-toggle="modal"
              to=""
              onClick={() => {
                if(evalutionInfo?.calendlyLink){
                  setShowBookModel(true)
                }else{
                  alert.error("There is some issue with booking, Please contact admin.")
                }
                
              }}
              role="button"
            >
              Book a new session
            </Link>
          </p>
          <p className="light_color mt-4">See your results below:</p>
        </div>

        <div className="evaluation_section">
          <p className="light_color">
            <b className="white_color">Summary:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.summary}
          </p>
          <p>
            {evalutionInfo?.finalEvaluationTags &&
            evalutionInfo?.finalEvaluationTags.length > 0
              ? evalutionInfo?.finalEvaluationTags.map((tag, index) => {
                  return <label className="dark_red">{tag?.tagName}</label>
                })
              : null}
          </p>
          <p className="light_color">
            <b className="white_color">What was good:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.goodThings}
          </p>
          <p className="light_color">
            <b className="white_color">To improve:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.toImprove}
          </p>
        </div>
      </div>
      {showBookModel && (
        <BookSession
          onClose={closeModel}
          calendlyLink={evalutionInfo?.calendlyLink}
          newBooking={"new"}
        />
      )}
    </>
  )
}

export default LearningGuideResultNotGoodJob

import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Link, useNavigate, useParams } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import clientService from "../../services/service"
import BookSession from "../models/BookSession"
import LearningGuideResultGoodJob from "./LearningGuideResultGoodjob"
import LearningGuideResultNotGoodJob from "./LearningGuideResultNotGoodJob"
import LearningGuideSchedule from "./LearningGuideSchedule"

const LearningGuideSection = () => {
  const navigator = useNavigate();
  const [showBookModel, setShowBookModel] = useState(false)
  const { guidId, courseId } = useParams()
  const [evalutionInfo, setEvalutionInfo] = useState(null)
  const alert = useAlert()
  useEffect(() => {
    getFinalEvalution()
  }, [guidId])
  const getFinalEvalution = async () => {
    const res = await clientService.get(
      `StudentCourse/GetFinalEvaluation?courseGuideId=${guidId}`
    )
    if(res.code == 207){
     // alert.error("");
      navigator("/");
    }else{
      setEvalutionInfo(res.data)
    }
  }
  const closeModel = async (e) => {
    if (e === true) {
      getFinalEvalution()
    }

    setShowBookModel(false)
  }
  if(!evalutionInfo?.courseGuideId){
    return null;
  }
  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="breadcrum light_breadcrum course-final-evalution">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">Learning</li>
                      <li className="breadcrumb-item">
                        <Link to={`/course/learning-guide/${courseId}`}>
                          {" "}
                          {evalutionInfo?.course?.courseName}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {evalutionInfo?.courseGuideName}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>

              {evalutionInfo?.finalEvaluationStatus === 1 ? (
                <div className="col-md-12 minus_blank_space mt-5">
                  <div className="full text-center mt-5">
                    <div className="icon">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: CustomIcons.big_calendar,
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="full text-center">
                    <h3 className="white_color mt-4">
                      <b className="white_color">Well done!</b>
                    </h3>
                    <p className="light_color mt-4">
                      You are now ready for your Final Evaluation.
                      <br />
                      Please book a session with your Trainer below.
                    </p>
                    <Link
                      to=""
                      className="main_btn_default mt-4"
                      data-bs-toggle="modal"
                      onClick={() => {
                        if(evalutionInfo.calendlyLink){
                          setShowBookModel(true)
                        }else{
                          alert.error("There is some issue with booking, Please contact admin.")
                        }
                        
                      }}
                      role="button"
                    >
                      Book session
                    </Link>
                  </div>
                </div>
              ) : evalutionInfo?.finalEvaluationStatus === 2 ? (
                <LearningGuideSchedule evalutionInfo={evalutionInfo} />
              ) : evalutionInfo?.finalEvaluationStatus === 3 && evalutionInfo && evalutionInfo?.finalEvaluationResult?.result == 1 ? (
                <LearningGuideResultGoodJob evalutionInfo={evalutionInfo} />
              ) : (
                <LearningGuideResultNotGoodJob evalutionInfo={evalutionInfo}  getFinalEvalution={getFinalEvalution} />
              )}
            </div>
          </div>
        </div>
      </div>
      {showBookModel && <BookSession onClose={closeModel} calendlyLink={evalutionInfo.calendlyLink} />}
    </>
  )
}

export default LearningGuideSection

import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import ChangePassword from "./models/ChangePassword"
import { useDispatch, useSelector } from "react-redux"
import {
  authState,
  getProfile,
  updateProfile,
  setUpdateProfileDataResponse,
  deleteMyAccount,
  setDeleteUserResponse,
  setUploadedProfilePicResponse,
} from "../store/slices/authSlice"
import { Modal } from "react-bootstrap"
import { Formik } from "formik"
import { useAlert } from "react-alert"
import CropUploadPhoto from "./models/CropUploadPhoto"
import RemovePhoto from "./models/RemovePhoto"
import CustomIcons from "../assets/CustomIcons"
import InputMask from "react-input-mask"

const TrainerProfile = () => {
  const [showDelete, setShowDelete] = useState(false)
  const [showCropModel, setShowCropModel] = useState(false)
  const [showRemovePhotoModel, setShowRemovePhotoModel] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [showChangePasswordModel, setShowChangePasswordModel] = useState(false)
  const {
    getProfileDataResponse,
    updateProfileDataResponse,
    deleteUser,
    uploadProfilePic,
  } = useSelector(authState)
  const dispatch = useDispatch()
  const alert = useAlert()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage !== "") {
      alert.error(errorMessage)
    }
  }, [alert])

  const handleDelete = () => {
    setShowDelete(false)
  }
  const closeModel = () => {
    setShowChangePasswordModel(false)
  }
  const closeCropModel = () => {
    setShowCropModel(false)
  }
  const closeRemovePhotoModel = () => {
    setShowRemovePhotoModel(false)
  }

  const deleteAccount = ({ setErrors }) => {
    dispatch(deleteMyAccount(setErrors))
  }

  useEffect(() => {
    if (
      updateProfileDataResponse.code === 500 ||
      updateProfileDataResponse.status === false
    ) {
      alert.error(updateProfileDataResponse.message)
      dispatch(setUpdateProfileDataResponse({}))
    } else if (
      updateProfileDataResponse.code === 200 ||
      updateProfileDataResponse.status === true
    ) {
      alert.success(updateProfileDataResponse.message)
      dispatch(setUpdateProfileDataResponse({}))
    }
  }, [alert, updateProfileDataResponse])

  useEffect(() => {
    if (deleteUser.code === 500 || deleteUser.status === false) {
      alert.error(deleteUser.message)
      dispatch(setDeleteUserResponse({}))
    } else if (deleteUser.code === 200 || deleteUser.status === true) {
      handleDelete()
      alert.success(deleteUser.message)
      dispatch(setDeleteUserResponse({}))
      localStorage.clear()
      navigate("/login")
    }
  }, [alert, deleteUser])

  const handleFormSubmit = (values, { setErrors }) => {
    var data = {
      ...values,
      id: getProfileDataResponse.id,
    }
    dispatch(updateProfile(data, setErrors))
  }

  useEffect(() => {
    if (uploadProfilePic.code === 500 || uploadProfilePic.status === false) {
      alert.error(uploadProfilePic.message)
      dispatch(setUploadedProfilePicResponse({}))
    } else if (
      uploadProfilePic.code === 200 ||
      uploadProfilePic.status === true
    ) {
      alert.success(uploadProfilePic.message)
      dispatch(setUploadedProfilePicResponse({}))
    }
  }, [alert, uploadProfilePic])

  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">My Profile</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-10 offset-xl-1">
                <div className="profile-information-blog">
                  <div className="top_profile_section">
                    <h5>Photo</h5>
                    <div className="d-flex position-relative">
                      {getProfileDataResponse.profileImage ? (
                        <img
                          src={getProfileDataResponse.profileImage}
                          className="flex-shrink-0 border-50"
                          alt="avtars"
                          width="30%"
                          height="30%"
                        />
                      ) : (
                        <div className="user-initial profile_default">
                          {getProfileDataResponse?.firstName ? (
                            <span className="text-capitalize">
                              {getProfileDataResponse?.firstName?.[0]}
                              {"" + getProfileDataResponse?.lastName?.[0]}
                            </span>
                          ) : null}
                        </div>
                      )}
                      <div>
                        <p>
                          Maximum size - 100MB
                          <br />
                          Supported file type - JPEG, BMP, PMG, GIF, or TIFF.
                        </p>
                        <div className="buttons profiles">
                          <div className="fileUpload btn btn--browse mr-5">
                            <span>Upload photo</span>
                            <input
                              id="uploadBtn"
                              type="file"
                              className="upload"
                              onChange={(e) => {
                                if (e.target.files.length >= 1) {
                                  var fileExt = e.target.files[0].name
                                    .split(".")
                                    .pop()
                                    .toUpperCase()
                                  if (
                                    [
                                      "JPEG",
                                      "JPG",
                                      "BMP",
                                      "PNG",
                                      "GIF",
                                      "TIFF",
                                    ].indexOf(fileExt) === -1
                                  ) {
                                    alert.error(
                                      "Please select JPEG, BMP, PMG, GIF, TIFF any of this file type."
                                    )
                                  } else {
                                    setShowCropModel(true)
                                    setSelectedImage(e.target.files[0])
                                  }
                                }
                              }}
                            />
                          </div>
                          {getProfileDataResponse.profileImage ? (
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setShowRemovePhotoModel(true)
                              }}
                            >
                              Remove
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_section">
                    <h5>Personal info</h5>
                    <Formik
                      initialValues={{
                        firstName: getProfileDataResponse.firstName,
                        lastName: getProfileDataResponse.lastName,
                        phoneNumber: getProfileDataResponse.phoneNumber,
                        email: getProfileDataResponse.email,
                      }}
                      enableReinitialize
                      validate={(values) => {
                        const errors = {}
                        if (!values.firstName) {
                          errors.firstName = "Required field"
                        } else if (!/^[aA-zZ\s]+$/i.test(values.firstName)) {
                          errors.firstName = "Please enter valid details"
                        }
                        if (!values.lastName) {
                          errors.lastName = "Required field"
                        } else if (!/^[aA-zZ\s]+$/i.test(values.lastName)) {
                          errors.lastName = "Please enter valid details"
                        }
                        if (!values.phoneNumber) {
                          errors.phoneNumber = "Required field"
                        }
                        if (values.phoneNumber) {
                          if (
                            !/\([1-9]{3}\)\s[0-9]{3}\s[0-9]{4}$/i.test(
                              values.phoneNumber
                            ) &&
                            !/[1-9]{3}[0-9]{3}[0-9]{4}$/i.test(values.phoneNumber)
                          ) {
                            errors.phoneNumber = "Please enter valid Phone Number"
                          }
                        }

                        return errors
                      }}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <fieldset>
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group pb-1">
                                  <label
                                    className="control-label col-xs-3 mb-1"
                                    htmlFor="name"
                                  >
                                    First Name
                                  </label>
                                  <div className="col-xs-9">
                                    <input
                                      type="text"
                                      className={
                                        errors.firstName && touched.firstName
                                          ? "form-control cform-control error-field"
                                          : "form-control cform-control"
                                      }
                                      name="firstName"
                                      placeholder="First Name"
                                      required
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.firstName}
                                    />
                                    <div className="error-message">
                                      {errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group pb-1">
                                  <label
                                    className="control-label col-xs-3 mb-1"
                                    htmlFor="name"
                                  >
                                    Last Name
                                  </label>
                                  <div className="col-xs-9">
                                    <input
                                      type="text"
                                      className={
                                        errors.lastName && touched.lastName
                                          ? "form-control cform-control error-field"
                                          : "form-control cform-control"
                                      }
                                      name="lastName"
                                      placeholder="Last Name"
                                      required
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.lastName}
                                    />
                                    <div className="error-message">
                                      {errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-4 pb-1">
                              <label
                                className="control-label col-xs-3 mb-1"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <div className="col-xs-9">
                                <div className="disable_field">
                                  <input
                                    type="email"
                                    className="form-control cform-control lock_icon"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    disabled
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  <span
                                    className="pointer"
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.lock,
                                    }}
                                  ></span>
                                </div>
                                <div className="error"></div>
                              </div>
                            </div>
                            <div className="form-group mb-4 pb-1">
                              <label
                                className="control-label col-xs-3 mb-1"
                                htmlFor="number"
                              >
                                Phone number
                              </label>
                              <div className="col-xs-9">
                                <InputMask
                                  mask="(999) 999 9999"
                                  className={
                                    errors.phoneNumber && touched.phoneNumber
                                      ? "form-control cform-control error-field"
                                      : "form-control cform-control flag"
                                  }
                                  value={values.phoneNumber}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                >
                                  {(inputProps) => (
                                    <input
                                      type="text"
                                      name="phoneNumber"
                                      placeholder="(XXX) XXX XXXX"
                                      {...inputProps}
                                    />
                                  )}
                                </InputMask>

                                <div className="error-message">
                                  {errors.phoneNumber &&
                                    touched.phoneNumber &&
                                    errors.phoneNumber}
                                </div>
                              </div>
                            </div>

                            <div className="buttons profiles">
                              <button className="default_btn" type="submit">
                                Save Changes
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  navigate("/dashboard")
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </fieldset>
                        </form>
                      )}
                    </Formik>
                    <div className="form-group mt-4 mb-4 pb-1">
                      <label
                        className="control-label col-xs-3 mb-1"
                        htmlFor="role"
                      >
                        Role
                      </label>
                      <div className="col-xs-9">
                        <div className="disable_field">
                          <input
                            type="text"
                            className="form-control cform-control lock_icon"
                            name="role"
                            value={getProfileDataResponse?.roleName}
                            disabled
                          />
                          <span
                            className="pointer"
                            dangerouslySetInnerHTML={{
                              __html: CustomIcons.lock,
                            }}
                          ></span>
                        </div>
                        <div className="error"></div>
                      </div>
                    </div>
                    {getProfileDataResponse?.allowPwdChange ? (
                      <>
                        <hr />
                        <h5>Password</h5>
                        <div className="buttons profiles">
                          <Link
                            className="default_btn"
                            data-bs-toggle="modal"
                            to=""
                            onClick={() => {
                              setShowChangePasswordModel(true)
                            }}
                            role="button"
                          >
                            Change password
                          </Link>
                        </div>{" "}
                      </>
                    ) : null}
                    <hr />
                    <h5>Danger zone</h5>
                    <div className="buttons profiles">
                      <Link
                        className="delete_btn"
                        data-bs-toggle="modal"
                        to=""
                        onClick={() => {
                          setShowDelete(true)
                        }}
                        role="button"
                      >
                        Delete my account
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDelete && (
        <Modal
          show={showDelete}
          className="modal-dark remove-profile sap"
          aria-labelledby="delete_user"
          centered
        >
          <Modal.Header
            onHide={handleDelete}
            closeButton={true}
            closeVariant="white"
            className="modal-header"
          >
            <Modal.Title className="modal-title">Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="white_color">
              Are you sure you want to delete your account?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer-left text-left">
              <button
                className="btn btn-submit"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={deleteAccount}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-fclose"
                onClick={handleDelete}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {showChangePasswordModel && (
        <ChangePassword
          onClose={closeModel}
          userId={getProfileDataResponse.id}
        />
      )}

      {showCropModel && selectedImage !== null && (
        <CropUploadPhoto
          onClose={closeCropModel}
          file={selectedImage}
          userId={getProfileDataResponse.id}
        />
      )}
      {showRemovePhotoModel && <RemovePhoto onClose={closeRemovePhotoModel} />}
    </>
  )
}
export default TrainerProfile

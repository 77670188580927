import { useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useParams } from "react-router"
import TrainerReviewed from "./Reviewed"
import TrainerToReview from "./ToReview"

const VideoFeedback = () => {
  const {type } = useParams()
  const [currentTab, setCurrentTab] = useState(type === "reviewed" ? "reviewed" :"to_review")


  return (
    <>
      <div className="aside-content-wrapper white_body video-review-tabs">
        <div className="container-fluid">
          <div className="model_content_fit_screen">
            <div className="modal-content">
              <div className="tabbar_head">
                <Tabs
                  defaultActiveKey="to_review"
                  className="nav nav-pills"
                  activeKey={currentTab}
                  onSelect={(k) => setCurrentTab(k)}
                >
                  <Tab
                    eventKey="to_review"
                    title="To review"
                    className="nav-item"
                  >
                    {currentTab === "to_review" ? <TrainerToReview /> : null}
                  </Tab>
                  <Tab
                    eventKey="reviewed"
                    title="Reviewed"
                    className="nav-item"
                  >
                    {currentTab === "reviewed" ? <TrainerReviewed /> : null}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default VideoFeedback

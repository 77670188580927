import { useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import { useNavigate, useOutletContext, useParams } from "react-router"
import { Link } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import clientService from "../../../services/service"
import QuestionBank from "./QuestionBank"

const PickLectures = () => {
  const { setExtraClass } = useOutletContext()
  const { courseId } = useParams()
  const [guideInfo, setGuideInfo] = useState({})
  const [selectedWeek, setSelectedWeek] = useState([])
  const inputEl = useRef(null)
  const navigate = useNavigate()
  const alert = useAlert()

  useEffect(() => {
    setExtraClass("pick_lectures")
    return () => {
      setExtraClass("")
    }
  }, [])

  useEffect(() => {
    getGuides()
  }, [courseId])

  const getGuides = async () => {
    const res = await clientService.post(
      `StudentTestYourself/GetCourseGuides?courseId=${courseId}`
    )
    setGuideInfo(res.data)
  }

  const createTest = () => {
    if(selectedWeek?.length > 0){
      navigate(`/course/test-yourself/${courseId}/lecture/question-bank`, { state: selectedWeek })
    }else{
      alert.error("Please pick atleast one lesson")
    }
  }

  useEffect(() => {
    if (inputEl) {
      if (
        selectedWeek.length == 0 ||
        selectedWeek.length == guideInfo?.guides?.length
      ) {
        inputEl.current.indeterminate = false
      } else {
        inputEl.current.indeterminate = true
      }
    }
  }, [selectedWeek, inputEl])
  return (
    <>
      <div className="aside-content-wrapper random_test pick_lectures">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/course/test-yourself/${courseId}`} className="text-light">
                        Random test
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Pick lectures
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12 col-sm-8">
                  <div className="full select_field">
                    <div className="form-group">
                      <input
                        ref={inputEl}
                        className="form-check-input"
                        type="checkbox"
                        value="remember"
                        id="check_all"
                        checked={
                          selectedWeek.length != 0 &&
                          selectedWeek.length == guideInfo?.guides?.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const ss = []
                            for (var i = 0; i < guideInfo?.guides?.length; i++) {
                              ss.push(guideInfo?.guides[i].courseGuideId)
                            }
                            setSelectedWeek(ss)
                          } else {
                            setSelectedWeek([])
                          }
                        }}
                      />{" "}
                      <label htmlFor="check_all">Select all</label>
                    </div>
                    <p className="light_text">
                      {/* Picked {selectedWeek?.length} of {guideInfo?.totalGuide} weeks */}
                      {selectedWeek?.length} of {guideInfo?.totalGuide} Lessons Selected
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <p className="clear-all"><a className="light_text" onClick={() => {setSelectedWeek([])}}>
                  Clear All
                </a></p>
              </div>
              
              
              
              </div>
              <div className="full">
                <div className="radio_button_section">
                  {guideInfo?.guides && guideInfo?.guides.length > 0
                    ? guideInfo?.guides.map((guide, index) => {
                        return (
                          <>
                            <div
                              class={
                                guide?.isCompleted
                                  ? "form-check custom_check"
                                  : "form-check custom_check lock_checkbox"
                              }
                            >
                              <div className="form-group">
                                {guide?.isCompleted ? (
                                  <input
                                    className="form-check-input mt-1"
                                    type="checkbox"
                                    value="remember"
                                    id={guide.courseGuideId}
                                    checked={
                                      selectedWeek.indexOf(
                                        guide.courseGuideId
                                      ) >= 0
                                    }
                                    onChange={(e) => {
                                      const ss = [...selectedWeek]
                                      if (
                                        selectedWeek.indexOf(
                                          guide.courseGuideId
                                        ) >= 0
                                      ) {
                                        ss.splice(
                                          selectedWeek.indexOf(
                                            guide.courseGuideId
                                          ),
                                          1
                                        )
                                      } else {
                                        ss.push(guide.courseGuideId)
                                      }
                                      setSelectedWeek(ss)
                                    }}
                                  />
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.lock,
                                    }}
                                  ></span>
                                )}
                                <label for={guide.courseGuideId}>
                                  {guide?.guideName}
                                </label>
                              </div>
                            </div>
                          </>
                        )
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="guide-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <button className="main_btn_default blue_color float-end" 
                // to={`/course/test-yourself/${courseId}/lecture/question-bank`}
                onClick={createTest}
                >
                  Create test
                </button>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
export default PickLectures

import { useEffect } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { authState, deleteProfilePicture, setDeletedProfilePicResponse } from "../../store/slices/authSlice"


const RemovePhoto = ({ onClose }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { deletedProfilePic } = useSelector(authState)

  useEffect(() => {
    if (
      deletedProfilePic.code === 500 ||
      deletedProfilePic.status === false
    ) {
      alert.error(deletedProfilePic.message)
      dispatch(setDeletedProfilePicResponse({}))
    } else if (
      deletedProfilePic.code === 200 ||
      deletedProfilePic.status === true
    ) {
      onClose()
      alert.success(deletedProfilePic.message)
      dispatch(setDeletedProfilePicResponse({}))
    }
  }, [alert, deletedProfilePic])

  const handleSubmit = ({setErrors}) => {
    dispatch(deleteProfilePicture(setErrors))
}
  return (
    <>
      <Modal
        show={true}
        id="remove_profile_image"
        className="modal-dark remove-profile sap"
        aria-labelledby="remove_profile_image"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            Remove profile image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p>Are you sure you want to remove profile image?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Yes, I'm sure
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RemovePhoto

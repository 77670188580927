import React, { useCallback, useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import SideBar from "../components/Shared/SideBar"
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import AdminHeader from "../common/dashboard/Admin"
import { useDispatch, useSelector } from "react-redux"
import { authState, getProfile } from "../store/slices/authSlice"

import io from "socket.io-client"
import RemovedFromCourse from "../pages/models/RemovedFromCourse"
import config from "../config"
import { appState } from "../store/slices/appSlice"

const socket = io(config.socketUrl)
let uId = "";
let lessonId = "";
let courseId = "";
let guideId = "";
let clocation = "";
const HIDE_SIDE_BAR_PAGE_LIST = ["/dashboard", "/courses", "/course/preview"]
const StudentBody = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [extraClass, setExtraClass] = useState("")
  const [showSideBar, setShowSideBar] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [isPreEvaluationEnabled, setIsPreEvaluationEnabled] = useState(false);
  const [isFinalEvaluationChanged, setIsFinalEvaluationChanged] = useState(false);

  const location = useLocation()
  // const alert = useAlert();
  const dispatch = useDispatch()

  const isUserLoggedIn = true
  const { getProfileDataResponse } = useSelector(authState)
  const { fullScreen } = useSelector(appState)
  uId = getProfileDataResponse?.id
  guideId = params?.guidId
  lessonId = params?.lessonId
  courseId = params?.courseId

  const handleInviteAccepted = useCallback((data) => {
     if (uId === data.userId && data.courseId === courseId) {
      setShowRemoveModal(true)
      navigate("/")
    } else if (clocation.indexOf(`/course/quiz/${courseId}`) === 0) {
      setIsPreEvaluationEnabled(data.isPreEvaluationEnabled);
      setIsFinalEvaluationChanged(false);
      setShowUpdateModal(true)
      navigate(`/course/learning-guide/${courseId}`)
    } else if (`/course/learning-guide/${courseId}` == clocation) {
      window.location.reload()
    } else if (data.firstGuideId && data.firstGuideId === guideId) {
      setIsPreEvaluationEnabled(data.isPreEvaluationEnabled);
      setShowUpdateModal(true)
      setIsFinalEvaluationChanged(false);
      navigate(`/course/learning-guide/${courseId}`)
    }else if(data.courseId === courseId){
      window.location.reload()
    }
  }, [])

  const handleFinalEvaluation = useCallback((data) => {
   if (clocation.indexOf(`/course/final-evalution/${courseId}`) === 0) {
     setIsFinalEvaluationChanged(true);
     setIsPreEvaluationEnabled(false)
     setShowUpdateModal(true)
     
     navigate(`/course/learning-guide/${courseId}`)
   } else if (`/course/learning-guide/${courseId}` == clocation) {
     window.location.reload()
   } else if(data.courseId === courseId){
     window.location.reload()
   }
 }, [])

  useEffect(() => {
    guideId = params?.guidId
    lessonId = params?.lessonId
    courseId = params?.courseId
    clocation = location.pathname
  }, [location.pathname])

  useEffect(() => {
    socket.on("connect", () => {})
    socket.on("disconnect", () => {})
    socket.on("update", handleInviteAccepted)
    socket.on("FinalEvaluation", handleFinalEvaluation)

    return () => {
      socket.off("connect")
      socket.off("disconnect")
      socket.off("pong")
    }
  }, [])

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (
      (location.pathname.indexOf("/course/") >= 0 &&
        location.pathname.indexOf("/course/preview") == -1) ||
      location.pathname == "/student/profile" ||
      location.pathname == "/prize-locker" ||
      location.pathname == "/faqs"
    ) {
      setShowSideBar(true)
    } else {
      setShowSideBar(false)
    }
  }, [location])

  if (!getProfileDataResponse.id) {
    return <div>Loading</div>
  }
  if (getProfileDataResponse.roleName != "Student") {
    if (getProfileDataResponse.roleName == "Reviewer")
      return <Navigate to="/reviewer" />
    else if (getProfileDataResponse.roleName == "Trainer")
      return <Navigate to="/trainer" />
    else return <Navigate to="/404" />
  }

  return (
    <React.Fragment>
      <div className="main overflow_scroll">
        <AdminHeader
          userRole="student"
          fullScreen={fullScreen}
        />
        <div className="set-header-responsive">
        <Container fluid>
          <Row>
            {showSideBar ? (
              <SideBar userRole="student" fullScreen={fullScreen} />
            ) : null}
            <div
              className={`random_test ${extraClass} ${
                fullScreen ? "full-screen" : ""
              }`}
            >
              <Outlet context={{ setExtraClass, setShowSideBar }} />
            </div>
          </Row>
        </Container>
        </div>
      </div>
      {showRemoveModal ? (
        <RemovedFromCourse
          body={
            "You have no longer access for this course.If you have any concern please contact admin."
          }
          onClose={() => {
            setShowRemoveModal(false)
          }}
        />
      ) : null}

      {showUpdateModal ? (
        <RemovedFromCourse
          body={
            isFinalEvaluationChanged ?
            "Final Evaluation has been disabled for this course"
            :
            isPreEvaluationEnabled? 
            "Pre and Post Evaluation has been enabled for this course, please complete Pre Evaluation before continuing.":
            "Pre and Post Evaluation has been disabled for this course."

          }
          onClose={() => {
            setShowUpdateModal(false)
            // window.location.reload()
            // navigate(`/course/learning-guide/${courseId}`)
          }}
        />
      ) : null}
    </React.Fragment>
  )
}
export default StudentBody

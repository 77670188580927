import axiosInterceptors from "../store/axiosInterceptors"
import { setErrorMessage } from "../store/slices/appSlice";
import store from "./../store/store";

const clientService = {
  get: async (endPoint, setErrors) => {
    try {
      return await axiosInterceptors.get(endPoint)
    } catch (e) {
      
      
      if (e.response.status === 401 || e.response.status === 500) {
        window.location.href = "/login"
      }else if(e.response.status === 405) {
        window.location.href = "/"
      }else if (e.response.data.errors) {
        setErrors(e.response.data.errors)
        return ""
      } else {
        store.dispatch(setErrorMessage(e.response.data.message))
        return e.response.data.message
      }  
    }
  },
  post: async (endPoint, data, setErrors) => {
    try {
      return await axiosInterceptors.post(endPoint, data)
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 500) {
        window.location.href = "/login"
      }
      // else if(e.response.status === 400) {
      //   window.location.href = "/"
      // }
      else if (e.response.data.errors) {
        setErrors(e.response.data.errors)
        return ""
      } else {
        store.dispatch(setErrorMessage(e.response.data.message))
        return e.response.data.message
      }
      // if (e.response.status === 401 || e.response.status === 500) {
      //   window.location.href = "/login"
      // }
    }
  },
  put: async (endPoint, data, setErrors) => {
    try {
      return await axiosInterceptors.put(endPoint, data)
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 500) {
        window.location.href = "/login"
      }
      // else if(e.response.status === 400) {
      //   window.location.href = "/"
      // }
      else if (e.response.data.errors) {
        setErrors(e.response.data.errors)
        return ""
      } else {
        return e.response.data.message
      }
      // if (e.response.status === 401 || e.response.status === 500) {
      //   window.location.href = "/login"
      // }
    }
  },
  remove: async (endPoint, setErrors) => {
    try {
      return await axiosInterceptors.delete(endPoint)
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 500) {
        window.location.href = "/login"
      }else if(e.response.status === 400) {
        window.location.href = "/"
      }else if (e.response.data.errors) {
        setErrors(e.response.data.errors)
        return ""
      } else {
        return e.response.data.message
      }
      // if (e.response.status === 401 || e.response.status === 500) {
      //   window.location.href = "/login"
      // }
    }
  },
}

export default clientService

import { useEffect } from "react"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import Header from "../components/Shared/Header"
import { authState, setValidateEmailLinkResponse, validateEmailLink } from "../store/slices/authSlice"

const ValidateEmail = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const [searchParams, setSearchParams] = useSearchParams()
  const { validateEmail } = useSelector(authState)
  useEffect(() => {
    var data = {
      activationCode: searchParams.get("activationCode"),
    }
    dispatch(validateEmailLink(data))
  }, [dispatch])

  useEffect(() => {
    if (
      validateEmail.code === 500 &&
      validateEmail.status === false
    ) {
      alert.error(validateEmail.message)
      dispatch(setValidateEmailLinkResponse({}))
    } else if (
      validateEmail.code === 200 &&
      validateEmail.status === false
    ) {
      alert.success(validateEmail.message)
      dispatch(setValidateEmailLinkResponse({}))
    }
  }, [validateEmail])
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-12">
              <div className="sucess_msg_full forgot-main-wrapper mx-auto">
                <div className="forgot-caption">
                  {/* <h1 className="title">Help is on the way!</h1> */}
                </div>
                <div className="forgot-inner-wrapper">
                  <p className="mt-4 large">
                    Thank you. Your Account Verified Successfully.
                  </p>
                </div>
                <div className="">
                  <div className="loing-footer-caption">
                    <h5 className="login-footer-links pt-4 pb-4">
                      <Link to="/login" className="text-link">
                        Login
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ValidateEmail

import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const courseConfigInitialState = {
  getConfigurationResponse: {
    testYourSelfEnabled: true,
    flashcardEnabled: true,
    randomizerEnabled: true,
    loadingScreenEnabled: true,
    availableAfterCompletingGuide: null,
  },
  getCourseConfigsResponse: {},
  getCourseConfigByIdResponse: {},
  updatedCourseConfigsResponse: {},
  publishedCourseConfigsResponse: {},
  duplicateCourseConfigsResponse: {},
  deleteCourseConfigsResponse: {},
  reviewerResp: {},
  trainerResp: {},
  loadingScreens: {},
  connectTips: {},
  randomTips: {},
  deleteResp: {},
  trainers: [],
  reviewers: [],
  learningWeeks: [],
  reviewerAssigned: [],
  trainerAssigned: [],
}

export const courseConfigSlice = createSlice({
  name: "courseConfig",
  initialState: courseConfigInitialState,
  reducers: {
    setGetConfigurationResponse: (state, action) => {
      state.getConfigurationResponse = action.payload.data
    },
    setTrainerResponse: (state, action) => {
      state.trainerResp = action.payload
    },
    setReviewerResponse: (state, action) => {
      state.reviewerResp = action.payload
    },
    setReviewerListResponse: (state, action) => {
      state.reviewers = action.payload.data
    },
    setTrainerListResponse: (state, action) => {
      state.trainers = action.payload.data
    },
    setReviewerAssignedListResponse: (state, action) => {
      state.reviewerAssigned = action.payload.data
    },
    setTrainerAssignedListResponse: (state, action) => {
      state.trainerAssigned = action.payload.data
    },
    setLoadingScreenResponse: (state, action) => {
      state.loadingScreens = action.payload.data
    },
    setConnectTipsResponse: (state, action) => {
      state.connectTips = action.payload
    },
    setRandomTipsResponse: (state, action) => {
      state.randomTips = action.payload
    },
    setDeleteLoadingScreenResponse: (state, action) => {
      state.deleteResp = action.payload
    },
    setLearningWeeks: (state, action) => {
      state.learningWeeks = action.payload.data
    },
  },
})

export const getConfiguration = (id) => async (dispatch) => {
  const response = await clientService.get(
    "StudentCourse/GetCourseSettings?courseId=" + id
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setGetConfigurationResponse(response))
  }
}

export const saveConfiguration = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/SaveCourseConfiguration",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setGetConfigurationResponse(response))
  }
}

export const assignTrainer = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/AssignTrainer",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setTrainerResponse(response))
    dispatch(trainerAssignedList(data))
  }
}

export const assignReviewer = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/AssignReviewer",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerResponse(response))
    dispatch(reviewerAssignedList(data))
  }
}

export const reviewerList = (data) => async (dispatch) => {
  const response = await clientService.get(
    "CourseConfiguration/GetReviewerList",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerListResponse(response))
  }
}

export const trainerList = (data) => async (dispatch) => {
  const response = await clientService.get(
    "CourseConfiguration/GetTrainerList",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setTrainerListResponse(response))
  }
}

export const reviewerAssignedList = (data) => async (dispatch) => {
  const response = await clientService.get(
    `CourseConfiguration/GetAssignedReviewer?courseId=${data.courseId}`,
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerAssignedListResponse(response))
  }
}

export const trainerAssignedList = (data) => async (dispatch) => {
  const response = await clientService.get(
    `CourseConfiguration/GetAssignedTrainer?courseId=${data.courseId}`,
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setTrainerAssignedListResponse(response))
  }
}

export const getLoadingScreen = (data) => async (dispatch) => {
  const response = await clientService.get(
    `CourseConfiguration/GetLoadingScreen?courseId=${data.courseId}`,
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setLoadingScreenResponse(response))
  }
}

export const getLearningWeeks = (data) => async (dispatch) => {
  const response = await clientService.get(
    `CourseConfiguration/GetLearningWeeks?courseId=${data.courseId}`,
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setLearningWeeks(response))
  }
}

export const saveConnectTips = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/SaveloadingScreenConnectTips",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setConnectTipsResponse(response))
    dispatch(getLoadingScreen(data));
  }
}

export const saveRandomTips = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/SaveLoadingScreenRandomTips",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setRandomTipsResponse(response))
  }
}

export const deleteLoadingScreen = (data) => async (dispatch) => {
  const response = await clientService.post(
    "CourseConfiguration/DeleteLoadingScreenContent",
    data
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeleteLoadingScreenResponse(response))
  }
}

export const {
  setGetConfigurationResponse,
  setReviewerResponse,
  setTrainerResponse,
  setReviewerListResponse,
  setTrainerListResponse,
  setReviewerAssignedListResponse,
  setTrainerAssignedListResponse,
  setConnectTipsResponse,
  setRandomTipsResponse,
  setLoadingScreenResponse,
  setDeleteLoadingScreenResponse,
  setLearningWeeks
} = courseConfigSlice.actions

export const courseConfigState = (state) => {
  return state.courseConfig
}

export default courseConfigSlice.reducer

import { useAlert } from "react-alert"
import { Link } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import clientService from "../../../services/service"

const QuestionProgress = ({
  lessonInfo,
  lessonId,
  prev,
  next,
  getCourseInfo,
  currentIndex,
  totalLength
}) => {
  const alert = useAlert()

  const markAsComplete = async () => {
    const data = {}
    const res = await clientService.post(
      `StudentCourse/MarkCompleteLesson?courseGuideLessonId=${lessonId}`,
      data
    )

    if (res.code < 300) {
      alert.success(res.message)
    }
    getCourseInfo()
  }
  return (
    <div className="pagination_section_page">
      <div className="d-flex space_between align-items-center">
        <p>
        {/* {currentIndex > totalLength + 1 ?  */}
          <Link to="" onClick={prev} className="prev">
            <span
              dangerouslySetInnerHTML={{ __html: CustomIcons.previous }}
            ></span>
            previous
          </Link>
           {/* : null} */}
        </p>
        <p>
          {!lessonInfo.iscompleted ? (
            <Link
              to=""
              className="main_btn_default blue_color"
              onClick={markAsComplete}
            >
              Mark complete
            </Link>
          ) : null}
        </p>
        <p>
        {currentIndex < totalLength - 1 ? 

          <Link to="" onClick={next} className="next">
            next
            <span dangerouslySetInnerHTML={{ __html: CustomIcons.next }}></span>
          </Link>
           : null}
        </p>
      </div>
    </div>
  )
}

export default QuestionProgress

import { Modal } from "react-bootstrap"
import { Formik } from "formik"
import clientService from "../../services/service"
import { useAlert } from "react-alert"
import { useState } from "react"

const LeaveFeedback = ({ onClose }) => {
  const alert = useAlert()
  const [showSuccessModel, setShowSuccessModel] = useState(false)

  const handleFormSubmit = async (values) => {
    var data = {
      subject: values.subject,
      feedback: values.feedback,
    }
    const res = await clientService.post("ReportError/AddReportError", data)
    if (res.status == true && res.code == 200) {
      // alert.success(res.message)
      setShowSuccessModel(true)
    } else {
      alert.error(res.message)
    }
    // onClose();
  }

  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="delete_user"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Leave Feedback</Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{ subject: "", feedback: "" }}
          validate={(values) => {
            const errors = {}
            if (!values.subject) {
              errors.subject = "Required field"
            }
            if (!values.feedback) {
              errors.feedback = "Required field"
            }
            return errors
          }}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <form method="post" className="feedback_form">
                  <div className="mb-3">
                    <label htmlFor="subject" className="form-label">
                      Subject
                    </label>

                    <input
                      type="text"
                      className="form-control white_color"
                      name="subject"
                      id="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Subject"
                      value={values.subject}
                    />
                    <div className="error-message">
                      {errors.subject && touched.subject && errors.subject}
                    </div>
                  </div>
                  <div className="mb-0">
                    <label htmlFor="message-text" className="col-form-label">
                      Feedback
                    </label>
                    <textarea
                      className="form-control white_color"
                      id="message-text"
                      placeholder="Feedback"
                      name="feedback"
                      maxLength={2000}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.feedback}
                    ></textarea>
                    <div className="error-message">
                      {errors.feedback && touched.feedback && errors.feedback}
                    </div>
                    <label className="control-label col-xs-3 mt-1">
                      {values?.feedback ? values?.feedback.length : 0} / 2000
                      characters
                    </label>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer-left text-left">
                  <button
                    className="btn btn-submit"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-fclose"
                    onClick={onClose}
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>

      {showSuccessModel && (
        <Modal
          show={showSuccessModel}
          className="modal-dark remove-profile sap change-password-success"
          aria-labelledby="feedbackssuccess"
          centered
        >
          <Modal.Body>
            <p className="white_color">Your feedback was succesfully sent</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer-left text-left">
              <button
                className="main_btn_default"
                onClick={() => {
                  setShowSuccessModel(false)
                  onClose()
                }}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default LeaveFeedback

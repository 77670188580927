import Header from "../components/Shared/Header"

const Success = () => {
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-12">
              <div className="sucess_msg_full forgot-main-wrapper mx-auto">
                <div className="forgot-caption">
                  <h1 className="title">Help is on the way!</h1>
                </div>
                <div className="forgot-inner-wrapper">
                  <p className="mt-4 large">
                    If a user account is found, we will send an email with more
                    information on how to reset your password.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Success

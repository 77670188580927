import Vimeo from "@u-wave/react-vimeo";

const VideoPlayer = ({
  videoId,
  autoplay,
  className,
  isVideoAvailable,
  onEnd,
  background
}) => {
  if (!videoId) {
    return null;
  }

  return (
    <>
      {isVideoAvailable ? (
        <Vimeo
          video={videoId}
          autoplay={autoplay || false}
          className={className}
          // width={"100%"}
          paused={false}
          showTitle={false}
          responsive={true}
          background={background || false}
          onEnd={() => {
            if (onEnd) {
              onEnd();
            }
          }}
        />
      ) : (
        <div className="light_text">
          We are processing this video, kindly check in few moments!.
        </div>
      )}
    </>
  );
};

export default VideoPlayer;

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  courseConfigState,
  getConfiguration,
} from "../../store/slices/courseConfigSlice"
import { useLocation, useNavigate } from "react-router-dom"

const RolePlay = () => {
  const courseId = localStorage.getItem("courseId")
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const { getConfigurationResponse } = useSelector(courseConfigState)

  useEffect(() => {
    if (courseId) {
      dispatch(getConfiguration(courseId))
    } else {
      navigate("/courses")
    }
  }, [courseId])


  if (getConfigurationResponse?.replayIFrameURL == null) {
    navigate(`/course/learning-guide/${courseId}`)
  }

  return (
    <div className="row">
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div class="overview-area resources-content mb-5">
            <div
              className="col-md-12 minus_blank_space mt-5"
            // style={"height: 500px !important;"}
            //   style={{ height: "500px" }}
            >
              <iframe
                width="100%"
                src={getConfigurationResponse?.replayIFrameURL}
                title="Combat Sales Roleplay"
                // style={{ height: "-webkit-fill-available" }}
                style={{ minHeight: "550px" }}
                allow="camera;microphone"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolePlay

import { useEffect, useState } from "react"
import VideoPlayer from "../../../common/VideoPlayer"
import clientService from "../../../services/service"

const VideoQuestion = ({ lessonId }) => {
  const [lessonInfo, setLessonInfo] = useState({})

  useEffect(() => {
    getCourseLession()
  }, [lessonId])

  const getCourseLession = async () => {
    const res = await clientService.get(
      `StudentCourse/GetLessionContent?courseGuideLessonId=${lessonId}`
    )
    setLessonInfo(res.data)
  }

  return (
    <div className="modal-body">
      <div className="resources_Content">
        {/* <video src={lessonInfo.video} controls className="img-fuild width-100" /> */}
        <VideoPlayer
          className="img-fluid height-100"
          videoId={lessonInfo?.vimeoLink}
          isVideoAvailable={
            lessonInfo?.lessonType === 4
              ? lessonInfo?.videoAnswerDetail?.studentVideoLesson
                  ?.isVideoAvailable
              : lessonInfo?.isVideoAvailable
          }
        />
        <div dangerouslySetInnerHTML={{ __html: lessonInfo.description }}></div>
      </div>
    </div>
  )
}
export default VideoQuestion

import { Link } from "react-router-dom"

const HeaderPrizeLoker = ({ loggedInuser }) => {
  return (
    <>
      <div className="notification-main-area">
        <ul className="reward_btn">
          <li className="item dropdown">
            <Link
              to={"/prize-locker"}
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3438 1.75H16.1875V0.8125C16.1875 0.709375 16.1031 0.625 16 0.625H4C3.89687 0.625 3.8125 0.709375 3.8125 0.8125V1.75H1.65625C1.38275 1.75 1.12044 1.85865 0.927046 2.05205C0.733649 2.24544 0.625 2.50775 0.625 2.78125V6.25C0.625 8.16484 2.03125 9.75625 3.86406 10.0469C4.22734 12.7703 6.41406 14.9148 9.15625 15.2148V17.6805H4.5625C4.14766 17.6805 3.8125 18.0156 3.8125 18.4305V19.1875C3.8125 19.2906 3.89687 19.375 4 19.375H16C16.1031 19.375 16.1875 19.2906 16.1875 19.1875V18.4305C16.1875 18.0156 15.8523 17.6805 15.4375 17.6805H10.8438V15.2148C13.5859 14.9148 15.7727 12.7703 16.1359 10.0469C17.9688 9.75625 19.375 8.16484 19.375 6.25V2.78125C19.375 2.50775 19.2663 2.24544 19.073 2.05205C18.8796 1.85865 18.6173 1.75 18.3438 1.75ZM2.3125 6.25V3.4375H3.8125V8.30313C3.37714 8.16406 2.99723 7.89025 2.72762 7.52122C2.45801 7.15219 2.31264 6.70703 2.3125 6.25ZM14.5 9.25C14.5 10.4008 14.0523 11.4859 13.2367 12.2992C12.4211 13.1148 11.3383 13.5625 10.1875 13.5625H9.8125C8.66172 13.5625 7.57656 13.1148 6.76328 12.2992C5.94766 11.4836 5.5 10.4008 5.5 9.25V2.3125H14.5V9.25ZM17.6875 6.25C17.6875 7.21094 17.057 8.02656 16.1875 8.30313V3.4375H17.6875V6.25Z"
                  fill="#E4E5EA"
                />
              </svg>
             {loggedInuser?.prizeCount !== 0 ? <span className="btn__badge">{loggedInuser?.prizeCount}</span> : null}
            </Link>
            <ul className="dropdown-menu">
              <div className="point-title">
                <p>You have {loggedInuser?.prizeCount} available prizes</p>
              </div>
              {loggedInuser?.prizeCount && loggedInuser?.prizes.length > 0
                ? loggedInuser?.prizes.map((item, index) => {
                    return (
                      <li key={`prize_count_name_${index}`} className="mb-3">
                        <Link
                          to="/prize-locker"
                          className="d-flex align-items-center"
                        >
                          <div className="img-icon me-2">
                            <img src={item?.image} alt="" />
                          </div>
                          <div className="point-caption">
                            <p>{item?.name}</p>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                : null}
            </ul>
          </li>
        </ul>
      </div>
    </>
  )
}
export default HeaderPrizeLoker

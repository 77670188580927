import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import Header from "../components/Shared/Header"
import { useDispatch, useSelector } from "react-redux"
import {
  authState,
  checkEmailExists,
  register,
  setNewUserData,
  setEmailExistsResponse,
  loginWithGoogle,
  sendEmailVerificationLink,
  setLoginResponse,
} from "../store/slices/authSlice"
import { useAlert } from "react-alert"
import config from "../config"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google"
import { GoogleOAuthProvider } from "@react-oauth/google"

const SignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const alert = useAlert()
  const { newUser, emailResponse, loginResponse } = useSelector(authState)
  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }
  useEffect(() => {
    if (newUser.code === 400 || newUser.status === false) {
      alert.error(newUser.message)
      dispatch(setNewUserData({}))
    } else if (newUser.code === 200 || newUser.status === true) {
      alert.success(newUser.message)
      dispatch(setNewUserData({}))
      navigate("/login")
    }
  }, [newUser])

  useEffect(() => {
    if (loginResponse.status === 400 || loginResponse.status === false) {
      alert.error(loginResponse.message)
      dispatch(setLoginResponse({}))
    } else if (loginResponse.code === 1 || loginResponse.status === true) {
      alert.success(loginResponse.message)
      dispatch(setLoginResponse({}))
      navigate("/dashboard")
    }
  }, [loginResponse, alert])
  // useEffect(() => {
  //   if (emailResponse.code === 200 && emailResponse.status === false) {
  //     alert.error(emailResponse.message)
  //     dispatch(setEmailExistsResponse({}))
  //   }
  // }, [emailResponse])

  const handleFormSubmit = (values) => {
    // var data = {
    //   email : values.email
    // }
    dispatch(register(values))
    // dispatch(sendEmailVerificationLink(data))
  }

  const validateEmail = (values, setErrors) => {
    var data = {
      email: values,
    }
    dispatch(checkEmailExists(data, setErrors))
  }

  const responseGoogle = (response) => {
    if (response.access_token && response.access_token) {
      const data = {
        provider: response.access_token,
        idToken: response.access_token,
      }
      dispatch(loginWithGoogle(data))
    }
  }

  const doGoogleLogin = useGoogleLogin({
    isSignedIn: true,
    accessType: "offline",
    onSuccess: responseGoogle,
  })

  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="register-main-wrapper mx-auto">
                <div className="register-caption">
                  <h1 className="title">
                    Where Opportunity{" "}
                    <b>
                      Knocks
                      {/* <span>®</span> */}
                    </b>
                  </h1>
                </div>
                <div className="register-inner-wrapper">
                  {/* <div className="register-google-area1"> */}
                  {/* 
                  <GoogleLogin
                      clientId={config.GoogleClientId}
                      render={renderProps => ( */}
                  {/* <button
                      onClick={doGoogleLogin}
                      disabled={renderProps.disabled}
                      className="btn btn-primary btn-google"
                    >
                      <span className="pe-1">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.537 6.69434H14V6.66667H8V9.33334H11.7677C11.218 10.8857 9.741 12 8 12C5.791 12 4 10.209 4 8C4 5.791 5.791 4 8 4C9.01967 4 9.94734 4.38467 10.6537 5.013L12.5393 3.12734C11.3487 2.01767 9.756 1.33334 8 1.33334C4.31834 1.33334 1.33334 4.31834 1.33334 8C1.33334 11.6817 4.31834 14.6667 8 14.6667C11.6817 14.6667 14.6667 11.6817 14.6667 8C14.6667 7.553 14.6207 7.11667 14.537 6.69434Z"
                            fill="#FFC107"
                          />
                          <path
                            d="M2.10201 4.897L4.29234 6.50334C4.885 5.036 6.32034 4 8 4C9.01967 4 9.94734 4.38467 10.6537 5.013L12.5393 3.12734C11.3487 2.01767 9.756 1.33334 8 1.33334C5.43934 1.33334 3.21867 2.779 2.10201 4.897Z"
                            fill="#FF3D00"
                          />
                          <path
                            d="M8 14.6667C9.722 14.6667 11.2867 14.0077 12.4697 12.936L10.4063 11.19C9.71452 11.7161 8.86915 12.0007 8 12C6.266 12 4.79367 10.8943 4.239 9.35132L2.065 11.0263C3.16834 13.1853 5.409 14.6667 8 14.6667Z"
                            fill="#4CAF50"
                          />
                          <path
                            d="M14.537 6.69434H14V6.66667H8V9.33334H11.7677C11.5047 10.0721 11.0311 10.7177 10.4053 11.1903L10.4063 11.1897L12.4697 12.9357C12.3237 13.0683 14.6667 11.3333 14.6667 8.00001C14.6667 7.553 14.6207 7.11667 14.537 6.69434Z"
                            fill="#1976D2"
                          />
                        </svg>
                      </span>&nbsp;Register with Google
                    </button> */}
                  {/* )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onError={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    /> */}
                  {/* </div> */}
                  {/* <div className="register-spacer-area">
                    <span>Or</span>
                  </div> */}
                  <p className="mt-4">Create account with your email address</p>
                  <div className="register-form-area">
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        acceptsTerms: "",
                      }}
                      validate={(values) => {
                        const errors = {}
                        if (!values.firstName) {
                          errors.firstName = "Required field"
                        } else if (!/^[aA-zZ\s]+$/i.test(values.firstName)) {
                          errors.firstName = "Please enter valid details"
                        }
                        if (!values.lastName) {
                          errors.lastName = "Required field"
                        } else if (!/^[aA-zZ\s]+$/i.test(values.lastName)) {
                          errors.lastName = "Please enter valid details"
                        }
                        if (!values.email) {
                          errors.email = "Required field"
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address"
                        }
                        if (!values.password) {
                          errors.password = "Required field"
                        } else if (
                          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/.test(
                            values.password
                          )
                        ) {
                          errors.password =
                            "Password must be at least 8 characters long with both letters and numbers."
                        }
                        if (!values.acceptsTerms) {
                          errors.acceptsTerms =
                            "You must agree to the Terms of Service."
                        }
                        if (
                          !values.acceptsTerms ||
                          !values.password ||
                          !values.email ||
                          !values.lastName ||
                          !values.firstName
                        ) {
                          errors.all = "All fields are required"
                        }
                        return errors
                      }}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setErrors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form method="post" onSubmit={handleSubmit}>
                          <div className="form-group pb-1 mb-3">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="first_name"
                            >
                              First name
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="text"
                                className={
                                  errors.firstName && touched.firstName
                                    ? "form-control cform-control error-field"
                                    : "form-control cform-control"
                                }
                                id="first_name"
                                placeholder="First name"
                                name="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                              />
                              <div className="error-message">
                                {errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName}
                              </div>
                            </div>
                          </div>
                          <div className="form-group pb-1 mb-3">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="last_name"
                            >
                              Last name
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="text"
                                className={
                                  errors.lastName && touched.lastName
                                    ? "form-control cform-control error-field"
                                    : "form-control cform-control"
                                }
                                id="last_name"
                                placeholder="Last name"
                                name="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                              />
                              <div className="error-message">
                                {errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName}
                              </div>
                            </div>
                          </div>
                          <div className="form-group pb-1 mb-3">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="inputEmail"
                            >
                              Email
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="text"
                                className={
                                  errors.email && touched.email
                                    ? "form-control cform-control error-field"
                                    : "form-control cform-control"
                                }
                                id="inputEmail"
                                placeholder="Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e)
                                  if (
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                      values.email
                                    )
                                  ) {
                                    validateEmail(values.email, setErrors)
                                  }
                                }}
                                value={values.email}
                              />
                              <div className="error-message">
                                {errors.email && touched.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <div className="row">
                              <div className="col-12">
                                <label
                                  className="control-label col-lg-12 col-xs-12 mb-1 pb-1 required_field"
                                  htmlFor="inputPassword"
                                >
                                  Password
                                </label>
                              </div>
                            </div>
                            <div className="col-xs-9">
                              <div className="input-group">
                                <input
                                  type={passwordType}
                                  className={
                                    errors.password && touched.password
                                      ? "form-control cform-control error-field"
                                      : "form-control cform-control border-right-0"
                                  }
                                  id="inputPassword"
                                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />

                                <span className="input-group-text white-icon">
                                  <span
                                    toggle="#password-field"
                                    className="fa fa-fw fa-eye field-icon toggle-password"
                                  >
                                    <FontAwesomeIcon
                                      className="pointer"
                                      onClick={togglePassword}
                                      icon={
                                        passwordType === "password"
                                          ? faEyeSlash
                                          : faEye
                                      }
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="error-message">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </div>
                            </div>
                          </div>
                          <div className="form-group checkbox_group mt-4 mb-3">
                            <div className="row">
                              <div className="col-sm-9">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="term_use"
                                  name="acceptsTerms"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.acceptsTerms}
                                />
                                &nbsp;
                                <label
                                  className="form-check-label white_color"
                                  htmlFor="term_use"
                                >
                                  I have read and agree to the
                                  <br />
                                  <Link to="/terms-condition">
                                    Terms of Use
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/privacy-policy">
                                    Customer Privacy Policy
                                  </Link>
                                  .
                                </label>
                                <div className="error-message">
                                  {errors.acceptsTerms &&
                                    touched.acceptsTerms &&
                                    errors.acceptsTerms}
                                </div>
                              </div>
                              <div className="error-message">
                                {errors.all && touched.all && errors.all}
                              </div>
                              <div className="col-sm-3">
                                <input
                                  type="submit"
                                  className="btn btn-primary btn_submit"
                                  value="Register"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="register-footer-wrapper mt-4">
                  <div className="loing-footer-caption">
                    <h5 className="login-footer-links pt-4 pb-4">
                      Already have an account!?{" "}
                      <Link to="/login" className="text-link">
                        Log in
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const LoginWithGoogle = () => {
  return (
    <GoogleOAuthProvider clientId={config.GoogleClientId}>
      <SignUp />{" "}
    </GoogleOAuthProvider>
  )
}
export default LoginWithGoogle

import { createSlice } from "@reduxjs/toolkit"

export const appInitialState = {
  loading: false,
  errorMessage: '',
  reloadSideBar:0, 
  fullScreen: false
}

export const appSlice = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setReloadSideBar: (state, action) => {
      state.reloadSideBar = state.reloadSideBar + 1;
    },
    setFullScreen :(state, action) => {
      state.fullScreen = action.payload
    }
  },
})

export const { setLoader, setErrorMessage, setReloadSideBar, setFullScreen } = appSlice.actions

export const appState = (state) => {
  return state.app
}

export default appSlice.reducer

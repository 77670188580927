import moment from "moment"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import clientService from "./../../../services/service"
const AvailablePrize = ({ setTotalScore }) => {
  const [prizeLocker, setPrizeLocker] = useState({})
  const [showGetPrize, setShowGetPrize] = useState(false)
  const [showCongrats, setShowCongrats] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(1000)
  const [studentPrizeLockerList, setStudentPrizeLockerList] = useState([])
  const [total, setTotal] = useState(0)
  const [type, setType] = useState(1)
  const alert = useAlert()

  const handelGetPrize = async () => {
    const data = {
      prizeLockerId: prizeLocker?.prizeLockerId,
    }

    const res = await clientService.post(
      "StudentPrizeLocker/RequestedPrizeByStudent",
      data
    )
    if (res.code == 200 && res.status == true) {
      setShowCongrats(true)
    } 
  }

  const getStudentPrizeLockerList = async () => {
    const data = {
      type: type,
      page: pageNumber,
      pageSize: perPage,
    }

    const res = await clientService.post(
      "StudentPrizeLocker/GetStudentPrizeLockerList",
      data
    )
    setStudentPrizeLockerList(
      pageNumber == 1
        ? res.data.availablePrizes
        : [...studentPrizeLockerList, ...res.data.availablePrizes]
    )

    setTotal(res?.data?.totalAvailablePrizes)
    setTotalScore(res?.data?.totalScore)
  }

  useEffect(() => {
    getStudentPrizeLockerList()
  }, [pageNumber, perPage, type])

  const closeModel = (e) => {
    if (e == true) {
      setType(0)
      getStudentPrizeLockerList()
    }
    setShowGetPrize(false)
  }

  const closeCongratsModel = () => {
    setShowCongrats(false)
    closeModel(true)
  }

  useEffect(() => {
    if (pageNumber == 1) {
      getStudentPrizeLockerList()
    } else {
      setPageNumber(1)
    }
  }, [])

  return (
    <>
      <div className="col-12 col-sm-12">
        <div className="available-prize-area mt-3 mb-2">
          <div className="available-prize-caption d-flex align-items-center justify-content-flex-start">
            <p>
              Available Prizes <span>({total})</span>
            </p>
          </div>
        </div>
      </div>
      <div className="prize-blocks-grid-area">
        <div className="row">
          {studentPrizeLockerList?.map((item, index) => {
            return (
              <>
                <div
                  key={`available_prize${index}`}
                  className="col-12 col-xl-4 col-lg-6 mb-3"
                >
                  <div className="prize-block-grid-inner">
                    <div className="prize-block-img">
                      <div className="image-block">
                        <img src={item?.prizeImage} alt="" />
                      </div>
                      <div className="prize-new-badge">
                        <div className="badge-new">
                          <p>New</p>
                        </div>
                      </div>
                      {item?.deadlineDate ? (
                        <div className="prize-available-badge">
                          <div className="badge-date d-flex align-items-center">
                            <svg
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.3333 1.33333C10.3333 0.965141 10.0349 0.666664 9.66668 0.666664C9.29849 0.666664 9.00001 0.965141 9.00001 1.33333V2H5.00001V1.33333C5.00001 0.965141 4.70153 0.666664 4.33334 0.666664C3.96515 0.666664 3.66668 0.965141 3.66668 1.33333V2H2.33334C1.22877 2 0.333344 2.89543 0.333344 4V6.66666V13.3333C0.333344 14.4379 1.22877 15.3333 2.33334 15.3333H11.6667C12.7712 15.3333 13.6667 14.4379 13.6667 13.3333V6.66666V4C13.6667 2.89543 12.7712 2 11.6667 2H10.3333V1.33333ZM12.3333 6V4C12.3333 3.63181 12.0349 3.33333 11.6667 3.33333H10.3333V4C10.3333 4.36819 10.0349 4.66666 9.66668 4.66666C9.29849 4.66666 9.00001 4.36819 9.00001 4V3.33333H5.00001V4C5.00001 4.36819 4.70153 4.66666 4.33334 4.66666C3.96515 4.66666 3.66668 4.36819 3.66668 4V3.33333H2.33334C1.96515 3.33333 1.66668 3.63181 1.66668 4V6H12.3333ZM1.66668 7.33333H12.3333V13.3333C12.3333 13.7015 12.0349 14 11.6667 14H2.33334C1.96515 14 1.66668 13.7015 1.66668 13.3333V7.33333Z"
                                fill="#333442"
                              />
                            </svg>
                            <span className="ms-2">
                              Available till{" "}
                              {moment(item?.deadlineDate).format("Do MMM")}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="prize-block-caption">
                      <h3 className="title">{item?.prize}</h3>
                      <h6 className="prize-points">
                        {item?.points} points{" "}
                        {item?.shouldBeReturned ? (
                          <span className="point-badge bg-returnable">
                            Returnable prize
                          </span>
                        ) : null}
                      </h6>

                      <div className="prize-block-btn">
                        <a
                          className="btn btn-get-prize"
                          data-bs-toggle="modal"
                          role="button"
                          onClick={() => {
                            setShowGetPrize(true)
                            setPrizeLocker(item)
                          }}
                        >
                          Get Prize
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
          {total > studentPrizeLockerList.length ? (
            <button
              className="btn btn-primary"
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              Load More
            </button>
          ) : null}
        </div>
      </div>

      {showGetPrize && (
        <Modal
          show={showGetPrize}
          className="modal-dark remove-profile sap"
          aria-labelledby="prizesuccess"
          id="prizesuccess"
          centered
        >
          <Modal.Header
            onHide={closeModel}
            closeButton={true}
            closeVariant="white"
            className="modal-header"
          >
            <Modal.Title className="modal-title">Get Prize</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="white_color">
              Are you sure you want to get {prizeLocker?.prize}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer-left text-left">
              <button
                className="btn btn-submit"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={handelGetPrize}
              >
                Yes, I'm sure
              </button>
              <button
                className="btn btn-fclose"
                onClick={closeModel}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {showCongrats && (
        <Modal
          show={showCongrats}
          className="modal-dark remove-profile sap"
          aria-labelledby="delete_user"
          centered
        >
          <Modal.Header
            onHide={closeCongratsModel}
            closeButton={true}
            closeVariant="white"
            className="modal-header"
          ></Modal.Header>
          <Modal.Body>
            <div className="prize-success-area">
              <div className="prize-success-inner text-center">
                <div className="image-block">
                  <img
                    src="/img/ic_popup_congrats.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="prize-success-caption">
                  <h5 className="title">Congrats! You've got the {prizeLocker?.prize}!</h5>
                  <p>You'll receive this on the first day of your Apprenticeship.</p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default AvailablePrize

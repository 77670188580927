import moment from "moment"
import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Link, useParams } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import clientService from "../../../services/service"
import InstructionModal from "./InstructionModal"
import Scenario from "./Scenario"
import RandomiserVideoReportModel from "./VideoReportModel"

const StundentRandomiser = (props) => {
  const { courseId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [randomiser, setRandomiser] = useState({})
  const [showInstructionModal, setShowInstructionModal] = useState(false)
  const [showViewReport, setShowViewReport] = useState(false)
  const [submittedId, setSubmittedId] = useState("")
  const [rendomizer, setRendomizer] = useState({})
  const alert = useAlert()
  const startAttempt = async () => {
    const res = await clientService.post(
      `StudentRandomizer/StartAttempt?courseId=${courseId}`
    )
    if (res.code == 200) {
      setRandomiser(res.data)
    } else if (res.message) {
      alert.error(res.message)
    }
  }

  useEffect(() => {
    getRendomizer()
  }, [])

  const getRendomizer = async () => {
    const res = await clientService.get(
      `StudentRandomizer/GetRandomizer?courseId=${courseId}`
    )
    if (res.status == true) {
      setRendomizer(res.data)
    } else {
      setRendomizer({})
    }
    setIsLoading(false)
  }
  if (isLoading) {
    return null
  }
  const closeModel = () => {
    setShowViewReport(false)
    setSubmittedId("")
  }
  return (
    <>
      <div className="aside-content-wrapper cources random-seen">
        {randomiser?.scenarioId ? (
          <Scenario
            scenarioId={randomiser?.scenarioId}
            scenario={randomiser}
            onCancel={(e) => {
              setRandomiser({})
              if (e === true) {
                startAttempt()
                getRendomizer()
              } else if (e === false) {
                getRendomizer()
              }
            }}
            startAttempt={startAttempt}
            setShowInstructionModal={setShowInstructionModal}
          />
        ) : (
          <div className="overview-area randomister_content">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="aside-title-area mt-0 mb-0">
                  <h2 className="title title-lg line-height_48 mt-0 mb-0">
                    Randomizer
                  </h2>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="aside-title-area mt-0 mb-0 d-flex justify-content-end">
                  {rendomizer?.studentVideos?.length >= 1 || rendomizer?.studentVideosReviewed?.length >= 1 ? (
                    <>
                      <span className="text-white label-spacing">
                        You used {rendomizer?.attempts} / 10 attempts today.
                      </span>{" "}
                      {rendomizer.isRandomizerAvailable ? (
                        <button
                          onClick={startAttempt}
                          className="main_btn_default ml-15"
                        >
                          {" "}
                          new attempt
                        </button>
                      ) : null}
                    </>
                  ) : null}

                  <Link
                    className="border_btn_default ml-15"
                    to={`/course/leaderboard/${courseId}`}
                  >
                    Leaderboard
                  </Link>
                </div>
              </div>
            </div>

            {rendomizer.studentVideosCount >= 1 ||
            rendomizer.studentVideosReviewedCount >= 1 ? (
              <>
                {rendomizer.studentVideosCount >= 1 ? (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="light_color mt-4 mb-0">
                          {rendomizer.studentVideosCount} Video Pending for
                          Review
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="randomister_blog">
                          <div
                            className="static_form  randomister_popup mt-4"
                            id="video_audio_popup"
                          >
                            <div className="center">
                              {rendomizer.studentVideos?.map((video, index) => {
                                const videoDate = moment
                                  .utc(video.createdDate)
                                  .local()
                                  .format("M/D/YY")
                                return (
                                  <div
                                    key={"idnex_" + index}
                                    className="popup_section p-0"
                                  >
                                    <div className="popup_content">
                                      <div className="">
                                        <video
                                          controls
                                          className="img-fluid"
                                          src={video.video}
                                        />

                                        <span className="player_recording_btn">
                                          <a
                                            className="player_round_btn_play"
                                          >
                                            <svg
                                              width="9"
                                              height="12"
                                              viewBox="0 0 9 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M7.91731 6.27722L0.851313 10.9879C0.801117 11.0213 0.7428 11.0404 0.682574 11.0433C0.622348 11.0462 0.562468 11.0327 0.509312 11.0042C0.456157 10.9758 0.411715 10.9334 0.380722 10.8817C0.349729 10.83 0.333344 10.7708 0.333313 10.7105V1.28922C0.333344 1.22892 0.349729 1.16976 0.380722 1.11804C0.411715 1.06633 0.456157 1.02398 0.509312 0.995522C0.562468 0.967063 0.622348 0.953554 0.682574 0.956436C0.7428 0.959317 0.801117 0.978479 0.851313 1.01188L7.91731 5.72255C7.96297 5.75299 8.0004 5.79423 8.02629 5.84261C8.05217 5.89099 8.06572 5.94501 8.06572 5.99988C8.06572 6.05475 8.05217 6.10877 8.02629 6.15715C8.0004 6.20553 7.96297 6.24677 7.91731 6.27722Z"
                                                fill="#E4E5EA"
                                              ></path>
                                            </svg>
                                          </a>
                                          <span className="video_info_label">
                                            <label className="v_time">
                                              00:00
                                            </label>
                                            <label className="v_date">
                                              {videoDate}
                                            </label>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="score_info">
                                      <p className="light_color">
                                        <b>Total Score: </b>No score yet
                                      </p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {rendomizer.studentVideosReviewedCount >= 1 ? (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="light_color mt-4 mb-0">
                          {rendomizer.studentVideosReviewedCount} Videos Reviewed
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="randomister_blog">
                          <div
                            className="static_form  randomister_popup mt-4"
                            id="video_audio_popup"
                          >
                            <div className="center">
                              {rendomizer.studentVideosReviewed?.map(
                                (video, index) => {
                                  const videoDate = moment
                                    .utc(video.createdDate)
                                    .local()
                                    .format("M/D/YY")
                                  return (
                                    <div
                                      key={"idnex_" + index}
                                      className="popup_section p-0"
                                    >
                                      <div className="popup_content">
                                        <div className="">
                                          <video
                                            controls
                                            className="img-fluid"
                                            src={video.video}
                                          />

                                          <span className="player_recording_btn">
                                            <a
                                              className="player_round_btn_play"
                                            >
                                              <svg
                                                width="9"
                                                height="12"
                                                viewBox="0 0 9 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M7.91731 6.27722L0.851313 10.9879C0.801117 11.0213 0.7428 11.0404 0.682574 11.0433C0.622348 11.0462 0.562468 11.0327 0.509312 11.0042C0.456157 10.9758 0.411715 10.9334 0.380722 10.8817C0.349729 10.83 0.333344 10.7708 0.333313 10.7105V1.28922C0.333344 1.22892 0.349729 1.16976 0.380722 1.11804C0.411715 1.06633 0.456157 1.02398 0.509312 0.995522C0.562468 0.967063 0.622348 0.953554 0.682574 0.956436C0.7428 0.959317 0.801117 0.978479 0.851313 1.01188L7.91731 5.72255C7.96297 5.75299 8.0004 5.79423 8.02629 5.84261C8.05217 5.89099 8.06572 5.94501 8.06572 5.99988C8.06572 6.05475 8.05217 6.10877 8.02629 6.15715C8.0004 6.20553 7.96297 6.24677 7.91731 6.27722Z"
                                                  fill="#E4E5EA"
                                                ></path>
                                              </svg>
                                            </a>
                                            <span className="video_info_label">
                                              <label className="v_time">
                                                00:00
                                              </label>
                                              <label className="v_date">
                                                {videoDate}
                                              </label>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="score_info">
                                        <p className="light_color">
                                          <b>Total Score:</b>{" "}
                                          <b className="green-text">
                                            {video?.score}
                                          </b>
                                        </p>
                                        <Link
                                          className="report_btn"
                                          data-bs-toggle="modal"
                                          to=""
                                          onClick={() => {
                                            setShowViewReport(true)
                                            setSubmittedId(video?.submitScenarioId)
                                          }}
                                        >
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: CustomIcons.document,
                                            }}
                                          ></span>{" "}
                                          View report
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <div className="row">
                <div className="col-lg-12 mt-5">
                  <div className="randomister_blog text-center mt-5">
                    <img src="/img/unlock_vendor.png" />
                    {rendomizer.isRandomizerAvailable === true ? <>
                    <h3 className="mt-4">
                      Congrats, you've unlocked Randomizer!
                    </h3>
                    <p className="mb-4">
                      Here you can read a scenario and then record yourself
                      answering to objections.
                    </p>
                    <div className="buttons">
                      <a
                        onClick={startAttempt}
                        className="main_btn_default pointer"
                      >
                        Start new attempt
                      </a>
                      <a
                        onClick={() => setShowInstructionModal(true)}
                        data-bs-toggle="modal"
                        href="#view_instruction"
                        className="border_btn_default"
                      >
                        View Instruction
                      </a>
                    </div>
                  </> : <> <h3 className="mt-4">
                      You must complete the full Course to unlock Randomizer!
                    </h3>
                    </>}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {showInstructionModal ? (
        <InstructionModal onClose={() => setShowInstructionModal(false)} />
      ) : null}
      {showViewReport && (
        <RandomiserVideoReportModel onClose={closeModel} submittedId={submittedId} />
      )}
    </>
  )
}

export default StundentRandomiser

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clientService from "../../../services/service";


const InstructionModal = ({onClose}) => {

    const [instruction, setInstruction] = useState({});
    const { courseId } = useParams();

    useEffect(() => {
        getInstruction();
    }, [courseId])

    const getInstruction = async () => {
        const res = await clientService.get(`StudentRandomizer/GetInstruction?courseId=${courseId}`);
        setInstruction(res.data);
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="create_a_guide"
            id="create_a_guide"
            centered
            show={true}
            onHide={onClose}
            className="modal-dark remove-profile create-guide-popup sap"
        >
            <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
                <Modal.Title>Instruction</Modal.Title>
            </Modal.Header>
            <Modal.Body className="white_text ">
                    <div dangerouslySetInnerHTML={{__html : instruction?.instruction}} />
            </Modal.Body>

            <Modal.Footer>
                <button
                onClick={onClose} type="submit" className="btn btn-submit">
                    GOT IT
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default InstructionModal;
import { configureStore } from "@reduxjs/toolkit"
import { loadState, saveState } from "./localStorage"

import { combineReducers } from "redux"
import throttle from "lodash/throttle"
import authReducer from "./slices/authSlice"
import courceReducer from "./slices/courceSlice"
import appReducer from "./slices/appSlice"
import courceGuideReducer from "./slices/courceGuideSlice"
import userManagementReducer from "./slices/userManagementSlice"
import tagManagementReducer from "./slices/tagManagementSlice"
import studentCourseReducer from "./slices/studentCourseSlice"
import quizReducer from "./slices/quizSlice"
import courseGuideLessonReducer from "./slices/courseGuideLessonSlice"
import randomiserReducer from "./slices/randomiserSlice"
import flashcardReducer from "./slices/flashcardSlice"
import courseConfigReducer from "./slices/courseConfigSlice"
import resourceReducer from "./slices/resourceSlice"
import reviewerCourseReducer from "./slices/reviewer/reviewerCourseSlice"

const rootReducer = combineReducers({
  auth: authReducer,
  cource: courceReducer,
  app: appReducer,
  courceGuide: courceGuideReducer,
  userManagement: userManagementReducer,
  tagManagement: tagManagementReducer,
  studentCourse: studentCourseReducer,
  quiz: quizReducer,
  courseGuideLesson: courseGuideLessonReducer,
  resource: resourceReducer,
  randomiser: randomiserReducer,
  flashcard: flashcardReducer,
  courseConfig: courseConfigReducer,
  reviewerCourse: reviewerCourseReducer,
})

const preloadedState = loadState()

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
})

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
      app: store.getState().app,
      cource: store.getState().cource,
      courceGuide: store.getState().courceGuide,
      userManagement: store.getState().userManagement,
      tagManagement: store.getState().tagManagement,
      studentCourse: store.getState().studentCourse,
      quiz: store.getState().quiz,
      courseGuideLesson: store.getState().courseGuideLesson,
      randomiser: store.getState().randomiser,
      flashcard: store.getState().flashcard,
      courseConfig: store.getState().courseConfig,
      resource: store.getState().resource,
      reviewerCourse: store.getState().reviewerCourse,
    })
  }, 3000)
)

export default store

import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom"
import DateConfirm from "./DateConfirm"
var moment = require('moment-timezone');
const ChooseDate = ({ onClose, course }) => {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date());
  const [highlightDates, setHighlightDates] = useState([]);
  const [dateConfirmModel, setDateConfirmModel] = useState(false)

  useEffect(() => {
    const pendingDats = startDate.getDate() + (((1 + 7 - startDate.getDay()) % 7) || 7);
    const dates = [];
    for (var i = startDate.getDate(); i <= pendingDats; i++) {
      dates.push(addDays(startDate, i - startDate.getDate()));
    }
    setHighlightDates(dates);
  }, [startDate])

  const addDays = (date, days) => {
    var dt = new Date(date);
    dt.setDate(dt.getDate() + days);
    return dt;
  }

  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap"
        aria-labelledby="create_a_lesson"
        size="lg"
        id="text_lesson_preview"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            Choose Your Start Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color">
            The day of the week that you choose to start your training is the same day of the week that your
            assignments will be due each week. Choose the best day that works for you.
          </p>
          <div className="calendar_section">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              inline
              monthsShown={2}
              minDate={new Date()}
              maxDate={addDays((new Date()), 6)}
              
              // highlightDates={highlightDates}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <Link
              className="main_btn_default"
              data-bs-toggle="modal"
              role="button"
              to=""
              onClick={() => {
                setDateConfirmModel(true)
              }}
            >
              Confirm
            </Link>
            <button
              onClick={onClose}
              className="btn btn-fclose"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {dateConfirmModel && <DateConfirm startDate={startDate} course={course.data} onClose={(e) => {

        if (e === true) {
          onClose(true)
        }
        setDateConfirmModel(false)
      }} />}
    </>
  )
}

export default ChooseDate

import { Modal } from "react-bootstrap"
import React, { useState } from "react"
import AvatarEditor from "react-avatar-editor"
import uploadFileToBlob from "../../components/azure-storage-blob"
import { useDispatch } from "react-redux"
import { useAlert } from "react-alert"
import { uploadProfilePicture } from "../../store/slices/authSlice"

const CropUploadPhoto = ({
  onClose,
  file,
  userId,
  imageSize,
  type,
  handleSave,
}) => {
  const [imageEditor, setImageEditor] = useState(null)
  const dispatch = useDispatch()
  const alert = useAlert()
  const [zoom, setZoom] = useState(1)

  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="remove_profile_image"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="profile_setup_section">
            <div className="img-crop-container">
              <AvatarEditor
                ref={(e) => {
                  setImageEditor(e)
                }}
                image={file}
                width={imageSize ? imageSize.minWidth : 300}
                height={imageSize ? imageSize.minHeight : 300}
                border={100}
                backgroundColor={"#000"}
                borderRadius={type === "square" ? 0 : 150}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={zoom}
                rotate={0}
              />
            </div>
          </div>
          <div className="range-wrapper">
            <div className="select-none">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.3335 1.66671C1.96531 1.66671 1.66683 1.96518 1.66683 2.33337V11.6667C1.66683 11.9008 1.78744 12.1066 1.96989 12.2256L9.19543 5.00004C9.45577 4.73969 9.87788 4.73969 10.1382 5.00004L12.3335 7.40712V2.33337C12.3335 1.96518 12.035 1.66671 11.6668 1.66671H2.3335ZM12.3335 9.24709C12.2324 9.21623 12.1373 9.16084 12.0574 9.08092L9.66683 6.41425L3.74771 12.3334H11.6668C12.035 12.3334 12.3335 12.0349 12.3335 11.6667V9.24709ZM0.333496 2.33337C0.333496 1.2288 1.22893 0.333374 2.3335 0.333374H11.6668C12.7714 0.333374 13.6668 1.2288 13.6668 2.33337V11.6667C13.6668 12.7713 12.7714 13.6667 11.6668 13.6667H2.3335C1.22893 13.6667 0.333496 12.7713 0.333496 11.6667V2.33337ZM4.66683 4.33337C4.48273 4.33337 4.3335 4.48261 4.3335 4.66671C4.3335 4.8508 4.48273 5.00004 4.66683 5.00004C4.85092 5.00004 5.00016 4.8508 5.00016 4.66671C5.00016 4.48261 4.85092 4.33337 4.66683 4.33337ZM3.00016 4.66671C3.00016 3.74623 3.74635 3.00004 4.66683 3.00004C5.5873 3.00004 6.3335 3.74623 6.3335 4.66671C6.3335 5.58718 5.5873 6.33337 4.66683 6.33337C3.74635 6.33337 3.00016 5.58718 3.00016 4.66671Z"
                  fill="#E4E5EA"
                />
              </svg>
            </div>
            <div className="range-container">
              <input
                type="range"
                step={0.1}
                min={0.5}
                max={2}
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
              />
            </div>
            <div className="select-none">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 2C2.44772 2 2 2.44772 2 3V17C2 17.3511 2.18091 17.6599 2.45459 17.8383L13.2929 7C13.6834 6.60948 14.3166 6.60948 14.7071 7L18 10.6106V3C18 2.44772 17.5523 2 17 2H3ZM18 13.3706C17.8484 13.3243 17.7057 13.2412 17.5858 13.1213L14 9.12132L5.12132 18H17C17.5523 18 18 17.5523 18 17V13.3706ZM0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3ZM6.5 6C6.22386 6 6 6.22386 6 6.5C6 6.77614 6.22386 7 6.5 7C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6ZM4 6.5C4 5.11929 5.11929 4 6.5 4C7.88071 4 9 5.11929 9 6.5C9 7.88071 7.88071 9 6.5 9C5.11929 9 4 7.88071 4 6.5Z"
                  fill="#E4E5EA"
                />
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              type="button"
              onClick={() => {
                const canvas = imageEditor.getImage().toDataURL()
                fetch(canvas)
                  .then((res) => res.blob())
                  .then(async (blob) => {
                    blob.name = "profile.png"
                    if (handleSave) {
                      handleSave(blob)
                      onClose(true)
                    } else {
                      var res = await uploadFileToBlob(blob, "user", userId)
                      if (res) {
                        var data = {
                          fileName: res,
                        }
                        dispatch(uploadProfilePicture(data))
                        onClose(true)
                      } else {
                        alert("Something wrong plese try again")
                      }
                    }
                  })
              }}
            >
              Save
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CropUploadPhoto

import React from 'react'
import { Modal } from "react-bootstrap"

const Prompt = ({ onClose, videoDetailResp }) => {

    const formattedText = videoDetailResp?.promptText?.replace(/\n\n/g, '<br><br>');

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="create_a_guide"
                id="create_a_guide"
                centered
                show={true}
                className="modal-dark remove-profile sap"
            >
                <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
                    <Modal.Title>Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                    <div dangerouslySetInnerHTML={{ __html: formattedText }} className='text-white' />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Prompt
import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const tagManagementInitialState = {
  randomizerTags: [],
  otherTags: [],
  newOtherTags: {},
  newRandomizerTags: {},
  editedOther: [],
  editedRandomizer: {},
  otherTagByIdResp: {},
}

export const tagManagementSlice = createSlice({
  name: "tagManagement",
  initialState: tagManagementInitialState,
  reducers: {
    setRandomizerTagsResponse: (state, action) => {
      state.randomizerTags = action.payload.data
    },
    setOtherTagsResponse: (state, action) => {
      state.otherTags = action.payload.data
    },
    setNewOtherTagResponse: (state, action) => {
      state.newOtherTags = action.payload
    },
    setNewRandomizerTagResponse: (state, action) => {
      state.newRandomizerTags = action.payload
    },
    setEditedRandomizerResponse: (state, action) => {
      state.editedRandomizer = action.payload
    },
    setEditedOtherResponse: (state, action) => {
      state.editedOther = action.payload
    },
    setOtherTagByIdResponse: (state, action) => {
      state.otherTagByIdResp = action.payload
    },
  },
})

export const getRandomizerTags = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/GetAllRandomizerTags",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setRandomizerTagsResponse(response))
  }
}

export const getOtherTags = (setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/GetAllOtherTags",
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setOtherTagsResponse(response))
  }
}

export const saveOtherTag = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/AddOtherTag",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setNewOtherTagResponse(response))
  }
}

export const saveRandomizerTag = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/AddRandomizerTag",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setNewRandomizerTagResponse(response))
  }
}

export const editRandomizerTag = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/UpdateRandomizerTag",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedRandomizerResponse(response))
  }
}

export const editOtherTag = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TagManagement/UpdateOtherTag",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedOtherResponse(response))
  }
}

export const getOtherTagById = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `TagManagement/GetOtherTagById?tagId=${data.tagId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setOtherTagByIdResponse(response.data))
  }
}



export const {
  setRandomizerTagsResponse,
  setOtherTagsResponse,
  setNewOtherTagResponse,
  setNewRandomizerTagResponse,
  setEditedRandomizerResponse,
  setEditedOtherResponse,
  setOtherTagByIdResponse,
} = tagManagementSlice.actions

export const tagManagementState = (state) => {
  return state.tagManagement
}

export default tagManagementSlice.reducer

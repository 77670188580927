import { icon } from "@fortawesome/fontawesome-svg-core"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import clientService from "../../services/service"
import { appState, setFullScreen } from "../../store/slices/appSlice"
import {
  courseConfigState,
  getConfiguration,
} from "../../store/slices/courseConfigSlice"

const menu_list = {
  admin: [
    {
      id: "1",
      label: "Home",
      link: "/dashboard",
      icon: `home`,
      highlight: `home_icon`,
    },

    {
      id: "2",
      label: "Courses",
      link: "/course/list",
      icon: `course`,
      highlight: `course_icon`,
    },

    {
      id: "3",
      label: "Students",
      link: "/admin/student",
      icon: `users`,
      highlight: `users_icon`,
    },

    {
      id: "4",
      label: "Prize Locker",
      link: "/prize/locker",
      icon: `price`,
      highlight: `price_icon`,
    },

    {
      id: "5",
      label: "FAQ",
      link: "/faqs",
      icon: `faq`,
      highlight: `faq_icon`,
    },

    {
      id: "6",
      label: "User Management",
      link: "/user-management",
      icon: `add_user_icon`,
      highlight: `add_user`,
    },

    {
      id: "7",
      label: "Tag Management",
      link: "/tag-management",
      icon: `star`,
      highlight: `star_icon`,
    },

    {
      id: "8",
      label: "User Homepage",
      link: "/user-homepage",
      icon: `user_homepage`,
      highlight: `user_homepage_icon`,
    },

    {
      id: "9",
      label: "Notifications",
      link: "/notifications",
      icon: `setting`,
      highlight: `setting_icon`,
    },
  ],
  student: [
    {
      id: "1",
      label: "Home",
      link: "/",
      icon: `/img/icons/home.png`,
      highlight: `/img/icons/home-color.png`,
    },

    {
      id: "2",
      label: "All Courses",
      link: "/courses",
      icon: `/img/icons/learning.png`,
      highlight: `/img/icons/learning-color.png`,
    },
    // {
    //   id: "3",
    //   label: "Flashcards",
    //   link: "/student/flashcard",
    //   icon: `duplicate`,
    //   highlight: `duplicate`,
    // },
    // {
    //   id: "4",
    //   label: "Randomizer",
    //   link: "/student/randomiser",
    //   icon: `randomisor_normal_icon`,
    //   highlight: `randomiser_icon`,
    // },
    // {
    //   id: "5",
    //   label: "Test Yourself",
    //   link: "/student/random-test",
    //   icon: `test_yourself`,
    //   highlight: `test_yourself`,
    // },
    // {
    //   id: "6",
    //   label: "Resources",
    //   link: "/student/resources",
    //   icon: `resources`,
    //   highlight: `resources_icon`,
    // },
  ],
  trainer: [
    {
      id: "1",
      label: "Home",
      link: "/trainer",
      icon: `/img/icons/home.png`,
      highlight: `/img/icons/home-color.png`,
    },

    {
      id: "2",
      label: "Courses",
      link: "/trainer/course/list",
      icon: `/img/icons/courses.png`,
      highlight: `/img/icons/courses-color.png`,
    },
    {
      id: "3",
      label: "Students",
      link: "/trainer/student",
      icon: `/img/icons/students.png`,
      highlight: `/img/icons/students-color.png`,
    },
    {
      id: "4",
      label: "Course Approval",
      link: "/trainer/requested-courses",
      icon: `/img/icons/course-approval.png`,
      highlight: `/img/icons/course-approval-color.png`,
    },
    {
      id: "5",
      label: "Student Questions",
      link: "/trainer/studentquestion",
      icon: `/img/icons/faq.png`,
      highlight: `/img/icons/faq-color.png`,
    },
  ],
  reviewer: [
    {
      id: "1",
      label: "Home",
      link: "/reviewer",
      icon: `/img/icons/home.png`,
      highlight: `/img/icons/home-color.png`,
    },
    {
      id: "2",
      label: "Randomizer",
      link: "/reviewer/randomiser/overview",
      icon: `/img/icons/randomizer.png`,
      highlight: `/img/icons/randomizer-color.png`,
    },
  ],
  course: [
    {
      id: "1",
      label: "Back to Courses",
      link: "/course/list",
      icon: `back`,
      highlight: `back`,
    },
    {
      id: "2",
      label: "Course Material",
      link: "/course/guide/:courseId",
      icon: `course`,
      highlight: `course_icon`,
    },
    {
      id: "3",
      label: "Students",
      link: "/course/students/:courseId",
      icon: `users`,
      highlight: `users_icon`,
    },
    {
      id: "4",
      label: "Randomizer",
      link: "/course/randomiser/:courseId",
      icon: `randomisor_normal_icon`,
      highlight: `randomiser_icon`,
    },
    {
      id: "5",
      label: "Resources",
      link: "/course/resources/:courseId",
      icon: `resources`,
      highlight: `resources_icon`,
    },
    {
      id: "6",
      label: "Course Settings",
      link: "/course/settings/:courseId",
      icon: `setting`,
      highlight: `setting_icon`,
    },
  ],
  trainerCourse: [
    {
      id: "1",
      label: "Back to Courses",
      link: "/trainer/course/list",
      icon: `/img/icons/all-courses.png`,
      highlight: `/img/icons/all-courses-color.png`,
    },
    {
      id: "2",
      label: "Home",
      link: "/dashboard",
      icon: `/img/icons/home.png`,
      highlight: `/img/icons/home-color.png`,
    },
    {
      id: "3",
      label: "Course Material",
      link: "/trainer/course/detail/:courseId",
      icon: `/img/icons/courses.png`,
      highlight: `/img/icons/courses-color.png`,
    },
    {
      id: "4",
      label: "Final Evaluation",
      link: "/trainer/course/final-evalution/:courseId",
      icon: `/img/icons/test-yourself.png`,
      highlight: `/img/icons/test-yourself-color.png`,
    },
    {
      id: "5",
      label: "Video Feedback",
      link: "/trainer/course/video-feedback/:courseId",
      icon: `/img/icons/video-feedbac.png`,
      highlight: `/img/icons/video-feedback-color.png`,
    },
    {
      id: "6",
      label: "Resources",
      link: "/trainer/course/resources/:courseId",
      icon: `/img/icons/resources.png`,
      highlight: `/img/icons/resources-color.png`,
    },
    {
      id: "7",
      label: "Questions",
      link: "/trainer/course/questions/:courseId",
      icon: `/img/icons/faq.png`,
      highlight: `/img/icons/faq-color.png`,
    },
  ],
  studentCourse: [
    {
      id: "1",
      label: "All Courses",
      link: "/courses",
      icon: `/img/icons/all-courses.png`,
      highlight: `/img/icons/all-courses-color.png`,
    },
    {
      id: "2",
      label: "Home",
      link: "/dashboard",
      icon: `/img/icons/home.png`,
      highlight: `/img/icons/home-color.png`,
    },
    {
      id: "3",
      label: "Learning",
      link: "/course/learning-guide/:courseId",
      icon: `/img/icons/learning.png`,
      highlight: `/img/icons/learning-color.png`,
    },
    {
      id: "4",
      label: "Flashcards",
      link: "/course/flashcard/:courseId",
      icon: `/img/icons/flashcards.png`,
      highlight: `/img/icons/flashcards-color.png`,
    },
    {
      id: "5",
      label: "Randomizer",
      link: "/course/randomiser/:courseId",
      icon: `/img/icons/randomizer.png`,
      highlight: `/img/icons/randomizer-color.png`,
    },
    {
      id: "6",
      label: "Test Yourself",
      link: "/course/test-yourself/:courseId",
      icon: `/img/icons/test-yourself.png`,
      highlight: `/img/icons/test-yourself-color.png`,
    },
    {
      id: "7",
      label: "Resources",
      link: "/course/resources/:courseId",
      icon: `/img/icons/resources.png`,
      highlight: `/img/icons/resources-color.png`,
    },
    {
      id: "8",
      label: "Replay",
      link: "/course/roleplay/:courseId",
      icon: `/img/video_icon.svg`,
      highlight: `/img/video_icon.svg`,
      icon: `/img/icons/video-play.png`,
      highlight: `/img/icons/video-play-color.png`,
    },
  ],
}
// /course/
const SideBar = (props) => {
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const [questionCount, setQuestionCount] = useState(0)
  const [studentId, setStudentId] = useState(null)
  const { getConfigurationResponse } = useSelector(courseConfigState)
  const { reloadSideBar, fullScreen } = useSelector(appState)
  const localCourseId = localStorage?.getItem("courseId")

  useEffect(() => {
    if (
      localCourseId === "" ||
      localCourseId == null ||
      localCourseId == undefined
    ) {
      if (params.courseId) {
        dispatch(getConfiguration(params.courseId))
        getSidebarCount()
      }
    } else {
      dispatch(getConfiguration(localCourseId))
      getSidebarCount()
    }
  }, [params.courseId, reloadSideBar])

  useEffect(() => {
    if (params?.studentId) {
      setStudentId(params?.studentId)
    }
  }, [params.studentId, reloadSideBar])

  const getSidebarCount = async () => {
    if (
      props.userRole === "trainer" &&
      location.pathname.indexOf(`/trainer/course/`) == 0 &&
      params.courseId
    ) {
      const res = await clientService.post(
        `TrainerCourse/GetQuestionCount?courseId=${params.courseId}`
      )
      setQuestionCount(res.data)
    }
  }

  return (
    <div
      className={
        fullScreen
          ? "left-nav nav-reviewer nactive"
          : "left-nav nav-reviewer active"
      }
    >
      <div className="offcanvas-body scrollbarstyle">
        <a
          onClick={() => {
            dispatch(setFullScreen(!fullScreen))
          }}
          className="toggle-nav1 pointer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.499999 12C0.5 5.64873 5.64873 0.5 12 0.500001C18.3513 0.500001 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64872 23.5 0.499999 18.3513 0.499999 12Z"
              fill="#0D0D14"
              stroke="#3A3C51"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4714 7.52864C14.7318 7.78899 14.7318 8.2111 14.4714 8.47145L10.9428 12L14.4714 15.5286C14.7318 15.789 14.7318 16.2111 14.4714 16.4714C14.2111 16.7318 13.789 16.7318 13.5286 16.4714L9.52861 12.4714C9.26826 12.2111 9.26826 11.789 9.52861 11.5286L13.5286 7.52864C13.789 7.26829 14.2111 7.26829 14.4714 7.52864Z"
              fill="#E4E5EA"
            />
          </svg>
        </a>
        <ul>
          {(location.pathname != "/trainer/course/list" &&
            location.pathname.indexOf("/trainer/course") == 0) ||
          location.pathname.indexOf("/course/") == "0" ? (
            <>
              {menu_list[
                props.userRole == "student" ? "studentCourse" : "trainerCourse"
              ].map((item, index) => {
                if (props.userRole == "student") {
                  if (
                    item.id == 5 &&
                    getConfigurationResponse?.randomizerEnabled == false
                  ) {
                    return null
                  }
                  if (
                    item.id == 6 &&
                    getConfigurationResponse?.testYourSelfEnabled == false
                  ) {
                    return null
                  }
                  if (
                    item.id == 4 &&
                    getConfigurationResponse?.flashcardEnabled == false
                  ) {
                    return null
                  }
                  if (
                    item.id == 8 &&
                    getConfigurationResponse?.isReplayEnabled == false
                  ) {
                    return null
                  }
                }

                let ActivePage =
                  location.pathname.indexOf(
                    item.link.replace(":courseId", params.courseId)
                  ) == 0

                if (
                  (location.pathname.indexOf("course/quiz") >= 0 ||
                    location.pathname.indexOf("course/guide") >= 0 ||
                    location.pathname.indexOf("course/final-evalution") >= 0 ||
                    location.pathname.indexOf("course/result") >= 0) &&
                  item.link.indexOf("course/learning-guide") >= 0
                ) {
                  ActivePage = true
                } else if (
                  location.pathname.indexOf("trainer/course/collections") >=
                    0 &&
                  item.link.indexOf("trainer/course/resources") >= 0
                ) {
                  ActivePage = true
                } else if (
                  location.pathname.indexOf("trainer/course/guide") >= 0 &&
                  item.link.indexOf("trainer/course/detail") >= 0
                ) {
                  ActivePage = true
                } else if (
                  location.pathname.indexOf(
                    "trainer/course/video-feedback/deatil"
                  ) >= 0 &&
                  item.link.indexOf("trainer/course/video-feedback") >= 0
                ) {
                  ActivePage = true
                }

                const counter =
                  item.id === "8" && questionCount
                    ? `<label class="notification_menu">${questionCount}</label>`
                    : ""

                return (
                  <li className="menu-item" key={`menu_${item.id}_${index}`}>
                    <Link
                      to={item.link.replace(":courseId", params.courseId)}
                      className={
                        ActivePage ? "menu-link activeNav" : "menu-link"
                      }
                    >
                      <span className={ActivePage ? "svg-active" : "svg-child"}>
                        <img src={ActivePage ? item.highlight : item.icon} />
                      </span>
                      <span className="menu-title">{item.label}</span>
                    </Link>
                  </li>
                )
              })}
            </>
          ) : (
            <>
              {menu_list[props.userRole].map((item, index) => {
                let isActive = location.pathname == item.link

                if (
                  location.pathname.indexOf("reviewer/randomiser") >= 0 &&
                  item.link.indexOf("reviewer/randomiser") >= 0
                ) {
                  isActive = true
                }
                if (
                  location.pathname.indexOf(`/trainer/studentUpdate`) >= 0 &&
                  item.link === "/trainer/student"
                ) {
                  isActive = true
                }

                return (
                  <li className="menu-item" key={`menu_${item.id}_${index}`}>
                    <Link
                      to={item.link}
                      className={isActive ? "menu-link activeNav" : "menu-link"}
                    >
                      <span className={isActive ? "svg-active" : "svg-child"}>
                        <img src={isActive ? item.highlight : item.icon} />
                      </span>
                      <span className="menu-title">{item.label} </span>
                    </Link>
                  </li>
                )
              })}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default SideBar

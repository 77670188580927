import { useEffect, useState } from "react"
import clientService from "../../../services/service"

const LockedPrize = ( { totalScore }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(1000)
  const [studentPrizeLocker, setStudentPrizeLocker] = useState([])
  const [total, setTotal] = useState(0)

  const getStudentPrizeLockerList = async () => {
    const data = {
      type: 2,
      page: pageNumber,
      pageSize: perPage,
    }

    const res = await clientService.post(
      "StudentPrizeLocker/GetStudentPrizeLockerList",
      data
    )

    setStudentPrizeLocker(
      pageNumber == 1
        ? res.data.lockedPrizes
        : [...studentPrizeLocker, ...res.data.lockedPrizes]
    )

    setTotal(res?.data?.totallockedPrizes)
  }

  useEffect(() => {
    if (pageNumber == 1) {
      getStudentPrizeLockerList()
    } else {
      setPageNumber(1)
    }
  }, [totalScore])

  useEffect(() => {
    getStudentPrizeLockerList()
  }, [pageNumber, perPage])

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="available-prize-area mt-3 mb-2">
            <div className="available-prize-caption d-flex align-items-center justify-content-flex-start">
              <p>{total} Locked Prizes</p>
            </div>
          </div>
        </div>

        <div className="prize-blocks-grid-area">
          <div className="row">
            {studentPrizeLocker?.map((item, index) => {
              return (
                <>
                  <div
                    key={`lock_prize${index}`}
                    className="col-12 col-xl-4 col-lg-6 mb-3"
                  >
                    <div className="prize-block-grid-inner prize-locked">
                      <div className="prize-block-img">
                        <div className="image-block">
                          <img src={item?.prizeImage} alt="" />
                        </div>
                        <div className="prize-popover-area">
                          <div className="prize-popover-inner">
                            <h4 className="title">Prize Not Available</h4>
                            <p>
                              You have to get at least{" "}
                              <span className="remain-points">{item?.points}</span>{" "}
                              points to get to the prize.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="prize-block-caption">
                        <h3 className="title">{item?.prize}</h3>
                        <h6 className="prize-points">
                          {item?.points} points{" "}
                          {item?.shouldBeReturned ? (
                            <span className="point-badge bg-returnable">
                              Returnable prize
                            </span>
                          ) : null}
                        </h6>

                        <div className="prize-block-btn">
                          <a href="" className="btn btn-get-prize btn-disabled">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.58578 3.25243C6.96085 2.87736 7.46956 2.66665 7.99999 2.66665C8.53042 2.66665 9.03913 2.87736 9.4142 3.25243C9.78928 3.62751 9.99999 4.13621 9.99999 4.66665V6.66665H5.99999V4.66665C5.99999 4.13621 6.2107 3.62751 6.58578 3.25243ZM4.66666 6.66665V4.66665C4.66666 3.78259 5.01785 2.93474 5.64297 2.30962C6.26809 1.6845 7.11593 1.33331 7.99999 1.33331C8.88404 1.33331 9.73189 1.6845 10.357 2.30962C10.9821 2.93474 11.3333 3.78259 11.3333 4.66665V6.66665C12.4379 6.66665 13.3333 7.56208 13.3333 8.66665V12.6666C13.3333 13.7712 12.4379 14.6666 11.3333 14.6666H4.66666C3.56209 14.6666 2.66666 13.7712 2.66666 12.6666V8.66665C2.66666 7.56208 3.56209 6.66665 4.66666 6.66665ZM10.6667 7.99998H11.3333C11.7015 7.99998 12 8.29846 12 8.66665V12.6666C12 13.0348 11.7015 13.3333 11.3333 13.3333H4.66666C4.29847 13.3333 3.99999 13.0348 3.99999 12.6666V8.66665C3.99999 8.29846 4.29847 7.99998 4.66666 7.99998H5.33332H10.6667ZM6.66666 10.6666C6.66666 9.93027 7.26361 9.33331 7.99999 9.33331C8.73637 9.33331 9.33332 9.93027 9.33332 10.6666C9.33332 11.403 8.73637 12 7.99999 12C7.26361 12 6.66666 11.403 6.66666 10.6666Z"
                                fill="#818496"
                              />
                            </svg>{" "}
                            Get Prize
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
            {total > studentPrizeLocker.length ? (
              <button
                className="btn btn-primary"
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                Load More
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default LockedPrize

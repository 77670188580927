import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const courseGuideLessonInitialState = {
  lessons: {},
  newLesson: {},
  newContent: {},
  videoContent: {},
  contentLesson: {},
  getCourceGuideByLessonIdResponse: {},
  updatedCourceGuideLessonResponse: {},
  publishedCourceGuideLessonResponse: [],
  duplicateCourceGuideLessonResponse: {},
  deleteCourceGuideLessonResponse: {},
}

export const courseGuideLessonSlice = createSlice({
  name: "courseGuideLesson",
  initialState: courseGuideLessonInitialState,
  reducers: {
    setCreateCourceGuideLessonResponse: (state, action) => {
      state.newLesson = action.payload
    },
    setGetAllCourceGuideLessonResponse: (state, action) => {
      state.lessons = action.payload.data
    },
    setGetCourceGuideByLessonIdResponse: (state, action) => {
      state.getCourceGuideByLessonIdResponse = action.payload
    },
    setUpdateCourceGuideLessonResponse: (state, action) => {
      state.updatedCourceGuideLessonResponse = action.payload
    },
    setPublishCourceGuideLessonResponse: (state, action) => {
      state.publishedCourceGuideLessonResponse = action.payload
    },
    setDuplicateCourceGuideLessonResponse: (state, action) => {
      state.duplicateCourceGuideLessonResponse = action.payload
    },
    setDeleteCourceGuideLessonResponse: (state, action) => {
      state.deleteCourceGuideLessonResponse = action.payload
    },
    setCreateLessonContentResponse: (state, action) => {
      state.newContent = action.payload
    },
    setGetContentLessonIdResponse: (state, action) => {
      state.contentLesson = action.payload
    },
    setVideoContentResponse: (state, action) => {
      state.videoContent = action.payload
    },
  },
})

export const createCourceGuideLessons =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/CreateCourseGuidesLession",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setCreateCourceGuideLessonResponse(response))
    }
  }

export const createLessonContent = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/SaveLessionContent",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCreateLessonContentResponse(response))
    dispatch(getAllCourceGuideLessons({
      courseGuideId: data.courseGuideId,
      page: 1,
      pageSize: 100,
      search: ""
    }))
  }
}

export const getAllCourceGuideLessons =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/GetAllCourseGuidesLession",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setGetAllCourceGuideLessonResponse(response))
    }
  }

export const getCourceGuideByLessonId =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      "CourseGuide/GetCourseGuideLessionById",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setGetCourceGuideByLessonIdResponse(response))
    }
  }

export const getContentLessonId = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `CourseGuide/GetLessionContentByLessionId?Id=${data.Id}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setGetContentLessonIdResponse(response))
  }
}

export const updateCourceGuideLesson =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/UpdateCourseGuideLession",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setUpdateCourceGuideLessonResponse(response))
    }
  }

export const publishCourceGuideLesson =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/PublishedUnPublishedCourseGuideLession",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setPublishCourceGuideLessonResponse(response))
    }
  }

export const duplicateCourceGuideLesson =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/DuplicateLessionCreate",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setDuplicateCourceGuideLessonResponse(response))
    }
  }

export const deleteCourceGuideLesson =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.post(
      "CourseGuide/DeleteCourseGuideLession",
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setDeleteCourceGuideLessonResponse(response))
    }
  }

export const saveUploadVideoContent = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/SaveUploadVideoLessionContent",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setVideoContentResponse(response))
  }
}

export const {
  setCreateCourceGuideLessonResponse,
  setGetAllCourceGuideLessonResponse,
  setGetCourceGuideByLessonIdResponse,
  setUpdateCourceGuideLessonResponse,
  setPublishCourceGuideLessonResponse,
  setDuplicateCourceGuideLessonResponse,
  setDeleteCourceGuideLessonResponse,
  setCreateLessonContentResponse,
  setGetContentLessonIdResponse,
  setVideoContentResponse,
} = courseGuideLessonSlice.actions

export const courseGuideLessonState = (state) => {
  return state.courseGuideLesson
}

export default courseGuideLessonSlice.reducer

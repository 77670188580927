import React, { useEffect, useState } from "react"
import clientService from "../../../services/service"
import { FileUploader } from "react-drag-drop-files"
import VideoTypes from "../../../consts/VideoTypes"
import { useAlert } from "react-alert"
import VideoRecordModal from "../../models/VideoRecordModal"
import CustomIcons from "../../../assets/CustomIcons"
import { useDispatch, useSelector } from "react-redux"
import { setLoader } from "../../../store/slices/appSlice"
import uploadFileToBlob from "../../../components/azure-storage-blob"
import DeleteQuestion from "../../models/DeleteQuestion"
import { Tab, OverlayTrigger, Tooltip, Nav } from "react-bootstrap"
import OtherStudentVideo from "./OtherStudentVideo"
import VideoQuestionComments from "./Video/QuestionComments"
import { authState } from "../../../store/slices/authSlice"
import UserAvtar from "../../../common/UserAvtar"
import VideoPlayer from "../../../common/VideoPlayer"
let timeInt = null;

let media_recorder = null
let camera_stream = null
let blobs_recorded = []
const VideoUpload = ({ lessonId, getCourseInfo, setTabType }) => {
  const dispatch = useDispatch()
  const videoRef = React.useRef(null)

  const { loggedInuser } = useSelector(authState)

  const alert = useAlert()
  const [lessonInfo, setLessonInfo] = useState({})
  const [enableVideoUploading, setEnableVideoUploading] = useState(false)
  const [enableVideoRecording, setEnableVideoRecording] = useState(false)
  const [showTestModal, setShowTestModal] = useState(false)
  const [recordingStatus, setRecordingStatus] = useState("idel")

  const [audioInput, setAudioInput] = useState(null)
  const [vidioInput, setVideoInput] = useState(null)
  const [cameraStream, setCameraStream] = useState(null)

  const [localLink, setLocalLink] = useState(null)
  const [videoBlob, setVideoBlob] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [showCancelModel, setShowCancelModel] = useState(false)
  const [currentTab, setCurrentTab] = useState("my_video")

  const [show, setShow] = useState(false)
  const [seconds, setSeconds] = useState(0);



  useEffect(() => {
    setEnableVideoUploading(false);
    setEnableVideoRecording(false);
    setRecordingStatus("idel");
    setAudioInput(null);
    setVideoInput(null);
    getCourseLession();
    setLocalLink(null);
    setVideoBlob(null);
    setSelectedFile(null);
    setCurrentTab("my_video")
  }, [lessonId])


  const startTimer = () => {
    if (timeInt) {
      clearInterval(timeInt)
    }
    timeInt = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
  }

  const stopTimer = () => {
    if (timeInt) {
      clearInterval(timeInt)
    }
  }


  const getScondsToTime = () => {
    if (seconds >= 60) {
      const min = Math.floor(seconds / 60);
      const sec = seconds % 60;
      var str = "";

      if (min < 10) {
        str = `0${min}`;
      } else {
        str = `${min}`;
      }
      if (sec < 10) {
        return `${str}:0${sec}`;
      } else {
        return `${str}:${sec}`;
      }
    } else if (seconds < 10) {
      return `00:0${seconds}`;
    } else {
      return `00:${seconds}`;
    }
  }


  const getCourseLession = async () => {
    const res = await clientService.get(
      `StudentCourse/GetLessionContent?courseGuideLessonId=${lessonId}`
    )
    setLessonInfo(res.data)
  }

  const handleChange = (res) => {
    const localUrl = URL.createObjectURL(res[0])
    setSelectedFile(res[0])
    setLocalLink(localUrl)
    alert.success("Video was Successfully Uploaded.")
  }

  const onClose = (e) => {
    if (e && e.audioInput && e.videoInput) {
      setAudioInput(e.audioInput)
      setVideoInput(e.videoInput)
      setEnableVideoRecording(true)
    }
    setShowTestModal(false)
  }
  useEffect(() => {
    if (audioInput && vidioInput && enableVideoRecording) {
      initRecording()
    }
  }, [audioInput, vidioInput, enableVideoRecording])
  const initRecording = async () => {
    setRecordingStatus("idel")
    camera_stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: {
          exact: vidioInput.deviceId,
        },
      },
      audio: {
        deviceId: {
          exact: audioInput.deviceId,
        },
      },
    })
    setCameraStream(camera_stream)
    videoRef.current.srcObject = camera_stream
  }
  const startRecording = async () => {
    setRecordingStatus("start")
    startTimer();
    media_recorder = new MediaRecorder(camera_stream, {
      mimeType: "video/webm",
    })

    // event : new recorded video blob available
    media_recorder.addEventListener("dataavailable", (e) => {
      blobs_recorded.push(e.data);
    })
    media_recorder.addEventListener("stop", () => {
      let video_local = URL.createObjectURL(
        new Blob(blobs_recorded, { type: "video/webm" })
      )
      setLocalLink(video_local)
      const blob = new Blob(blobs_recorded, { type: "video/mp4" })
      blob.name = "student_video_" + lessonId + ".mp4"
      setVideoBlob(blob)
    })
    media_recorder.start(1000)
  }
  const stopRecording = () => {
    setLocalLink("")
    setRecordingStatus("stop");
    stopTimer();
    media_recorder.stop()
  }

  const sendToReview = async () => {
    dispatch(setLoader(true))
    var res = null
    if (enableVideoUploading) {
      res = await uploadFileToBlob(selectedFile, "course", lessonInfo.courseId)
    } else {
      res = await uploadFileToBlob(videoBlob, "course", lessonInfo.courseId)
    }

    if (res) {
      const update = await clientService.post(
        "StudentCourse/UploadVideoLesson",
        {
          courseGuideLessonId: lessonId,
          video: res,
        }
      )
      if (update.code == 200 || update.status === true) {
        alert.success(
          `Video was successfully submitted for review. Now you can watch videos of other students in tab "Videos of other students".`
        )
      }
      getCourseInfo()
      getCourseLession()
    }
    dispatch(setLoader(false))
  }

  const handleDelete = async (e) => {
    if (e === true) {
      setLocalLink("")
      setEnableVideoUploading(false)
      setEnableVideoRecording(false)
    }
    setShowDeleteModel(false)
  }

  const handleCancel = async (e) => {
    if (e === true) {
      if (media_recorder) {
        media_recorder.stop()
      }
      setLocalLink("")
      stopTimer();
      setEnableVideoUploading(false)
      setEnableVideoRecording(false)
      setSeconds(0);
      blobs_recorded = [];
      setRecordingStatus("idel")

    }
    setShowCancelModel(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2">
          <div className="tabbar_head video-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="my_video">
              <div className="tabs">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="my_video" onClick={() => { setTabType("my") }} className="pointer">
                      My Video
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"other_video"} className="pointer">
                      {lessonInfo.isCompleted ? (
                        <a onClick={() => { setTabType("other") }} variant="secondary">Videos of Other Students</a>
                      ) : (
                        <OverlayTrigger
                          key={"ot"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-top`} className="pointer">
                              Not available until you upload your video.
                            </Tooltip>
                          }
                        >
                          <a variant="secondary">Videos of Other Students</a>
                        </OverlayTrigger>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="my_video">
                  <>
                    <div className="modal-body waiting-for-feedback video-upload-feedback">
                      {lessonInfo.isCompleted ? (
                        <>
                          <VideoPlayer
                            className="img-fluid height-100"
                            videoId={lessonInfo?.vimeoLink}
                            isVideoAvailable={
                              lessonInfo?.lessonType === 4
                                ? lessonInfo?.videoAnswerDetail?.studentVideoLesson?.isVideoAvailable
                                : lessonInfo?.isVideoAvailable
                            }
                          />
                          <div className="current-user">
                            <UserAvtar name={loggedInuser?.firstName + " " + loggedInuser?.lastName} image={loggedInuser.profileImage} />
                            <div className="user-name">
                              {loggedInuser?.firstName} {" "}
                              {loggedInuser?.lastName}
                            </div>
                          </div>
                          <h3 className="text-center mb-3"><b>Trainer's Feedback</b></h3>
                          {lessonInfo?.videoAnswerDetail?.trainerName ?
                            <div className="row feedback">
                              <div className="col-auto">
                                <UserAvtar
                                  name={lessonInfo.videoAnswerDetail?.trainerName}
                                  image={lessonInfo.videoAnswerDetail?.trainerImage}
                                />
                              </div>
                              <div className="col-auto">
                                <h3>{lessonInfo.videoAnswerDetail?.trainerName}</h3>
                                <div className="position">Trainer</div>
                              </div>
                            </div> : null}
                          {lessonInfo.videoAnswerDetail?.comments &&
                            <div className="review">
                              <span>Comment : </span>
                              <span>{lessonInfo.videoAnswerDetail?.comments}</span>
                            </div>
                          }
                          <div className="tag-container mt-2">
                            {lessonInfo?.videoAnswerDetail && lessonInfo?.videoAnswerDetail.tags.length > 0 ? lessonInfo?.videoAnswerDetail.tags?.map((tag) => {
                              return (
                                <>
                                  <div className={tag?.color == 0 ? "video_green" : tag?.color == 1 ? "video_red" : "video_blue"}>
                                    <span>{tag.tagName}</span>
                                  </div>
                                </>
                              )
                            }) : null}
                          </div>
                          <div className="mt-3">
                            <VideoPlayer
                              className="img-fluid height-100"
                              videoId={lessonInfo?.trainerVideoResponse?.vimeoLink}
                              isVideoAvailable={lessonInfo?.trainerVideoResponse?.isVideoAvailable}
                            />
                          </div>
                          {lessonInfo?.videoAnswerDetail?.comments ? null
                            : <p className="waiting-for-feedback mt-3">
                              <span className="lorange-badge">Video Under Review</span>
                            </p>
                          }
                          <VideoQuestionComments
                            studentCourseGuideLessonId={lessonInfo.studentCourseGuideLessonId}
                            discussionId={"mydiscussion"}
                          />
                        </>
                      ) : (
                        <div className="resources_Content">
                          {!enableVideoRecording && !enableVideoUploading ? (
                            <div className="row">
                              {lessonInfo.enableVideoRecord && (
                                <div
                                  onClick={() => {
                                    setShowTestModal(true)
                                  }}
                                  className="col-lg-6"
                                >
                                  <div className="video_upload_section record_video mt-4">
                                    <p>
                                      <svg
                                        width="16"
                                        height="12"
                                        viewBox="0 0 16 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M1.33333 2.66602C1.33333 2.29783 1.63181 1.99935 2 1.99935H9.33333C9.70152 1.99935 10 2.29783 10 2.66602V5.99935V9.33268C10 9.70087 9.70152 9.99935 9.33333 9.99935H2C1.63181 9.99935 1.33333 9.70087 1.33333 9.33268V2.66602ZM11.3333 4.70389V2.66602C11.3333 1.56145 10.4379 0.666016 9.33333 0.666016H2C0.895431 0.666016 0 1.56145 0 2.66602V9.33268C0 10.4373 0.895431 11.3327 2 11.3327H9.33333C10.4379 11.3327 11.3333 10.4373 11.3333 9.33268V7.29481L14.9458 9.87517C15.1491 10.0203 15.4163 10.0397 15.6384 9.92546C15.8604 9.81119 16 9.58241 16 9.33268V2.66602C16 2.41629 15.8604 2.18751 15.6384 2.07324C15.4163 1.95897 15.1491 1.97838 14.9458 2.12353L11.3333 4.70389ZM14.6667 8.03723L11.8136 5.99935L14.6667 3.96148V8.03723Z"
                                          fill="#E4E5EA"
                                        ></path>
                                      </svg>
                                      Record video
                                    </p>
                                  </div>
                                </div>
                              )}
                              {lessonInfo.enableVideoUpload && (
                                <div className="col-lg-6">
                                  <div
                                    onClick={() => {
                                      setEnableVideoUploading(true)
                                    }}
                                    className="video_upload_section record_video mt-4"
                                  >
                                    <p>
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M7.00065 0.333984C7.36884 0.333984 7.66732 0.611141 7.66732 0.953032V7.39118L9.86258 5.19591C10.1229 4.93556 10.545 4.93556 10.8054 5.19591C11.0657 5.45626 11.0657 5.87837 10.8054 6.13872L7.47206 9.47206C7.21171 9.73241 6.7896 9.73241 6.52925 9.47206L3.19591 6.13872C2.93556 5.87837 2.93556 5.45626 3.19591 5.19591C3.45626 4.93556 3.87837 4.93556 4.13872 5.19591L6.33398 7.39118V0.953032C6.33398 0.611141 6.63246 0.333984 7.00065 0.333984ZM1.66732 9.00065C1.66732 8.63246 1.36884 8.33398 1.00065 8.33398C0.632461 8.33398 0.333984 8.63246 0.333984 9.00065V11.6673C0.333984 12.1978 0.544698 12.7065 0.919771 13.0815C1.29484 13.4566 1.80355 13.6673 2.33398 13.6673H11.6673C12.1978 13.6673 12.7065 13.4566 13.0815 13.0815C13.4566 12.7065 13.6673 12.1978 13.6673 11.6673V9.00065C13.6673 8.63246 13.3688 8.33398 13.0007 8.33398C12.6325 8.33398 12.334 8.63246 12.334 9.00065V11.6673C12.334 11.8441 12.2637 12.0137 12.1387 12.1387C12.0137 12.2637 11.8441 12.334 11.6673 12.334H2.33398C2.15717 12.334 1.9876 12.2637 1.86258 12.1387C1.73756 12.0137 1.66732 11.8441 1.66732 11.6673V9.00065Z"
                                          fill="#E4E5EA"
                                        ></path>
                                      </svg>
                                      Upload video
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}

                          {enableVideoRecording ? (
                            <>
                              <div>Video Record</div>

                              {localLink && recordingStatus === "stop" ? (
                                <>
                                  {" "}
                                  <video
                                    webkit-playsinline="true"
                                    playsinline="true"
                                    src={localLink}
                                    muted={true}
                                    className="img-fluid"
                                    controls
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-submit mr-16 dark-purple"
                                    onClick={sendToReview}
                                  >
                                    Send to review
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-fclose btn-puple-outline"
                                    onClick={() => {
                                      setLocalLink("")
                                      setEnableVideoUploading(false)
                                      setEnableVideoRecording(false)
                                      setRecordingStatus("idel")
                                      setSeconds(0);
                                      blobs_recorded = []
                                    }}
                                  >
                                    Retry
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn- float-right del-button"
                                    onClick={() => {
                                      setShowDeleteModel(true)
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.delete,
                                    }}
                                  ></button>
                                </>
                              ) : (
                                <div className="video-container-new">
                                  <div className="video postion-relative">
                                    <video
                                      style={{ width: "100%" }}
                                      ref={videoRef}
                                      autoPlay
                                      muted={true}
                                      className="image-fluid video-preview"
                                      webkit-playsinline="true"
                                      playsinline="true"
                                    ></video>
                                    <div className="video-timer">{getScondsToTime()}</div>
                                  </div>

                                  {recordingStatus !== "stop" ? (
                                    <>
                                      {recordingStatus === "idel" ? (
                                        <button
                                          className="btn btn-submit dark-purple"
                                          type="button"
                                          onClick={startRecording}
                                        >
                                          Start Recording
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-submit dark-purple"
                                          onClick={stopRecording}
                                        >
                                          Stop Recording
                                        </button>
                                      )}

                                      <button
                                        type="button"
                                        className="btn btn-fclose del-button ml-15"
                                        onClick={() => {
                                          setShowCancelModel(true)
                                        }}
                                      >
                                        CANCEL
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-submit mr-16 dark-purple"
                                        onClick={stopRecording}
                                      >
                                        Send to review
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-fclose btn-puple-outline"
                                        onClick={stopRecording}
                                      >
                                        Retry
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-fclose float-right del-button"
                                        onClick={() => {
                                          setShowDeleteModel(true)
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: CustomIcons.delete,
                                        }}
                                      ></button>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          ) : null}

                          {enableVideoUploading ? (
                            <>
                              {localLink ? (
                                <>
                                  {" "}
                                  <div>
                                    <video
                                      webkit-playsinline="true"
                                      playsinline="true"
                                      src={localLink}
                                      className="img-fluid"
                                      controls
                                    />
                                    {/* <VideoPlayer
                                      className="img-fluid height-100"
                                      videoId={localLink}
                                    /> */}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-submit mr-16 dark-purple"
                                    onClick={sendToReview}
                                  >
                                    Send to review
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-fclose btn-puple-outline"
                                    onClick={() => {
                                      setLocalLink("")
                                      setEnableVideoUploading(false)
                                      setEnableVideoRecording(false)
                                    }}
                                  >
                                    Retry
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-fclose float-right del-button"
                                    onClick={() => {
                                      setShowDeleteModel(true)
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.delete,
                                    }}
                                  ></button>
                                </>
                              ) : (
                                <>
                                  <div className="row mt-3">
                                    <div className="col-12">Video Upload</div>
                                  </div>
                                  <div className="row">
                                    <FileUploader
                                      multiple={true}
                                      handleChange={handleChange}
                                      name="file"
                                      types={VideoTypes}
                                      onTypeError={() => {
                                        alert.success("File not support")
                                      }}
                                      onSizeError={() => {
                                        alert.error(
                                          "You Can't Upload File with More than 500MB Size"
                                        )
                                      }}
                                    >
                                      <div className="file_upload_section">
                                        <div className="file_upload_inner text-center">
                                          <p>
                                            <svg
                                              width="27"
                                              height="27"
                                              viewBox="0 0 27 27"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2.59123 4.02105C2.59123 3.23111 3.2316 2.59075 4.02154 2.59075H6.2276V6.22711H2.59123V4.02105ZM2.59123 8.65135H6.2276V12.2877H2.59123V8.65135ZM0.166992 4.02105V7.43923V13.4998V19.5604V22.9786C0.166992 25.1074 1.89273 26.8332 4.02154 26.8332H7.43972H19.5609H22.9791C25.1079 26.8332 26.8337 25.1074 26.8337 22.9786V19.5604V13.4998V7.43923V4.02105C26.8337 1.89224 25.1079 0.166504 22.9791 0.166504H19.5609H7.43972H4.02154C1.89273 0.166504 0.166992 1.89224 0.166992 4.02105ZM24.4094 6.22711V4.02105C24.4094 3.23112 23.7691 2.59075 22.9791 2.59075H20.7731V6.22711H24.4094ZM20.7731 8.65135H24.4094V12.2877H20.7731V8.65135ZM18.3488 7.43923V2.59075H8.65184V7.43923V12.2877H18.3488V7.43923ZM24.4094 14.712V18.3483H20.7731V14.712H24.4094ZM24.4094 20.7726H20.7731V24.4089H22.9791C23.769 24.4089 24.4094 23.7686 24.4094 22.9786V20.7726ZM18.3488 14.712V19.5604V24.4089H8.65184V19.5604V14.712H18.3488ZM2.59123 20.7726V22.9786C2.59123 23.7686 3.2316 24.4089 4.02154 24.4089H6.2276V20.7726H2.59123ZM6.2276 18.3483H2.59123V14.712H6.2276V18.3483Z"
                                                fill="#ACAFBF"
                                              />
                                            </svg>
                                          </p>
                                          <p>
                                            <b>
                                              Drop video to attach or{" "}
                                              <label>
                                                <input
                                                  className="d-none"
                                                  type="file"
                                                  value=""
                                                />
                                                browse
                                              </label>
                                            </b>
                                          </p>
                                          <p>
                                            Supported file type - MP4, MOV, AVI,
                                            FLV, MKV, WMV, AVCHD, WEBM, H.264,
                                            MPEG-4
                                            <br />
                                            Maximum size - 500MB,{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </FileUploader>
                                  </div>
                                </>
                              )}
                            </>
                          ) : null}
                          {lessonInfo?.videoAnswerDetail &&
                            <>
                              <h3 className="text-center mt-5 mb-3"><b>Trainer's Feedback</b></h3>
                              {lessonInfo?.videoAnswerDetail?.trainerName ?
                                <div className="row feedback">
                                  <div className="col-auto">
                                    <UserAvtar
                                      name={lessonInfo.videoAnswerDetail?.trainerName}
                                      image={lessonInfo.videoAnswerDetail?.trainerImage}
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <h3>{lessonInfo.videoAnswerDetail?.trainerName}</h3>
                                    <div className="position">Trainer</div>
                                  </div>
                                </div>
                                : null}
                              {lessonInfo.videoAnswerDetail?.comments &&
                                <div className="review">
                                  <span>Comment : </span>
                                  <span>{lessonInfo.videoAnswerDetail?.comments}</span>
                                </div>
                              }
                              <div className="tag-container mt-2">
                                {lessonInfo?.videoAnswerDetail && lessonInfo?.videoAnswerDetail.tags.length > 0 ? lessonInfo?.videoAnswerDetail.tags?.map((tag) => {
                                  return (
                                    <>
                                      <div className={tag?.color == 0 ? "video_green" : tag?.color == 1 ? "video_red" : "video_blue"}>
                                        <span>{tag.tagName}</span>
                                      </div>
                                    </>
                                  )
                                }) : null}
                              </div>
                            </>
                          }
                          <div className="mt-3">
                            <VideoPlayer
                              className="img-fluid height-100"
                              videoId={lessonInfo?.trainerVideoResponse?.vimeoLink}
                              isVideoAvailable={lessonInfo?.trainerVideoResponse?.isVideoAvailable}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </Tab.Pane>
                <Tab.Pane eventKey="other_video" className="other-video-box">
                  {lessonInfo.isCompleted ? (
                    <OtherStudentVideo lessonId={lessonId} />
                  ) : (
                    <p>Not available until you upload your video.</p>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

            {showTestModal ? <VideoRecordModal onClose={onClose} /> : null}

            {showDeleteModel ? (
              <DeleteQuestion
                onClose={handleDelete}
                title={"Delete This Video"}
                desciption="Are you sure you want to delete this video?"
              />
            ) : null}

            {showCancelModel ? (
              <DeleteQuestion
                onClose={handleCancel}
                title={"Cancelling This Recording"}
                desciption="Are you sure you want to cancel?<br /> 
                The video will be not saved and you'll go back to the first step."
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
export default VideoUpload

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import ChooseDate from "../models/ChooseDate"
import { Accordion } from "react-bootstrap"
import { authState } from "../../store/slices/authSlice"
import NewEnrollCourse from "../models/NewEnrollCourse"

const StudentCoursePreview = () => {
  const { courseId } = useParams()
  const [courseList, setCourseList] = useState({})
  const [showDateModel, setShowDateModel] = useState(false)
  const [showNewCourseModel, setShowNewCourseModel] = useState(false)
  const { loading } = useSelector(appState)
  const { getProfileDataResponse } = useSelector(authState)
  const { setShowSideBar } = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    getCourseList()
    setShowSideBar(false)
  }, [])

  const getCourseList = async () => {
    if (loading) {
      return
    }
    const res = await clientService.get(
      `StudentCourse/CoursePreview?courseId=${courseId}`
    )
    setCourseList(res)
  }
  const closeModel = async (e) => {
    // if (e === true && !courseList?.data?.isApprovalPending) {
    //   navigate("/course/learning-guide/" + courseId)
    // }
    getCourseList()
    setShowDateModel(false)
  }

  const closeCourseModel = (e) => {
    if (e === true) {
      getCourseList()
    }
    setShowNewCourseModel(false)
  }

  useEffect(() => {
    if (courseList && !courseList?.data?.isApprovalPending && courseList?.data?.isEnrolled && !courseList?.data?.isLeft && !courseList?.data?.isPaused) {
      navigate("/course/learning-guide/" + courseId)
    }
  }, [courseList])

  return (
    <>
      <div className="container-fluid align-items-center pt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="full breadcrum_style2">
              <p>
                <Link to="/courses">
                  <span
                    dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                  ></span>
                  All Courses
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="layout_width">
              <div className="d-flex space_between align-items-center">
                <h3 className="break-word flex-1 mr-5 text-white">
                  {courseList?.data?.course}
                </h3>
                {courseList?.data?.isEnrolled == false ||
                  courseList?.data?.isLeft ? (
                  <Link
                    className="main_btn_default float-end"
                    data-bs-toggle="modal"
                    role="button"
                    to=""
                    onClick={() => {
                      getProfileDataResponse?.enrolledCourses >= 2
                        ? setShowNewCourseModel(true)
                        : setShowDateModel(true)
                    }}
                  >
                    {courseList?.data?.isLeft
                      ? "Re Attempt"
                      : courseList?.data?.isApprovalPending
                        ? "Waiting for Approval"
                        : "Enroll for free"}
                  </Link>
                ) : courseList?.data?.isApprovalPending ? (
                  <Link
                    className="main_btn_default float-end"
                    data-bs-toggle="modal"
                    role="button"
                    to=""
                  >
                    {" "}
                    Waiting for Approval
                  </Link>
                ) : null}
              </div>
              <div className="post_training mt-4">
                <Accordion className="faq-item-list" defaultActiveKey="0">
                  {courseList?.data?.learningWeeks.length > 0
                    ? courseList.data.learningWeeks.map((guide, index) => {
                      return (
                        <Accordion.Item
                          className="accordion-item"
                          eventKey={index}
                        >
                          <Accordion.Header>
                            {guide?.guideName}
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="list_style1">
                              {guide?.lessons.map((lesson, i) => {
                                return (
                                  <li key={`lesson_guide${i}`}>{lesson}</li>
                                )
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    })
                    : null}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDateModel && <ChooseDate onClose={closeModel} course={courseList} />}
      {showNewCourseModel && (
        <NewEnrollCourse
          onClose={(e) => {
            if (e === true) {
              setShowDateModel(true)
            }
            setShowNewCourseModel(false)
          }}
          course={courseList}
          closeModel={closeModel}
        />
      )}
      <div class="bg-shape-area cbg-shape-area">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1174 900"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_912_55058)">
            <path
              d="M300.056 1007.3C412.982 1085.17 547.3 1126.06 684.461 1124.32C821.622 1122.58 954.859 1078.3 1065.77 997.584C1176.68 916.871 1259.8 803.711 1303.64 673.734C1347.48 543.756 1349.88 403.373 1310.51 271.971L1106.72 333.028C1133.44 422.222 1131.81 517.513 1102.05 605.741C1072.3 693.969 1015.88 770.78 940.591 825.568C865.305 880.355 774.865 910.416 681.761 911.597C588.657 912.779 497.483 885.024 420.83 832.164L300.056 1007.3Z"
              fill="#3F1485"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_912_55058"
              x="0.0565186"
              y="-28.0289"
              width="1638.32"
              height="1452.41"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150"
                result="effect1_foregroundBlur_912_55058"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  )
}

export default StudentCoursePreview

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import InfiniteScroll from "react-infinite-scroll-component"
import CustomIcons from "../../assets/CustomIcons"
import ChooseDate from "../models/ChooseDate"
import { authState, getProfile } from "../../store/slices/authSlice"
import NewEnrollCourse from "../models/NewEnrollCourse"
import Resume from "../models/Resume"
import { useAlert } from "react-alert"

const StudentCouseList = () => {
  const [courseList, setCourseList] = useState([])
  const [currentCourse, setCurrentCourse] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalRecord, setTotalRecord] = useState(0)
  const [showDateModel, setShowDateModel] = useState(false)
  const [showResumeModel, setShowResumeModel] = useState(false)
  const { loading } = useSelector(appState)
  const { getProfileDataResponse } = useSelector(authState)
  const [showNewCourseModel, setShowNewCourseModel] = useState(false)
  const alert = useAlert()

  const dispatch = useDispatch()
  useEffect(() => {
    getCourseList()
    localStorage.setItem("courseId", "")
  }, [pageNumber])

  const getCourseList = async () => {
    if (loading) {
      return
    }
    const requestParams = {
      pageNo: pageNumber,
      pageSize: 5,
      search: "",
      sortingColunm: "",
      sortingOrder: "",
    }
    const res = await clientService.post(
      "StudentCourse/GetAllCourseList",
      requestParams
    )
    setCourseList([...courseList, ...res.data.courseListViewModel])
    setTotalRecord(res.data.total)
  }

  const closeModel = async (e) => {
    if (e === true) {
      const requestParams = {
        pageNo: 1,
        pageSize: pageNumber * 5,
        search: "",
        sortingColunm: "",
        sortingOrder: "",
      }
      const res = await clientService.post(
        "StudentCourse/GetAllCourseList",
        requestParams
      )
      setCourseList([...res.data.courseListViewModel])
      dispatch(getProfile())
    }
    setShowDateModel(false)
  }

  const resumeClose = (e) => {
    closeModel(true)
    setShowResumeModel(false)
  }

  const closeCourseModel = (e) => {
    if (e === true) {
      getCourseList()
    }
    setShowNewCourseModel(false)
  }

  const enrollCourse = async (course) => {
    let startDate = new Date()
    const dt = startDate.toISOString().replace(".000", "")
    const postData = {
      courseId: course?.id,
      startDate: dt,
    }
    try {
      const res = await clientService.post(
        "StudentCourse/EnrollToCourse",
        postData
      )
      if (res.code === 200 && res.status) {
        alert.success(res.message)
        closeModel(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div className="container-fluid align-items-center pt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="full breadcrum_style2">
              <p>
                <Link to="/dashboard">
                  <span
                    dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                  ></span>
                  Home
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <InfiniteScroll
              dataLength={courseList.length} //This is important field to render the next data
              next={() => {
                setPageNumber(pageNumber + 1)
              }}
              hasMore={true}
              loader={<h4>Loading...</h4>}
            >
              {courseList.map((course, index) => {
                return (
                  <Link
                    className={course.isFreezed ? "freezed" : ""}
                    to={
                      course.isFreezed
                        ? ""
                        : course.isEnrolled &&
                          !course.isLeft &&
                          !course?.isApprovalPending &&
                          !course?.isPaused
                        ? `/course/learning-guide/${course.id}`
                        : `/course/preview/${course.id}`
                    }
                  >
                    <div
                      key={`course_${index}`}
                      className="courses_blog circle_progressbar"
                    >
                      <div className="course_image">
                        <img src={course.courseImage} alt="" />
                      </div>
                      <div className="course_content">
                        <div className="left_conent">
                          <h3>{course.course}</h3>
                          <p>{course.description}</p>
                          {course.isPaused ? (
                            <Link
                              className="main_btn_default"
                              data-bs-toggle="modal"
                              role="button"
                              to=""
                              onClick={() => {
                                setCurrentCourse(course)
                                setShowResumeModel(true)
                              }}
                            >
                              Re-enroll
                            </Link>
                          ) : course.isEnrolled && !course.isLeft ? (
                            <>
                              <a
                                className="main_btn_default"
                                // data-bs-toggle="modal"
                                type="button"
                                href={
                                  course.isFreezed
                                    ? ""
                                    : course.isEnrolled &&
                                      !course.isLeft &&
                                      !course?.isApprovalPending &&
                                      !course?.isPaused
                                    ? `/course/learning-guide/${course.id}`
                                    : `/course/preview/${course.id}`
                                }
                              >
                                {course?.isApprovalPending
                                  ? "Waiting for Approval"
                                  : "Continue"}
                              </a>{" "}
                              {/* <Link
                                  className="main_btn_default"
                                  data-bs-toggle="modal"
                                  role="button"
                                  to={
                                    course.isFreezed ? "" :
                                      course.isEnrolled && !course.isLeft && !course?.isApprovalPending && !course?.isPaused ?
                                        `/course/learning-guide/${course.id}` :
                                        `/course/preview/${course.id}`}
                                >
                                  {course?.isApprovalPending ? "Waiting for Approval" : "Continue"}
                                </Link>{" "} */}
                              <span>
                                {course?.isApprovalPending ? null : (
                                  <>
                                    {course.completedLesson} of{" "}
                                    {course.totalLesson} lessons completed
                                  </>
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <Link
                                className="main_btn_default"
                                data-bs-toggle="modal"
                                role="button"
                                to=""
                                onClick={() => {
                                  {
                                    getProfileDataResponse?.enrolledCourses >= 2
                                      ? setShowNewCourseModel(true)
                                      : enrollCourse(course)
                                  }
                                  setCurrentCourse(course)
                                }}
                              >
                                {course?.isLeft
                                  ? "Re Attempt"
                                  : course?.isApprovalPending
                                  ? "Waiting for Approval"
                                  : "Enroll"}
                              </Link>
                            </>
                          )}
                        </div>
                        <div className="right_processbar">
                          <div
                            className={
                              course.isLeft
                                ? `progress-circle progress-0`
                                : `progress-circle progress-${course.progress}`
                            }
                          >
                            <span>{course.isLeft ? "0" : course.progress}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {showDateModel && (
        <ChooseDate onClose={closeModel} course={{ data: currentCourse }} />
      )}
      {showResumeModel && (
        <Resume onClose={resumeClose} course={{ data: currentCourse }} />
      )}
      {showNewCourseModel && (
        <NewEnrollCourse
          onClose={(e) => {
            if (e === true) {
              setShowDateModel(true)
            }
            setShowNewCourseModel(false)
          }}
          course={{ data: currentCourse }}
          closeModel={closeModel}
        />
      )}
      <div class="bg-shape-area cbg-shape-area">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1174 900"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_912_55058)">
            <path
              d="M300.056 1007.3C412.982 1085.17 547.3 1126.06 684.461 1124.32C821.622 1122.58 954.859 1078.3 1065.77 997.584C1176.68 916.871 1259.8 803.711 1303.64 673.734C1347.48 543.756 1349.88 403.373 1310.51 271.971L1106.72 333.028C1133.44 422.222 1131.81 517.513 1102.05 605.741C1072.3 693.969 1015.88 770.78 940.591 825.568C865.305 880.355 774.865 910.416 681.761 911.597C588.657 912.779 497.483 885.024 420.83 832.164L300.056 1007.3Z"
              fill="#3F1485"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_912_55058"
              x="0.0565186"
              y="-28.0289"
              width="1638.32"
              height="1452.41"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150"
                result="effect1_foregroundBlur_912_55058"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  )
}

export default StudentCouseList

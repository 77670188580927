import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import clientService from "../../../services/service"
import { appState } from "../../../store/slices/appSlice"

const TestYourself = () => {
  const { courseId } = useParams()
  const [accessInfo, setAccessInfo] = useState({})
  const { loading } = useSelector(appState)

  useEffect(() => {
    checkAccess()
  }, [courseId])

  const checkAccess = async () => {
    const res = await clientService.get(
      `StudentTestYourself/CheckAccess?courseId=${courseId}`
    )
    setAccessInfo(res)
  }

  return (
    <>
      <div className="aside-content-wrapper random_test">
        <div className="container-fluid h-100 align-items-center">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <div className="question_section">
                {accessInfo?.data === true ? (
                  <div className="row">
                    <div className="col-sm-6">
                      <Link
                        to={`/course/test-yourself/${courseId}/question-randomizer`}
                      >
                        <div className="question_blog">
                          <h3>
                            Question
                            <br />
                            Randomizer
                          </h3>
                        </div>
                      </Link>
                    </div>
                    <div className="col-sm-6">
                      <Link
                        to={`/course/test-yourself/${courseId}/pick-lecture`}
                      >
                        <div className="question_blog">
                          <h3>Question Bank</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    {!loading ? (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="random-config-main">
                            <div className="radom-config-inner">
                              <div className="img-area text-center">
                                <img
                                  className="empty-icon-img"
                                  src="/img/ic_blank_test.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="random-config-caption">
                                <h3>
                                  You must complete at least <br /> 2
                                  Learning Guides to access "Test Yourself" feature
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestYourself

import { useEffect, useState } from "react"
import { Pagination, Row, Col } from "react-bootstrap"

const CustomPagination = ({ total, limit, curretnPage, setPage, setLimit }) => {
  const [pages, setPages] = useState([1])

  useEffect(() => {
    if (total) {
      var min = curretnPage - 2
      var max = Math.ceil(total / limit)
      if (min <= 0) {
        min = 1
      }
      if (curretnPage < max - 2) {
        max = curretnPage + 2
      }
      var pg = []
      for (var i = min; i <= max; i++) {
        pg.push(i)
      }
      setPages(pg)
    }
  }, [total, limit, curretnPage])

  const updatePage = (page) => {
    setPage(page)
  }

  const updateLimit = (e) => {
    setLimit(e.target.value)
  }
  return (
    <Row>
      <Col></Col>
      <Col>
        <Pagination className="justify-content-end">
          {curretnPage !== 1 ? (
            <>
              <Pagination.First onClick={() => updatePage(1)} />
              <Pagination.Prev onClick={() => updatePage(curretnPage - 1)} />
            </>
          ) : null}

          {pages.map((page, indes) => {
            return (
              <Pagination.Item
                active={page === curretnPage}
                key={"apge" + indes}
                onClick={() => updatePage(page)}
              >
                {page}
              </Pagination.Item>
            )
          })}
          {curretnPage !== Math.ceil(total / limit) ? (
            <>
              <Pagination.Next onClick={() => updatePage(curretnPage + 1)} />
              <Pagination.Last
                onClick={() => updatePage(Math.ceil(total / limit))}
              />
            </>
          ) : null}
        </Pagination>
      </Col>
    </Row>
  )
}

export default CustomPagination

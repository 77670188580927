import React, { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import Login from "./pages/login"
import SignUp from "./pages/Signup"
import ForgotPassword from "./pages/ForgotPassword"
import CreateNewPassword from "./pages/CreateNewPassword"
import SignUpInvite from "./pages/SignUpInvite"
import ReviewerHome from "./pages/reviewer/Home"
import ReviewerVideo from "./pages/reviewer/ReviewerVideo"
import StudentBody from "./routing/student"
import ReviewerBody from "./routing/reviewer"
import RandomiserOverView from "./pages/reviewer/RandomiserOverView"
import RandomiserOverViewDetail from "./pages/reviewer/RandomiserOverViewDetail"
import RandomiserOverViewStudentDetail from "./pages/reviewer/RandomiserOverViewStudentDetail"
import TrainerBody from "./routing/trainer"
import TestLesson from "./pages/trainer/TestLesson"

import Faqs from "./pages/student/Faqs"
import Profile from "./pages/profile"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { appState, setErrorMessage } from "./store/slices/appSlice"
import { setLoginData } from "./store/slices/authSlice"
import LearningGuideSection from "./pages/student/LearningGuideSection"
import LearningGuideQuizCorrect from "./pages/student/LearningGuideQuizCorrect"
import LearningGuideQuizScore from "./pages/student/LearningGuideQuizScore"
import LearningGuideResultGoodJob from "./pages/student/LearningGuideResultGoodjob"
import LearningGuideResultNotGoodJob from "./pages/student/LearningGuideResultNotGoodJob"
import LearningGuideQuizStart from "./pages/student/LearningGuideQuizStart"
import StudentLearningGuide from "./pages/student/StudentLearningGuide"
import LeaderboardRandomiser from "./pages/student/LeaderboardRandomiser"
import Randomiser from "./pages/student/Randomiser"
import Success from "./pages/Success"
import ValidateEmail from "./pages/ValidateEmail"
import StudentCouseList from "./pages/student/StudentCouseList"
import StudentHome from "./pages/student/StudentHome"
import StudentCoursePreview from "./pages/student/StudentCoursePreview"
import TrainerCousrseList from "./pages/trainer/TrainerCousrseList"
import TrainerDashboard from "./pages/trainer/TrainerDashboard"
import TrainerCourseGuide from "./pages/trainer/TrainerCourseGuide"
import TrainerStudent from "./pages/trainer/TrainerStudent"
import StudentProfile from "./pages/StudentProfile"
import VideoFeedback from "./pages/trainer/VideoFeedback/VideoFeedback"
import VideoFeedbackDetail from "./pages/trainer/VideoFeedback/VideoFeedbackDetail"
import StundentRandomiser from "./pages/student/Randomizer"
import TrainerFinalEvalution from "./pages/trainer/FinalEvalution/TrainerFinalEvalution"
import TrainerResource from "./pages/trainer/Resources/TrainerResource"
import TrainerTextResourse from "./pages/trainer/Resources/TextResource"
import CollectionList from "./pages/trainer/Resources/CollectionList"
import StudentResourceList from "./pages/student/Resources/ResourceList"
import StudentCollectionList from "./pages/student/Resources/CollectionList"
import StudentTextResource from "./pages/student/Resources/StudentTextResource"
import TestYourself from "./pages/student/TestYourself/TestYourself"
import PickLectures from "./pages/student/TestYourself/PickLectures"
import QuestionRandomizer from "./pages/student/TestYourself/QuestionRandomizer"
import QuestionBank from "./pages/student/TestYourself/QuestionBank"
import FlashCard from "./pages/student/FlashCard/Flashcard"
import FlashCardQuestion from "./pages/student/FlashCard/FlashCardQuestion"
import TrainerQuestion from "./pages/trainer/Questions/TrainerQuestion"
import StudentPrizeLocker from "./pages/student/PrizeLocker/StudentPrizeLocker"
import TrainerProfile from "./pages/TrainerProfile"
import LoginAs from "./pages/loginAs"
import CourseApproval from "./pages/trainer/CourseApproval"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import TermsCondition from "./pages/TermsCondition"
import PageNotFound from "./pages/PageNotFound"
import StudentQuestion from "./pages/trainer/StudentQuestion"
import RolePlay from "./pages/student/rolePlay"
import StudentProfileEdit from "./pages/trainer/StudentProfileEdit"

function App() {
  const alert = useAlert()
  const dispatch = useDispatch()
  const { errorMessage } = useSelector(appState)
  useEffect(() => {
    dispatch(setLoginData())
  }, [dispatch])
  useEffect(() => {
    if (errorMessage) {
      alert.error(errorMessage)
      dispatch(setErrorMessage(""))
    }
  }, [errorMessage])
  return (
    <Router>
      <Routes>
        {/* Student Auth Routes Start*/}

        <Route path="/" element={<StudentBody />}>
          <Route exact path="" element={<StudentHome />} />
          <Route exact path="dashboard" element={<StudentHome />} />
          <Route exact path="courses" element={<StudentCouseList />} />
          <Route exact path="prize-locker" element={<StudentPrizeLocker />} />
          <Route exact path="faqs" element={<Faqs />} />
          <Route
            exact
            path="course/preview/:courseId"
            element={<StudentCoursePreview extraClass="cources" />}
          />
          <Route exact path="student/profile" element={<StudentProfile />} />
          {/* <Route exact path="resources" element={<Resources />} /> */}
          <Route
            exact
            path="course/test-yourself/:courseId"
            element={<TestYourself />}
          />
          <Route
            exact
            path="course/test-yourself/:courseId/pick-lecture"
            element={<PickLectures extraClass="pick_lectures" />}
          />
          <Route
            exact
            path="course/test-yourself/:courseId/lecture/question-bank"
            element={<QuestionBank extraClass="white_body" />}
          />
          <Route
            exact
            path="course/test-yourself/:courseId/question-randomizer"
            element={<QuestionRandomizer />}
          />
          <Route
            exact
            path="course/learning-guide/:courseId"
            element={<StudentLearningGuide />}
          />
          <Route
            exact
            path="course/resources/:courseId"
            element={<StudentResourceList />}
          />
          <Route
            exact
            path="course/resources/:courseId/:resourceId"
            element={<StudentTextResource />}
          />
          <Route
            exact
            path="course/collections/:courseId/:collectionId"
            element={<StudentCollectionList />}
          />
          <Route
            exact
            path="/course/final-evalution/:courseId/:guidId"
            element={<LearningGuideSection />}
          />

          <Route
            exact
            path="/course/guide/:courseId/:guidId"
            element={
              <LearningGuideQuizCorrect extraClass="white_body lessons_sidebar_section" />
            }
          />

          <Route path="course/flashcard/:courseId" element={<FlashCard />} />
          <Route
            path="course/flashcard/:courseId/:guideId"
            element={<FlashCardQuestion />}
          />
          <Route
            exact
            path="course/quiz/:courseId/:lessonId"
            element={<LearningGuideQuizStart extraClass="white_body" />}
          />
          <Route
            exact
            path="course/result/:courseId/:lessonId"
            element={<LearningGuideQuizScore extraClass="white_body" />}
          />
          <Route
            exact
            path="course/final-evalution/result/:courseId/:guidId"
            element={<LearningGuideResultGoodJob />}
          />
          <Route
            exact
            path="course/final-evalution/not-good-result/:courseId/:guidId"
            element={<LearningGuideResultNotGoodJob />}
          />
          <Route
            exact
            path="learning-guide-quiz-start"
            element={<LearningGuideQuizStart extraClass="white_body" />}
          />
          <Route
            exact
            path="course/randomiser/:courseId"
            element={
              <StundentRandomiser extraClass="lessons_sidebar_section" />
            }
          />
          <Route
            exact
            path="course/leaderboard/:courseId"
            element={
              <LeaderboardRandomiser extraClass="lessons_sidebar_section" />
            }
          />

          <Route exact path="randomiser" element={<Randomiser />} />
          <Route exact path="course/roleplay/:courseId" element={<RolePlay />} />
        </Route>

        {/* Student Auth Routes End */}

        {/* Reviewer Auth Routes Start*/}

        <Route path="/reviewer/" element={<ReviewerBody />}>
          <Route exact path="profile" element={<Profile />} />
          <Route path="" element={<ReviewerHome />} />
          <Route path="video" element={<ReviewerVideo />} />
          <Route path="randomiser/overview" element={<RandomiserOverView />} />
          <Route
            path="randomiser/detail/:courseId"
            element={<RandomiserOverViewDetail />}
          />
          <Route
            path="randomiser/detail/:courseId/reviewed"
            element={<RandomiserOverViewDetail />}
          />
          <Route
            path="randomiser/detail/:courseId/:submittedId"
            element={<RandomiserOverViewStudentDetail />}
          />
        </Route>
        {/* Reviewer Auth Routes End */}

        {/*  Trainer Routes Start */}
        <Route path="/trainer/" element={<TrainerBody />}>
          <Route
            exact
            path=""
            element={<TrainerDashboard extraClass="resources_hide_hover" />}
          />
          <Route exact path="requested-courses" element={<CourseApproval />} />
          <Route exact path="profile" element={<TrainerProfile />} />
          <Route exact path="course/list" element={<TrainerCousrseList />} />
          <Route exact path="studentUpdate/:studentId" element={<StudentProfileEdit />} />
          <Route exact path="student" element={<TrainerStudent />} />
          <Route exact path="studentquestion" element={<StudentQuestion />} />
          <Route
            exact
            path="course/final-evalution/:courseId"
            element={<TrainerFinalEvalution />}
          />
          <Route
            exact
            path="course/resources/:courseId"
            element={<TrainerResource />}
          />
          <Route
            exact
            path="course/questions/:courseId"
            element={<TrainerQuestion />}
          />
          <Route
            exact
            path="course/collections/:courseId/:collectionId"
            element={<CollectionList />}
          />
          <Route
            exact
            path="course/resources/:courseId/:resourceId"
            element={<TrainerTextResourse />}
          />
          <Route
            exact
            path="course/video-feedback/:courseId"
            element={<VideoFeedback />}
          />
          <Route
            exact
            path="course/video-feedback/:courseId/:type"
            element={<VideoFeedback />}
          />
          <Route
            exact
            path="course/video-feedback/deatil/:courseId/:guideLessonId"
            element={<VideoFeedbackDetail />}
          />
          <Route
            exact
            path="course/video-feedback/deatil/:courseId/:guideLessonId/:type"
            element={<VideoFeedbackDetail />}
          />
          <Route
            exact
            path="course/detail/:courseId"
            element={<TrainerCourseGuide extraClass="small_header" />}
          />
          <Route
            exact
            path="course/guide/:courseId/:guideId"
            element={
              <TestLesson
                extraClass="white_body lessons_sidebar_section"
                // isOutside={true}
                bodyClass={"lessons_sidebar_section"}
              />
            }
          />
        </Route>
        {/*  Trainer Routes End */}

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
        <Route exact path="/login-as/:token" element={<LoginAs />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/register" element={<SignUp />} />
        <Route exact path="/invitation/join" element={<SignUpInvite />} />
        <Route exact path="/success" element={<Success />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-condition" element={<TermsCondition />} />
        <Route
          exact
          path="/Auth/ValidateRestorePasswordLink"
          element={<CreateNewPassword />}
        />
        <Route
          exact
          path="/auth/validateEmailLink"
          element={<ValidateEmail />}
        />
        <Route exact path="/" element={<Login />} />
      </Routes>
    </Router>
  )
}

export default App

import { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useSelector } from "react-redux";
import clientService from "../../../services/service";
import { appState } from "../../../store/slices/appSlice";

const FlashCardQuestion = ({ currentItem }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flashCards, setFlashCards] = useState({});
  const [isFlipped, setIsFlipped] = useState(false);
  const { loading } = useSelector(appState);

  useEffect(() => {
    getFlashCard();
  }, [currentItem]);

  const getFlashCard = async () => {
    const res = await clientService.get(
      `StudentCourse/GetFlashcards?courseGuideId=${currentItem}`
    );
    setFlashCards(res);
    setIsFlipped(false);
    setCurrentIndex(0);
  };

  return (
    <>
      {!loading ? (
        <>
          {flashCards && flashCards?.code === 200 ? (
            flashCards?.data?.flashCardItem.length > 0 ? (
              <>
                <div className="col-lg-12 mt-3">
                  {flashCards?.data?.count > 0 ? (
                    <ReactCardFlip
                      flipDirection={"vertical"}
                      isFlipped={isFlipped}>
                      <div className="flascard_guide pointer" onClick={() => setIsFlipped(true)}>
                        <div className="pointer">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: flashCards?.data?.flashCardItem[
                                currentIndex
                              ]?.term?.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                            }}

                            className="pointer"
                          ></h3>
                        </div>
                      </div>
                      <div className="flascard_guide pointer" onClick={() => setIsFlipped(false)}>
                        <div className="pointer">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: flashCards?.data?.flashCardItem[
                                currentIndex
                              ]?.defination?.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br />"
                              ),
                            }}

                            className="pointer"
                          ></h3>
                        </div>
                      </div>
                    </ReactCardFlip>
                  ) : null}
                </div>
                <div className="col-lg-12">
                  <ul className="flascard_guide_controls">
                    {currentIndex > 0 ? (
                      <li
                        className="pointer"
                        onClick={() => {
                          setCurrentIndex(currentIndex - 1);
                          setIsFlipped(false);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.70711 1.70711C9.09763 1.31658 9.09763 0.683417 8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L3.41421 9H15.0625C15.5803 9 16 8.55228 16 8C16 7.44772 15.5803 7 15.0625 7H3.41421L8.70711 1.70711Z"
                            fill="#E4E5EA"
                          />
                        </svg>
                      </li>
                    ) : null}
                    <li>
                      {flashCards?.data ? (
                        <>
                          {currentIndex + 1} / {flashCards?.data?.count}{" "}
                        </>
                      ) : null}
                    </li>
                    {currentIndex < flashCards?.data?.count - 1 ? (
                      <li
                        className="pointer"
                        onClick={() => {
                          setCurrentIndex(currentIndex + 1);
                          setIsFlipped(false);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683417 6.90237 1.31658 7.29289 1.70711L12.5858 7H0.9375C0.419733 7 0 7.44772 0 8C0 8.55228 0.419733 9 0.9375 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
                            fill="#ACAFBF"
                          />
                        </svg>
                      </li>
                    ) : null}
                  </ul>
                </div>
                {flashCards?.data.quizeletLink ? (
                  <div className="col-lg-12 d-flex justify-content-center mt-4">
                    <a
                      target="_blank"
                      className="main_btn_default blue_color float-end mt-3"
                      href={
                        flashCards?.data?.quizeletLink.indexOf("http") === 0
                          ? flashCards?.data?.quizeletLink
                          : `http://${flashCards?.data?.quizeletLink}`
                      }
                    >
                      open on quizlet
                    </a>
                  </div>
                ) : null}{" "}
              </>
            ) : !loading ? (
              <div className="col-md-12 minus_blank_space mt-5">
                <div className="full text-center mt-5">
                  <img src="/img/transparent_empty_icon.png" alt="" />
                </div>
                <div className="full text-center">
                  <p className="light_text mb-0 mt-5">
                    There is no data available yet.
                  </p>
                </div>
              </div>
            ) : null
          ) : !loading ? (
            <div className="col-md-12 minus_blank_space mt-5">
              <div className="full text-center mt-5">
                <img src="/img/transparent_empty_icon.png" alt="" />
              </div>
              <div className="full text-center">
                <p className="light_text mb-0 mt-5">
                  There is no data available yet.
                </p>
                <p className="light_text mb-0 mt-5 flashcard-note">
                  <span>
                    After completing a guide a flash card will be unlocked. By
                    clicking on the Unlocked Flashcard you will be able to
                    access it.
                  </span>
                </p>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default FlashCardQuestion;

import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import clientService from "../../services/service"

const LearningGuideQuizStart = () => {
  const [courseInfo, serCourseInfo] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const { courseId, lessonId } = useParams()
  const [ansIds, setAnsIds] = useState([])
  const [isAnswered, setIsAnswered] = useState(false)
  const navigate = useNavigate()

  const [sumittedRespons, setSubmittedResponse] = useState({})
  useEffect(() => {
    getCourseDetails()
  }, [])

  const getCourseDetails = async () => {
    const res = await clientService.get(
      `StudentCourse/GetStartQuizAndFinished?courseGuideLessonId=${lessonId}`
    )
    serCourseInfo(res.data)
  }

  const next = () => {
    setAnsIds([])
    setIsAnswered(false)
    setSubmittedResponse({})
    if (currentQuestionIndex < courseInfo?.quizLessions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      navigate(`/course/result/${courseId}/${lessonId}`)
    }
  }
  const saveData = async () => {
    const answers = []
    for (var i = 0; i < ansIds.length; i++) {
      answers.push({
        quizAnswerId: ansIds[i],
      })
    }
    const data = {
      quizLessionId:
        courseInfo?.quizLessions[currentQuestionIndex]?.quizLessionId,
      studentQuizLessionId:
        courseInfo?.quizLessions[currentQuestionIndex]?.studentQuizLessionId,
      questionType:
        courseInfo?.quizLessions[currentQuestionIndex]?.questionType,
      answers: answers,
    }
    const res = await clientService.post(
      `StudentCourse/SaveStudentQuizAnswer`,
      data
    )
    if (res.code == 200 && res.status) {
      // alert.success(res.message)
      setSubmittedResponse(res.data)
      setIsAnswered(true)
    }
  }
  if (courseInfo === null) {
    return null
  }

  const getClass = (optionId) => {
    if (sumittedRespons.isAnswerCorrect === true) {
      if (ansIds.indexOf(optionId) >= 0) {
        return "right_ans form-check custom_radio pointer"
      }
    } else if (sumittedRespons.isAnswerCorrect === false) {
      if (ansIds.indexOf(optionId) >= 0) {
        return "false_ans form-check custom_radio pointer"
      }
    }

    if (
      courseInfo?.quizLessions[currentQuestionIndex]?.quizState == 1 &&
      courseInfo?.quizLessions[currentQuestionIndex]?.answersList?.indexOf(
        "optionId"
      ) >= 0
    ) {
      return "right_ans form-check custom_radio pointer"
    }
    if (
      courseInfo?.quizLessions[currentQuestionIndex]?.quizState == 2 &&
      courseInfo?.quizLessions[currentQuestionIndex]?.answersList?.indexOf(
        "optionId"
      ) >= 0
    ) {
      return "false_ans form-check custom_radio pointer"
    }
    return " form-check custom_radio pointer"
  }
  return (
    <>
      <div className="aside-content-wrapper white_body random_test quiz-list-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Learning</li>
                    <li className="breadcrumb-item">
                      <Link to={`/course/learning-guide/${courseId}`}>
                        {courseInfo?.guideName}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {courseInfo?.guideTypeName}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="full">
                <p className="light_text">
                  Questions {currentQuestionIndex + 1} of{" "}
                  {courseInfo?.totalQuestion}
                </p>
              </div>
              <div className="full">
                <h3>
                  {courseInfo?.quizLessions[currentQuestionIndex]?.question}
                  <span className="multi_question">
                    {" "}
                    {courseInfo?.quizLessions[currentQuestionIndex]
                      ?.questionType === 2
                      ? `[Select Multiple Answers]`
                      : null}
                  </span>
                </h3>
              </div>
              <div className="full">
                <div className="radio_button_section">
                  {courseInfo?.quizLessions[currentQuestionIndex]?.options?.map(
                    (option, opIndex) => {
                      return (
                        <div
                          key={option.quizLessionOptionId}
                          onClick={() => {
                            if (
                              courseInfo?.quizLessions[currentQuestionIndex]
                                ?.isAttempt ||
                              isAnswered
                            ) {
                              return
                            }
                            if (
                              courseInfo?.quizLessions[currentQuestionIndex]
                                ?.questionType == 1
                            ) {
                              setAnsIds([option.quizLessionOptionId])
                            } else {
                              const aids = [...ansIds]
                              const itemIndex = aids.indexOf(
                                option.quizLessionOptionId
                              )
                              if (itemIndex >= 0) {
                                aids.splice(itemIndex, 1)
                              } else {
                                aids.push(option.quizLessionOptionId)
                              }
                              setAnsIds(aids)
                            }
                          }}
                          className={getClass(option.quizLessionOptionId)}
                        >
                          <input
                            id={option.quizLessionOptionId}
                            className="form-check-input"
                            type={
                              courseInfo?.quizLessions[currentQuestionIndex]
                                ?.questionType == 2
                                ? "checkbox"
                                : "radio"
                            }
                            name="flexRadioDefault"
                            checked={
                              courseInfo?.quizLessions[
                                currentQuestionIndex
                              ]?.answersList?.indexOf(
                                option.quizLessionOptionId
                              ) >= 0 ||
                              ansIds.indexOf(option.quizLessionOptionId) >= 0
                            }
                            value={option.quizLessionOptionId}
                          />
                          <label className="form-check-label">
                            {option.optionName}
                          </label>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="quiz-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {courseInfo?.quizLessions[currentQuestionIndex]?.isAttempt ||
                isAnswered ? (
                  <button
                    className="main_btn_default blue_color float-end"
                    onClick={next}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    disabled={ansIds.length == 0}
                    className="main_btn_default blue_color float-end dark-purple"
                    onClick={saveData}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default LearningGuideQuizStart

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Accordion } from "react-bootstrap"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import CustomPagination from "../../utils/CustomPagination"
import CustomIcons from "../../assets/CustomIcons"

const Faqs = () => {
  const [faqsList, setFaqsList] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState("")
  const [searchTemp, setSearchTemp] = useState("")
  const [perPage, setPerPage] = useState(10)
  const { loading } = useSelector(appState)

  const getFaqsList = async () => {
    if (loading) {
      return
    }
    var data = {
      pageNo: pageNumber,
      pageSize: perPage,
      search: search,
    }
    const res = await clientService.post("StudentFaq/GetAllFaqList", data)
    if (res.code == 200 && res.status) {
      setFaqsList(res)
    }
  }

  useEffect(() => {
    getFaqsList()
  }, [pageNumber, perPage])

  useEffect(() => {
    if(pageNumber == 1) {
      getFaqsList()
    }else{
      setPageNumber(1)
    }
  }, [search])

  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="faq-area-main-area">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">FAQ</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="faq-content-area">
            <div className="row height d-flex justify-content-center align-items-center">
              <div className="col-md-12">
                <div className="search-main-area mt-2 mb-5">
                  <div className="search mb-3">
                    <span className="icon-search">
                      <span
                        dangerouslySetInnerHTML={{ __html: CustomIcons.search }}
                      ></span>
                    </span>
                    <input
                      className="form-control text-white"
                      type="text"
                      name="search"
                      placeholder="Search"
                      value={searchTemp}
                      onChange={(e) => {
                        if(e.target.value === ""){
                          setSearch("")
                        }
                        setSearchTemp(e.target.value)
                      }}
                      onKeyUp={(e) => {
                        if (e.key == "Enter") {
                          setSearch(searchTemp)
                        }
                      }}
                    />
                    {search ? 
                    <span className="icon-clear pointer" onClick={() => setSearchTemp("")}>
                      <span
                        dangerouslySetInnerHTML={{ __html: CustomIcons.cross_icon }}
                      ></span>
                    </span> : null }
                  </div>
                </div>

                {faqsList?.data?.faqListModels?.length > 0 ? (
                  <>

                    <div
                      key={`faqs`}
                      className="faq-listings-main-area"
                    >
                      <div
                        className="faq-listings-inner-area accordion"
                        id={`faqaccordion`}
                      >
                        <Accordion defaultActiveKey="46543513232">
                          {faqsList?.data?.faqListModels?.map((items, index) => {
                            return (

                              <Accordion.Item eventKey={`${search}${index}`}>
                                <Accordion.Header>
                                  {items?.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>{items?.answer}</p>
                                </Accordion.Body>
                              </Accordion.Item>

                            )
                          })}
                        </Accordion>
                      </div>
                    </div>
                    <CustomPagination
                      total={faqsList?.data?.total}
                      limit={perPage}
                      curretnPage={pageNumber}
                      setPage={setPageNumber}
                      setLimit={setPerPage}
                    />
                  </>
                ) : !loading ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="text-white">0 results found</div>
                      <div className="empty-block-area mt-5 pt-5 mb-5">
                        <div className="text-center">
                          <div className="empty-block-icon mb-3">
                            <img
                              src="/img/ic_faq_empty.png"
                              className="img-fluid"
                              alt="FAQ Empty"
                            />
                          </div>
                          <div className="empty-block-title">
                            <h6 className="title">No results.</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faqs

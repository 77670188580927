import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useLocation, useParams } from "react-router"
import { Link } from "react-router-dom"
import NoData from "../../../common/NoData"
import clientService from "../../../services/service"
import TestYourSelfResult from "./TestYourSelfResult"

const QuestionBank = () => {
  const { courseId } = useParams()
  const { state } = useLocation()
  const [questions, setQuestions] = useState([])
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [ansIds, setAnsIds] = useState([])
  const [isAnswered, setIsAnswered] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [correctCount, setCorrectCount] = useState(0)

  const [sumittedRespons, setSubmittedResponse] = useState({})
  const alert = useAlert()

  useEffect(() => {
    getQuestions()
  }, [courseId])

  const getQuestions = async () => {
    var data = {
      courseGuideIds: state,
    }
    const res = await clientService.post(
      "StudentTestYourself/GetQuestionBanks",
      data
    )
    setQuestions(res.data?.quizLessions || [])
    setTotalQuestion(res.data?.totalQuestion || 0)
  }

  const getClass = (optionId) => {
    if (sumittedRespons.isAnswerCorrect === true) {
      if (ansIds.indexOf(optionId) >= 0) {
        return "right_ans form-check custom_radio pointer"
      }
    } else if (sumittedRespons.isAnswerCorrect === false) {
      if (ansIds.indexOf(optionId) >= 0) {
        return "false_ans form-check custom_radio pointer"
      }
    }

    if (
      questions[currentQuestionIndex]?.quizState == 1 &&
      questions[currentQuestionIndex]?.answersList?.indexOf("optionId") >= 0
    ) {
      return "right_ans form-check custom_radio pointer"
    }
    if (
      questions[currentQuestionIndex]?.quizState == 2 &&
      questions[currentQuestionIndex]?.answersList?.indexOf("optionId") >= 0
    ) {
      return "false_ans form-check custom_radio pointer"
    }
    return " form-check custom_radio pointer"
  }
  const next = () => {
    setAnsIds([])
    setIsAnswered(false)
    if (sumittedRespons.isQuizCompleted) {
      // getCourseInfo();
      setShowResult(true)
    }
    setSubmittedResponse({})
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setShowResult(true)
    }
  }
  const saveData = async () => {
    var isCorrectAns = true
    for (var i = 0; i < questions[currentQuestionIndex].options.length; i++) {
      if (
        questions[currentQuestionIndex].options[i].isCorrect === true &&
        ansIds.indexOf(
          questions[currentQuestionIndex].options[i].quizLessionOptionId
        ) == -1
      ) {
        isCorrectAns = false
      } else if (
        questions[currentQuestionIndex].options[i].isCorrect === false &&
        ansIds.indexOf(
          questions[currentQuestionIndex].options[i].quizLessionOptionId
        ) >= 0
      ) {
        isCorrectAns = false
      }
    }

    if (isCorrectAns) {
      setCorrectCount(correctCount + 1)
    }
    const ques = [...questions]
    ques[currentQuestionIndex].isAnswerCorrect = isCorrectAns
    ques[currentQuestionIndex].isAttempt = true
    ques[currentQuestionIndex].quizState = isCorrectAns ? 1 : 2
    ques[currentQuestionIndex].selectedOptions = ansIds
    setQuestions(ques)
  }

  const getResult = () => {
    if (questions[currentQuestionIndex]?.isAttempt || isAnswered) {
      if (
        sumittedRespons.isAnswerCorrect === true ||
        questions[currentQuestionIndex]?.quizState == 1
      ) {
        return (
          <div className="form-check ans_submit_msg">
            <p>
              <img src="/img/emo.png" alt="#" />
              Good job!
            </p>
          </div>
        )
      } else if (
        sumittedRespons.isAnswerCorrect === false ||
        questions[currentQuestionIndex]?.quizState == 2
      ) {
        return (
          <div className="incrrect_ans">
            <div className="form-check ans_submit_msg">
              <p>
                <img src="/img/false_ans.png" alt="#" />
                This answer is incorrect!
              </p>
            </div>
          </div>
        )
      }
    }
    return ""
  }

  if (showResult) {
    return (
      <TestYourSelfResult
        questions={questions}
        correctCount={correctCount}
        courseId={courseId}
      />
    )
  }

  return (
    <>
      <div className="aside-content-wrapper white_body random_test">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/course/test-yourself/${courseId}`}>
                        Random test
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link
                        to={`/course/test-yourself/${courseId}/pick-lecture`}
                      >
                        Pick lectures
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Test
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {totalQuestion > 0 ? (
              <div className="col-lg-12">
                <div className="full">
                  <p className="light_text">
                    {" "}
                    Questions {currentQuestionIndex + 1} of {totalQuestion}
                  </p>
                </div>
                <div className="full">
                  <h3>
                    {" "}
                    {questions[currentQuestionIndex]?.question}{" "}
                    <span className="multi_question">
                      {questions[currentQuestionIndex]?.questionType === 2
                        ? `[Select Multiple Answers]`
                        : null}
                    </span>
                  </h3>
                </div>
                <div className="full">
                  <div className="radio_button_section">
                    {questions[currentQuestionIndex]?.options?.map(
                      (option, opIndex) => {
                        return (
                          <div
                            key={option.quizLessionOptionId}
                            onClick={() => {
                              if (
                                questions[currentQuestionIndex].isAttempt ||
                                isAnswered
                              ) {
                                return
                              }
                              if (
                                questions[currentQuestionIndex]?.questionType ==
                                1
                              ) {
                                setAnsIds([option.quizLessionOptionId])
                              } else {
                                const aids = [...ansIds]
                                const itemIndex = aids.indexOf(
                                  option.quizLessionOptionId
                                )
                                if (itemIndex >= 0) {
                                  aids.splice(itemIndex, 1)
                                } else {
                                  aids.push(option.quizLessionOptionId)
                                }
                                setAnsIds(aids)
                              }
                            }}
                            className={getClass(option.quizLessionOptionId)}
                          >
                            <input
                              id={option.quizLessionOptionId}
                              className="form-check-input"
                              type={
                                questions[currentQuestionIndex]?.questionType ==
                                2
                                  ? "checkbox"
                                  : "radio"
                              }
                              name="flexRadioDefault"
                              checked={
                                questions[
                                  currentQuestionIndex
                                ]?.answersList?.indexOf(
                                  option.quizLessionOptionId
                                ) >= 0 ||
                                ansIds.indexOf(option.quizLessionOptionId) >= 0
                              }
                              value={option.quizLessionOptionId}
                            />
                            <label className="form-check-label">
                              {option.optionName}
                            </label>
                          </div>
                        )
                      }
                    )}
                  </div>
                  {getResult()}
                </div>
              </div>
            ) : (
              <NoData title={"There is no question"} />
            )}
          </div>
        </div>
        {totalQuestion > 0 ? (
          <footer className="guide-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  {questions[currentQuestionIndex]?.isAttempt || isAnswered ? (
                    <button
                      className="main_btn_default blue_color float-end dark-purple"
                      onClick={next}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      disabled={ansIds.length == 0}
                      className="dark-purple main_btn_default blue_color float-end dark-purple"
                      onClick={saveData}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </footer>
        ) : null}
      </div>
    </>
  )
}
export default QuestionBank

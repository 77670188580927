import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import VideoPlayer from "../../common/VideoPlayer";
import clientService from "../../services/service";
import { appState } from "../../store/slices/appSlice";

const StudentLearningGuide = () => {
  const [courseInfo, serCourseInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState([]);
  const { courseId } = useParams();
  const { loading } = useSelector(appState);
  const navigate = useNavigate();
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const { setShowSideBar } = useOutletContext();
  const [vimeoLink, setVimeoLink] = useState();

  useEffect(() => {
    getLoadinScreen();
    getCourseDetails();
    setShowSideBar(false);

    if (courseId == localStorage.getItem("courseId")) {
      setShowLoadingScreen(false);
    } else {
      localStorage.setItem("courseId", courseId);
    }
  }, [courseId]);

  const getCourseDetails = async () => {
    const res = await clientService.get(
      `StudentCourse/GetAllCourseGuides?courseId=${courseId}`
    );
    serCourseInfo(res.data);
  };

  const getLoadinScreen = async () => {
    const res = await clientService.get(
      `StudentCourse/GetLoadingScreen?courseId=${courseId}`
    );
    setLoadingInfo(res);
    if (res?.data?.randomTipsContents[0]?.vimeoLink) {
      setVimeoLink(res?.data?.randomTipsContents[0]?.vimeoLink);
    } else if (res?.data?.connectTipsContents[0]?.vimeoLink) {
      setVimeoLink(res?.data?.connectTipsContents[0]?.vimeoLink);
    } else {
      setShowSideBar(true);
      setShowLoadingScreen(false);
    }
  };

  useEffect(() => {
    if (courseInfo && courseInfo?.isLeft) {
      navigate("/course/preview/" + courseId);
    }
  }, [courseInfo]);

  if (courseInfo === null) {
    return null;
  }
  if (showLoadingScreen) {

    return (
      <>
        <div className="aside-content-wrapper cources">
          {vimeoLink ? (
            <VideoPlayer
              autoplay={true}
              className="img-fluid height-100"
              videoId={vimeoLink}
              background={true}
              isVideoAvailable={true}
              onEnd={() => {
                setShowSideBar(true);
                setShowLoadingScreen(false);
              }}
            />
          ) : null}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="aside-content-wrapper cources">
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">
                    {courseInfo?.course?.courseName}
                  </h2>
                </div>
              </div>
            </div>
            {courseInfo?.isFreezed === false ? (
              <div className="asied-tiles-listings learning-blog">
                <div className="row mt-2">
                  {courseInfo?.learnigCourseGuides?.map((course, index) => {
                    const currentdate = new Date().toISOString();
                    return (
                      <div
                        key={`course${index}`}
                        className="col-12 col-xl-4 col-lg-6 mb-4"
                      >
                        <Link
                          to={
                            course.isActive
                              ? course.type == 1 || course.type == 3
                                ? course.isCompleted
                                  ? `/course/result/${courseId}/${course.courseGuideLessionId}`
                                  : `/course/quiz/${courseId}/${course.courseGuideLessionId}`
                                : course.type == 4
                                  ? `/course/final-evalution/${courseId}/${course.courseGuideId}`
                                  : `/course/guide/${courseId}/${course.courseGuideId}`
                              : ""
                          }
                        >
                          <div
                            className={
                              course.isActive
                                ? "prize-block-grid-inner"
                                : "prize-block-grid-inner freezed-guide"
                            }
                          >
                            <div className="prize-block-img">
                              <div className="image-block">
                                <img src={course.image} alt="#" />
                              </div>
                              {course.isActive ? null : (
                                <div className="prize-popover-area">
                                  <div className="prize-popover-inner">
                                    <h4 className="title">
                                      {/* {course.guideName}  */} Unavailable
                                    </h4>
                                    <p>
                                      {((course?.isWaitingForFeedBack || (course?.startDate > currentdate)) && (courseInfo?.learnigCourseGuides[index - 1]?.isCompleted || index == 0))
                                        ? `This lesson is currently unavailable. This lesson will be unlocked by either your trainer reviewing your video submission, or your next assignment opening on
                                      ${moment(course?.startDate).format("MM/DD/YYYY")}`
                                        : "You must complete previous weeks lessons before unlocking this lesson."}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="prize-block-caption">
                              <h3 className="title d-flex space_between">
                                <span>{course.typeName}</span>
                                <span className="light_color">
                                  {course.lessonCount}{" "}
                                  {course.type == 2 ? "Lessons" : "Questions"}
                                </span>
                              </h3>
                              <h5 className="prize-points mb-3">
                                {course.guideName}
                              </h5>
                              {course.type == 4 ? (
                                <div className="tinstatus">
                                  {course.isActive
                                    ? course.finalEvalueationStatusName
                                    : "Unavailable"}
                                </div>
                              ) : (
                                <div className="progress tin">
                                  <div
                                    className="progress-bar"
                                    style={{ width: course.progress + "%" }}
                                    role="progressbar"
                                    aria-valuenow={50}
                                    aria-valuemin="100"
                                    aria-valuemax="100"
                                  >
                                    <span>{course.progress}%</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : !loading ? (
              <>
                <div className="row mt-5 pointer">
                  <div className="col-md-12 mt-5">
                    <div className="full text-center">
                      <img
                        className="empty-icon-img"
                        src="/img/transparent_empty_icon.png"
                        alt="#"
                      />
                    </div>
                    <div className="full text-center pointer">
                      <h3 className="mb-0 mt-5">
                        {" "}
                        This course has been frozen by Combat Sales.
                      </h3>
                      <br />
                      <p className="white_color mb-0">
                        Please, contact your trainer {courseInfo?.trainerName}{" "}
                        at{" "}
                        <a
                          href={`mailto:${courseInfo?.trainerEmail}`}
                          className="purple-color"
                        >
                          {courseInfo?.trainerEmail}{" "}
                        </a>
                        <br /> to restore your progress.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div class="bg-shape-area cbg-shape-area">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1174 900"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_912_55058)">
            <path
              d="M300.056 1007.3C412.982 1085.17 547.3 1126.06 684.461 1124.32C821.622 1122.58 954.859 1078.3 1065.77 997.584C1176.68 916.871 1259.8 803.711 1303.64 673.734C1347.48 543.756 1349.88 403.373 1310.51 271.971L1106.72 333.028C1133.44 422.222 1131.81 517.513 1102.05 605.741C1072.3 693.969 1015.88 770.78 940.591 825.568C865.305 880.355 774.865 910.416 681.761 911.597C588.657 912.779 497.483 885.024 420.83 832.164L300.056 1007.3Z"
              fill="#3F1485"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_912_55058"
              x="0.0565186"
              y="-28.0289"
              width="1638.32"
              height="1452.41"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150"
                result="effect1_foregroundBlur_912_55058"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default StudentLearningGuide;

import { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { getSubmittedScenarios, reviewerCourseState } from "../../store/slices/reviewer/reviewerCourseSlice"
import Reviewed from "./Reviewed"
import ToReview from "./ToReview"

const RandomiserOverViewDetail = () => {
  const { type, courseId } = useParams()
  const { scenarios } = useSelector(reviewerCourseState)
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(
    type === "reviewed" ? "reviewed" : "to_review"
  )

  useEffect(() => {
    dispatch(getSubmittedScenarios({ courseId: courseId }))
  }, [dispatch])
  return (
    <>
      <div className="container-fluid">
        <div className="overview-area">
          <div className="row">
            <div className="col-12">
              <div className="breadcrum light_breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/reviewer/randomiser/overview" className="text-light">Courses</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {scenarios?.courseName}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-12">
              <div className="tabbar_head reviewer-tabs">
              <Tabs
                  defaultActiveKey="to_review"
                  className="nav nav-pills"
                  activeKey={currentTab}
                  onSelect={(k) => setCurrentTab(k)}
                >
                  <Tab
                    eventKey="to_review"
                    title="To review"
                    className="nav-item"
                  >
                    {currentTab === "to_review" ? <ToReview /> : null}
                  </Tab>
                  <Tab
                    eventKey="reviewed"
                    title="Reviewed"
                    className="nav-item"
                  >
                    {currentTab === "reviewed" ? <Reviewed /> : null}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RandomiserOverViewDetail

import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"

import moment from "moment-timezone";
import clientService from "../../services/service";
import { useAlert } from "react-alert";

const DateConfirm = ({ onClose, startDate, course }) => {

  const alert = useAlert()
  const saveData = async () => {

    const dt = startDate.toISOString().replace(".000", "");
    const postData = {
        "courseId": course.id,
        "startDate": dt
      }
      try{
        const res = await clientService.post("StudentCourse/EnrollToCourse", postData);
        if(res.code === 200 && res.status) {
          alert.success(res.message);
          onClose(true)
        }
      }catch(e){

      }
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap resource-popup "
        aria-labelledby="date_confirm"
        size="lg"
        id="date_confirm"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Date Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color break-word">
            You have selected {moment(startDate).format("dddd, MMMM, Do, YYYY")} to start “{course.course}”
            <br />
            Your assigments are due every {moment(startDate).format("dddd,")} by 11:59 pm EST (10 pm MST).
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <a
              onClick={saveData}
              className="main_btn_default"
              role="button"
            >
              Confirm
            </a>
            <button
              onClick={onClose}
              className="btn btn-fclose"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Back to calendar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DateConfirm

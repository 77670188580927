import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../components/Shared/SideBar";
import { Container, Row } from "react-bootstrap"
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AdminHeader from "../common/dashboard/Admin";
import { authState, getProfile } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

import io from "socket.io-client";
import config from "../config";
import { appState } from "../store/slices/appSlice";
const socket = io(config.socketUrl);

const ReviewerBody = () => {
  const dispatch = useDispatch();
  const { getProfileDataResponse } = useSelector(authState);
  const { fullScreen } = useSelector(appState);
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const handleInviteAccepted = useCallback((data) => {
    // window.location.reload();
  }, []);

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});
    // socket.on("FinalEvaluation", handleInviteAccepted);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  if (!getProfileDataResponse.id) {
    return <div>Loading</div>;
  }
  if (getProfileDataResponse.roleName != "Reviewer") {
    if (getProfileDataResponse.roleName == "Trainer")
      return <Navigate to="/trainer" />;
    else if (getProfileDataResponse.roleName == "Student")
      return <Navigate to="/" />;
    else return <Navigate to="/404" />;
  }

  return (
    <>
      <div className="main overflow_scroll small_header">
        <AdminHeader fullScreen={fullScreen} userRole="Reviewer"/>
        <Container fluid>
          <Row>
            <SideBar userRole="reviewer" fullScreen={fullScreen} />
            <div className={`random_test ${fullScreen ? "full-screen" : ""}`}>
              <div className="aside-content-wrapper">
                <Outlet />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ReviewerBody;

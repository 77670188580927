import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"
import jwt from "jwt-decode"

export const authInitialState = {
  loading: false,
  newUser: {},
  loginResponse: {},
  getProfileDataResponse: {},
  changePasswordResponse: {},
  forgotPasswordLink: {},
  validateForgotPasswordLink: {},
  updateProfileDataResponse: [],
  loggedInuser: {},
  jwtData: {},
  emailResponse: {},
  passwordResp: {},
  validateInviteResponse: {},
  inviteResp: {},
  deleteUser: {},
  uploadProfilePic: {},
  deletedProfilePic: {},
  emailLink: {},
  validateEmail: {},
  loadingCounter: 1,
}

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    updateLoadCounter: (state, action) => {
      state.loadingCounter = state.loadingCounter + 1
    },
    setUserDataOnInit: (state, action) => {
      state.loggedInuser = action.payload // jwt(localStorage.getItem("token"));
      state.jwtData = action.payload // jwt(localStorage.getItem("token"));
      state.loggedInuser.roleName = action.payload.roleType
        ? action.payload.roleType
        : action.payload.roleName // jwt(localStorage.getItem("token"));
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setNewUserData: (state, action) => {
      state.newUser = action.payload
    },
    setLoginResponse: (state, action) => {
      state.loginResponse = action.payload
      try {
        state.loggedInuser = jwt(action.payload.data)
      } catch (e) { }
    },
    setGetProfileDataResponse: (state, action) => {
      localStorage.setItem("companyId", action.payload.data?.companyId)
      state.getProfileDataResponse = action.payload.data
      state.loggedInuser = action.payload.data
    },
    setUpdateProfileDataResponse: (state, action) => {
      state.updateProfileDataResponse = action.payload
    },
    setChangePasswordResponse: (state, action) => {
      state.changePasswordResponse = action.payload
    },
    setSendForgotPasswordLinkResponse: (state, action) => {
      state.forgotPasswordLink = action.payload
    },
    setValidateForgotPasswordLinkResponse: (state, action) => {
      state.validateForgotPasswordLink = action.payload
    },
    setEmailExistsResponse: (state, action) => {
      state.emailResponse = action.payload
    },
    setNewPasswordResponse: (state, action) => {
      state.passwordResp = action.payload
    },
    setValidateInvitationResponse: (state, action) => {
      state.validateInviteResponse = action.payload
    },
    setInvitationResponse: (state, action) => {
      state.inviteResp = action.payload
    },
    setDeleteUserResponse: (state, action) => {
      state.deleteUser = action.payload
    },
    setUploadedProfilePicResponse: (state, action) => {
      state.uploadProfilePic = action.payload
    },
    setDeletedProfilePicResponse: (state, action) => {
      state.deletedProfilePic = action.payload
    },
    setSendEmailLinkResponse: (state, action) => {
      state.emailLink = action.payload
    },
    setValidateEmailLinkResponse: (state, action) => {
      state.validateEmail = action.payload
    },
  },
})

export const setLoginData = () => async (dispatch) => {
  const data = jwt(localStorage.getItem("token"))
  dispatch(setUserDataOnInit(data))
}

export const register = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "Auth/UserRegistration",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    localStorage.setItem("token", response.data)
    dispatch(setNewUserData(response))
    const data = jwt(response.data)
    dispatch(setUserDataOnInit(data))
  }
}

export const login = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Auth/UserLogin", data, setErrors)
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else if (response.code === 403) {
    localStorage.clear()
    window.alert(response?.message);
    window.location.assign("/login")
  } else {
    localStorage.setItem("token", response.data)
    dispatch(setLoginResponse(response))
    const data = jwt(response.data)
    dispatch(setUserDataOnInit(data))
  }
}

export const loginWithGoogle = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "Auth/ExternalSignIn",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    localStorage.setItem("token", response.data)
    dispatch(setLoginResponse(response))
    const data = jwt(response.data)
    dispatch(setUserDataOnInit(data))
  }
}

export const checkEmailExists = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `Auth/CheckEmailAlreadyExists?email=${data.email}`,
    data,
    null
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    if (response.data) {
      setErrors({ email: response.message })
    }
    dispatch(setEmailExistsResponse(response))
  }
}

export const getProfile = (setErrors) => async (dispatch) => {
  const response = await clientService.get("User/GetUserDetail", setErrors)
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else if (response.code === 403) {
    localStorage.clear()
    window.alert(response?.message);
    window.location.assign("/login")
  } else {
    dispatch(setGetProfileDataResponse(response))
  }
}

export const updateProfile = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("User/UpdateUser", data, setErrors)
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUpdateProfileDataResponse(response))
    dispatch(getProfile())
  }
}

export const changePassword = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "User/Changepassword",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setChangePasswordResponse(response))
  }
}

export const sendForgotPasswordLink = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "Auth/SendForgotPasswordLink",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setSendForgotPasswordLinkResponse(response))
  }
}

export const validateForgotPassLink = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `Auth/ValidateForgotPasswordLink?activationCode=${data.verificationCode}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setValidateForgotPasswordLinkResponse(response))
  }
}

export const setNewPassword = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "Auth/SetNewPassword",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setNewPasswordResponse(response))
  }
}

export const validateInvitation = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `Auth/ValidateInvitation?activationCode=${data.activationCode}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setValidateInvitationResponse(response))
  }
}

export const acceptInvitation = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "Auth/AcceptInvitation",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setInvitationResponse(response))
  }
}

export const deleteMyAccount = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "User/DeleteMyAccount",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeleteUserResponse(response))
  }
}

export const uploadProfilePicture = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "User/UploadProfileImage",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUploadedProfilePicResponse(response))
    dispatch(getProfile())
  }
}
export const deleteProfilePicture = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "User/DeleteProfilePicture",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeletedProfilePicResponse(response))
    dispatch(getProfile())
  }
}

export const sendEmailVerificationLink =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      `Auth/SendEmailVerificationMail?email=${data.email}`,
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setSendEmailLinkResponse(response))
    }
  }

export const validateEmailLink = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `Auth/ValidateEmailVerificationLink?activationCode=${data.activationCode}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setValidateEmailLinkResponse(response))
  }
}

export const {
  setLoader,
  setNewUserData,
  setLoginResponse,
  setChangePasswordResponse,
  setSendForgotPasswordLinkResponse,
  setValidateForgotPasswordLinkResponse,
  setGetProfileDataResponse,
  setUpdateProfileDataResponse,
  setUserDataOnInit,
  setEmailExistsResponse,
  setNewPasswordResponse,
  setInvitationResponse,
  setValidateInvitationResponse,
  setDeleteUserResponse,
  setDeletedProfilePicResponse,
  setUploadedProfilePicResponse,
  setSendEmailLinkResponse,
  setValidateEmailLinkResponse,
  updateLoadCounter,
} = authSlice.actions

export const authState = (state) => {
  return state.auth
}

export default authSlice.reducer

import React, { useEffect, useState } from "react"
import clientService from "../../../services/service"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { authState, getProfile } from "../../../store/slices/authSlice"
import CustomIcons from "../../../assets/CustomIcons"
import TrainerVideoUploadLessonDetail from "./VideoUploadDetail"
import { useLocation } from "react-router-dom"
import VideoPlayer from "../../../common/VideoPlayer"
import UserAvtar from "../../../common/UserAvtar"

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const TrainerVideUploadLesson = ({ lesson, prev, next }) => {
  const [lessonInfo, setLessonInfo] = useState({})
  const [showDetail, setShowDetail] = useState(false)
  const [studentGuideId, setStudentGuideId] = useState("")
  const [pageNo, setPageNo] = useState(1)
  const dispatch = useDispatch()

  const query = useQuery()
  const guideLessonId = query.get("lessonId")
  const discussionId = query.get("discussionId")
  const studentCourseGuideLessionId = query.get("studentCourseGuideLessionId")

  useEffect(() => {
    getGuideLessons()
    dispatch(getProfile())
  }, [lesson?.courseGuideLessonId])

  useEffect(() => {
    if (pageNo == 1) {
      return
    } else {
      setPageNo(1)
    }
  }, [lesson?.courseGuideLessonId])

  const getGuideLessons = async () => {
    var data = {
      courseGuideLessonId: lesson?.courseGuideLessonId,
      pageNo: 1,
      pageSize: 10,
    }
    const res = await clientService.post(
      "TrainerCourse/GetStudentUploadedVideos",
      data
    )
    setLessonInfo(res.data)

    if (studentCourseGuideLessionId) {
      for (let index = 0; index < res.data?.studentVideos?.length; index++) {
        if (
          res.data?.studentVideos[index].studentCourseGuideLessonId ==
          studentCourseGuideLessionId
        ) {
          setStudentGuideId(
            res.data?.studentVideos[index].studentCourseGuideLessonId
          )
          setShowDetail(true)
        }
      }
    }
  }

  return (
    <>
      <div className="video-upload-feedback">
        <div className="resources_Content">
          {showDetail ? (
            <TrainerVideoUploadLessonDetail
              setShowDetail={setShowDetail}
              studentGuideId={studentGuideId}
              lesson={lesson}
            />
          ) : (
            <>
              <h3>
                {lessonInfo?.totalVideo ? lessonInfo?.totalVideo : 0} videos
              </h3>
              {lessonInfo?.studentVideos && lessonInfo?.studentVideos.length > 0
                ? lessonInfo.studentVideos.map((video, index) => {
                    return (
                      <>
                        <a
                          onClick={() => {
                            setShowDetail(true)
                            setStudentGuideId(video?.studentCourseGuideLessonId)
                          }}
                        >
                          <div className="learning_video_section">
                            <div className="video_blog">
                              <div className="video_section">
                                {/* <video
                                webkit-playsinline="true"
                                playsinline="true"
                                className="img-fluid width-100 height-100"
                                controls
                                src={video?.videoAnswer}
                              >
                                {" "}
                              </video> */}
                                <VideoPlayer
                                  className="img-fluid height-100"
                                  videoId={video?.vimeoLink}
                                  isVideoAvailable={video?.isVideoAvailable}
                                />
                              </div>
                              <div className="training_video_info d-flex">
                                <div className="video_upload_info">
                                  <UserAvtar
                                    name={video?.userName}
                                    image={video.profileImage}
                                  />
                                  <div className="video_info">
                                    <h3>{video?.userName}</h3>
                                    <p>{video?.days} days ago</p>
                                  </div>
                                </div>
                                <div className="video_post_section">
                                  <p className="light_text">
                                    {video?.discussion}

                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: CustomIcons.message,
                                      }}
                                    ></span>
                                  </p>
                                  <p className="light_text">
                                    {video?.like}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: CustomIcons.like,
                                      }}
                                    ></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </>
                    )
                  })
                : null}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TrainerVideUploadLesson

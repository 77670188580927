import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useOutletContext, useParams, useNavigate } from "react-router-dom"
import UserAvtar from "../../common/UserAvtar"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import { authState } from "../../store/slices/authSlice"

const LeaderboardRandomiser = (props) => {
  const { courseId } = useParams()
  const [leaderBoard, setLeaderBoard] = useState({})
  const { loggedInuser } = useSelector(authState)
  const { loading } = useSelector(appState)

  const navigate = useNavigate()
  useEffect(() => {
    if (courseId) {
      getLeaderBoard()
    }
  }, [courseId])
  const getLeaderBoard = async () => {
    const res = await clientService.get(
      `StudentRandomizer/StudentLeaderboard?courseId=${courseId}`
    )
    setLeaderBoard(res.data)
  }
  return (
    <>
      <div className="aside-content-wrapper cources">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="randomister_blog">
                <div
                  className="static_form randomister_popup leaderboard-popup mt-5"
                  id="leaderboard_form"
                >
                  <div className="popup_section p-4">
                    <div className="popup_header">
                      <h4>Leaderboard</h4>
                      <a
                        data-bs-toggle="modal"
                        onClick={() => navigate(-1)}
                        href="#view_instruction"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                            fill="#E4E5EA"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="popup_content">
                      <div className="leaderboard_profile">
                        <div className="profile_img">
                          <UserAvtar
                            name={
                              loggedInuser.firstName +
                              " " +
                              loggedInuser.lastName
                            }
                            image={loggedInuser.profileImage}
                          />
                          <label className="notification">
                            {leaderBoard.studentRank?.rankNo
                              ? leaderBoard.studentRank?.rankNo
                              : !loading
                              ? "#"
                              : null}
                          </label>
                        </div>
                        <div className="profile_info">
                          <p>
                            Score:{" "}
                            <b>{leaderBoard.studentRank?.score || "-"}</b>
                          </p>
                          <h3>
                            {loggedInuser.firstName} {loggedInuser.lastName}
                          </h3>
                        </div>
                      </div>
                      <div className="table leaderboard_table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" width="15%">
                                Position
                              </th>
                              <th scope="col" width="65%">
                                User
                              </th>
                              <th
                                className="text-right"
                                scope="col"
                                width="20%"
                              >
                                Total Score
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaderBoard?.leaderBoarList?.map(
                              (student, index) => {
                                return (
                                  <tr>
                                    <th>
                                      <span className="number">
                                        {student.rankNo}
                                      </span>
                                    </th>
                                    <td>
                                      <div className="user-infobox">
                                        <UserAvtar
                                          name={student.studentName}
                                          image={student.studentImage}
                                        />
                                        <span>{student.studentName}</span>
                                      </div>
                                    </td>
                                    <td className="text-right">
                                      {student.score}
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                            {leaderBoard?.leaderBoarList?.length >= 1 ? null : (
                              <tr>
                                <th colSpan={3}>No record found</th>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaderboardRandomiser

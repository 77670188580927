import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const randomiserInitialState = {
    newRandomisersResponse: {},
    getRandomisersResponse: [],
    getRandomiserByIdResponse: {},
    updatedRandomisersResponse: {},
    publishedRandomisersResponse: {},
    duplicateRandomisersResponse: {},
    deleteRandomisersResponse: {},
    deleteRandomiserQuestionResponse: {},
    newInstruction: {},
    instructions: {},
}

export const randomiserSlice = createSlice({
  name: "randomiser",
  initialState: randomiserInitialState,
  reducers: {
    setCreateRandomiserResponse: (state, action) => {
      state.newRandomisersResponse = action.payload
    },
    setGetRandomiserResponse: (state, action) => {
      state.getRandomisersResponse = action.payload.data;
    },
    setGetRandomiserByIdResponse: (state, action) => {
      state.getRandomiserByIdResponse = action.payload.data
    },
    setUpdateRandomiserResponse: (state, action) => {
      state.updatedRandomisersResponse = action.payload
    },
    setPublishRandomiserResponse: (state, action) => {
      state.publishedRandomisersResponse = action.payload
    },
    setDuplicateRandomiserResponse: (state, action) => {
      state.duplicateRandomisersResponse = action.payload
    },
    setDeleteRandomiserResponse: (state, action) => {
      state.deleteRandomisersResponse = action.payload
    },
    setDeleteRandomiserQuestionResponse: (state, action) => {
      state.deleteRandomiserQuestionResponse = action.payload
    },
    setCreateInstructionResponse: (state, action) => {
      state.newInstruction = action.payload
    },
    setGetInstructionsResponse: (state, action) => {
      state.instructions = action.payload.data
    },
  },
  
})

export const createRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/CreateScenarios", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCreateRandomiserResponse(response))
  }
}

export const getAllRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/Getscenarios", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetRandomiserResponse(response))
  }
}

export const createInstruction = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/AddInstruction", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCreateInstructionResponse(response))
  }
}

export const getInstructionByCourse = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(`Randomizer/GetInstructionCourseWise?Id=${data.Id}`, data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetInstructionsResponse(response))
  }
}

export const getRandomiserById = (data) => async (dispatch) => {
  const response = await clientService.get(`Randomizer/GetScenariosIdWise?Id=${data.Id}`, data)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetRandomiserByIdResponse(response))
  }
}

export const updateRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/UpdateScenarios", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdateRandomiserResponse(response))
  }
}

export const publishRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/ScenariosPublished", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPublishRandomiserResponse(response))
  }
}

export const duplicateRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/DuplicateScenariosCreate", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDuplicateRandomiserResponse(response))
  }
}

export const deleteRandomiser = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/DeleteRandomizerScenarios", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteRandomiserResponse(response))
  }
}

export const deleteRandomiserQuestion = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Randomizer/DeleteQuestion", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteRandomiserQuestionResponse(response))
  }
}



export const {
    setCreateRandomiserResponse,
    setGetRandomiserResponse,
    setGetRandomiserByIdResponse,
    setUpdateRandomiserResponse,
    setPublishRandomiserResponse,
    setDuplicateRandomiserResponse,
    setDeleteRandomiserResponse,
    setDeleteRandomiserQuestionResponse,
    setGetInstructionsResponse,
    setCreateInstructionResponse
} = randomiserSlice.actions

export const randomiserState = (state) => {
  return state.randomiser
}

export default randomiserSlice.reducer

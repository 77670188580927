const DownloadFile = (url, fileName) => {

  fetch(url, {
    method: "GET",
    headers: {}
  })
    .then(response => {
      response.arrayBuffer().then(function (buffer) {
        console.log("TEST")
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
}

export default DownloadFile
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Offcanvas } from "react-bootstrap"
import clientService from "../../services/service"
import CustomIcons from "../../assets/CustomIcons"
import { Link, useNavigate } from "react-router-dom"
import { getProfile } from "../../store/slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import { appState } from "../../store/slices/appSlice"

const Notification = ({ loggedInuser }) => {
  const [shownotification, setShowNotification] = useState(false)
  const [notificationList, setNotificationList] = useState({})
  const handleCloseNotification = () => setShowNotification(false)
  const handleShowNotification = () => setShowNotification(true)
  const alert = useAlert()
  const [total, setTotal] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(30)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(appState)

  useEffect(() => {
    if (shownotification) {
      getAllNotification()
    }
  }, [shownotification, pageNumber, perPage])

  const getAllNotification = async () => {
    var data = {
      pageNo: pageNumber,
      pageSize: perPage,
    }
    if (loggedInuser?.roleName == "Student") {
      const res = await clientService.post(
        "StudentNotification/GetAllNotifications",
        data
      )
      setNotificationList(
        pageNumber == 1
          ? res?.data
          : [...notificationList, ...res?.data]
      )

      setTotal(res?.data?.total)
    } else if (loggedInuser?.roleName == "Reviewer") {
      const res = await clientService.post(
        "ReviewerNotification/GetAllNotifications",
        data
      )
      setNotificationList(
        pageNumber == 1
          ? res?.data
          : [...notificationList, ...res?.data]
      )
      setTotal(res?.data?.total)
    } else if (loggedInuser?.roleName == "Trainer") {
      const res = await clientService.post(
        "TrainerNotification/GetAllNotifications",
        data
      )
      setNotificationList(
        pageNumber == 1
          ? res?.data
          : [...notificationList, ...res?.data]
      )
      setTotal(res?.data?.total)
    }
  }

  const markComplete = async (data) => {
    if (loggedInuser?.roleName == "Student") {
      const res = await clientService.post("StudentNotification/MarkAsRead", {
        ids: [data],
      })
      if (res.code == 200 && res.status == true) {
        getAllNotification()
        setShowNotification(false)
      } else {
        alert.error(res.message)
        getAllNotification()
      }
    } else if (loggedInuser?.roleName == "Reviewer") {
      const res = await clientService.post("ReviewerNotification/MarkAsRead", {
        ids: [data],
      })
      if (res.code == 200 && res.status == true) {
        getAllNotification()
        setShowNotification(false)
      } else {
        getAllNotification()
      }
    } else if (loggedInuser?.roleName == "Trainer") {
      const res = await clientService.post("TrainerNotification/MarkAsRead", {
        ids: [data],
      })
      if (res.code == 200 && res.status == true) {
        getAllNotification()
        setShowNotification(false)
      } else {
        alert.error(res.message)
        getAllNotification()
      }
    }
    dispatch(getProfile())
  }

  const handleChecked = async () => {
    if (loggedInuser?.roleName == "Student") {
      const res = await clientService.get("StudentNotification/MarkAllAsRead")
      if (res.code == 200 && res.status == true) {
        alert.success(res.message)
        getAllNotification()
        setShowNotification(false)
      } else {
        alert.error(res.message)
        getAllNotification()
      }
    } else if (loggedInuser?.roleName == "Reviewer") {
      const res = await clientService.get("ReviewerNotification/MarkAllAsRead")
      if (res.code == 200 && res.status == true) {
        alert.success(res.message)
        getAllNotification()
        setShowNotification(false)
      } else {
        alert.error(res.message)
        getAllNotification()
      }
    } else if (loggedInuser?.roleName == "Trainer") {
      const res = await clientService.get("TrainerNotification/MarkAllAsRead")
      if (res.code == 200 && res.status == true) {
        alert.success(res.message)
        getAllNotification()
        setShowNotification(false)
      } else {
        alert.error(res.message)
        getAllNotification()
      }
    }
    dispatch(getProfile())
  }

  const navigateTo = (item) => {
    var URL_PREFIX = "/"
    if (loggedInuser?.roleName == "Reviewer") {
      URL_PREFIX = "/reviewer/"
    } else if (loggedInuser?.roleName == "Trainer") {
      URL_PREFIX = "/trainer/"
    }

    if (item.notificationType == 1 || item.notificationType == 18) {
      if (item.lessonId) {
        navigate(
          `${URL_PREFIX}course/guide/${item.courseId}/${item.guideId}?lessonId=${item.lessonId}`
        )
      } else if (item.guideId) {
        navigate(
          `${URL_PREFIX}course/guide/${item.courseId}/${item.guideId}`
        )
      } else {
        navigate(`${URL_PREFIX}course/learning-guide/${item.courseId}`)
      }
    } else if (item.notificationType == 2) {
      navigate(
        `${URL_PREFIX}course/guide/${item.courseId}/${item.guideId}?lessonId=${item.lessonId}`
      )
    } else if (item.notificationType == 3) {
      navigate(`${URL_PREFIX}course/randomiser/${item.courseId}`)
    } else if (item.notificationType == 4) {
      navigate(`${URL_PREFIX}prize-locker`)
    } else if (item.notificationType == 5) {
      navigate(`${URL_PREFIX}course/final-evalution/${item.courseId}`)
    } else if (item.notificationType == 6) {
      navigate(
        `${URL_PREFIX}course/final-evalution/${item.courseId}/${item.guideId}`
      )
    } else if (item.notificationType == 7) {
      navigate(`${URL_PREFIX}courses`)
    } else if (item.notificationType == 8) {
      navigate(`${URL_PREFIX}course/detail/${item.courseId}`)
    } else if (item.notificationType == 9) {
      navigate(`${URL_PREFIX}course/video-feedback/${item.courseId}`)
    } else if (item.notificationType == 10) {
      navigate(`${URL_PREFIX}course/final-evalution/${item.courseId}`)
    } else if (item.notificationType == 11) {
      navigate(`${URL_PREFIX}course/final-evalution/${item.courseId}`)
    } else if (item.notificationType == 12) {
      navigate(`${URL_PREFIX}course/final-evalution/${item.courseId}`)
    } else if (item.notificationType == 13) {
      navigate(`${URL_PREFIX}randomiser/detail/${item.courseId}`)
    } else if (item.notificationType == 15) {
      navigate(
        `${URL_PREFIX}course/guide/${item.courseId}/${item.guideId}?lessonId=${item.lessonId}`
      )
    } else if (loggedInuser?.roleName == "Trainer" && item.notificationType == 16) {
      navigate(`${URL_PREFIX}course/video-feedback/${item.courseId}`)
    } else if (loggedInuser?.roleName == "Student" && item.notificationType == 16) {
      navigate(`${URL_PREFIX}course/guide/${item.courseId}/${item.guideId}?lessonId=${item.lessonId}`)
    } else if (loggedInuser?.roleName == "Student" && item.notificationType == 17) {
      navigate(`${URL_PREFIX}courses`)
    }
    setShowNotification(false)
    markComplete(item?.id)
  }
  return (
    <>
      <div onClick={handleShowNotification} className="notification-main-area">
        <ul className="notification-drop">
          <li className="item">
            <button
              className="notification_btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNotification"
              aria-controls="offcanvasRight"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 1C10.1435 1 8.36302 1.7375 7.05027 3.05025C5.73751 4.36301 5.00002 6.14348 5.00002 8C5.00002 11.3527 4.28295 13.4346 3.62212 14.6461C3.29033 15.2544 2.96724 15.6535 2.74281 15.8904C2.63034 16.0091 2.54195 16.0878 2.48874 16.1322C2.46212 16.1544 2.44426 16.168 2.43656 16.1737L2.43371 16.1758C2.07522 16.4221 1.91658 16.8725 2.04292 17.2898C2.17062 17.7115 2.55935 18 3.00002 18H21C21.4407 18 21.8294 17.7115 21.9571 17.2898C22.0835 16.8725 21.9248 16.4221 21.5663 16.1758L21.5635 16.1737C21.5558 16.168 21.5379 16.1544 21.5113 16.1322C21.4581 16.0878 21.3697 16.0091 21.2572 15.8904C21.0328 15.6535 20.7097 15.2544 20.3779 14.6461C19.7171 13.4346 19 11.3527 19 8C19 6.14349 18.2625 4.36301 16.9498 3.05025C15.637 1.7375 13.8565 1 12 1ZM18.8507 16H5.14934C5.22543 15.8753 5.30181 15.7434 5.37791 15.6039C6.21708 14.0654 7.00002 11.6473 7.00002 8C7.00002 6.67392 7.5268 5.40215 8.46448 4.46447C9.40216 3.52678 10.6739 3 12 3C13.3261 3 14.5979 3.52678 15.5356 4.46447C16.4732 5.40215 17 6.67392 17 8C17 11.6473 17.7829 14.0654 18.6221 15.6039C18.6982 15.7434 18.7746 15.8753 18.8507 16ZM11.135 20.4983C10.8579 20.0205 10.246 19.8579 9.76824 20.135C9.29052 20.4122 9.1279 21.0241 9.40502 21.5018C9.66873 21.9564 10.0473 22.3338 10.5027 22.5961C10.9581 22.8584 11.4744 22.9965 12 22.9965C12.5256 22.9965 13.0419 22.8584 13.4974 22.5961C13.9528 22.3338 14.3313 21.9564 14.595 21.5018C14.8721 21.0241 14.7095 20.4122 14.2318 20.135C13.7541 19.8579 13.1421 20.0205 12.865 20.4983C12.7771 20.6498 12.6509 20.7756 12.4991 20.863C12.3473 20.9505 12.1752 20.9965 12 20.9965C11.8248 20.9965 11.6527 20.9505 11.5009 20.863C11.3491 20.7756 11.2229 20.6498 11.135 20.4983Z"
                  fill="#E4E5EA"
                />
              </svg>
              {loggedInuser?.notifications === 0 ? null : (
                <span className="btn__badge">
                  {" "}
                  {loggedInuser?.notifications}
                </span>
              )}
            </button>
          </li>
        </ul>
      </div>

      <Offcanvas
        show={shownotification}
        placement={"end"}
        onHide={handleCloseNotification}
        className="notificationOffCanvas"
      >
        <div className="offcanvas-body">
          <div className="notification_head">
            <p className="small">New</p>
            <div className="float-end">
              <div className="mark_checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="remember"
                  id="mark_read_all"
                  onClick={handleChecked}
                />
                <p>Mark all as read</p>
              </div>
              <button
                onClick={handleCloseNotification}
                type="button"
                className="btn-close notification_close"
                data-bs-dismiss="offcanvas"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                    fill="#E4E5EA"
                  />
                </svg>
              </button>
            </div>
          </div>
          {notificationList?.newNotifications && notificationList?.newNotifications?.length > 0
            ? notificationList?.newNotifications.map((item, index) => {

              var message = item?.message;
              if (item.dateValue) {
                const dateString = moment(item.dateValue).format(
                  "MM/DD/yyyy"
                );
                const timeString = moment(item.dateValue).format(
                  "hh:mm a"
                );
                message = message.replace("{Date}", dateString);
                message = message.replace("{Time}", timeString);
              }

              return (
                <>
                  <div
                    className="notification_content"
                    key={`notification_${index}`}
                  >
                    <div
                      className="notification_blog new"
                      onClick={() => {
                        navigateTo(item)
                      }}
                    >
                      <div className="d-flex position-relative">
                        <div className="icon_notification">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: CustomIcons.notification_bell,
                            }}
                          ></span>
                        </div>

                        <div>
                          <h5 className="mt-0">{item?.subject}</h5>
                          <p>
                            {message}
                            <br />
                            {/* <span>Today, 10:24 am</span> */}
                            <span>
                              {/* {moment(item?.notificationDate).format(
                                    "dddd, hh:mm a"
                                  )} */}
                              {moment.utc(item?.notificationDate)
                                .local()
                                .format("dddd, hh:mm a")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
            : !loading ? (<div className="white_text ml-10">There is no new notifications.</div>) : null}
          <div ke className="notification_head">
            <p className="small">Earlier</p>
          </div>
          {notificationList?.earlierNotifications && notificationList?.earlierNotifications?.length > 0
            ? notificationList?.earlierNotifications.map((item, index) => {

              var message = item?.message;
              if (item.dateValue) {
                const dateString = moment(item.dateValue).format(
                  "MM/DD/yyyy"
                );
                const timeString = moment(item.dateValue).format(
                  "hh:mm a"
                );
                message = message.replace("{Date}", dateString);
                message = message.replace("{Time}", timeString);
              }

              return (
                <>
                  <>
                    <div
                      className="notification_content"
                      key={`notification_earlier_${index}`}
                    >
                      <div
                        className="notification_blog"
                        onClick={() => navigateTo(item)}
                      >
                        <div className="d-flex position-relative pointer">
                          <div className="icon_notification">
                            {item?.profileImage ? (
                              <img src={item?.profileImage} alt="#" />
                            ) : (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: CustomIcons.notification_bell,
                                }}
                              ></span>
                            )}
                          </div>
                          <div>
                            <h5 className="mt-0">{item?.subject}</h5>
                            <p>
                              {message}
                              <br />
                              {/* <span>Today, 10:24 am</span> */}
                              <span>
                                {moment.utc(item?.notificationDate)
                                  .local()
                                  .format("dddd, hh:mm a")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </>
              )
            })
            : !loading ? (<div className="white_text ml-10">There is no notifications.</div>) : null}
        </div>
        {total > notificationList.length ? (
          <button
            className="btn btn-primary"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Load More
          </button>
        ) : null}
      </Offcanvas>
    </>
  )
}

export default Notification

import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import ResourceFilterType from "../../../consts/ResourceFilterType"
import clientService from "../../../services/service"
import DownloadFile from "../../../utils/DownloadFile"
import TextLessonPreview from "../../models/TextLessonPreview"
import VideoLessonPreview from "../../models/VideoLessonPreview"

const CollectionList = () => {
  const { courseId, collectionId } = useParams()
  const alert = useAlert()
  const [resourceInfo, setResourceInfo] = useState({})
  const [previewModel, setPreviewModel] = useState(false)
  const [previewVideoModel, setPreviewVideoModel] = useState(false)
  const [resourceId, setResourceId] = useState("")

  const closeModel = () => {
    setPreviewModel(false)
    setResourceId("")
  }
  const closeVideoModel = () => {
    setPreviewVideoModel(false)
    setResourceId("")
  }

  useEffect(() => {
    getAllResources()
  }, [courseId])

  const getAllResources = async () => {
    const res = await clientService.get(
      `TrainerResource/GetAllResourcesWithinCollection?CollectionId=${collectionId}`
    )
    setResourceInfo(res.data)
  }

  const getTitleInfo = () => {
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="aside-title-area mt-0 mb-0">
            <nav aria-label="breadcrumb collection-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    to={`/trainer/course/resources/${courseId}`}
                    className="text-white"
                  >
                    Resources
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Collection : {resourceInfo?.collectionName}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    )
  }

  if (collectionId != resourceInfo.collectionId) {
    return <div className="aside-content-wrapper resources_hide_hover"></div>
  }

  if (
    resourceInfo?.textResources &&
    resourceInfo?.textResources.length == 0 &&
    resourceInfo?.videoResources &&
    resourceInfo?.videoResources.length == 0 &&
    resourceInfo?.downloadFileResources &&
    resourceInfo?.downloadFileResources.length == 0 &&
    resourceInfo?.usefullLinkResources &&
    resourceInfo?.usefullLinkResources.length == 0
  ) {
    return (
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area resources-content mb-5">
            {getTitleInfo()}
            <div className="col-md-12 minus_blank_space mt-5">
              <div className="full text-center mt-5">
                <img src="/img/ic_faq_empty.png" alt="" />
              </div>
              <div className="full text-center">
                <p className="light_text mb-0 mt-4">
                  No results for selected filters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area resources-content mb-5">
            {getTitleInfo()}

            {resourceInfo?.textResources &&
              resourceInfo?.textResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">Text Resources</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.textResources &&
                resourceInfo?.textResources.length > 0
                ? resourceInfo?.textResources.map((text, index) => {
                  return (
                    <>
                      <div
                        key={`text_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link
                            to=""
                            onClick={() => {
                              setPreviewModel(true)
                              setResourceId(text?.resourseId)
                            }}
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor orange">
                                {text?.image ? (
                                  <img src={text?.image} />
                                ) : (
                                  <span className="up">
                                    {text?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{text?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
              {previewModel ? (
                <TextLessonPreview
                  content={resourceId}
                  onClose={closeModel}
                  previewbtn={false}
                />
              ) : null}
            </div>

            {resourceInfo?.videoResources &&
              resourceInfo?.videoResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">
                      Video Resources
                    </h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.videoResources &&
                resourceInfo?.videoResources.length > 0
                ? resourceInfo?.videoResources.map((video, index) => {
                  return (
                    <>
                      <div
                        key={`video_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link
                            to=""
                            onClick={() => {
                              setPreviewVideoModel(true)
                              setResourceId(video?.resourseId)
                            }}
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor orange">
                                {video?.image ? (
                                  <img src={video?.image} />
                                ) : (
                                  <span className="up">
                                    {video?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{video?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
              {previewVideoModel && (
                <VideoLessonPreview
                  content={resourceId}
                  onClose={closeVideoModel}
                  previewbtn={false}
                />
              )}
            </div>

            {resourceInfo?.downloadFileResources &&
              resourceInfo?.downloadFileResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">
                      Files to Download
                    </h2>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row">
              {resourceInfo?.downloadFileResources &&
                resourceInfo?.downloadFileResources.length > 0
                ? resourceInfo?.downloadFileResources.map((file, index) => {
                  return (
                    <>
                      <div
                        key={`file_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link to="">
                            <div onClick={() => {
                              DownloadFile(
                                file?.attachment,
                                file?.attachmentName
                              )
                            }}
                              className="d-flex position-relative">
                              <div className="resources_vendor blue">
                                {file?.image ? (
                                  <img src={file?.image} />
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.download,
                                    }}
                                  ></span>
                                )}
                              </div>
                              <div>
                                <p>{file?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
            </div>

            {resourceInfo?.usefullLinkResources &&
              resourceInfo?.usefullLinkResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">Useful Links</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.usefullLinkResources &&
                resourceInfo?.usefullLinkResources.length > 0
                ? resourceInfo?.usefullLinkResources.map((link, index) => {
                  return (
                    <>
                      <div
                        key={`link_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <a
                            href={
                              link?.link.indexOf("http") === 0
                                ? link?.link
                                : `http://${link?.link}`
                            }
                            target="_blank"
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor">
                                {link?.image ? (
                                  <img src={link?.image} />
                                ) : (
                                  <span className="up">
                                    {link?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{link?.resourceName}</p>
                                <label className="youtube">
                                  {link?.linkType}
                                </label>
                              </div>
                            </div>
                          </a>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionList

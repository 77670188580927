import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import CustomIcons from "../../../assets/CustomIcons"
import FinalEvaluationSearchType from "../../../consts/FinalEvaluationSearchType"
import clientService from "../../../services/service"
import { appState } from "../../../store/slices/appSlice"
import CustomDropDown from "../../../common/CustomDropDown"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import ViewFeedback from "../../models/ViewFeedback"
import moment from "moment"

const TrainerFinalEvalution = () => {
  const [search, setSearch] = useState("")
  const [showFeedbackModel, setShowFeedbackModel] = useState(false)
  const [evalutionInfo, setEvalutionInfo] = useState({})
  const { loading } = useSelector(appState)
  const [selectedFilter, setSelectedFilter] = useState(
    FinalEvaluationSearchType[0]
  )
  const { courseId } = useParams()
  const [studentList, setStudentList] = useState([])

  useEffect(() => {
    getStudentList()
  }, [courseId, selectedFilter])

  useEffect(() => {
    if (search === "") {
      getStudentList()
    }
  }, [search])

  const getStudentList = async () => {
    if (loading) {
      return
    }
    const data = {
      searchName: search,
      courseId: courseId,
      searchId: Number(selectedFilter.value),
    }
    const res = await clientService.post(
      "TrainerCourse/GetFinalEvaluationList",
      data
    )
    setStudentList(res)
  }

  const closeFeedbackModel = (e) => {
    if (e === true) {
      getStudentList()
    }
    setShowFeedbackModel(false)
  }
  return (
    <>
      <div className="aside-content-wrapper resources_hide_hover">
        <div className="container-fluid">
          <div className="overview-area resources-content mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area mb-4">
                  <h2 className="title title-lg mb-0">
                    {studentList?.data?.studentList?.data?.couseName}
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-9 col-xl-8 col-lg-7 col-md-7">
                <div className="searchbar_main form_section">
                  <div className="search_bar">
                    <fieldset>
                      <input
                        className="form-control cform-control"
                        type="text"
                        name="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value)
                        }}
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            getStudentList()
                          }
                        }}
                      />
                      <button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: CustomIcons.search,
                          }}
                        ></span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-5 evaluations_dropdown">
                <CustomDropDown
                  options={FinalEvaluationSearchType}
                  currentItem={selectedFilter}
                  onChange={setSelectedFilter}
                  label={"label"}
                />
              </div>
            </div>
            {studentList?.code === 200 && studentList?.status === true ? (
              <>
                {studentList?.data?.waitingForFinalEvaluationCount > 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="aside-title-area mt-4 mb-0">
                        <h3 className="title title-lg mb-0">
                          Waiting for Final Evaluations (
                          {studentList?.data?.waitingForFinalEvaluationCount})
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  {studentList?.data?.waitingForFinalEvaluationCount > 0
                    ? studentList?.data?.waitingForFinalEvaluation.map(
                        (evalutionItem, index) => {
                          var stillUtc = moment
                            .utc(evalutionItem.scheduleDate)
                            .toDate()
                          var local = moment(stillUtc)
                            .local()
                            .format("D MMM [at] h : m a")
                          return (
                            <>
                              <div className="col-lg-6 col-xl-3">
                                <div className="resources_blog">
                                  <p className="time_resources">
                                    <svg
                                      width="14"
                                      height="16"
                                      viewBox="0 0 14 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.3333 1.33334C10.3333 0.965149 10.0348 0.666672 9.66659 0.666672C9.2984 0.666672 8.99992 0.965149 8.99992 1.33334V2.00001H4.99992V1.33334C4.99992 0.965149 4.70144 0.666672 4.33325 0.666672C3.96506 0.666672 3.66659 0.965149 3.66659 1.33334V2.00001H2.33325C1.22868 2.00001 0.333252 2.89544 0.333252 4V6.66667V13.3333C0.333252 14.4379 1.22868 15.3333 2.33325 15.3333H11.6666C12.7712 15.3333 13.6666 14.4379 13.6666 13.3333V6.66667V4C13.6666 2.89544 12.7712 2.00001 11.6666 2.00001H10.3333V1.33334ZM12.3333 6V4C12.3333 3.63182 12.0348 3.33334 11.6666 3.33334H10.3333V4C10.3333 4.36819 10.0348 4.66667 9.66659 4.66667C9.2984 4.66667 8.99992 4.36819 8.99992 4V3.33334H4.99992V4C4.99992 4.36819 4.70144 4.66667 4.33325 4.66667C3.96506 4.66667 3.66659 4.36819 3.66659 4V3.33334H2.33325C1.96506 3.33334 1.66659 3.63182 1.66659 4V6H12.3333ZM1.66659 7.33334H12.3333V13.3333C12.3333 13.7015 12.0348 14 11.6666 14H2.33325C1.96506 14 1.66659 13.7015 1.66659 13.3333V7.33334Z"
                                        fill="white"
                                      />
                                    </svg>{" "}
                                    {local}
                                  </p>
                                  <div className="d-flex position-relative">
                                    <div className="resources_vendor orange">
                                      {evalutionItem?.profileImage ? (
                                        <img
                                          src={evalutionItem?.profileImage}
                                        />
                                      ) : (
                                        <span className="up">
                                          {evalutionItem?.studentName[0]}
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      <p>
                                        {evalutionItem?.studentName}
                                        <br />
                                        <small>
                                          {studentList?.data?.couseName}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                      )
                    : null}
                </div>
                {studentList?.data?.waitingForFeedbackCount > 0 ? (
                  <div className="row">
                    <div className="col-12 mt-2">
                      <div className="aside-title-area mt-4 mb-0">
                        <h3 className="title title-lg mb-0">
                          Waiting for Feedback (
                          {studentList?.data?.waitingForFeedbackCount})
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  {studentList?.data?.waitingForFeedbackCount > 0
                    ? studentList?.data?.waitingForFeedback.map(
                        (feedbackItem, index) => {
                          return (
                            <>
                              <div className="col-lg-6 col-xl-3">
                                <div className="resources_blog give_feedback">
                                  <Link
                                    data-bs-toggle="modal"
                                    to=""
                                    role="button"
                                    onClick={() => {
                                      setShowFeedbackModel(true)
                                      setEvalutionInfo(feedbackItem)
                                    }}
                                  >
                                    <p className="time_resources">
                                      <svg
                                        width="12"
                                        height="16"
                                        viewBox="0 0 12 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M0.585786 1.25245C0.960859 0.877381 1.46957 0.666668 2 0.666668H7.33333C7.51014 0.666668 7.67971 0.736906 7.80474 0.86193L11.8047 4.86193C11.9298 4.98695 12 5.15652 12 5.33333V13.3333C12 13.8638 11.7893 14.3725 11.4142 14.7475C11.0391 15.1226 10.5304 15.3333 10 15.3333H2C1.46957 15.3333 0.960859 15.1226 0.585786 14.7475C0.210714 14.3725 0 13.8638 0 13.3333V2.66667C0 2.13623 0.210714 1.62753 0.585786 1.25245ZM2 2C1.82319 2 1.65362 2.07024 1.5286 2.19526C1.40357 2.32029 1.33333 2.48986 1.33333 2.66667V13.3333C1.33333 13.5101 1.40357 13.6797 1.5286 13.8047C1.65362 13.9298 1.82319 14 2 14H10C10.1768 14 10.3464 13.9298 10.4714 13.8047C10.5964 13.6797 10.6667 13.5101 10.6667 13.3333V6H7.33333C6.96514 6 6.66667 5.70152 6.66667 5.33333V2H2ZM8 2.94281L9.72386 4.66667H8V2.94281ZM3.33333 8C2.96514 8 2.66667 8.29848 2.66667 8.66667C2.66667 9.03486 2.96514 9.33333 3.33333 9.33333H8.66667C9.03486 9.33333 9.33333 9.03486 9.33333 8.66667C9.33333 8.29848 9.03486 8 8.66667 8H3.33333ZM2.66667 11.3333C2.66667 10.9651 2.96514 10.6667 3.33333 10.6667H8.66667C9.03486 10.6667 9.33333 10.9651 9.33333 11.3333C9.33333 11.7015 9.03486 12 8.66667 12H3.33333C2.96514 12 2.66667 11.7015 2.66667 11.3333ZM3.33333 5.33333C2.96514 5.33333 2.66667 5.63181 2.66667 6C2.66667 6.36819 2.96514 6.66667 3.33333 6.66667H4.66667C5.03486 6.66667 5.33333 6.36819 5.33333 6C5.33333 5.63181 5.03486 5.33333 4.66667 5.33333H3.33333Z"
                                          fill="#B559FF"
                                        />
                                      </svg>{" "}
                                      GIVE FEEDBACK
                                    </p>
                                  </Link>
                                  <div className="d-flex position-relative">
                                    <div className="resources_vendor orange">
                                      {feedbackItem?.profileImage ? (
                                        <img src={feedbackItem?.profileImage} />
                                      ) : (
                                        <span className="up">
                                          {feedbackItem?.studentName[0]}
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      <p>
                                        {feedbackItem?.studentName}
                                        <br />
                                        <small>
                                          {studentList?.data?.couseName}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                      )
                    : null}
                </div>

                {studentList?.data?.givenFeedbackCount > 0 ? (
                  <div className="row">
                    <div className="col-12 mt-2">
                      <div className="aside-title-area mt-4 mb-0">
                        <h3 className="title title-lg mb-0">
                          Given Feedbacks (
                          {studentList?.data?.givenFeedbackCount}){" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  {studentList?.data?.givenFeedbackCount > 0
                    ? studentList?.data?.givenFeedback.map(
                        (givenItem, index) => {
                          return (
                            <>
                              <div className="col-lg-6 col-xl-3">
                                <div className="resources_blog give_feedback">
                                  <Link
                                    data-bs-toggle="modal"
                                    to=""
                                    role="button"
                                    onClick={() => {
                                      setShowFeedbackModel(true)
                                      setEvalutionInfo(givenItem)
                                    }}
                                  >
                                    <p className="time_resources">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: CustomIcons.document,
                                        }}
                                      ></span>{" "}
                                      VIEW FEEDBACK
                                    </p>
                                  </Link>
                                  <div className="d-flex position-relative">
                                    <div className="resources_vendor orange">
                                      {givenItem?.profileImage ? (
                                        <img src={givenItem?.profileImage} />
                                      ) : (
                                        <span className="up">
                                          {givenItem?.studentName[0]}
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      <p>
                                        {givenItem?.studentName}
                                        <br />
                                        <small>
                                          {studentList?.data?.couseName}
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                      )
                    : null}
                </div>
              </>
            ) : !loading ? (
              <div className="row mt-5">
                <div className="col-md-12 mt-5">
                  <div className="full text-center">
                    <img
                      className="empty-icon-img"
                      src="/img/unlock_vendor.png"
                      alt="#"
                    />
                  </div>
                  <div className="full text-center">
                    <p className="light_text mb-0 mt-5">
                      There is no data available yet.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showFeedbackModel && (
        <ViewFeedback
          onClose={closeFeedbackModel}
          evalutionInfo={evalutionInfo}
        />
      )}
    </>
  )
}

export default TrainerFinalEvalution

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import EditCourseStudentStatus from "../models/EditCourseStudentStatus"
import EditTrainerStudent from "../models/EditTrainerStudent"
import CustomPagination from "../../utils/CustomPagination"
import moment from "moment/moment"
import Select from "react-select"
import CustomIcons from "../../assets/CustomIcons"
import InviteStudent from "../models/InviteStudent"
import { Dropdown } from "react-bootstrap"
import RestorePassword from "./../models/RestorePassword"
import config from "../../config"

const TrainerStudent = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState("")
  const [perPage, setPerPage] = useState(10)
  const [sortBy, setSortBy] = useState("studentName")
  const [sortOrder, setSortOrder] = useState("ASC")
  const [showEditStatusModel, setShowEditStatusModel] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState([])
  const dispatch = useDispatch()
  const inputEl = useRef(null)
  const { loading } = useSelector(appState)
  const [studentList, setStudentList] = useState([])
  const [showEditModel, setShowEditModel] = useState(false)
  const [studentInfo, setStudentInfo] = useState({})
  const [showInviteModel, setShowInviteModel] = useState(false)
  const [activityStatus, setActivityStatus] = useState(null)
  const [courseStatus, setCourseStatus] = useState(null)
  const [team, setTeam] = useState([])
  const [selectedTeam, setSelectedTeam] = useState("")
  const [showRestoreModel, setShowRestoreModel] = useState(false)
  const [userInfo, setUserInfo] = useState({})

  const getTeam = async () => {
    const res = await clientService.get("TrainerCourse/GetTeamList")
    var _teamList = [{ teamName: "All Team", id: "" }, ...res?.data]
    setTeam(_teamList)
  }

  useEffect(() => {
    getTeam()
  }, [])

  useEffect(() => {
    getCourseStudentList()
  }, [
    dispatch,
    pageNumber,
    perPage,
    sortBy,
    sortOrder,
    search,
    selectedTeam,
    activityStatus,
    courseStatus,
  ])

  const getCourseStudentList = async () => {
    if (loading) {
      return
    }
    const data = {
      search: search,
      page: pageNumber,
      pageSize: perPage,
      sortingColunm: sortBy,
      sortingOrder: sortOrder,
      activityStatus: activityStatus,
      courseStatus: courseStatus,
      teamid: selectedTeam,
    }
    const res = await clientService.post("TrainerCourse/GetStudentList", data)
    setStudentList(res)
  }

  const closeStatusModel = (refresh) => {
    if (refresh === true) {
      getCourseStudentList()
    }
    setShowEditStatusModel(false)
    setSelectedStudent([])
  }

  const closeModel = (refresh) => {
    if (refresh === true) {
      getCourseStudentList()
    }
    setShowEditModel(false)
    setShowInviteModel(false)
    setStudentInfo({})
  }

  const getOrderClass = (columnName) => {
    if (columnName === sortBy) {
      if (sortOrder === "ASC") {
        return "pointer up"
      } else {
        return "pointer down"
      }
    } else {
      return "pointer up-down"
    }
  }

  const updateSortOrder = (sBy) => {
    if (sBy === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
    } else {
      setSortBy(sBy)
      setSortOrder("ASC")
    }
  }

  const selectStudentFun = (student) => {
    let _selectedStudent = [...selectedStudent]
    _selectedStudent.push(student?.userId)
    setSelectedStudent(_selectedStudent)
  }

  const removeStudent = (student) => {
    let _selectedStudent = [...selectedStudent]
    const index = _selectedStudent.findIndex(
      (studentId) => studentId === student?.userId
    )
    _selectedStudent.splice(index, 1)
    setSelectedStudent(_selectedStudent)
  }

  const customstyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#18191f",
        color: isDisabled ? "#9369c8" : "#9fa0a4",
      }
    },
  }

  const closeRestoreModel = (refresh) => {
    if (refresh === true) {
      getCourseStudentList()
    }
    setShowRestoreModel(false)
  }

  const logInAsUser = async (userInfo) => {
    const res = await clientService.post(
      `UserManagement/LoginAsUser?UserId=${userInfo?.userId}`
    )
    window.open(res?.data?.domain + "/login-as/" + res?.data?.token)
  }

  return (
    <>
      <div className="aside-content-wrapper overflow-hidden scroll-y">
        <div className="container-fluid">
          <div className="mt-3">
            <div className="col-12 d-flex justify-content-sm-between align-itemes-center flex-column flex-sm-row">
              <div className="aside-title-area">
                <h2 className="title title-lg mt-0">Post Training Students</h2>
                <p className="text-secondary">
                  {studentList?.data?.userCount} total
                </p>
              </div>
              <div className="text-center mb-4 mb-sm-0">
                <button
                  to=""
                  className="main_btn_default full_width_btn"
                  onClick={() => {
                    setShowInviteModel(true)
                  }}
                  role="button"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.73975 1.22294C6.73975 0.854751 6.44127 0.556274 6.07308 0.556274C5.70489 0.556274 5.40641 0.854751 5.40641 1.22294V5.22294H1.40641C1.03822 5.22294 0.739746 5.52142 0.739746 5.88961C0.739746 6.2578 1.03822 6.55627 1.40641 6.55627H5.40641V10.5563C5.40641 10.9245 5.70489 11.2229 6.07308 11.2229C6.44127 11.2229 6.73975 10.9245 6.73975 10.5563V6.55627H10.7397C11.1079 6.55627 11.4064 6.2578 11.4064 5.88961C11.4064 5.52142 11.1079 5.22294 10.7397 5.22294H6.73975V1.22294Z"
                      fill="#F9F9FB"
                    />
                  </svg>
                  {" Invite Student"}
                </button>
              </div>
            </div>

            <div className="d-flex align-items-center flex-column flex-xl-row justify-content-center justify-content-lg-start">
              <div className="searchbar_main form_section me-auto w-50 mb-4 trainer_search">
                <div className="search_bar">
                  <fieldset>
                    <input
                      className="form-control cform-control text-white"
                      type="text"
                      name="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                        setPageNumber(1)
                      }}
                      onKeyUp={(e) => {
                        if (e.key == "Enter") {
                          getCourseStudentList()
                        }
                      }}
                    />
                    <button>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: CustomIcons.search,
                        }}
                      ></span>
                    </button>
                  </fieldset>
                </div>
              </div>

              <div className="d-flex gap-2 gap-lg-5 flex-column flex-sm-row mb-2">
                <div className="aside-title-area">
                  <h2 className="white_color text-center">
                    <small>ACTIVITY STATUS</small>
                  </h2>
                  <div className="d-flex gap-2 justify-content-center">
                    <button
                      className="btn-active rounded-pill"
                      onClick={() => {
                        if (activityStatus == 1) {
                          setActivityStatus(null)
                        } else {
                          setActivityStatus(1)
                        }
                        setPageNumber(1)
                      }}
                    >
                      Active
                    </button>
                    <button
                      className="btn-inactive rounded-pill"
                      onClick={() => {
                        if (activityStatus == 2) {
                          setActivityStatus(null)
                        } else {
                          setActivityStatus(2)
                        }
                        setPageNumber(1)
                      }}
                    >
                      Frozen
                    </button>
                  </div>
                </div>

                <div className="aside-title-area">
                  <h2 className="white_color text-center">
                    <small>COURSE STATUS</small>
                  </h2>
                  <div className="d-flex gap-2">
                    <button
                      className="btn-inprogress rounded-pill"
                      onClick={() => {
                        if (courseStatus == 1) {
                          setCourseStatus(null)
                        } else {
                          setCourseStatus(1)
                        }
                        setPageNumber(1)
                      }}
                    >
                      In Progress
                    </button>
                    <button
                      className="btn-inactive rounded-pill"
                      onClick={() => {
                        if (courseStatus == 4) {
                          setCourseStatus(null)
                        } else {
                          setCourseStatus(4)
                        }
                        setPageNumber(1)
                      }}
                    >
                      Completed
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="w-25 mb-3">
                    <div key={`company_dropdown`}>
                      <Select
                        name="type"
                        id="sources"
                        className="custom-select sources"
                        defaultValue={selectedTeam}
                        isSearchable={false}
                        getValue={(e) => {
                          return e.id
                        }}
                        options={team}
                        onChange={(e) => {
                          setSelectedTeam(e?.id)
                        }}
                        styles={customstyle}
                        getOptionLabel={(e) => {
                          return e?.teamName ? (
                            <div className="list-item d-flex justify-content-between align-items-center">
                              {e?.teamName}
                              {e.id == selectedTeam && (
                                <img
                                  className="select-icon"
                                  src="/svg/check.svg"
                                  height={20}
                                  width={20}
                                  alt=""
                                />
                              )}
                            </div>
                          ) : null
                        }}
                      />
                    </div>
                  </div> */}
              {/* )} */}
            </div>
          </div>

          <div className="responsive-table mt-3">
            {studentList?.data?.student?.length > 0 ? (
              <table className="table default_table_theme course-listbox horizantalscroll student-table">
                <thead>
                  <tr>
                    <th
                      className="sticky"
                      // onClick={() => updateSortOrder("studentName")}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: 10, marginRight: 75 }}
                      >
                        {/* <input
                          ref={inputEl}
                          className="form-check-input me-3"
                          type="checkbox"
                          value="remember"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const ss = []
                              for (
                                var i = 0;
                                i < studentList?.data?.student?.length;
                                i++
                              ) {
                                ss.push(studentList?.data?.student[i]?.userId)
                              }
                              setSelectedStudent(ss)
                            } else {
                              setSelectedStudent([])
                            }
                          }}
                        />{" "} */}
                        Student{" "}
                      </div>
                    </th>

                    <th
                      scope="col"
                      className={getOrderClass("courseName")}
                      onClick={() => updateSortOrder("courseName")}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderBottom: 1,
                        minHeight: "50px",
                      }}
                    >
                      <ul>
                        <li>Course Name</li>
                        <li style={{ fontSize: 12 }}>Course Status</li>
                      </ul>
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("completedModules")}
                      onClick={() => updateSortOrder("completedModules")}
                    >
                      Completed Modules
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("progress")}
                      onClick={() => updateSortOrder("progress")}
                    >
                      Progress
                    </th>

                    <th
                      className={getOrderClass("courseStateText")}
                      onClick={() => updateSortOrder("courseStateText")}
                    >
                      Status{" "}
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("startDate")}
                      onClick={() => updateSortOrder("startDate")}
                    >
                      Start Date
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("completionDate")}
                      onClick={() => updateSortOrder("completiontDate")}
                    >
                      Completion Date
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("scorepreeval")}
                      onClick={() => updateSortOrder("scorepreeval")}
                    >
                      Score Pre Eval
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("scoreposteval")}
                      onClick={() => updateSortOrder("scoreposteval")}
                    >
                      Score Post Eval
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("finalexam")}
                      onClick={() => updateSortOrder("finalexam")}
                    >
                      Final Exam
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("position")}
                      onClick={() => updateSortOrder("position")}
                    >
                      Position
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("team")}
                      onClick={() => updateSortOrder("team")}
                    >
                      Team
                    </th>

                    <th
                      scope="col"
                      // className={getOrderClass("points")}
                      onClick={() => updateSortOrder("points")}
                    >
                      Points
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {studentList?.data?.student?.length > 0
                    ? studentList?.data?.student.map((student, index) => {
                        if (student?.courseList?.length > 0) {
                          return (
                            <>
                              {student?.courseList.map((course, i) => {
                                return (
                                  <>
                                    <tr key={`user_list_${index}`}>
                                      {i == 0 && (
                                        <td
                                          rowSpan={student?.courseList?.length}
                                          className="sticky"
                                        >
                                          <Link
                                            className="text-light"
                                            to={`/trainer/studentUpdate/${student?.userId}`}
                                          >
                                            <div className="student_info_admin_controls">
                                              <div className="d-flex align-items-center">
                                                {/* <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value="remember"
                                              id="mark_read_all"
                                              checked={selectedStudent.some(
                                                (element) => {
                                                  return (
                                                    student?.userId == element
                                                  )
                                                }
                                              )}
                                              onClick={(e) => {
                                                if (e.target.checked) {
                                                  selectStudentFun(student)
                                                } else {
                                                  removeStudent(student)
                                                }
                                              }}
                                            /> */}
                                                {student?.profileImage ? (
                                                  <img
                                                    src={student?.profileImage}
                                                    className="pointer"
                                                    alt=""
                                                    style={{
                                                      marginRight:
                                                        student?.profileImage
                                                          ? 5
                                                          : 0,
                                                    }}
                                                  />
                                                ) : (
                                                  <span className="up pointer">
                                                    {student?.studentName[0]}
                                                  </span>
                                                )}
                                                <div
                                                  className="d-flex flex-column"
                                                  style={{
                                                    marginLeft: 5,
                                                    textAlign: "justify",
                                                  }}
                                                >
                                                  <span className="fw-lighter text-capitalize">
                                                    {student?.studentName}
                                                  </span>
                                                  <span className="text-secondary text-wrap-pretty mb-0">
                                                    {student?.companyName}
                                                  </span>
                                                  {!student?.isVerified && (
                                                    <p className="varification-pending text-warning">
                                                      Verification Pending
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </td>
                                      )}

                                      <td
                                        className="white_color"
                                        style={{ textAlign: "left" }}
                                      >
                                        <ul>
                                          <li>{course?.courseName}</li>
                                          <span>
                                            {course?.courseStatus == 1
                                              ? "In Progress"
                                              : course?.courseStatus == 2
                                              ? "Paused"
                                              : course?.courseStatus == 3
                                              ? "Left"
                                              : "Completed"}
                                          </span>
                                        </ul>
                                      </td>
                                      <td className="white_color">
                                        <ul>
                                          <li className="me-2">
                                            {`${course?.completedModule}/${course?.totalModule}`}
                                          </li>
                                        </ul>
                                      </td>

                                      <td>
                                        {course?.progress ? (
                                          <div className="progress_student">
                                            <div className="progress mb-1">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                  width: course?.progress + "%",
                                                }}
                                                aria-valuenow="100"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              >
                                                {course?.progress}%
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </td>

                                      <td>
                                        {course?.courseState == 1 ? (
                                          <button className="btn-active rounded-pill">
                                            Active
                                          </button>
                                        ) : (
                                          <button className="btn-inactive rounded-pill">
                                            Frozen
                                          </button>
                                        )}
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {moment(course?.startDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </span>
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {course?.completedDate
                                            ? moment(
                                                course?.completedDate
                                              ).format("DD-MM-YYYY")
                                            : ""}
                                        </span>
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {course?.startQuiz
                                            ? `${course?.startQuiz}%`
                                            : ""}
                                        </span>
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {course?.finishQuiz
                                            ? `${course?.finishQuiz}%`
                                            : ""}
                                        </span>
                                      </td>

                                      <td>
                                        {course?.isCompleted == true ? (
                                          <button
                                            className="btn btn-success rounded-pill"
                                            type="button"
                                          >
                                            Completed
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-outline rounded-pill"
                                            type="button"
                                          >
                                            Incomplete
                                          </button>
                                        )}
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {course?.position
                                            ? course?.position
                                            : ""}
                                        </span>
                                      </td>

                                      <td>
                                        {course?.teamName ? (
                                          <span className="Student_Team me-3">
                                            {course?.teamName}
                                          </span>
                                        ) : null}
                                      </td>

                                      <td className="white_color">
                                        <span className="date">
                                          {course?.points}
                                        </span>
                                      </td>
                                      <td>
                                        <Dropdown className="user-info-dropdown student-restore-dropdown">
                                          <Dropdown.Toggle id="dropdown-basic">
                                            <svg
                                              width="18"
                                              height="4"
                                              viewBox="0 0 18 4"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0ZM7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2ZM14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.89543 18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2Z"
                                                fill="#E4E5EA"
                                              />
                                            </svg>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item>
                                              <Link
                                                to={`/trainer/studentUpdate/${student?.userId}`}
                                              >
                                                <svg
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 18 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M14.1724 0C13.1573 0 12.1838 0.403259 11.466 1.12106L10.3963 2.19069L0.234963 12.3521C0.0845187 12.5025 0 12.7066 0 12.9193L0 17.1978C0 17.6408 0.359163 18 0.802214 18H5.08069C5.29345 18 5.4975 17.9155 5.64794 17.765L15.8078 7.6052L15.8093 7.60366L15.8109 7.60212L16.8789 6.53404C17.5967 5.81624 18 4.84268 18 3.82755C18 2.81242 17.5967 1.83887 16.8789 1.12106C16.1611 0.403259 15.1876 0 14.1724 0ZM15.2421 5.90191L15.7444 5.39954C16.1614 4.98262 16.3956 4.41716 16.3956 3.82755C16.3956 3.23794 16.1614 2.67248 15.7444 2.25557C15.3275 1.83865 14.7621 1.60443 14.1724 1.60443C13.5828 1.60443 13.0174 1.83865 12.6005 2.25557L12.0981 2.75794L15.2421 5.90191ZM10.9636 3.89244L1.60443 13.2516V16.3956H4.7484L14.1076 7.03641L10.9636 3.89244Z"
                                                    fill="#E4E5EA"
                                                  />
                                                </svg>{" "}
                                                Edit Student Info
                                              </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                              <Link
                                                to=""
                                                onClick={() => {
                                                  logInAsUser(student)
                                                  // setUserInfo(user)
                                                }}
                                              >
                                                <svg
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7957 0 17L0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0L7 0C7.55228 0 8 0.447715 8 1C8 1.55228 7.55228 2 7 2H3ZM13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L14.7071 15.7071C14.3166 16.0976 13.6834 16.0976 13.2929 15.7071C12.9024 15.3166 12.9024 14.6834 13.2929 14.2929L16.5858 11H6.92857C6.41574 11 6 10.5523 6 10C6 9.44771 6.41574 9 6.92857 9H16.5858L13.2929 5.70711C12.9024 5.31658 12.9024 4.68342 13.2929 4.29289Z"
                                                    fill="#E4E5EA"
                                                  />
                                                </svg>{" "}
                                                Log in as Student
                                              </Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                              {" "}
                                              <Link
                                                data-bs-toggle="modal"
                                                to=""
                                                onClick={() => {
                                                  setShowRestoreModel(true)
                                                  setUserInfo(student)
                                                }}
                                                role="button"
                                              >
                                                <svg
                                                  width="22"
                                                  height="21"
                                                  viewBox="0 0 22 21"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M16.8366 1.2576C14.9392 0.208678 12.7523 -0.195179 10.6053 0.10688C8.46356 0.408219 6.47734 1.39582 4.94448 2.92135L2 5.73418V2C2 1.44772 1.55228 1 1 1C0.447715 1 0 1.44772 0 2V8C0 8.55228 0.447715 9 1 9H7C7.55228 9 8 8.55228 8 8C8 7.44772 7.55228 7 7 7H3.57027L6.32477 4.36876C6.33212 4.36186 6.33936 4.35484 6.3465 4.34772C7.57398 3.12231 9.16646 2.32902 10.884 2.08737C12.6015 1.84573 14.3511 2.16881 15.869 3.00795C17.387 3.84709 18.5911 5.15682 19.2999 6.7398C20.0088 8.32279 20.184 10.0933 19.7991 11.7845C19.4143 13.4757 18.4902 14.996 17.1662 16.1164C15.8421 17.2368 14.1898 17.8965 12.4582 17.9961C10.7267 18.0958 9.00959 17.63 7.56575 16.6689C6.12191 15.7079 5.02952 14.3036 4.45317 12.6677C4.26965 12.1468 3.6986 11.8733 3.1777 12.0568C2.6568 12.2404 2.3833 12.8114 2.56682 13.3323C3.28726 15.3772 4.65275 17.1325 6.45755 18.3338C8.26235 19.5352 10.4087 20.1174 12.5732 19.9928C14.7376 19.8683 16.803 19.0436 18.4581 17.6431C20.1131 16.2427 21.2682 14.3423 21.7493 12.2283C22.2304 10.1142 22.0114 7.90115 21.1253 5.92242C20.2392 3.94369 18.7341 2.30652 16.8366 1.2576Z"
                                                    fill="#E4E5EA"
                                                  />
                                                </svg>{" "}
                                                Restore Password
                                              </Link>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </>
                          )
                        } else {
                          return (
                            <tr key={`user_list_${index}`}>
                              <td className="sticky">
                                <Link
                                  className="text-light"
                                  to={`/trainer/studentUpdate/${student?.userId}`}
                                >
                                  <div className="student_info_admin_controls">
                                    <div className="d-flex align-items-center">
                                      {student?.profileImage ? (
                                        <img
                                          className="pointer"
                                          src={student?.profileImage}
                                          alt=""
                                        />
                                      ) : (
                                        <span className="up pointer">
                                          {student?.studentName[0]}
                                        </span>
                                      )}
                                      <div
                                        className="d-flex flex-column"
                                        style={{
                                          marginLeft: 5,
                                          textAlign: "justify",
                                        }}
                                      >
                                        <span className="fw-lighter text-capitalize">
                                          {student?.studentName}
                                        </span>
                                        <span className="text-secondary text-wrap-pretty mb-0">
                                          {student?.companyName}
                                        </span>
                                        {!student?.isVerified && (
                                          <p className="varification-pending text-warning">
                                            Verification Pending
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <Dropdown className="user-info-dropdown student-restore-dropdown">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <svg
                                      width="18"
                                      height="4"
                                      viewBox="0 0 18 4"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0ZM7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2ZM14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.89543 18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2Z"
                                        fill="#E4E5EA"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link
                                        to={`/trainer/studentUpdate/${student?.userId}`}
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.1724 0C13.1573 0 12.1838 0.403259 11.466 1.12106L10.3963 2.19069L0.234963 12.3521C0.0845187 12.5025 0 12.7066 0 12.9193L0 17.1978C0 17.6408 0.359163 18 0.802214 18H5.08069C5.29345 18 5.4975 17.9155 5.64794 17.765L15.8078 7.6052L15.8093 7.60366L15.8109 7.60212L16.8789 6.53404C17.5967 5.81624 18 4.84268 18 3.82755C18 2.81242 17.5967 1.83887 16.8789 1.12106C16.1611 0.403259 15.1876 0 14.1724 0ZM15.2421 5.90191L15.7444 5.39954C16.1614 4.98262 16.3956 4.41716 16.3956 3.82755C16.3956 3.23794 16.1614 2.67248 15.7444 2.25557C15.3275 1.83865 14.7621 1.60443 14.1724 1.60443C13.5828 1.60443 13.0174 1.83865 12.6005 2.25557L12.0981 2.75794L15.2421 5.90191ZM10.9636 3.89244L1.60443 13.2516V16.3956H4.7484L14.1076 7.03641L10.9636 3.89244Z"
                                            fill="#E4E5EA"
                                          />
                                        </svg>{" "}
                                        Edit Student Info
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <Link
                                        to=""
                                        onClick={() => {
                                          logInAsUser(student)
                                          // setUserInfo(user)
                                        }}
                                      >
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7957 0 17L0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0L7 0C7.55228 0 8 0.447715 8 1C8 1.55228 7.55228 2 7 2H3ZM13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L14.7071 15.7071C14.3166 16.0976 13.6834 16.0976 13.2929 15.7071C12.9024 15.3166 12.9024 14.6834 13.2929 14.2929L16.5858 11H6.92857C6.41574 11 6 10.5523 6 10C6 9.44771 6.41574 9 6.92857 9H16.5858L13.2929 5.70711C12.9024 5.31658 12.9024 4.68342 13.2929 4.29289Z"
                                            fill="#E4E5EA"
                                          />
                                        </svg>{" "}
                                        Log in as Student
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      {" "}
                                      <Link
                                        data-bs-toggle="modal"
                                        to=""
                                        onClick={() => {
                                          setShowRestoreModel(true)
                                          setUserInfo(student)
                                        }}
                                        role="button"
                                      >
                                        <svg
                                          width="22"
                                          height="21"
                                          viewBox="0 0 22 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M16.8366 1.2576C14.9392 0.208678 12.7523 -0.195179 10.6053 0.10688C8.46356 0.408219 6.47734 1.39582 4.94448 2.92135L2 5.73418V2C2 1.44772 1.55228 1 1 1C0.447715 1 0 1.44772 0 2V8C0 8.55228 0.447715 9 1 9H7C7.55228 9 8 8.55228 8 8C8 7.44772 7.55228 7 7 7H3.57027L6.32477 4.36876C6.33212 4.36186 6.33936 4.35484 6.3465 4.34772C7.57398 3.12231 9.16646 2.32902 10.884 2.08737C12.6015 1.84573 14.3511 2.16881 15.869 3.00795C17.387 3.84709 18.5911 5.15682 19.2999 6.7398C20.0088 8.32279 20.184 10.0933 19.7991 11.7845C19.4143 13.4757 18.4902 14.996 17.1662 16.1164C15.8421 17.2368 14.1898 17.8965 12.4582 17.9961C10.7267 18.0958 9.00959 17.63 7.56575 16.6689C6.12191 15.7079 5.02952 14.3036 4.45317 12.6677C4.26965 12.1468 3.6986 11.8733 3.1777 12.0568C2.6568 12.2404 2.3833 12.8114 2.56682 13.3323C3.28726 15.3772 4.65275 17.1325 6.45755 18.3338C8.26235 19.5352 10.4087 20.1174 12.5732 19.9928C14.7376 19.8683 16.803 19.0436 18.4581 17.6431C20.1131 16.2427 21.2682 14.3423 21.7493 12.2283C22.2304 10.1142 22.0114 7.90115 21.1253 5.92242C20.2392 3.94369 18.7341 2.30652 16.8366 1.2576Z"
                                            fill="#E4E5EA"
                                          />
                                        </svg>{" "}
                                        Restore Password
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        }
                      })
                    : null}
                </tbody>
              </table>
            ) : !loading ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  <img className="mt-5" src="/img/unlock_vendor.png" />
                </div>
                <div className="col-12 text-center">
                  <p className="light_text">There are no students yet.</p>
                </div>
              </div>
            ) : null}
          </div>
          {studentList?.data?.student?.length === 0 ? null : (
            <CustomPagination
              total={studentList?.data?.userCount}
              limit={perPage}
              curretnPage={pageNumber}
              setPage={setPageNumber}
              setLimit={setPerPage}
            />
          )}
        </div>
      </div>
      {showEditStatusModel && (
        <EditCourseStudentStatus
          onClose={closeStatusModel}
          selectedStudent={selectedStudent}
        />
      )}

      {showInviteModel && <InviteStudent onClose={closeModel} />}

      {showEditModel && (
        <EditTrainerStudent studentInfo={studentInfo} onClose={closeModel} />
      )}

      {showRestoreModel && (
        <RestorePassword onClose={closeRestoreModel} userInfo={userInfo} />
      )}
    </>
  )
}
export default TrainerStudent

import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import clientService from "../../services/service"

const LearningGuideQuizScore = () => {
  const [courseInfo, serCourseInfo] = useState(null)
  const { courseId, lessonId } = useParams()

  useEffect(() => {
    getCourseDetails()
  }, [])

  const getCourseDetails = async () => {
    const res = await clientService.get(
      `StudentCourse/GetStudentQuizResult?courseGuideLessonId=${lessonId}`
    )
    serCourseInfo(res.data)
  }

  if (!courseInfo?.courseGuideId) {
    return null
  }
  return (
    <>
      <div className="aside-content-wrapper white_body random_test">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex space_between">
                <div className="breadcrum">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">Learning</li>
                      <li className="breadcrumb-item">
                        <Link to={`/course/learning-guide/${courseId}`}>
                          {courseInfo?.course?.courseName}
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Pre Evaluation
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="right_btn">
                  <Link
                    to={`/course/learning-guide/${courseId}`}
                    className="main_btn_default blue_color float-end"
                  >
                    Confirm
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <img
                src={
                  // courseInfo.score >= 70
                  //   ? 
                    "/img/good_job_icon.png"
                    // : "/img/not_good_job.png"
                }
                className="mb-3 mt-3"
                alt="#"
              />
              <h3 className="mb-3">
                {/* {courseInfo.score >= 70
                  ? */}
                   Well Done!
                  {/* : "Whoops!"} */}
                {/* <br /> */}
                {/* Your score is {courseInfo?.score}% */}
              </h3>
              <p className="light_text">
                {/* {courseInfo.score >= 70 ? "" : "You need 90% to move to next chapter."} */}
                {/* You answered {courseInfo?.totalCorrectAnswer} out of{" "}
                {courseInfo?.totalQuestion} questions correctly. */}
                You’ve successfully completed your Pre Evaluation.
              </p>
            </div>
            {/* <div className="col-lg-12 d-flex justify-content-center">
                        <a href="#" className="main_btn_default blue_color float-end">Retry</a>
                    </div> */}
            <div className="col-lg-12">
              <div className="full">
                <div className="radio_button_section">
                  {courseInfo?.answers?.map((answers, index) => {
                    return (
                      <div
                        className={
                          answers.correctAnswer == 1
                            ? "form-check custom_radio right_ans"
                            : answers.correctAnswer == 2
                            ? "form-check custom_radio false_ans"
                            : "form-check custom_radio"
                        }
                      >
                        <h4>
                          {index + 1}. {answers.question}
                        </h4>
                        <p>
                          {answers.correctAnswer == 1 ? (
                            <b className="green-text">Correct: </b>
                          ) : answers.correctAnswer == 2 ? (
                            <b className="red-text">Incorrect: </b>
                          ) : (
                            ""
                          )}
                          {answers.answer.map((ans, index) => {
                            return <p>{ans}</p>
                          })}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LearningGuideQuizScore

import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const courceGuideInitialState = {
  newCourceGuideResponse: {},
  getCourceGuideResponse: {},
  getCourceGuideByIdResponse: {},
  updatedCourceGuideResponse: {},
  publishedCourceGuideResponse: {},
  duplicateCourceGuideResponse: {},
  deleteCourceGuideResponse: {},
  newEvulation: {},
  evulations: [],
  deleteSteps: {},
  reloadTopBar : 0
}

export const courceGuideSlice = createSlice({
  name: "courceGuide",
  initialState: courceGuideInitialState,
  reducers: {
    setCreateCourceGuideResponse: (state, action) => {
      state.newCourceGuideResponse = action.payload
    },
    setGetCourceGuideResponse: (state, action) => {
      state.getCourceGuideResponse = action.payload.data
    },
    setGetCourceGuideByIdResponse: (state, action) => {
      state.getCourceGuideByIdResponse = action.payload
    },
    setUpdateCourceGuideResponse: (state, action) => {
      state.updatedCourceGuideResponse = action.payload
    },
    setPublishCourceGuideResponse: (state, action) => {
      state.publishedCourceGuideResponse = action.payload
    },
    setDuplicateCourceGuideResponse: (state, action) => {
      state.duplicateCourceGuideResponse = action.payload
    },
    setDeleteCourceGuideResponse: (state, action) => {
      state.deleteCourceGuideResponse = action.payload
    },
    setEvulationSaveResponse: (state, action) => {
      state.newEvulation = action.payload
    },
    setEvulationResponse: (state, action) => {
      state.evulations = action.payload.data
    },
    setDeleteStepEvulationResponse: (state, action) => {
      state.deleteSteps = action.payload
    },
    setReloadTopBar : (state, action) => {
      state.reloadTopBar = state.reloadTopBar + 1;
    }
  },
})

export const createCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/CreateCourseGuides",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCreateCourceGuideResponse(response))
  }
}

export const getAllCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/GetAllCourseGuides",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setGetCourceGuideResponse(response))
  }
}

export const getCourceGuideById = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `CourseGuide/GetCourseGuideById?Id=${data.Id}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setGetCourceGuideByIdResponse(response))
  }
}

export const updateCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/UpdateCourseGuide",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUpdateCourceGuideResponse(response))
  }
}

export const publishCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/PublishedUnPublishedCourseGuide",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setPublishCourceGuideResponse(response))
  }
}

export const duplicateCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/DuplicateCourseGuideCreate",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDuplicateCourceGuideResponse(response))
  }
}

export const deleteCourceGuide = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/DeleteCourseGuide",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeleteCourceGuideResponse(response))
  }
}

export const saveEvaluation = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/SaveFinalEvaluationStep",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEvulationSaveResponse(response))
  }
}

export const getEvaluation = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `CourseGuide/GetFinalEvaluationStep?CourseGuideId=${data.CourseGuideId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEvulationResponse(response))
  }
}

export const deleteEvaluationStep = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseGuide/DeleteEvaluationStep",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeleteStepEvulationResponse(response))
  }
}

export const {
  setCreateCourceGuideResponse,
  setGetCourceGuideResponse,
  setGetCourceGuideByIdResponse,
  setUpdateCourceGuideResponse,
  setPublishCourceGuideResponse,
  setDuplicateCourceGuideResponse,
  setDeleteCourceGuideResponse,
  setEvulationSaveResponse,
  setEvulationResponse,
  setDeleteStepEvulationResponse,
  setReloadTopBar
} = courceGuideSlice.actions

export const courceGuideState = (state) => {
  return state.courceGuide
}

export default courceGuideSlice.reducer

import { Modal } from "react-bootstrap"
import { useParams } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import { useState } from "react"
import CustomIcons from "../../assets/CustomIcons"
import { useAlert } from "react-alert"
import clientService from "../../services/service"
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useSelector } from "react-redux"
import { authState } from "../../store/slices/authSlice"

const BookSession = ({ onClose, calendlyLink, newBooking }) => {

  const { getProfileDataResponse } = useSelector(authState)
  const alert = useAlert()
  const { guidId } = useParams()
  useCalendlyEventListener({
    onEventScheduled: (e) => { handleSubmit(e) },
  });

  const handleSubmit = async (e) => {

    const postData = {
      "courseGuideId": guidId,
      "apiUrl": e.data.payload.event.uri
    }
    try {
      if(newBooking === "new") {
        const res = await clientService.post("StudentCourse/BookNewSession", postData);
        if (res.code === 200 && res.status) {
          alert.success(res.message);
          onClose(true)
        }
      }else{
        const res = await clientService.post("StudentCourse/BookSession", postData);
        if (res.code === 200 && res.status) {
          alert.success(res.message);
          onClose(true)
        }
      }
     
    } catch (e) {

    }
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap"
        aria-labelledby="book_secction"
        id="book_secction"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Session details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="full text-center">
            <div className="icon">
              <span
                dangerouslySetInnerHTML={{ __html: CustomIcons.big_calendar }}
              ></span>
            </div>
          </div>
          <div className="full text-center">
            <h3 className="white_color">
              <b>Perfect!</b>
            </h3>
            <p className="white_color mt-2">
              Tell us date and time of your session:
            </p>
          </div>
          <div className="full form_section mt-2">

            <InlineWidget
              prefill={{
                name: getProfileDataResponse.firstName,
                email: getProfileDataResponse.email,
                customAnswers: {
                  a1: getProfileDataResponse.id
                }
              }}
              url={calendlyLink}

            />

            {/* <form>
              <fieldset>
                <div className="form-group mb-4 pb-1">
                  <div className="col-xs-9">
                    <DatePicker
                      className="form-control cform-control"
                      selected={startDate}
                      minDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="MMMM d"
                    />
                  </div>
                </div>
                <div className="form-group mb-4 pb-1">
                  <div className="col-xs-9">
                    <Select
                      name="time"
                      id="time"
                      className="form-control cform-control"
                      options={Time}
                      placeholder="Select time of session"
                    />
                  </div>
                </div>
              </fieldset>
            </form> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="main_btn_default"
              data-bs-toggle="modal"
              role="button"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              onClick={onClose}
              className="btn btn-fclose"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Session not booked
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default BookSession;

import React, { useEffect, useRef, useState } from "react"
import $ from 'jquery';
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import clientService from "../../../services/service"
import { appState } from "../../../store/slices/appSlice"
import FlashCardQuestion from "./FlashCardQuestion"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FlashCardItem from "./FlashCardItem";

const FlashCard = () => {
  const [learningWeeks, setLearningWeeks] = useState({})
  const [preview, setPreview] = useState(false)
  const [currentItem, setCurrentItem] = useState("")
  const { courseId } = useParams()
  const [loading, setLoading] = useState(true);
  // const { loading } = useSelector(appState)
  const owlCarouselRef = useRef();

  useEffect(() => {
    getLearningWeeks()
  }, [courseId])

  const getLearningWeeks = async () => {
    const res = await clientService.get(
      `StudentCourse/GetLearningWeek?courseId=${courseId}`
    )
    setLearningWeeks(res)
    var hasPreview = false;
    for (var i = 0; i < res?.data?.courseEnroll.length; i++) {
      if (res?.data?.courseEnroll[i].isCompleted === true && !hasPreview) {
        hasPreview = true;
        setCurrentItem(res?.data?.courseEnroll[i].guideId)
      }
    }
    if (hasPreview) {
      setPreview(true)
    }
    setLoading(false);
  }

  const handleItemClick = (index) => {
    setCurrentItem(learningWeeks?.data?.courseEnroll[index]?.guideId);
  }

  const options = {
    nav: true,
    loop: false,
    margin: 25,
    dots: false,
    smartSpeed: 1200,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      376: {
        items: 2,
      },
      575: {
        items: 3,
      },
      767: {
        items: 6,
      },
      1199: {
        items: 6,
      }
    },
  };

  return (
    <>
      <div className="aside-content-wrapper white_body random_test">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 position-relative">
              <div class="cb-cust-nav cb-prev-icon"></div>
              <div className="flashcard_week">
                <OwlCarousel className='cb-flashcard-slider' {...options}
                  ref={owlCarouselRef}
                >
                  {learningWeeks?.data?.courseEnroll &&
                    learningWeeks?.data?.courseEnroll.length > 0
                    ? learningWeeks.data.courseEnroll.map((week, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleItemClick(index)}
                        >
                          <FlashCardItem week={week} />
                        </div>
                      )
                    })
                    : null}
                </OwlCarousel>
              </div>
              <div class="cb-cust-nav cb-next-icon"></div>
            </div>
          </div>
          {!preview ? (
            !loading ? (
              <div className="col-lg-12">
                <div className="flascard_guide">
                  <p className="large">
                    To unlock Flashcards, you must complete a Learning Guide.
                    <br />
                  </p>
                </div>
              </div>
            ) : null
          ) :
            <FlashCardQuestion currentItem={currentItem} />
          }
        </div>
      </div>
    </>
  )
}

export default FlashCard

import React, { useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import clientService from "../../services/service"
import Select from "react-select"

const SavedTimeModal = ({ onClose, trainerRequiredMinutes }) => {
  const [selectedTime, setSelectedTime] = useState({
    text: "Choose correct item",
    value: "",
  })

  const [errorMessage, setErrorMessage] = useState("")

  const timeDuration = [
    // { text: "Choose correct item", value: "" },
    { text: "15 Minutes", value: 15 },
    { text: "30 Minutes", value: 30 },
    { text: "45 Minutes", value: 45 },
    { text: "1 Hour", value: 60 },
    { text: "NA", value: 0 },
  ]

  useEffect(() => {
    let _timeDuration = timeDuration.filter((op) => op?.value == trainerRequiredMinutes)
    setSelectedTime(_timeDuration)
  }, [])

  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap calendar_style_2"
        aria-labelledby="create_a_guide"
        size="md"
        id="text_lesson_preview"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-light bold text-center">
            How long does it usually take you to train someone per session?
          </p>
          <Select
            name="sources"
            className="custom-select sources"
            placeholder="Choose correct item"
            getOptionLabel={(e) => {
              return e.text
            }}
            onChange={(e) => {
              setSelectedTime(e)
              setErrorMessage("")
            }}
            value={selectedTime}
            options={timeDuration}
          />
          <p className="text-danger">{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={async () => {
              if (selectedTime.value !== "") {
                const data = {
                  minutes: selectedTime.value,
                }
                const res = await clientService.post(
                  "TrainerDashboard/SaveTimeToReview",
                  data
                )
                onClose()
              } else {
                setErrorMessage("Required")
              }
            }}
            className="btn btn-fclose btn_thankyou"
            data-bs-target="new_courses"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            Thank You !
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SavedTimeModal

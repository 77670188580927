import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import Header from "../components/Shared/Header"
import {
  authState,
  setNewPassword,
  setNewPasswordResponse,
  setValidateForgotPasswordLinkResponse,
  validateForgotPassLink,
} from "../store/slices/authSlice"
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/;

const CreateNewPassword = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const navigate = useNavigate()
  const { passwordResp, validateForgotPasswordLink } = useSelector(authState)
  const [passwordType, setPasswordType] = useState("password")
  const [confirmPasswordType, setConfirmPasswordType] = useState("password")
  const [searchParams, setSearchParams] = useSearchParams()

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text")
      return
    }
    setConfirmPasswordType("password")
  }


  const Schema = Yup.object().shape({
    newPassword: Yup.string().required('Required field.').matches(PASSWORD_VALIDATOR, 
      "New password must be at least 8 characters long with both letters and numbers."),
    confirmPassword: Yup.string()
    .required('Required field.')
    .when('newPassword', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Your new passwords do not match."
      )
    })
  });

  useEffect(() => {
    if (passwordResp.code === 500 || passwordResp.status === false) {
      alert.error(passwordResp.message)
      dispatch(setNewPasswordResponse({}))
    } else if (passwordResp.code === 200 || passwordResp.status === true) {
      alert.success(passwordResp.message)
      dispatch(setNewPasswordResponse({}))
      navigate("/login")
    }
  }, [passwordResp])

  useEffect(() => {
    if (
      validateForgotPasswordLink.code === 500 ||
      validateForgotPasswordLink.status === false
    ) {
      alert.error(validateForgotPasswordLink.message)
      dispatch(setValidateForgotPasswordLinkResponse({}))
    } else if (
      validateForgotPasswordLink.code === 200 ||
      validateForgotPasswordLink.status === true
    ) {
      dispatch(setValidateForgotPasswordLinkResponse({}))
    }
  }, [validateForgotPasswordLink])

  useEffect(() => {
    var data = {
      verificationCode: searchParams.get("activationCode"),
    }
    dispatch(validateForgotPassLink(data))
  }, [dispatch])

  const handleFormSubmit = (values, { setErrors }) => {
    var data = {
      ...values,
      verificationCode: searchParams.get("activationCode"),
    }
    dispatch(setNewPassword(data, setErrors))
  }
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="new_password forgot-main-wrapper mx-auto">
                <div className="forgot-caption">
                  <h1 className="title">Choose your new password</h1>
                </div>
                <div className="forgot-inner-wrapper">
                  <div className="forgot-form-area">
                    <Formik
                      initialValues={{
                        newPassword: "",
                        confirmPassword: "",
                      }}
                      validationSchema={Schema}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group pb-1 mb-4">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="password"
                            >
                              Password
                            </label>
                            <div className="col-xs-9">

                            <div className="input-group">
                              <input
                                type={passwordType}
                                className={errors.newPassword && touched.newPassword ? "form-control cform-control error-field" : "form-control cform-control border-right-0"}
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                name="newPassword"
                                value={values.newPassword}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />

                              <span className="input-group-text white-icon">
                                <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password">
                                  <FontAwesomeIcon
                                    className="pointer"
                                    onClick={togglePassword}
                                    icon={
                                      passwordType === "password"
                                        ? faEyeSlash
                                        : faEye
                                    }
                                  />
                                </span>
                              </span>
                            </div>
                              
                            <div className="error-message">
                              {errors.newPassword &&
                                touched.newPassword &&
                                errors.newPassword}
                            </div>
                              
                            </div>
                          </div>
                          <div className="form-group pb-1 mb-4">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="password_new"
                            >
                              Confirm New Password
                            </label>

                            <div className="col-xs-9">
                              <div className="input-group">
                                <input
                                  type={confirmPasswordType}
                                  className={errors.confirmPassword && touched.confirmPassword ? "form-control cform-control error-field" : "form-control cform-control border-right-0"}
                                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />

                                <span className="input-group-text white-icon">
                                  <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password">
                                    <FontAwesomeIcon
                                      className="pointer"
                                      onClick={toggleConfirmPassword}
                                      icon={
                                        confirmPasswordType === "password"
                                          ? faEyeSlash
                                          : faEye
                                      }
                                    />
                                  </span>
                                </span>
                              </div>
                              <div className="error-message">
                                {errors.confirmPassword &&
                                  touched.confirmPassword &&
                                  errors.confirmPassword}
                              </div>
                              
                            </div>
                          </div>
                          <div className="form-group checkbox_group pb-1 mb-4">
                            <div className="row">
                              <div className="col-sm-12">
                                <input
                                  type="submit"
                                  className="btn btn-primary btn_submit"
                                  value="Change my password"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateNewPassword

import moment from "moment";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomIcons from "../../assets/CustomIcons";
import UserAvtar from "../../common/UserAvtar";
import VideoPlayer from "../../common/VideoPlayer";
import clientService from "../../services/service";

import {
  addScenarioReview,
  getReviewScenarioDetail,
  getReviewTags,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice";
import HowToUse from "../models/HowToUse";

const RandomiserOverViewStudentDetail = () => {
  const { submittedId, courseId } = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { reviewDetail, reviewTags, scenarioReview } =
    useSelector(reviewerCourseState);
  const [showUseModel, setShowUseModel] = useState(false);

  const [nonVerbals, setNonVerbals] = useState(null);
  const [verbals, setVerbals] = useState(null);
  const [paraVerbals, setParaVerbals] = useState(null);

  const [nonVerbalsTags, setNonVerbalsTags] = useState([]);
  const [verbalsTags, setVerbalsTags] = useState([]);
  const [paraVerbalsTags, setParaVerbalsTags] = useState([]);
  const [prevData, setPrevData] = useState({});
  const [nextData, setNextData] = useState({});
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    dispatch(getReviewScenarioDetail({ SubmittedId: submittedId }));
    dispatch(getReviewTags());
    getPrevData();
    getNextData();
  }, [dispatch, submittedId]);

  const handleFormSubmit = async () => {
    var randomizerScenarioTags = [];
    for (var i = 0; i < nonVerbalsTags.length; i++) {
      randomizerScenarioTags.push({ randomizerTagId: nonVerbalsTags[i] });
    }
    for (var i = 0; i < verbalsTags.length; i++) {
      randomizerScenarioTags.push({ randomizerTagId: verbalsTags[i] });
    }
    for (var i = 0; i < paraVerbalsTags.length; i++) {
      randomizerScenarioTags.push({ randomizerTagId: paraVerbalsTags[i] });
    }

    if (
      verbals !== null &&
      nonVerbals !== null &&
      paraVerbals !== null &&
      randomizerScenarioTags?.length > 0
    ) {
      setHasError(false);
      var data = {
        submittedScenarioId: submittedId,
        verbals: verbals === "NA" ? 0 : verbals,
        nonVerbals: nonVerbals === "NA" ? 0 : nonVerbals,
        paraVerbals: paraVerbals === "NA" ? 0 : paraVerbals,
        randomizerScenarioTags: randomizerScenarioTags,
        isNonVerbalNA: nonVerbals === "NA",
        isVerbalNA: verbals === "NA",
        isPeraVerbalNA: paraVerbals === "NA",
      };
      const res = await clientService.post(
        "ReviewerRandomizer/AddScenarioReview",
        data
      );
      if (res.code == 200) {
        alert.success(res.message);
        navigate(`/reviewer/randomiser/detail/${courseId}`);
      } else {
        alert.console.error(res.message);
      }
    } else {
      setHasError(true);
    }
  };

  const getPrevData = async () => {
    const res = await clientService.get(
      `ReviewerRandomizer/GetPreviousScenarioDetail?SubmittedId=${submittedId}`
    );
    setPrevData(res?.data);
  };

  const getNextData = async () => {
    const res = await clientService.get(
      `ReviewerRandomizer/GetNextScenarioDetail?SubmittedId=${submittedId}`
    );
    setNextData(res?.data);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="overview-area">
          <div className="row">
            <div className="col-12">
              <div className="breadcrum light_breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        to="/reviewer/randomiser/overview"
                        className="text-light"
                      >
                        Courses
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {reviewDetail?.data?.courseName}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className="col-12">
              <div className="tabbar_head">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">
                    <Link
                      to={`/reviewer/randomiser/detail/${courseId}`}
                      className="nav-link active"
                      data-bs-toggle="pill"
                      id="to_review"
                    >
                      To review
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={`/reviewer/randomiser/detail/${courseId}/reviewed`}
                      className="nav-link"
                      data-bs-toggle="pill"
                      id="reviewed"
                    >
                      Reviewed
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="tab-content mt-4">
                <div className="row">
                  <div className="col-12">
                    <h3 className="backtopage back-to-video">
                      <Link to={`/reviewer/randomiser/detail/${courseId}`}>
                        <span
                          dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                        ></span>
                        Back to All Videos
                      </Link>
                    </h3>
                  </div>
                </div>
                <div
                  id="to_review"
                  className="container tab-pane active video-box-list"
                >
                  <div className="row mt-4">
                    <div className="col-12 video_content">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <Accordion className="faq-item-list">
                          <Accordion.Item className="accordion-item">
                            <Accordion.Header>
                              <div className="row">
                                <div className="col-12 col-md-8">
                                  <h4 className="accordion-title">Scenario</h4>
                                </div>

                                <div className="col-12 col-md-4 sm-text-left">
                                  <span
                                    onClick={() => {
                                      setShowUseModel(true);
                                    }}
                                  >
                                    How to score the video?
                                  </span>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>{reviewDetail?.data?.scenario}</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-12 mb-5">
                      <div className="video_post_section d-block single_video">
                        <div className="video_blog">
                          <div className="">
                            <label className="video_time">
                              {reviewDetail?.data?.secondsPerQuestion}
                            </label>
                            <label className="video_time video_date">
                              {moment(reviewDetail?.data?.createdDate).format(
                                "MM/DD/YY"
                              )}
                            </label>
                            <div className="play_button">
                              {/* <video className="img-fluid height-100" controls>
                                <source
                                  src={reviewDetail?.data?.video}
                                  type="video/mp4"
                                ></source>
                              </video> */}
                              <VideoPlayer
                                className="img-fluid height-100"
                                videoId={reviewDetail?.data?.vimeoLink}
                                isVideoAvailable={
                                  reviewDetail?.data?.isVideoAvailable
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="video_post_info">
                          <div className="video_upload_info">
                            <UserAvtar
                              name={reviewDetail?.data?.studentName}
                              image={reviewDetail?.data?.userImage}
                            />

                            <div className="video_info offwhite_color">
                              <h3>{reviewDetail?.data?.studentName}</h3>
                              <p>{reviewDetail?.data?.days} days ago</p>
                            </div>
                          </div>
                        </div>

                        <div className="verbals_section mt-4">
                          <h5>Non verbals</h5>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex score_label">
                                <p>Score</p>
                                <ul>
                                  {[0, 1, 2, 3, 4, 5, "NA"].map(
                                    (time, index) => {
                                      return (
                                        <li
                                          key={`non${index}`}
                                          className={
                                            time === nonVerbals
                                              ? "selected pointer"
                                              : "pointer"
                                          }
                                          onClick={() => setNonVerbals(time)}
                                        >
                                          {time}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>

                                <p className="basic_tooltip">
                                  <Link to="#">
                                    <img src="/img/icon_info.png" alt="#" />
                                    <span className="tooltip_hover">
                                      0 - not watchable
                                      <br />5 - great job
                                    </span>
                                  </Link>
                                </p>
                              </div>
                              {hasError && nonVerbals === null ? (
                                <div className="error-message">
                                  Required field.
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col tag-title">
                              <p>Tags</p>
                            </div>
                            <div className="col-12">
                              <div className="d-flex default_label">
                                <ul className="green_label">
                                  {reviewTags?.data?.nonVerbals &&
                                  reviewTags?.data?.nonVerbals.length > 0
                                    ? reviewTags?.data?.nonVerbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 1) {
                                            return;
                                          }
                                          const activeClass =
                                            nonVerbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...nonVerbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setNonVerbalsTags(ss);
                                              }}
                                              className={"green " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                              <div className="d-flex default_label">
                                <ul className="red_label align_fix">
                                  {reviewTags?.data?.nonVerbals &&
                                  reviewTags?.data?.nonVerbals.length > 0
                                    ? reviewTags?.data?.nonVerbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 0) {
                                            return;
                                          }

                                          const activeClass =
                                            nonVerbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...nonVerbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setNonVerbalsTags(ss);
                                              }}
                                              className={"red " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                              {hasError && nonVerbalsTags.length == 0 ? (
                                <div className="error-message">
                                  Required field.
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="verbals_section mt-4">
                          <h5>Verbals</h5>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex score_label">
                                <p>Score</p>
                                <ul>
                                  {[0, 1, 2, 3, 4, 5, "NA"].map(
                                    (time, index) => {
                                      return (
                                        <li
                                          key={`non1${index}`}
                                          className={
                                            time == verbals
                                              ? "selected pointer"
                                              : "pointer"
                                          }
                                          onClick={() => setVerbals(time)}
                                        >
                                          {time}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                                <p className="basic_tooltip">
                                  <Link to="#">
                                    <img src="/img/icon_info.png" alt="#" />
                                    <span className="tooltip_hover">
                                      0 - not watchable
                                      <br />5 - great job
                                    </span>
                                  </Link>
                                </p>
                              </div>
                              {hasError && verbals === null ? (
                                <div className="error-message">
                                  Required field.
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col tag-title">
                              <p>Tags</p>
                            </div>
                            <div className="col-12">
                              <div className="d-flex default_label">
                                {/* <p>Tags</p> */}
                                <ul className="green_label">
                                  {reviewTags?.data?.verbals &&
                                  reviewTags?.data?.verbals.length > 0
                                    ? reviewTags?.data?.verbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 1) {
                                            return;
                                          }
                                          const activeClass =
                                            verbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...verbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setVerbalsTags(ss);
                                              }}
                                              className={"green " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                              <div className="d-flex default_label">
                                <ul className="red_label align_fix">
                                  {reviewTags?.data?.verbals &&
                                  reviewTags?.data?.verbals.length > 0
                                    ? reviewTags?.data?.verbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 0) {
                                            return;
                                          }

                                          const activeClass =
                                            verbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...verbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setVerbalsTags(ss);
                                              }}
                                              className={"red " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                          {hasError && verbalsTags.length == 0 ? (
                            <div className="error-message">Required field.</div>
                          ) : null}
                        </div>

                        <div className="verbals_section mt-4">
                          <h5>Para-verbals</h5>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex score_label">
                                <p>Score</p>
                                <ul>
                                  {[0, 1, 2, 3, 4, 5, "NA"].map(
                                    (time, index) => {
                                      return (
                                        <li
                                          key={`Para${index}`}
                                          className={
                                            time == paraVerbals
                                              ? "selected pointer"
                                              : "pointer"
                                          }
                                          onClick={() => setParaVerbals(time)}
                                        >
                                          {time}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                                <p className="basic_tooltip">
                                  <Link to="#">
                                    <img src="/img/icon_info.png" alt="#" />
                                    <span className="tooltip_hover">
                                      0 - not watchable
                                      <br />5 - great job
                                    </span>
                                  </Link>
                                </p>
                              </div>
                              {hasError && paraVerbals === null ? (
                                <div className="error-message">
                                  Required field.
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col tag-title">
                              <p>Tags</p>
                            </div>
                            <div className="col-12">
                              <div className="d-flex default_label">
                                <ul className="green_label">
                                  {reviewTags?.data?.paraVerbals &&
                                  reviewTags?.data?.paraVerbals.length > 0
                                    ? reviewTags?.data?.paraVerbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 1) {
                                            return;
                                          }
                                          const activeClass =
                                            paraVerbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...paraVerbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setParaVerbalsTags(ss);
                                              }}
                                              className={"green " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                              <div className="d-flex default_label">
                                <ul className="red_label align_fix">
                                  {reviewTags?.data?.paraVerbals &&
                                  reviewTags?.data?.paraVerbals.length > 0
                                    ? reviewTags?.data?.paraVerbals.map(
                                        (tag, index) => {
                                          if (tag?.color === 0) {
                                            return;
                                          }

                                          const activeClass =
                                            paraVerbalsTags.indexOf(tag.id) >= 0
                                              ? "active pointer"
                                              : "pointer";
                                          return (
                                            <li
                                              key={`tag-1${index}`}
                                              onClick={() => {
                                                let ss = [...paraVerbalsTags];
                                                if (ss.indexOf(tag.id) == -1) {
                                                  ss.push(tag.id);
                                                } else {
                                                  ss.splice(
                                                    ss.indexOf(tag.id),
                                                    1
                                                  );
                                                }
                                                setParaVerbalsTags(ss);
                                              }}
                                              className={"red " + activeClass}
                                            >
                                              {tag?.tagName}
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                          {hasError && paraVerbalsTags.length == 0 ? (
                            <div className="error-message">Required field.</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="dark_footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="bottom_control_video video-bottom-button">
                  {prevData?.submittedId ? (
                    <Link
                      className="video_btn_control prev"
                      to={`/reviewer/randomiser/detail/${courseId}/${prevData?.submittedId}`}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: CustomIcons.previous,
                        }}
                      ></span>
                      Previous
                    </Link>
                  ) : (
                    <a className="video_btn_control prev"></a>
                  )}
                  <button
                    className="main_btn_default blue_color"
                    type="submit"
                    onClick={handleFormSubmit}
                  >
                    Complete Review
                  </button>
                  {nextData?.submittedId ? (
                    <Link
                      className="video_btn_control next"
                      to={`/reviewer/randomiser/detail/${courseId}/${nextData?.submittedId}`}
                    >
                      Next
                      <span
                        dangerouslySetInnerHTML={{ __html: CustomIcons.next }}
                      ></span>
                    </Link>
                  ) : (
                    <a className="video_btn_control next"></a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      {showUseModel && (
        <HowToUse
          onClose={() => {
            setShowUseModel(false);
          }}
          scenarioId={reviewDetail?.data?.scenarioId}
        />
      )}
    </>
  );
};
export default RandomiserOverViewStudentDetail;

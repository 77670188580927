import React from 'react'
import { Modal } from 'react-bootstrap'

const ExpandedWalkThroughModal = ({ showModal, onClose, url }) => {

    return (
        <>
            <Modal
                show={true}
                className="modal-dark sap"
                aria-labelledby="create_a_lesson"
                size="xl"
                id="text_lesson_preview"
                centered
            >
                <Modal.Header
                    onHide={() => onClose()}
                    closeButton={true}
                    closeVariant="white"
                    className="modal-header"
                >
                    <Modal.Title className="modal-title">
                        Walkthrough
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        width="100%"
                        src={url}
                        title="Combat Sales Roleplay"
                        // style={{ height: "-webkit-fill-available" }}
                        style={{ minHeight: "500px" }}
                        allow="camera;microphone"
                    ></iframe>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ExpandedWalkThroughModal
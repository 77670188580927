import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const userManagementInitialState = {
  invitationResponse: {},
  trainerListResponse: {},
  reviewerListResponse: {},
  rolesResponse: [],
  courseList: [],
  userListResponse: [],
  userByIdResponse: {},
  updatedUserResponse: {},
  deletedUserManagementResponse: {},
}

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: userManagementInitialState,
  reducers: {
    setInvitationResponse: (state, action) => {
      state.invitationResponse = action.payload
    },
    setRolesResponse: (state, action) => {
      state.rolesResponse = action.payload.data
    },
    setCourseListResponse: (state, action) => {
      state.courseList = action.payload.data
    },
    setTrainerListResponse: (state, action) => {
      state.trainerListResponse = action.payload
    },
    setReviewerListResponse: (state, action) => {
      state.reviewerListResponse = action.payload
    },
    setUsersListResponse: (state, action) => {
      state.userListResponse = action.payload
    },
    setUserByIdResponse: (state, action) => {
      state.userByIdResponse = action.payload
    },
    setUpdateUserResponse: (state, action) => {
      state.updatedUserResponse = action.payload
    },
    setDeletedUserManagementResponse: (state, action) => {
      state.deletedUserManagementResponse = action.payload
    },
  },
})

export const sendInvitation = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "UserManagement/SendInvitation",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setInvitationResponse(response))
  }
}

export const getAllRoles = (setErrors) => async (dispatch) => {
  const response = await clientService.get(
    "UserManagement/GetAllRoles",
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setRolesResponse(response))
  }
}

export const getTrainerList = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    "UserManagement​/GetTrainerList",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setTrainerListResponse(response))
  }
}

export const getReviewerList = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    "UserManagement​/GetReviewerList",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerListResponse(response))
  }
}

export const getAllUsers = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "UserManagement/GetAllUsers",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUsersListResponse(response.data))
  }
}

export const getAllCourses = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    "UserManagement/GetAllCourseList",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCourseListResponse(response))
  }
}

export const getUserById = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `UserManagement/GetUsersbyId?userId=${data.userId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUserByIdResponse(response.data))
  }
}

export const updateUserWithRole = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "UserManagement/UpdateUsersWithRole",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setUpdateUserResponse(response))
  }
}

export const deleteUserAccountByUserManagement = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "UserManagement/DeleteUserAccount",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setDeletedUserManagementResponse(response))
  }
}

export const {
  setInvitationResponse,
  setRolesResponse,
  setTrainerListResponse,
  setReviewerListResponse,
  setUsersListResponse,
  setUserByIdResponse,
  setUpdateUserResponse,
  setCourseListResponse,
  setDeletedUserManagementResponse
} = userManagementSlice.actions

export const userManagementState = (state) => {
  return state.userManagement
}

export default userManagementSlice.reducer

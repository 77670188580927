import { Button, Modal } from "react-bootstrap"
import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "react-alert"
import {
  authState,
  changePassword,
  setChangePasswordResponse,
} from "../../store/slices/authSlice"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons"
import * as Yup from "yup"

const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/

const ChangePassword = ({ onClose, userId }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState("password")
  const [newPasswordType, setNewPasswordType] = useState("password")
  const [confirmPasswordType, setConfirmPasswordType] = useState("password")
  const { changePasswordResponse } = useSelector(authState)
  const [showSuccessModel, setShowSuccessModel] = useState(false)

  useEffect(() => {
    if (
      changePasswordResponse.code === 500 ||
      changePasswordResponse.status === false
    ) {
      alert.error(changePasswordResponse.message)
      dispatch(setChangePasswordResponse({}))
    } else if (
      changePasswordResponse.code === 200 ||
      changePasswordResponse.status === true
    ) {
      dispatch(setChangePasswordResponse({}))
      setShowSuccessModel(true)
      // onClose()
      // alert.success(changePasswordResponse.message)
      // dispatch(setChangePasswordResponse({}))
      // navigate("/profile")
    }
  }, [alert, changePasswordResponse])

  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required("Required field."),
    newPassword: Yup.string()
      .required("Required field.")
      .matches(
        PASSWORD_VALIDATOR,
        "New password must be at least 8 characters long with both letters and numbers."
      ),
    confirmPassword: Yup.string()
      .required("Required field.")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Passwords do not match."
        ),
      }),
  })

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }
  const toggleNewPassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text")
      return
    }
    setNewPasswordType("password")
  }
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text")
      return
    }
    setConfirmPasswordType("password")
  }

  const handleFormSubmit = (values, { setErrors }) => {
    var data = {
      ...values,
      id: userId,
    }
    dispatch(changePassword(data, setErrors))
  }
  return (
    <>
      <Modal
        show={true}
        aria-labelledby="change_password"
        centered
        className="modal-dark remove-profile sap"
      >
        <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={Schema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              method="post"
              className="feedback_form"
              onSubmit={handleSubmit}
            >
              <Modal.Body className="change-password-popup">
                <div className="mb-4">
                  <div className="field_with_icon">
                    <label htmlFor="subject" className="form-label">
                      Current Password
                    </label>

                    <div className="input-group">
                      <input
                        id="password-field"
                        type={passwordType}
                        name="currentPassword"
                        className={
                          errors.currentPassword && touched.currentPassword
                            ? "form-control error-field"
                            : "form-control border-right-0"
                        }
                        aria-describedby="subject"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currentPassword}
                      />
                      <span className="input-group-text white-icon">
                        <span
                          toggle="#password-field"
                          className="fa fa-fw fa-eye field-icon toggle-password"
                        >
                          <FontAwesomeIcon
                            className="pointer"
                            onClick={togglePassword}
                            icon={
                              passwordType === "password" ? faEyeSlash : faEye
                            }
                          />
                        </span>
                      </span>
                    </div>
                    <div className="error-message">
                      {errors.currentPassword &&
                        touched.currentPassword &&
                        errors.currentPassword}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="field_with_icon">
                    <label htmlFor="new-password" className="form-label">
                      New Password
                    </label>

                    <div className="input-group">
                      <input
                        type={newPasswordType}
                        className={
                          errors.newPassword && touched.newPassword
                            ? "form-control error-field"
                            : "form-control border-right-0"
                        }
                        name="newPassword"
                        aria-describedby="new-password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                      />

                      <span className="input-group-text white-icon">
                        <span className="fa fa-fw fa-eye field-icon toggle-password">
                          <FontAwesomeIcon
                            className="pointer"
                            onClick={toggleNewPassword}
                            icon={
                              newPasswordType === "password"
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </span>
                      </span>
                    </div>
                    <span className="password_guide mb-0">
                      Use a password at least 8 characters long with both
                      letters and numbers.
                    </span>
                    <div className="error-message">
                      {errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="field_with_icon">
                    <label htmlFor="repeat-password" className="form-label">
                      Confirm New Password
                    </label>

                    <div className="input-group">
                      <input
                        type={confirmPasswordType}
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "form-control error-field"
                            : "form-control border-right-0"
                        }
                        name="confirmPassword"
                        aria-describedby="repeat-password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />

                      <span className="input-group-text white-icon">
                        <span
                          toggle="#password-field"
                          className="fa fa-fw fa-eye field-icon toggle-password"
                        >
                          <FontAwesomeIcon
                            className="pointer"
                            onClick={toggleConfirmPassword}
                            icon={
                              confirmPasswordType === "password"
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </span>
                      </span>
                    </div>

                    <div className="error-message">
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="primary" className="btn-submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>

      {showSuccessModel && (
        <Modal
          show={showSuccessModel}
          className="modal-dark remove-profile sap change-password-success"
          aria-labelledby="password_saved"
          centered
        >
          <Modal.Body>
            <p className="white_color">Your new password has been saved.</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer-left text-left">
              <button
                className="btn-submit btn btn-primary"
                onClick={() => {
                  setShowSuccessModel(false)
                  onClose()
                }}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default ChangePassword

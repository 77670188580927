import { useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getReviewerInstruction,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice"

const HowToUse = ({ onClose, scenarioId }) => {
  const dispatch = useDispatch()
  const { reviewerInstruction } = useSelector(reviewerCourseState)

  useEffect(() => {
    dispatch(getReviewerInstruction({ scenarioId: scenarioId }))
  }, [dispatch])
  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap "
        aria-labelledby="evaluation_session"
        size="lg"
        id="evaluation_session"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            How to score the video?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color">
            {reviewerInstruction?.data?.instruction}
          </p>
          <p className="mt-4 mb-0">
            <a
              className="border_btn_default"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              Close
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default HowToUse

import React, { useCallback, useEffect, useState } from "react"
import SideBar from "../components/Shared/SideBar"
import { Navigate, Outlet } from "react-router-dom"
import AdminHeader from "../common/dashboard/Admin"
import { useDispatch, useSelector } from "react-redux"
import { authState, getProfile } from "../store/slices/authSlice"
import { Container, Row } from "react-bootstrap"

import io from "socket.io-client"
import config from "../config"
import { appState } from "../store/slices/appSlice"
const socket = io(config.socketUrl)

const TrainerBody = () => {
  const { fullScreen } = useSelector(appState)

  const [extraClass, setExtraClass] = useState("")
  const [showSideBar, setShowSideBar] = useState(true)
  const dispatch = useDispatch()
  const { getProfileDataResponse } = useSelector(authState)

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  const handleInviteAccepted = useCallback((data) => {
    window.location.reload()
  }, [])

  useEffect(() => {
    socket.on("connect", () => { })
    socket.on("disconnect", () => { })
    socket.on("FinalEvaluation", handleInviteAccepted)

    return () => {
      socket.off("connect")
      socket.off("disconnect")
      socket.off("pong")
    }
  }, [])

  if (!getProfileDataResponse.id) {
    return <div>Loading</div>
  }
  if (getProfileDataResponse.roleName != "Trainer") {
    if (getProfileDataResponse.roleName == "Reviewer")
      return <Navigate to="/reviewer" />
    else if (getProfileDataResponse.roleName == "Student")
      return <Navigate to="/" />
    else return <Navigate to="/404" />
  }

  return (
    <React.Fragment>
      <div className="main overflow_scroll">
        <AdminHeader fullScreen={fullScreen} userRole="Trainer" />
        <Container fluid>
          <Row>
            {showSideBar ? (
              <SideBar userRole="trainer" fullScreen={fullScreen} />
            ) : null}
            <div
              className={`random_test trainer ${extraClass} ${fullScreen ? "full-screen" : ""}`}
            >
              <Outlet context={{ setExtraClass, setShowSideBar }} />
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TrainerBody

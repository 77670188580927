import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    Chart,
    ChartTitle,
    ChartLegend,
    ChartArea,
    ChartTooltip,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
} from "@progress/kendo-react-charts";

const labelContent = (e) => `${e.category}: \n ${e.value}%`

const mapSeries = (series, index, array) => {
    return (
        <ChartSeriesItem
            type="donut"
            startAngle={90}
            name={series.name}
            data={series.data}
            field="value"
            categoryField="category"
            colorField="color"
            size={10}
            holeSize={50}
        >
            {index === array.length - 1 && (
                <ChartSeriesLabels
                    position=""
                    background="none"
                    content={labelContent}
                />
            )}
        </ChartSeriesItem >
    );
}
const renderTooltip = (context) => {


    const tooltipStyle = {
        backgroundColor: 'rgba(67, 69, 87, 1)',
        // padding: '3px',
        // borderRadius: '4px',
        color: '#ffffff',
        fontSize: '14px'
    };
    const spanStyle = {
        color: "rgba(27, 142, 178, 1)"
    }

    const { category, series, value, dataItem } = context.point || context;
    const { total, count } = dataItem

    let message = "";
    if (category === "Pending Outer") {
        if (count === total && count === 0) {
            message = `Video Reviewed (${0}) - 0%`
        } else {
            message = `Video Pending (${count}) - ${value}%`
        }
    } else if (category === "Reviewed Outer") {
        if (count === 0) {
            message = `Video Reviewed (${count}) - 100%`
        } else {
            message = `Video Reviewed (${count}) - ${value}%`
        }

    } else if (category === "Pending Inner") {
        if (count === 0) {
            message = `Video Pending (${count}) - 100%`
        } else {
            message = `Video Pending (${count}) - ${value}%`
        }

    } else if (category === "Reviewed Inner") {
        if (count === total) {
            message = `Video Pending (${0}) - 0%`
        } else {
            message = `Video Reviewed (${count}) - ${value}%`
        }
    }

    return (
        <div style={tooltipStyle}>
            {message}
        </div>
    );
};

const TodaysActivityChart = (props) => {
    const total = props.outerCircleCount + props.innerCircleCount;
    const internetGrowthData = [{
        "name": "Video",
        "data": [{
            "category": `Pending Inner`,
            "value": props.innerCircleStoke,
            "total": total,
            "count": props.innerCircleCount,
            "color": "#9e26ff",
        },
        {
            "category": `Reviewed Inner`,
            "value": props.outerCircleStoke,
            "total": total,
            "count": props.outerCircleCount,
            "color": "#3C565B",
        }]
    }, {
        "name": "Video",
        "data": [{
            "category": `Reviewed Outer`,
            "value": props.outerCircleStoke,
            "total": total,
            "count": props.outerCircleCount,
            "color": "#26cbff"
        },
        {
            "category": `Pending Outer`,
            "value": props.innerCircleStoke,
            "total": total,
            "count": props.innerCircleCount,
            "color": "#3C565B",
        },]
    }]
    if (props.innerCircleCount == 0 && props.outerCircleCount == 0) {
        return <div style={{ fontSize: "16px", color: "gray", textAlign: 'center' }}>No data for today</div>
    }
    return (
        <Chart className="today_activity" style={{ textAlign: 'center' }}>
            <ChartTooltip render={renderTooltip} />
            {/* <ChartTitle text="Share of Internet Population Growth" /> */}
            <ChartLegend visible={false} />
            <ChartArea background="none" />
            <ChartSeries>{internetGrowthData.map(mapSeries)}</ChartSeries>
        </Chart>
    );
}

export default TodaysActivityChart;

import moment from "moment"
import { useEffect } from "react"
import { useState } from "react"
import { useAlert } from "react-alert"
import UserAvtar from "../../../common/UserAvtar"
import clientService from "../../../services/service"

const DiscussionInfo = ({ discussion }) => {
  const alert = useAlert()
  const [likeCount, setLikeCount] = useState(discussion.likeCount)
  const [commnetCount, setCommnetCount] = useState(discussion.commnetCount)
  const [showSendReply, setShowSendReply] = useState(false)
  const [showReplies, setShowReplies] = useState(false)
  const [comment, setComment] = useState("")
  const [replies, setReplies] = useState([])
  const [pageNO, setPageNO] = useState(1)

  useEffect(() => {
    if (showReplies && replies.length == 0) {
      getReplies()
    }
  }, [showReplies])

  useEffect(() => {
    if (pageNO != 1) {
      getReplies()
    }
  }, [pageNO])
  const likeDiscussion = async () => {
    const res = await clientService.post("StudentCourse/LikeDiscussion", {
      discussionId: discussion.discussionId,
    })
    alert.success(res.message)
    setLikeCount(res.data.likeCount)
  }
  const addComment = async () => {
    if (comment) {
      const data = {
        discussionId: discussion.discussionId,
        discussion: comment,
      }
      const res = await clientService.post("StudentCourse/AddComment", data)
      alert.success(res.message)
      setComment("")
      setReplies([
        {
          areYouTrainer: false,
          commentId: res.data.id,
          createdDate: moment.utc(),
          discussion: res.data.discussion,
          likeCount: 0,
          profileImage: res.data.profileImage,
          userName: res.data.userName,
        },
        ...replies,
      ])
      setCommnetCount(commnetCount + 1)
    }
  }
  const getReplies = async () => {
    const data = {
      discussionId: discussion.discussionId,
      pageNo: pageNO,
      pageSize: 5,
    }
    const res = await clientService.post("StudentCourse/GetAllComments", data)
    if (pageNO == 1) {
      setReplies(res.data.commentList)
    } else {
      setReplies([...replies, ...res.data.commentList])
    }
  }
  const time = moment.utc(discussion.createdDate).local().format("M/D/Y h:mm A")

  const likeComment = async (index, id) => {
    const res = await clientService.post("StudentCourse/LikeComment", {
      commentId: id,
    })
    const resplies = [...replies]
    resplies[index].likeCount = res.data.likeCount
    setReplies(resplies)
  }
  return (
    <div className="comment_blog d-flex">
      <UserAvtar name={discussion.userName} image={discussion.profileImage} />
      <div className="comment_inner_section">
        <div className="top_section d-flex space_between">
          <h3 className="comment_name">
            {discussion.userName}
            {discussion?.isForTrainer ? (
              <label className="trainer_label">Ask Trainer</label>
            ) : null}
            <span className="date_time">{time}</span>
          </h3>
          <p>
            {likeCount}
            <svg
              onClick={likeDiscussion}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.70485 0.431897C6.82329 0.16926 7.08756 0 7.37917 0C8.162 0 8.91277 0.306491 9.46632 0.852048C10.0199 1.39761 10.3308 2.13754 10.3308 2.90907V5.09088H13.7658C14.0851 5.08797 14.4013 5.15318 14.6927 5.28207C14.9855 5.41158 15.2462 5.60227 15.4568 5.84094C15.6673 6.0796 15.8227 6.36054 15.9122 6.66428C16.0017 6.96802 16.0231 7.2873 15.9749 7.6L14.9566 14.1453C14.8765 14.6654 14.6083 15.1399 14.2016 15.4806C13.796 15.8204 13.2797 16.0048 12.7474 15.9999H2.21375C1.62663 15.9999 1.06355 15.77 0.648393 15.3609C0.233234 14.9517 0 14.3968 0 13.8181V8.72722C0 8.14857 0.233234 7.59362 0.648393 7.18445C1.06355 6.77528 1.62663 6.54542 2.21375 6.54542H3.94795L6.70485 0.431897ZM5.16542 7.42702L7.8277 1.52334C8.0501 1.59326 8.25462 1.71486 8.42275 1.88056C8.69952 2.15334 8.85501 2.52331 8.85501 2.90907V5.81815C8.85501 6.21981 9.18538 6.54542 9.59292 6.54542H13.7695L13.7779 6.54537C13.8849 6.54418 13.9908 6.56592 14.0884 6.60908C14.186 6.65225 14.2729 6.71582 14.3431 6.79537C14.4133 6.87493 14.4651 6.96858 14.4949 7.06982C14.5247 7.17097 14.5318 7.2773 14.5159 7.38144L13.4975 13.9272C13.4708 14.1007 13.3814 14.2587 13.2458 14.3723C13.1102 14.4859 12.9375 14.5474 12.7596 14.5454L5.16542 14.5454V7.42702ZM3.68959 14.5454V7.99995H2.21375C2.01804 7.99995 1.83035 8.07658 1.69197 8.21297C1.55358 8.34935 1.47583 8.53434 1.47583 8.72722V13.8181C1.47583 14.011 1.55358 14.196 1.69197 14.3324C1.83035 14.4687 2.01804 14.5454 2.21375 14.5454H3.68959Z"
                fill="#ACAFBF"
              ></path>
            </svg>
          </p>
        </div>
        <div
          className="comment_cont pointer"
          onClick={() => setShowReplies(!showReplies)}
        >
          <p className="mt-3">{discussion.discussion}</p>
          <p>
            {commnetCount} replies
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7071 0.292893C13.3166 -0.097631 12.6834 -0.097631 12.2929 0.292893L7 5.58579L1.70711 0.292894C1.31658 -0.09763 0.683417 -0.0976299 0.292893 0.292894C-0.0976314 0.683419 -0.0976313 1.31658 0.292894 1.70711L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893Z"
                fill="#ACAFBF"
              ></path>
            </svg>
          </p>
        </div>

        {showReplies && replies.length >= 1 && (
          <div className="comment_child">
            {replies.map((reply, index) => {
              const timeString = moment
                .utc(reply.createdDate)
                .local()
                .format("M/D/Y h:mm A")

              return (
                <div key={reply.commentId} className="comment_blog d-flex">
                  <UserAvtar name={reply.userName} image={reply.profileImage} />
                  <div className="comment_inner_section">
                    <div className="top_section d-flex space_between">
                      <h3 className="comment_name">
                        {reply.userName}{" "}
                        {/* {discussion?.isForTrainer ? (
                          <label className="trainer_label">Ask Trainer</label>
                        ) : null} */}
                        <span className="date_time">{timeString}</span>
                      </h3>
                      <p
                        onClick={() => {
                          likeComment(index, reply.commentId)
                        }}
                      >
                        {reply.likeCount}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.70485 0.431897C6.82329 0.16926 7.08756 0 7.37917 0C8.162 0 8.91277 0.306491 9.46632 0.852048C10.0199 1.39761 10.3308 2.13754 10.3308 2.90907V5.09088H13.7658C14.0851 5.08797 14.4013 5.15318 14.6927 5.28207C14.9855 5.41158 15.2462 5.60227 15.4568 5.84094C15.6673 6.0796 15.8227 6.36054 15.9122 6.66428C16.0017 6.96802 16.0231 7.2873 15.9749 7.6L14.9566 14.1453C14.8765 14.6654 14.6083 15.1399 14.2016 15.4806C13.796 15.8204 13.2797 16.0048 12.7474 15.9999H2.21375C1.62663 15.9999 1.06355 15.77 0.648393 15.3609C0.233234 14.9517 0 14.3968 0 13.8181V8.72722C0 8.14857 0.233234 7.59362 0.648393 7.18445C1.06355 6.77528 1.62663 6.54542 2.21375 6.54542H3.94795L6.70485 0.431897ZM5.16542 7.42702L7.8277 1.52334C8.0501 1.59326 8.25462 1.71486 8.42275 1.88056C8.69952 2.15334 8.85501 2.52331 8.85501 2.90907V5.81815C8.85501 6.21981 9.18538 6.54542 9.59292 6.54542H13.7695L13.7779 6.54537C13.8849 6.54418 13.9908 6.56592 14.0884 6.60908C14.186 6.65225 14.2729 6.71582 14.3431 6.79537C14.4133 6.87493 14.4651 6.96858 14.4949 7.06982C14.5247 7.17097 14.5318 7.2773 14.5159 7.38144L13.4975 13.9272C13.4708 14.1007 13.3814 14.2587 13.2458 14.3723C13.1102 14.4859 12.9375 14.5474 12.7596 14.5454L5.16542 14.5454V7.42702ZM3.68959 14.5454V7.99995H2.21375C2.01804 7.99995 1.83035 8.07658 1.69197 8.21297C1.55358 8.34935 1.47583 8.53434 1.47583 8.72722V13.8181C1.47583 14.011 1.55358 14.196 1.69197 14.3324C1.83035 14.4687 2.01804 14.5454 2.21375 14.5454H3.68959Z"
                            fill="#ACAFBF"
                          ></path>
                        </svg>
                      </p>
                    </div>
                    <div className="comment_cont">
                      <p className="mt-2">{reply.discussion}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        <div className="comment_footer">
          {showSendReply ? (
            <div className="comment_section">
              <div className="default_comment_field">
                <div className="user_avtar">
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    name="message"
                    placeholder="Enter message"
                  />
                  <button onClick={addComment}>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.4445 10.3342C21.6024 10.5111 21.6984 10.7443 21.6984 11.0001C21.6984 11.4054 21.4573 11.7544 21.1106 11.9114L2.0403 21.0934C1.67087 21.2713 1.23029 21.2056 0.92879 20.9278C0.627284 20.6499 0.52595 20.2161 0.673138 19.8334L4.0706 11L0.673139 2.16662C0.525951 1.78393 0.627284 1.35016 0.92879 1.07229C1.23029 0.794425 1.67087 0.728762 2.0403 0.906635L21.1127 10.0896C21.1825 10.1215 21.2481 10.1611 21.3082 10.2074C21.3191 10.2158 21.3298 10.2244 21.3402 10.2332C21.3776 10.2644 21.4124 10.2982 21.4445 10.3342ZM16.3163 12.0001H5.82881L3.44529 18.1972L16.3163 12.0001ZM16.3164 10.0001L3.44529 3.80287L5.82883 10.0001H16.3164Z"
                        fill="#9E26FF"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p>
              <a onClick={() => setShowSendReply(true)} href="#">
                <svg
                  width="20"
                  height="12"
                  viewBox="0 0 20 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 1C20 0.447715 19.5523 0 19 0C18.4477 0 18 0.447715 18 1V3C18 3.79565 17.6839 4.55871 17.1213 5.12132C16.5587 5.68393 15.7956 6 15 6H3.41421L5.70711 3.70711C6.09763 3.31658 6.09763 2.68342 5.70711 2.29289C5.31658 1.90237 4.68342 1.90237 4.29289 2.29289L0.292893 6.29289C-0.0976311 6.68342 -0.0976311 7.31658 0.292893 7.70711L4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071C6.09763 11.3166 6.09763 10.6834 5.70711 10.2929L3.41421 8H15C16.3261 8 17.5979 7.47321 18.5355 6.53553C19.4732 5.59785 20 4.32608 20 3V1Z"
                    fill="#ACAFBF"
                  ></path>
                </svg>
                Reply
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default DiscussionInfo

import Header from "../components/Shared/Header"

const TermsCondition = () => {
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-12">
              <div className="sucess_msg_full forgot-main-wrapper mx-auto privacy-policy">
                <div className="forgot-caption">
                  <h1 className="title">TERMS OF SERVICE</h1>
                </div>
                <div className="forgot-inner-wrapper">
                  <p className="mt-4 text-left">
                    <b>1. ACCEPTANCE OF SERVICE AND AGREEMENT TO TERMS.</b> <br />{" "}
                    These Terms of Service constitute a legally binding
                    agreement made between you, whether personally or on behalf
                    of an entity (“you”) and Combat Sales ("Company," “we,"
                    “us," or “our”), concerning your access to and use of the
                    combat sales website as well as any other media form, media
                    channel, mobile website or mobile application related,
                    linked, or otherwise connected thereto (collectively, the
                    “Site”). You agree that by accessing the Site, you have
                    read, understood, and agreed to be bound by all of these
                    Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
                    USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
                    AND YOU MUST DISCONTINUE USE IMMEDIATELY. Please ensure that
                    you check the applicable Terms every time you use our Site
                    so that you understand which Terms apply. You will be
                    subject to, and will be deemed to have been made aware of
                    and to have accepted, the changes in any revised Terms of
                    Use by your continued use of the Site after the date such
                    revised Terms of Service are posted.
                    <br /> <br />
                    <b>1.SERVICES AND CUSTOMER’S OBLIGATION.</b> <br /> <br /> <br />
                    1. 2.1.Services- Customers can avail the services through
                    [Insert Website Name] <br /> <br />
                    2. 2.2.Obligation and representation of the Customer: <br />{" "}
                    <br />
                    i.Registration - You must create an Account and subscribe
                    for the Services at [Insert Website Name] in order to use
                    the Services. You are in charge of maintaining an Account
                    once you've created one and safeguarding its login
                    information. You acknowledge that effective communication
                    with us depends on your prompt notification of any changes
                    to the information you have provided.
                    <br />
                    ii. You hereby acknowledge that You are (i) at least 18
                    years old; (ii) legally able to bind yourself or the party
                    you may be representing to our Terms; and (iii) agree to use
                    the Service only for purposes that are allowed by these
                    Terms of Service. There may be usage restrictions and
                    limitations on the services we offer. You must make sure you
                    adhere to these constraints and limitations.
                    <br />
                    iii.You represent that you will not use the Site for any
                    illegal or unauthorized purpose; and that your use of the
                    Site will not violate any applicable law or regulation. If
                    you provide any information that is untrue, inaccurate, not
                    current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Site (or any portion thereof).
                    <br /> <br />
                    <b>1. SITE MANAGEMENT.</b> <br /> We reserve the right, but not the
                    obligation, to: (1) monitor the Site for violations of these
                    Terms of Service; (2) take appropriate legal action against
                    anyone who, in our sole discretion, violates the law or
                    these Terms of Use, including without limitation, reporting
                    such user to law enforcement authorities; (3) in our sole
                    discretion and without limitation, refuse, restrict access
                    to, limit the availability of, or disable (to the extent
                    technologically feasible) any of your Contributions or any
                    portion thereof; (4) in our sole discretion and without
                    limitation, notice, or liability, to remove from the Site or
                    otherwise disable all files and content that are excessive
                    in size or are in any way burdensome to our systems; and (5)
                    otherwise manage the Site in a manner designed to protect
                    our rights and property and to facilitate the proper
                    functioning of the Site. We will make every effort to offer
                    you with services that adhere to our privacy policy and any
                    applicable documentation.
                    <br /> <br />
                    <b>PROHIBITED ACTIVITIES.</b> <br /> The Site may only be accessed
                    and used for the purposes for which it is intended by us. As
                    a user of the Site, you agree not to:
                    <br />
                    i. Copy, modify, reuse, license, sub-license, sell or
                    transfer in any manner, commercially or otherwise, the
                    Services offered by the Site.
                    <br />
                    ii. disassemble, reverse engineer or decompile the Services
                    or Software or otherwise attempt to discover the source
                    code, object code or underlying structure, ideas or
                    algorithms of the Services or any software, documentation or
                    data related to or provided with the Services;
                    <br />
                    iii. access the Services in order to develop a competing
                    product or service;
                    <br />
                    iv. use the Service to provide a service for others;
                    <br />
                    v. remove or modify a copyright or other proprietary rights
                    notice on or in the Services;
                    <br />
                    vi. violate any Applicable Law;
                    <br />
                    vii. disable, hack or otherwise interfere with any security,
                    digital signing, digital rights management, verification or
                    authentication mechanisms implemented in or by the Services;
                    <br />
                    viii. intentionally include, send, store or run software
                    viruses, worms, Trojan horses or other harmful computer
                    code, files, scripts, agents or programs from the Services;
                    <br />
                    ix. intentionally cause a computer to malfunction,
                    regardless of how long the malfunction persists;
                    <br />
                    x. alter, disable, or erase any computer data, computer
                    programs or computer software without authorization; or
                    <br />
                    xi. introduce information through our Platform which places
                    confidentiality burden that is not agreed upon between you;
                    or
                    <br />
                    xii. Except as permitted by applicable law, decipher,
                    decompile, disassemble, or reverse engineer any of the
                    software comprising or in any way making up a part of the
                    Site; or
                    <br />
                    xiii. Any other activity that threatens the existence of
                    Combat Sales LLC. <br /> <br />
                    <b>1.INTELLECTUAL PROPERTY RIGHTS.</b> <br /> <br />
                    <br />
                    Unless otherwise indicated, the Site is our proprietary
                    property and all source code, databases, functionality,
                    software, website designs, audio, video, text, photographs,
                    and graphics on the Site (collectively, the “Content”) and
                    the trademarks, service marks, and logos contained therein
                    (the “Marks”) are owned or controlled by us or licensed to
                    us, and are protected by copyright and trademark laws and
                    various other intellectual property rights and unfair
                    competition laws of the United States, international
                    copyright laws, and international conventions. The Content
                    and the Marks are provided on the Site “AS IS” for your
                    information and personal use only. Except as expressly
                    provided in these Terms of Use, no part of the Site and no
                    Content or Marks may be copied, reproduced, aggregated,
                    republished, uploaded, posted, publicly displayed, encoded,
                    translated, transmitted, distributed, sold, licensed, or
                    otherwise exploited for any commercial purpose whatsoever,
                    without our express prior written permission. Provided that
                    you are eligible to use the Site, you are granted a limited
                    license to access and use the Site and to download or print
                    a copy of any portion of the Content to which you have
                    properly gained access solely for your personal,
                    non-commercial use. We reserve all rights not expressly
                    granted to you in and to the Site, the Content and the
                    Marks.
                    <br />
                    <br />
                    <b>1. PROPRIETARY RIGHTS.</b> <br />
                    <br />
                    1. 6.1. OWNERSHIP AND RESERVATION OF RIGHTS TO COMBAT SALES
                    INTELLECTUAL PROPERTY. All of the rights, titles, and
                    interests in and to the Service, as well as all associated
                    intellectual property rights, are owned by Combat Sales and
                    its licensors. Combat Sales retains all rights, titles, and
                    interests in and to the Service, including all Intellectual
                    Property Rights, subject to the specific limited rights that
                    are expressly given to you in accordance with these Terms of
                    Service. To be clear, Customer does not have any ownership
                    stake in the Service.
                    <br />
                    2. 6.2. OWNERSHIP OF CUSTOMER DATA. As between Combat Sales
                    and Customer, Customer owns Customer Data.
                    <br />
                    3. 6.3. GRANT OF RIGHTS.
                    <br /> <br />
                    (a)<b> By Combat Sales.</b> <br /> Combat Sales hereby grants you a
                    non-exclusive, non-transferable, non-sublicensable,
                    worldwide right to use the Service solely during the Term
                    and only for your internal business needs, subject to your
                    Subscription requirements and the Terms.
                    <br /> <br />
                    (b) <b>By Customer.</b> <br />
                    <br />
                    <br />
                    i. You acknowledge that all such data may be handled by a
                    third party without further authorization from you for the
                    following reasons: (A) the third party's own lawful business
                    purposes; (B) our business purposes, including transaction
                    processing, data monitoring, or storage; or (C) regulatory
                    or other requirements imposed on us in Atlanta, Georgia, the
                    United States of America, or in any other jurisdiction in
                    which we offer services or may otherwise choose to store or
                    process data.
                    <br /> <br />
                    iii. <b>Aggregated Information.</b> <br /> As long as such usage
                    complies with applicable law and does not divulge any
                    confidential information or the identity of an authorized
                    user to a third party, we will own all aggregated
                    information and may use it for any purpose we see fit,
                    including but not limited to publication and the creation of
                    derivative works from the information.
                    <br /> <br />
                    iv. <b>Input by Customer.</b> <br /> Any Customer Input may be used
                    or incorporated into Services under a licence granted to us
                    that is irrevocable, royalty-free, transferable, worldwide,
                    and sublicensable. You are not required to offer customer
                    input. We reserve the right to delete, move, and amend any
                    customer input submitted if we determine in our sole and
                    absolute discretion that doing so is appropriate or
                    required, even though we do not seek to monitor or control
                    the submission of customer input.
                    <br />
                    <br />
                    <b>1.DISCLAIMER.</b> <br /> THE SITE IS PROVIDED ON AN AS-IS AND
                    AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND
                    OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                    EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
                    OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE
                    THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                    REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                    SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE
                    SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
                    ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                    MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                    NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
                    THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                    SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                    AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                    SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                    WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD
                    PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                    MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS
                    A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                    OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT,
                    ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                    OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
                    THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                    APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
                    WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                    MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                    PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
                    PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
                    YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                    APPROPRIATE. LIMITATIONS OF LIABILITY IN NO EVENT WILL WE OR
                    OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
                    THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                    EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
                    INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                    DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    <br />
                    <br />
                    <b>1.INDEMNIFICATION.</b> <br /> You agree to defend, indemnify,
                    and hold us harmless, including our subsidiaries,
                    affiliates, and all of our respective officers, agents,
                    partners, and employees, from and against any loss, damage,
                    liability, claim, or demand, including reasonable attorneys’
                    fees and expenses, made by any third party due to or arising
                    out of: (1) your Contributions; (2) use of the Site; (3)
                    breach of these Terms of Use; (4) any breach of your
                    representations and warranties set forth in these Terms of
                    Use; (5) your violation of the rights of a third party,
                    including but not limited to intellectual property rights;
                    or (6) any overt harmful act toward any other user of the
                    Site with whom you connected via the Site. Notwithstanding
                    the foregoing, we reserve the right, at your expense, to
                    assume the exclusive defense and control of any matter for
                    which you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defense of such claims.
                    We will use reasonable efforts to notify you of any such
                    claim, action, or proceeding which is subject to this
                    indemnification upon becoming aware of it.
                    <br />
                    <br />
                    <b>1.LIMITATION OF LIABILITY.</b> <br /> IN NO EVENT WILL COMBAT
                    SALES OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
                    YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                    CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                    DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA,
                    OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF
                    WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    <br />
                    <br />
                    <b>1.TERM AND TERMINATION.</b> <br /> These Terms of Use shall
                    remain in full force and effect while you use the Site.
                    WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
                    WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                    NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
                    (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR
                    ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
                    FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
                    CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                    REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN
                    THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
                    INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
                    OUR SOLE DISCRETION. If we terminate or suspend your account
                    for any reason, you are prohibited from registering and
                    creating a new account under your name, a fake or borrowed
                    name, or the name of any third party, even if you may be
                    acting on behalf of the third party. In addition to
                    terminating or suspending your account, we reserve the right
                    to take appropriate legal action, including without
                    limitation pursuing civil, criminal, and injunctive redress.
                    <br />
                    <br />
                    <b>1.GENERAL PROVISIONS. </b> <br />
                    11.1 <b>Definitions.</b> <br /> Terms used in these Terms of
                    Service shall have the following meanings:
                    <br />
                    <br />
                    <br />
                    (a) “Account” means the user account, which includes a
                    username and password, associated with a Customer on our
                    Platform to access and use the Service;
                    <br />
                    <br />
                    (b) “Aggregated Information” means all data that has been
                    anonymized after being obtained from your use of the
                    services, including use data, performance metrics, data, and
                    other content, provided that none of this data can be used
                    to identify you or another user.
                    <br />
                    <br />
                    (c) “Applicable Laws” means all statutes, codes, rules,
                    regulations, bylaws, judicial or arbitral or administrative
                    or ministerial or departmental or regulatory judgments,
                    orders, decisions, rulings or awards, policies, guidelines,
                    or any provisions of the foregoing, including common and
                    civil law and equity general principles, binding on or
                    affecting the Person referred to in the context in which
                    such word is used;
                    <br />
                    <br />
                    (d)“Content” means all information, data, and content found
                    in any such material, including but not limited to any and
                    all videos, music, sounds, images, live streams,
                    documentation, reports, materials, files, text, images,
                    logos, artwork, graphics, pictures, advertisements, works,
                    and any other works of authorship;
                    <br />
                    <br />
                    (e)“Customer Data” means any non-anonymized electronic data
                    that is gathered and/or processed about the Customer, the
                    Users, and the Users using the Service, including personal
                    data, login credentials, and other data relevant to such
                    parties' usage of the Service;
                    <br />
                    <br />
                    (f) “Documentation” means the operating and usage
                    instructions for the Services that Combat Sales provides to
                    Customer in accordance with these Terms of Service, as
                    updated from time to time by Combat Sales;
                    <br />
                    <br />
                    (g)“Intellectual Property” includes patents, patent
                    applications, trade practices, technical know-how,
                    copyright, service marks, certificates of invention, utility
                    models, continuations, continuations-in-part, provisional,
                    divisions, reissues, renewals, re-examinations, and
                    extensions of innovations, discoveries, or improvements
                    (whether or not reduced to practise); whether presently
                    existent, including the right to request registrations,
                    certifications, or renewals with respect to them, the right
                    to pursue, enforce, and seek restitution..
                    <br />
                    <br />
                    (h) “Intellectual Property Rights” means any rights related
                    to Intellectual Property.
                    <br />
                    <br />
                    (i) “Person” means a natural person, a business, a
                    government, or any other legal, business, or governmental
                    entity, including, but not limited to, a corporation,
                    general partnership, joint venture, limited partnership,
                    limited liability company, trust, business association,
                    group acting jointly, or any individual acting in a
                    representative capacity;
                    <br />
                    <br />
                    (j) “Platform” means the software, code, hardware, and
                    servers utilised in the administration and delivery of the
                    Services, including the Software and Documentation;
                    <br />
                    <br />
                    (k) “Service” means any solution or product we make
                    available through our platform, as well as any related
                    content. By "Services," we refer to a variety of services.
                    <br />
                    <br />
                    (l) “Software” includes software products used in connection
                    with the Service, such as the dashboard, reporting centre,
                    and chat agent for Combat Sales, and may include code that
                    is made available or distributed with the Software, as
                    appropriate, under the terms of third-party licence
                    agreements, including open source;
                    <br />
                    <br />
                    (m) Users” refers to users who access or view customer
                    content as well as the customer's employees,
                    representatives, consultants, contractors, or agents who are
                    permitted to use the services in the customer's favour and
                    who have individual user IDs and passwords for the services;
                    <br />
                    <br />
                    (n) “User Data” means the electronic information that the
                    Service collects and analyses about the traits and
                    behaviours of Users (including personal data about such
                    Users) in relation to how they use or view Customer Content.
                    <br />
                    <br />
                    <br />
                    11.2 <b>Permission for Communication.</b> <br /> We communicate
                    with you through email and other technological channels. You
                    acknowledge and agree that by giving us your email address
                    or other personally identifying data while using or before
                    accessing the Services, you: (i) agree to receive
                    communications from us, our Affiliates, and applicable Users
                    in electronic formats, such as through the email address you
                    have provided, SMS messages to your phone, or other
                    pre-arranged methods of contact; (ii) have the option to
                    stop receiving communications from any such party at any
                    time by following the procedures on our website, but we do
                    not assume any responsibility for any communication from
                    another party to you, especially if you have provided your
                    email address.
                    <br />
                    <br />
                    11.3 <b>Modification of our Terms.</b> <br /> We reserve the right,
                    at our sole discretion, to amend these Terms of Service at
                    any time. We will ensure the Terms, which are available on
                    our Website, show the date of last amendment. Your continued
                    use of our Services shall constitute your (i) affirmative
                    acknowledgement of our most current Terms, and (ii)
                    agreement to abide and be bound by our Terms, as amended. If
                    at any time you choose not to accept our revised Terms, then
                    please do not use the Services and as applicable, terminate
                    your Subscription and Account in accordance with the Terms.
                    <br />
                    <br />
                    11.4 <b>Governing Law.</b> <br /> Our Terms and any access to or
                    use of the Services shall be governed by, and construed in
                    accordance with the internal laws of the Province of Atlanta
                    and the federal laws of Georgia, without giving effect to
                    any choice or conflict of law provision or rule that would
                    cause the application of the laws of any jurisdiction other
                    than those of the province of Ontario.
                    <br />
                    <br />
                    11.5 <b>Waiver of Class Proceedings.</b> <br /> You hereby
                    relinquish any claim, dispute, or controversy that may have
                    given rise to a right you may have to bring or take part in
                    a class action lawsuit against us, and, where applicable,
                    you and any Users hereby agree to withdraw from any class
                    action lawsuit that may have been brought against us in the
                    past.
                    <br />
                    <br />
                    11.6 <b>Severability.</b> <br /> The remaining portion of our Terms
                    or the application of such portion of provision in
                    circumstances other than those for which it is so declared
                    illegal or unenforceable shall not be affected if any
                    portion or provision of our Terms is to any extent found to
                    be unlawful or unenforceable by a court of competent
                    jurisdiction. Each portion and provision of our Terms shall
                    be valid and enforceable to the fullest extent permitted by
                    law.
                    <br />
                    <br />
                    11.7 <b>Notices.</b> <br /> Any notice to us, including those
                    regarding termination, should be made in writing by
                    certified postal mail to __________________, unless
                    otherwise specified in our Terms or as expressly required by
                    law. Any notice to you will be sent to the email address
                    that is most current on your Account.
                    <br />
                    <br />
                    11.8 <b>Other Rules of Interpretation.</b> <br /> The word "or" is
                    not exclusive; the terms "including," "including," and
                    "include" mean "including without limitation;" and "shall"
                    means "will" and "must," all three of which can be changed
                    interchangeably and shall not mean "may." Any reference to
                    gender encompasses all genders.
                    <br />
                    <br />
                    11.9 <b>No Waiver of Covenants.</b> <br /> Any covenant, agreement,
                    term, provision, or condition contained in the Terms will
                    not be understood as having been waived or renounced if we
                    fail to insist on strict compliance with them or if we fail
                    to exercise any choice. Instead, they will continue to be in
                    full force. We cannot be assumed to have waived anything
                    unless it is in writing.
                    <br />
                    <br />
                    11.10 <b>Entire Agreement and Priority.</b> <br /> All earlier
                    communications, agreements and understandings, whether
                    written or oral, with respect to the subject matter of our
                    Terms are superseded by these Terms of Service, any
                    subscription order form terms and conditions, and our
                    Privacy Policy, which are all included by reference. If
                    there is a discrepancy between the Terms and an order form,
                    the Terms' terms and conditions will take precedence.
                    <br />
                    <br />
                    <b>1. MISCELLANEOUS.</b> <br /> These Terms of Use and any policies
                    or operating rules posted by us on the Site or in respect to
                    the Site constitute the entire agreement and understanding
                    between you and us. Our failure to exercise or enforce any
                    right or provision of these Terms of Use shall not operate
                    as a waiver of such right or provision. These Terms of Use
                    operate to the fullest extent permissible by law. We may
                    assign any or all of our rights and obligations to others at
                    any time. We shall not be responsible or liable for any
                    loss, damage, delay, or failure to act caused by any cause
                    beyond our reasonable control. If any provision or part of a
                    provision of these Terms of Use is determined to be
                    unlawful, void, or unenforceable, that provision or part of
                    the provision is deemed severable from these Terms of Use
                    and does not affect the validity and enforceability of any
                    remaining provisions. There is no joint venture,
                    partnership, employment or agency relationship created
                    between you and us as a result of these Terms of Use or use
                    of the Site. You agree that these Terms of Use will not be
                    construed against us by virtue of having drafted them. You
                    hereby waive any and all defenses you may have based on the
                    electronic form of these Terms of Use and the lack of
                    signing by the parties hereto to execute these Terms of Use.
                    <br />
                    <br />
                    <b>1. CONTACT US.</b> <br /> In order to resolve a complaint
                    regarding the Site or to receive further information
                    regarding use of the Site, please contact us at:
                    <br />
                    <br />
                    Combat Sales:_________
                    <br />
                    Email: <a
                          href="mailto:support@combatsales.com"
                          className="purple-color"
                        >support@combatsales.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsCondition

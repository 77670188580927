import { useSelector } from "react-redux"
import { appState } from "../store/slices/appSlice"

const Loader = () => {
  const { loading } = useSelector(appState)

  return loading ? (
    <div id="loader">
      <div className="loader"></div>{" "}
    </div>
  ) : null
}

export default Loader

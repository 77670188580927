import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import UserAvtar from "../../../common/UserAvtar"
import clientService from "../../../services/service"
import { authState, getProfile } from "../../../store/slices/authSlice"
import TrainerDiscussionInfo from "../TrainerDiscussionInfo"


const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TrainerTextLesson = ({ lesson, prev, next }) => {
  const { getProfileDataResponse } = useSelector(authState)
  const [lessonInfo, setLessonInfo] = useState({})
  const [comment, setComment] = useState("")
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [discussions, setDiscussions] = useState([])
  const alert = useAlert();
  const dispatch = useDispatch();

  const query = useQuery();
  const guideLessonId = query.get("lessonId");
  const discussionId = query.get("discussionId");


  useEffect(() => {
    getGuideLessons()
    dispatch(getProfile())
  }, [])

  useEffect(() => {
    getGuideLessons()
    if (pageNo == 1) {
      getDiscussion()
    } else {
      setPageNo(1)
    }
  }, [lesson?.courseGuideLessonId])

  const getGuideLessons = async () => {
    const res = await clientService.get(
      `TrainerCourse/GetTextAndVideoLesson?CourseGuideLessonId=${lesson?.courseGuideLessonId}`
    )
    setLessonInfo(res.data)
  }

  const getDiscussion = async () => {
    if (guideLessonId == lesson?.courseGuideLessonId && discussionId) {
      const data = {
        lessonId: lesson?.courseGuideLessonId,
        discussionId: discussionId,
        pageNo: pageNo,
        pageSize: 1,
      }
      const res = await clientService.post(
        "TrainerCourse/GetAllDiscussion",
        data
      )
      setTotal(res.data.discussionCount)
      setDiscussions(res.data.discusstionList)
    } else if (lesson?.courseGuideLessonId) {
      const data = {
        lessonId: lesson?.courseGuideLessonId,
        pageNo: pageNo,
        pageSize: 10,
      }
      const res = await clientService.post(
        "TrainerCourse/GetAllDiscussion",
        data
      )
      setTotal(res.data.discussionCount)
      if (pageNo == 1) {
        setDiscussions(res.data.discusstionList)
      } else {
        setDiscussions([...discussions, res.data.discusstionList])
      }
    }
  }

  const addDiscussion = async () => {
    if (comment) {
      const data = {
        lessonId: lesson?.courseGuideLessonId,
        discussion: comment,
      }
      const res = await clientService.post(
        "TrainerCourse/AddDiscussionForTrainer",
        data
      )
      if (res.status) {
        alert.success(res.message)
        setComment("")
        if (pageNo == 1) {
          getDiscussion()
        } else {
          setPageNo(1)
        }
      } else {
        alert.error(res.message)
      }
    }
  }
  return (
    <>
      <div className="resources_Content">
        <h3>{lesson?.lessonName}</h3>

        <p
          className="note"
          dangerouslySetInnerHTML={{ __html: lessonInfo?.discription }}
        ></p>

        <div className="pagination_section_page">
          <div className="d-flex space_between align-items-center">
            <p>
              <Link onClick={prev} to="" className="prev">
                <span dangerouslySetInnerHTML={{ __html: CustomIcons.previous }}></span>
                {" "}
                previous
              </Link>
            </p>
            <p>
              <Link onClick={next} to="" className="next">
                next
                <span dangerouslySetInnerHTML={{ __html: CustomIcons.next }}></span>
              </Link>
            </p>
          </div>
        </div>
        <div className="mt-4 mt-4 d-flex space_between align-items-center flex-column flex-sm-row" id="mydiscussion">
          {total > 0 &&
            <h3>Discussions</h3>
          }
          <p className="light_text">{total} Conversation</p>
        </div>
        <div className="comment_section">
          <div className="default_comment_field">
            <div className="user_avtar">
              <UserAvtar name={getProfileDataResponse.firstName + " " + getProfileDataResponse.lastName} image={getProfileDataResponse?.profileImage} />
              <input
                type="text"
                placeholder="Write a comment or question…"
                name=""
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key == "Enter") {
                    addDiscussion()
                  }
                }}
              />
              <button onClick={addDiscussion}>
                <span dangerouslySetInnerHTML={{ __html: CustomIcons.purple_send }}></span>
              </button>
            </div>
          </div>
          {discussions.map((discussion) => (
            <TrainerDiscussionInfo discussion={discussion} />
          ))}
        </div>
      </div>
    </>
  )
}

export default TrainerTextLesson

import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import { authState } from "../../store/slices/authSlice"
import CousreDeadline from "../models/CousreDeadline"
import WalkThrough from "../models/WalkThrough"

const StudentHome = () => {
  const [studentProfile, setStudentProfile] = useState({})
  const [homeCourses, setHomeCourses] = useState({})
  const [showCourseDeadlineModel, setShowCourseDeadlineModel] = useState(false)
  const [courseId, setCourseId] = useState("")
  const [bgDetail, setBgDetail] = useState([])
  const { loadingCounter } = useSelector(authState)
  const { loading } = useSelector(appState)
  const [opacity, setOpacity] = useState(0)
  const [bgImageUrl, setBgImageUrl] = useState("")
  const [homeStrek, setHomeStrek] = useState([])

  const getStudentProfile = async () => {
    if (loading) {
      return <></>
    }
    const res = await clientService.post("StudentHome/GetStudentProfile")
    setStudentProfile(res)
  }
  const getStudentHomeCourses = async () => {
    if (loading) {
      return <></>
    }
    const res = await clientService.post("StudentHome/GetStudentCourseDetail")
    setHomeCourses(res.data)
  }
  const getStudentBackground = async () => {
    if (loading) {
      return <></>
    }
    const res = await clientService.get("StudentHome/GetStudentBackground")
    setBgDetail(res?.data)
  }
  const getStudentHomeStreak = async () => {
    const res = await clientService.get("StudentHome/GetStudentStreak")
    setHomeStrek(res?.data)
  }

  useEffect(() => {
    getStudentProfile()
    getStudentHomeCourses()
    getStudentBackground()
    getStudentHomeStreak()
  }, [loadingCounter])

  useEffect(() => {
    if (bgDetail && bgDetail?.image) {
      setBgImageUrl(bgDetail?.image)
    }
    if (bgDetail && bgDetail?.opacity) {
      const c = Number(bgDetail?.opacity / 100)
      setOpacity(c)
    }
  }, [bgDetail])

  return (
    <>
      <div
        className="overview-area"
        style={{
          position: "relative",
        }}
      >
        <div
          className="user_background_homepage"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: `url(${bgImageUrl})`,
            opacity: opacity,
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundSize: "100% auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div
          className="user_dashboard home_bg"
          style={{
            zIndex: 2,
            position: "relative",
          }}
        >
          <div className="row pt-3">
            <div className="col-lg-12">
              <div className="full breadcrum_style2">
                <p>
                  <Link to={"/courses"}>
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                    ></span>
                    All Courses{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="left_sidebar_section">
                <div className="aside-tiles-item">
                  <div className="shadow-shape">
                    <svg
                      width="76"
                      height="76"
                      viewBox="0 0 76 76"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_1502_40280)">
                        <circle cx="38" cy="38" r="8" fill="#26CBFF"></circle>
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_1502_40280"
                          x="0"
                          y="0"
                          width="76"
                          height="76"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          ></feFlood>
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          ></feBlend>
                          <feGaussianBlur
                            stdDeviation="15"
                            result="effect1_foregroundBlur_1502_40280"
                          ></feGaussianBlur>
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="d-flex align-items-center justify-content-flex-start">
                    <div className="tile-icon">
                      <svg
                        width="42"
                        height="39"
                        viewBox="0 0 42 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.14343 12.8175C3.14343 9.84794 5.23451 7.44063 7.81399 7.44063H36.7668C39.3463 7.44063 41.4374 9.84794 41.4374 12.8175V32.998C41.4374 35.9675 39.3463 38.3749 36.7668 38.3749H7.81399C5.23451 38.3749 3.14343 35.9675 3.14343 32.998V12.8175Z"
                          fill="#26CBFF"
                          fillOpacity="0.05"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.568 5.48277H36.0238C38.1324 5.48277 39.8417 7.42701 39.8417 9.82534V32.8576C39.8417 35.2559 38.1324 37.2001 36.0238 37.2001H4.56799C2.45937 37.2001 0.75 35.2559 0.75 32.8576V9.82534C0.75 7.427 2.45938 5.48277 4.568 5.48277ZM4.568 9.19771C2.8811 9.19771 1.5136 10.7531 1.5136 12.6718V32.8576C1.5136 34.7762 2.8811 36.3316 4.56799 36.3316H36.0238C37.7106 36.3316 39.0781 34.7762 39.0781 32.8576V12.6718C39.0781 10.7531 37.7107 9.19771 36.0238 9.19771H4.568Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M8.6249 15.6639H12.8199C13.4239 15.6639 13.9136 16.0588 13.9136 16.5458V21.0471C13.9136 21.5342 13.4239 21.9291 12.8199 21.9291H8.6249C8.02089 21.9291 7.53125 21.5342 7.53125 21.0471V16.5458C7.53125 16.0588 8.0209 15.6639 8.6249 15.6639Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M8.6249 25.0614H12.8199C13.4239 25.0614 13.9136 25.4562 13.9136 25.9433V30.4446C13.9136 30.9317 13.4239 31.3265 12.8199 31.3265H8.6249C8.02089 31.3265 7.53125 30.9317 7.53125 30.4446V25.9433C7.53125 25.4562 8.0209 25.0614 8.6249 25.0614Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M18.1981 15.6639H22.3932C22.9972 15.6639 23.4868 16.0588 23.4868 16.5458V21.0471C23.4868 21.5342 22.9972 21.9291 22.3932 21.9291H18.1981C17.5941 21.9291 17.1045 21.5342 17.1045 21.0471V16.5458C17.1045 16.0588 17.5941 15.6639 18.1981 15.6639Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M18.1981 25.0614H22.3932C22.9972 25.0614 23.4868 25.4562 23.4868 25.9433V30.4446C23.4868 30.9317 22.9972 31.3265 22.3932 31.3265H18.1981C17.5941 31.3265 17.1045 30.9317 17.1045 30.4446V25.9433C17.1045 25.4562 17.5941 25.0614 18.1981 25.0614Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M27.7719 15.6639H31.9669C32.5709 15.6639 33.0605 16.0588 33.0605 16.5458V21.0471C33.0605 21.5342 32.5709 21.9291 31.9669 21.9291H27.7719C27.1679 21.9291 26.6782 21.5342 26.6782 21.0471V16.5458C26.6782 16.0588 27.1679 15.6639 27.7719 15.6639Z"
                          fill="#26CBFF"
                        />
                        <path
                          opacity="0.2"
                          d="M27.7719 25.0614H31.9669C32.5709 25.0614 33.0605 25.4562 33.0605 25.9433V30.4446C33.0605 30.9317 32.5709 31.3265 31.9669 31.3265H27.7719C27.1679 31.3265 26.6782 30.9317 26.6782 30.4446V25.9433C26.6782 25.4562 27.1679 25.0614 27.7719 25.0614Z"
                          fill="#26CBFF"
                        />
                        <path
                          d="M9.89355 2.2552C9.89355 1.83961 10.2368 1.50271 10.6601 1.50271H13.2601C13.6835 1.50271 14.0267 1.83961 14.0267 2.2552V10.4072H9.89355V2.2552Z"
                          fill="#191A21"
                        />
                        <path
                          d="M25.4504 2.2552C25.4504 1.83961 25.7936 1.50271 26.217 1.50271H28.817C29.2404 1.50271 29.5836 1.83961 29.5836 2.2552V10.4072H25.4504V2.2552Z"
                          fill="#191A21"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.7931 8.32632C14.7931 9.89103 13.5247 11.1595 11.96 11.1595C10.3953 11.1595 9.12683 9.89103 9.12683 8.32632V2.25499C9.12683 1.42381 9.81324 0.75 10.66 0.75H13.26C14.1067 0.75 14.7931 1.42381 14.7931 2.25499V8.32632ZM10.66 1.50249C10.2366 1.50249 9.8934 1.8394 9.8934 2.25499V8.40698C9.8934 9.51155 10.7888 10.407 11.8934 10.407H12.0266C13.1311 10.407 14.0266 9.51155 14.0266 8.40698V2.25499C14.0266 1.8394 13.6834 1.50249 13.26 1.50249H10.66Z"
                          fill="#26CBFF"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M30.35 8.32632C30.35 9.89103 29.0816 11.1595 27.5169 11.1595C25.9522 11.1595 24.6837 9.89103 24.6837 8.32632V2.25499C24.6837 1.42381 25.3701 0.75 26.2168 0.75H28.8169C29.6636 0.75 30.35 1.42381 30.35 2.25499V8.32632ZM26.2168 1.50249C25.7935 1.50249 25.4503 1.8394 25.4503 2.25499V8.40698C25.4503 9.51155 26.3457 10.407 27.4503 10.407H27.5834C28.688 10.407 29.5834 9.51155 29.5834 8.40698V2.25499C29.5834 1.8394 29.2402 1.50249 28.8169 1.50249H26.2168Z"
                          fill="#26CBFF"
                        />
                      </svg>
                    </div>
                    <div className="tile-caption">
                      <p className="white_color mb-0">
                        <b>
                          That's {homeStrek ? homeStrek : "0"} days straight!
                          <br />
                          {homeStrek === 0
                            ? "Complete a lesson to start your streak."
                            : homeStrek === 1
                              ? "Complete more lessons to continue your streak"
                              : homeStrek === 2
                                ? "Complete more lessons to continue your steak"
                                : "You've built a strong learning habit!"}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>

                {homeCourses?.courseDetail &&
                  homeCourses?.courseDetail?.length > 0 ? (
                  homeCourses?.courseDetail?.map((course, index) => {
                    return (
                      <>
                        <div
                          key={`home_page_${index}`}
                          className="aside-tiles-item mt-4 pick_lectures"
                        >
                          <a
                            className="calendar_icon"
                            data-bs-toggle="modal"
                            role="button"
                            onClick={() => {
                              setShowCourseDeadlineModel(true)
                              setCourseId(course?.courseId)
                            }}
                          >
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M27.3334 17.3333C27.3334 16.9651 27.0349 16.6667 26.6667 16.6667C26.2985 16.6667 26 16.9651 26 17.3333V18H22V17.3333C22 16.9651 21.7016 16.6667 21.3334 16.6667C20.9652 16.6667 20.6667 16.9651 20.6667 17.3333V18H19.3334C18.2288 18 17.3334 18.8954 17.3334 20V22.6667V29.3333C17.3334 30.4379 18.2288 31.3333 19.3334 31.3333H28.6667C29.7713 31.3333 30.6667 30.4379 30.6667 29.3333V22.6667V20C30.6667 18.8954 29.7713 18 28.6667 18H27.3334V17.3333ZM29.3334 22V20C29.3334 19.6318 29.0349 19.3333 28.6667 19.3333H27.3334V20C27.3334 20.3682 27.0349 20.6667 26.6667 20.6667C26.2985 20.6667 26 20.3682 26 20V19.3333H22V20C22 20.3682 21.7016 20.6667 21.3334 20.6667C20.9652 20.6667 20.6667 20.3682 20.6667 20V19.3333H19.3334C18.9652 19.3333 18.6667 19.6318 18.6667 20V22H29.3334ZM18.6667 23.3333H29.3334V29.3333C29.3334 29.7015 29.0349 30 28.6667 30H19.3334C18.9652 30 18.6667 29.7015 18.6667 29.3333V23.3333Z"
                                fill="#E4E5EA"
                              />
                              <path
                                d="M8 1H40V-1H8V1ZM47 8V40H49V8H47ZM40 47H8V49H40V47ZM1 40V8H-1V40H1ZM8 47C4.13401 47 1 43.866 1 40H-1C-1 44.9706 3.02944 49 8 49V47ZM47 40C47 43.866 43.866 47 40 47V49C44.9706 49 49 44.9706 49 40H47ZM40 1C43.866 1 47 4.13401 47 8H49C49 3.02944 44.9706 -1 40 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
                                fill="#818496"
                              />
                            </svg>
                          </a>
                          <h3 className="white_color mt-0">
                            {course?.courseName}
                            <span className="text_small light_text">
                              Objectives
                            </span>
                          </h3>
                          <ul className="green_checkbox_layout ">
                            {course?.courseDetail.length > 0
                              ? course?.courseDetail?.map((guide, i) => {
                                return (
                                  <li
                                    key={`index_${course?.courseId}_${i}`}
                                    className="custom_check"
                                  >
                                    <div
                                      className={
                                        guide?.isCompleted
                                          ? "form-group test_complete_status"
                                          : "form-group"
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        checked={guide?.isCompleted}
                                        readOnly
                                      />
                                      <label className="white_text">
                                        {guide?.lessonName}
                                      </label>
                                      {guide?.isCompleted ? null : (
                                        <Link
                                          to={`/course/learning-guide/${course?.courseId}`}
                                        >
                                          <span className="info_label go_btn">
                                            Go
                                          </span>
                                        </Link>
                                      )}
                                    </div>
                                  </li>
                                )
                              })
                              : null}
                          </ul>
                        </div>
                      </>
                    )
                  })
                ) : !loading ? (
                  <div className="aside-tiles-item mt-4 pick_lectures">
                    <p className="light_text">
                      You have not picked a course yet. Go to the "All Courses"
                      page and choose the appropriate course to see the
                      objectives.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-xl-4 col-lg-2"></div>

            <div className="col-xl-4 col-lg-5">
              <div className="aside-tiles-item pick_lectures">
                <div className="profile_section_right student-profile-home">
                  <div className="text-center">
                    {/* <img className="mt-3 img-fluid" src={studentProfile?.data?.studentImage} alt="#" /> */}
                    {studentProfile?.data?.studentImage ? (
                      <div className="student-profile-box">
                        <img src={studentProfile?.data?.studentImage} />
                      </div>
                    ) : (
                      <div className="student-profile-box">
                        <span className="up">
                          {studentProfile?.data?.studentName[0]}
                          {studentProfile?.data?.studentName?.split(" ")[1][0]}
                        </span>
                      </div>
                    )}
                  </div>
                  <h3 className="mt-3 text-light text-center">
                    {studentProfile?.data?.studentName}
                    <br />
                    <br />
                    <span className="light_color">
                      {studentProfile?.data?.date.substring(0, 4)}
                    </span>
                  </h3>
                  <div className="profile_info_icon mb-3">
                    <ul>
                      {/* <li>
                        <div className="certified_number">
                          <a>
                            {studentProfile?.data?.branchId === 1 ? (
                              <img src="/img/pest_control.png" />
                            ) : studentProfile?.data?.branchId === 2 ? (
                              <img src="/img/security.png" />
                            ) : studentProfile?.data?.branchId === 3 ? (
                              <img src="/img/solar.png" />
                            ) : studentProfile?.data?.branchId === 4 ? (
                              <img src="/img/roofing.png" />
                            ) : "-"}
                          </a>
                        </div>
                        <p className="hover_show">
                          <span>Branch: {studentProfile?.data?.branchName
                            ? studentProfile?.data?.branchName
                            : "-"}{" "}</span>
                        </p>
                      </li> */}
                      <li>
                        <div className="certified_number company_img">
                          <a>
                            {studentProfile?.data?.companyLogo ? (
                              <img src={studentProfile?.data?.companyLogo} />
                            ) : "-"}
                          </a>
                        </div>

                        <p className="hover_show">
                          <span>Company: {studentProfile?.data?.companyName ? studentProfile?.data?.companyName : "-"}</span>
                        </p>
                      </li>
                      <li>
                        <div className="certified_number">
                          <a>
                            {studentProfile?.data?.certificationId
                              ? studentProfile?.data?.certificationId
                              : "-"}
                          </a>{" "}
                        </div>
                        <p className="hover_show">
                          <span>Certifications:{" "}
                            {studentProfile?.data?.certificationName
                              ? studentProfile?.data?.certificationName
                              : "-"}{" "}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {showCourseDeadlineModel && (
              <CousreDeadline
                courseId={courseId}
                onClose={() => {
                  setShowCourseDeadlineModel(false)
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentHome

import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import config from '../config';

const sasToken = config.sasToken
const storageAccountName = config.StorageAccount; 

export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}


const createBlobInContainer = async (containerClient, file, dir) => {
    const fileName = (new Date()).getTime() + "_" + Math.random() + "_" + file.name;
    const blobClient = containerClient.getBlockBlobClient(`${dir}/` + fileName);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    const res =  await blobClient.uploadData(file, options);
    if(res?._response?.status === 201){
      return fileName;
    }
    return ""
}
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file, container, dir) => {
  if (!file) return [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient = blobService.getContainerClient(container);
  // const containerClient = blobService.createContainer(containerName);
  await containerClient.createIfNotExists({
    access: 'container',
  });
  const fileName = await createBlobInContainer(containerClient, file, dir);
  if(fileName){
    // return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`;
    return `${fileName}`;
  }else{
    return ""
  }
  
  // console.log(c);
  // return await getBlobsInContainer(containerClient);
};

export default uploadFileToBlob;
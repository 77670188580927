import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import clientService from "../../../services/service"

const QuizeLessonResult = ({ lessonId, restartQuize, moveNext, lessonCount, currentIndex }) => {
  const [courseInfo, setCourseInfo] = useState({})

  useEffect(() => {
    getCourseDetails()
  }, [lessonId])

  const getCourseDetails = async () => {
    const res = await clientService.get(
      `StudentCourse/GetStudentQuizResult?courseGuideLessonId=${lessonId}`
    )
    setCourseInfo(res?.data)
  }
  const retry = async () => {
    const res = await clientService.get(
      `StudentCourse/RetakeGuideQuizLesson?CourseGuideLessonId=${lessonId}`
    )
    restartQuize()
  }

  if (!courseInfo?.courseGuideId) {
    return null
  }
  return (
    <>
      {courseInfo.quizResult && lessonCount > 1 && currentIndex + 1 != lessonCount ? (
        <div className="right_btn">
          <Link to="" onClick={() => moveNext()} className="main_btn_default blue_color float-end">
            Continue
          </Link>
        </div>
      ) : null}
      <div className="col-lg-12 text-center">
        <img
          src={
            courseInfo.quizResult
              ? "/img/good_job_icon.png"
              : "/img/good_job_icon.png"
          }
          className="mb-3 mt-3"
          alt="#"
        />
        <h3 className="mb-3">
          {courseInfo.quizResult ? "Good job!" : "Whoops!"}
          <br />
          Your score is {courseInfo.score}%
        </h3>
        <p className="light_text">
          {courseInfo.score >= 70
            ? ""
            : "You need 90% to move to the next chapter."}
          {/* You answered {courseInfo?.totalCorrectAnswer} out of{" "}
          {courseInfo?.totalQuestion} questions correctly. */}
        </p>
      </div>
      {!courseInfo.quizResult ? (
        <div className="col-lg-12 d-flex justify-content-center">
          <a
            href="#"
            onClick={retry}
            className="main_btn_default blue_color float-end"
          >
            Retry
          </a>
        </div>
      ) : null}
      <div className="col-lg-12">
        <div className="full">
          <div className="radio_button_section">
            {courseInfo?.answers?.map((answers, index) => {
              return (
                <div
                  className={
                    answers.correctAnswer == 1
                      ? "form-check custom_radio right_ans"
                      : answers.correctAnswer == 2
                        ? "form-check custom_radio false_ans"
                        : "form-check custom_radio"
                  }
                >
                  <h4>
                    {index + 1}. {answers.question}
                  </h4>
                  <p>
                    {answers.correctAnswer == 1 ? (
                      <b className="green-text">Correct: </b>
                    ) : answers.correctAnswer == 2 ? (
                      <b className="red-text">Incorrect: </b>
                    ) : (
                      ""
                    )}
                    {answers.answer.map((ans, index) => {
                      return <p>{ans}</p>
                    })}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default QuizeLessonResult

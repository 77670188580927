import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import Select from "react-select"
import clientService from "../../services/service"

const StudentPickCourse = ({ onClose }) => {
  const alert = useAlert()
  const [courses, setCourses] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  const getCourseList = async () => {
    const res = await clientService.get("StudentHome/GetStudentCourses")
    setCourses(res.data.courses)
    const sOp = []
    if (res.data.selectedCourses && res.data.selectedCourses.length > 0) {
      for (var i = 0; i < res.data.selectedCourses.length; i++) {
        sOp.push(res.data.selectedCourses[i])
      }
      setSelectedOptions(sOp)
    }
  }

  useEffect(() => {
    getCourseList()
  }, [])

  const handleSubmit = async () => {
    const coures = []
    for (var i = 0; i < selectedOptions.length; i++) {
      coures.push(selectedOptions[i].courseId)
    }
    var data = {
      selectedCourses: coures,
    }
    const res = await clientService.post("StudentHome/AddHomePageCourses", data)
    if (res.code == 200 && res.status == true) {
      alert.success(res.message)
      onClose(true)
    }
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap"
        aria-labelledby="celender"
        size="lg"
        id="celender"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            Select Courses to Display
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color mb-3">
            Please select the courses you’d like to display. You can take more
            than two courses at one once. However, you can only display two at a
            time.
          </p>
          <Select
            getOptionLabel={(e) => {
              return e.courseName
            }}
            getOptionValue={(e) => {
              return e.courseId
            }}
            isMulti
            className="custom-select sources mt-2"
            placeholder="Select a type"
            options={courses}
            value={selectedOptions}
            onChange={(e) => setSelectedOptions(e)}
            isOptionDisabled={() => selectedOptions.length >= 2}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <Link
              className="main_btn_default"
              data-bs-toggle="modal"
              role="button"
              to=""
              onClick={handleSubmit}
            >
              Confirm
            </Link>
            <button
              onClick={onClose}
              className="btn btn-fclose"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default StudentPickCourse

import { useAlert } from "react-alert"
import clientService from "../../../../services/service"
import { authState } from "../../../../store/slices/authSlice"
import { useSelector } from "react-redux"
import { useState } from "react"
import { useEffect } from "react"
import VideoDiscussionInfo from "./DiscussionInfo"
import UserAvtar from "../../../../common/UserAvtar"

const VideoQuestionComments = ({ studentCourseGuideLessonId, discussionId }) => {
  const { getProfileDataResponse } = useSelector(authState)
  const [pageNo, setPageNo] = useState(1)
  const [comment, setComment] = useState("")
  const [total, setTotal] = useState(0)
  const [discussions, setDiscussions] = useState([])

  const [isForTrainer, setIsForTrainer] = useState(false)
  const alert = useAlert()

  useEffect(() => {
    if (pageNo == 1) {
      getDiscussion()
    } else {
      setPageNo(1)
    }
  }, [studentCourseGuideLessonId])
  useEffect(() => {
    getDiscussion()
  }, [pageNo])

  const getDiscussion = async () => {
    if (studentCourseGuideLessonId) {
      const data = {
        studentCourseGuideLessionId: studentCourseGuideLessonId,
        pageNo: pageNo,
        pageSize: 10,
      }
      const res = await clientService.post(
        "StudentVideoDiscussion/GetAllDiscussion",
        data
      )
      setTotal(res.data.discussionCount)
      if (pageNo == 1) {
        setDiscussions(res.data.discusstionList)
      } else {
        setDiscussions([...discussions, res.data.discusstionList])
      }
    }
  }

  const addDiscussion = async () => {
    if (comment) {
      const data = {
        studentCourseGuideLessionId: studentCourseGuideLessonId,
        isForTrainer: isForTrainer,
        discussion: comment,
      }

      const res = await clientService.post(
        "StudentVideoDiscussion/AddDiscussion",
        data
      )
      if (res.status === true && res.code === 200) {
        alert.success(res.message)
        setComment("")
        setIsForTrainer(false)
        if (pageNo == 1) {
          getDiscussion()
        } else {
          setPageNo(1)
        }
      } else {
        alert.error(res.message)
      }
    }
  }

  return (
    <>
      <div className=" mt-4 mt-4 d-flex space_between" id={discussionId}>
        <h3>Discussions {total}</h3>
        <p className="light_text">{total} Conversation</p>
      </div>
      <div className="comment_section">
        <div className="default_comment_field">
          <div className="user_avtar">
            <UserAvtar name={getProfileDataResponse.firstName + " " + getProfileDataResponse.lastName} image={getProfileDataResponse?.profileImage} />
            <input
              type="text"
              placeholder="Write a comment or question…"
              name=""
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button onClick={addDiscussion}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.4445 10.3342C21.6024 10.5111 21.6984 10.7443 21.6984 11.0001C21.6984 11.4054 21.4573 11.7544 21.1106 11.9114L2.0403 21.0934C1.67087 21.2713 1.23029 21.2056 0.92879 20.9278C0.627284 20.6499 0.52595 20.2161 0.673138 19.8334L4.0706 11L0.673139 2.16662C0.525951 1.78393 0.627284 1.35016 0.92879 1.07229C1.23029 0.794425 1.67087 0.728762 2.0403 0.906635L21.1127 10.0896C21.1825 10.1215 21.2481 10.1611 21.3082 10.2074C21.3191 10.2158 21.3298 10.2244 21.3402 10.2332C21.3776 10.2644 21.4124 10.2982 21.4445 10.3342ZM16.3163 12.0001H5.82881L3.44529 18.1972L16.3163 12.0001ZM16.3164 10.0001L3.44529 3.80287L5.82883 10.0001H16.3164Z"
                  fill="#9E26FF"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="questions_trainer form-check custom_radio pick_lectures">
          <div className="form-group">
            <input
              type="checkbox"
              id="check"
              disabled
              checked={isForTrainer}
              onChange={(e) => {
                setIsForTrainer(e.target.checked)
              }}
            />
            <label htmlFor="check" onClick={() => {
              setIsForTrainer(!isForTrainer)
            }}>Question for Trainer</label>
          </div>
        </div>
        {discussions.map((discussion) => (
          <VideoDiscussionInfo
            key={discussion.discussionId}
            discussion={discussion}
          />
        ))}
      </div>
    </>
  )
}

export default VideoQuestionComments

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import Select from "react-select"
import CustomIcons from "../../../assets/CustomIcons"
import UserAvtar from "../../../common/UserAvtar"
import VideoPlayer from "../../../common/VideoPlayer"
import clientService from "../../../services/service"
import { appState } from "../../../store/slices/appSlice"

const TrainerReviewed = () => {
  const { courseId } = useParams()
  const [learningWeeks, setLearningWeeks] = useState([])
  const { loading } = useSelector(appState)

  const [weekId, setWeekId] = useState({
    text: "All weeks",
    value: "",
  })
  const [videoResp, setVideoResp] = useState({})

  useEffect(() => {
    getLearningWeeksOption()
    getVideos()
  }, [courseId, weekId])

  const getLearningWeeksOption = async () => {
    const res = await clientService.get(
      `TrainerCourse/GetLearningWeeks?CourseId=${courseId}`
    )
    setLearningWeeks(res.data)
  }
  const getVideos = async () => {
    var data = {
      learningWeekId: weekId ? weekId?.value : "",
      courseId: courseId,
      pageNo: 1,
      pageSize: 10,
    }
    const res = await clientService.post(
      "TrainerCourse/GetReviewedVideos",
      data
    )
    setVideoResp(res.data)
  }
  return (
    <>
      <div className="tab-content">
        <div id="to_review" className="tab-pane active">
          <div className="resources_Content mt-4">
            <div className="row">
              <div className="col-12 col-md-8">
                <h3 className="mb-0 mt-3 mb-1">
                  {videoResp ? videoResp?.totalVideo : 0} videos
                </h3>
              </div>
              <div className="col-12 col-md-4">
                <Select
                  name="sources"
                  className="custom-select sources"
                  placeholder="All weeks"
                  getOptionLabel={(e) => {
                    return e.text
                  }}
                  onChange={(e) => {
                    setWeekId(e)
                  }}
                  value={weekId}
                  options={[{ value: "", text: "All weeks" }, ...learningWeeks]}
                />
              </div>
            </div>
            <div className="learning_video_section full_section">
              <div className="row">
                {videoResp?.videos && videoResp.videos.length > 0 ? (
                  videoResp.videos.map((video, index) => {
                    return (
                      <>
                        <div className="col-lg-6">
                          <Link
                            to={`/trainer/course/video-feedback/deatil/${courseId}/${video?.studentCourseGuideLessonId}/reviewed`}
                          >
                            <div className="video_blog mt-4">
                              <div className="video_section">
                                {/* <video
                                  webkit-playsinline="true"
                                  playsinline="true"
                                  className="img-fluid width-100 height-100"
                                  controls
                                  src={video?.videoAnswer}
                                ></video> */}
                                <VideoPlayer
                                  className="img-fluid height-100"
                                  videoId={video?.vimeoLink}
                                  isVideoAvailable={video?.isVideoAvailable}
                                />
                              </div>
                              <div className="training_video_info d-flex">
                                <div className="video_upload_info">
                                  <UserAvtar
                                    name={video?.userName}
                                    image={video.profileImage}
                                  />

                                  <div className="video_info">
                                    <h3>{video?.userName}</h3>
                                    <p>{video?.days} days ago</p>
                                  </div>
                                </div>
                                <div className="video_post_section">
                                  <p className="light_text">
                                    {video?.discussion}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: CustomIcons.message,
                                      }}
                                    ></span>
                                  </p>
                                  <p className="light_text">
                                    {video?.like}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: CustomIcons.like,
                                      }}
                                    ></span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    )
                  })
                ) : !loading ? (
                  <div className="row mt-5">
                    <div className="col-md-12 mt-5">
                      <div className="full text-center">
                        <img
                          className="empty-icon-img"
                          src="/img/transparent_empty_icon.png"
                          alt="#"
                        />
                      </div>
                      <div className="full text-center">
                        <p className="light_text mb-0 mt-5">
                          There is no data available yet.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TrainerReviewed

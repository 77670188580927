import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../../services/service"
import { setErrorMessage } from "../appSlice"

export const reviewerCourseInitialState = {
  reviewerCourses: {},
  scenarios: {},
  reviewTags: {},
  scenarioReview: {},
  reviewerInstruction: {},
  report: {},
  reviewDetail: {},
  submittedReviewDetail: {},
}

export const reviewerCourseSlice = createSlice({
  name: "reviewerCourse",
  initialState: reviewerCourseInitialState,
  reducers: {
    setReviewerCourses: (state, action) => {
      state.reviewerCourses = action.payload
    },
    setSubmittedScenarios: (state, action) => {
      state.scenarios = action.payload
    },
    setSubmittedReport: (state, action) => {
      state.report = action.payload
    },
    setReviewTags: (state, action) => {
      state.reviewTags = action.payload
    },
    setScenarioReviewResponse: (state, action) => {
      state.scenarioReview = action.payload
    },
    setReviewerInstructionResponse: (state, action) => {
      state.reviewerInstruction = action.payload
    },
    setReviewDetailResponse: (state, action) => {
      state.reviewDetail = action.payload
    },
    setSubmittedReviewDetailResponse: (state, action) => {
      state.submittedReviewDetail = action.payload
    },
  },
})

export const getReviewerCourses = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ReviewerCourse/GetAllReviewerCourses",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerCourses(response.data))
  }
}

export const getSubmittedScenarios = (data, setErrors) => async (dispatch) => {
  dispatch(setSubmittedScenarios({data : {}}))
  const response = await clientService.get(
    `ReviewerRandomizer/GetSubmittedScenariosList?courseId=${data.courseId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setSubmittedScenarios(response.data))
  }
}

export const getSubmittedReport = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `ReviewerRandomizer/GetScenarioFeedback?submittedId=${data.submittedId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setSubmittedReport(response.data))
  }
}

export const getReviewTags = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    "ReviewerRandomizer/GetReviewTags",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewTags(response))
  }
}

export const addScenarioReview = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ReviewerCourse/AddScenarioReview",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setScenarioReviewResponse(response))
  }
}

export const getReviewerInstruction = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `ReviewerRandomizer/GetReviewerInstruction?scenarioId=${data.scenarioId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setReviewerInstructionResponse(response))
  }
}

export const getReviewScenarioDetail =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      `ReviewerRandomizer/GetScenarioDetail?SubmittedId=${data.SubmittedId}`,
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setReviewDetailResponse(response))
    }
  }

export const getSubmittedReviewScenarioDetail =
  (data, setErrors) => async (dispatch) => {
    const response = await clientService.get(
      `ReviewerRandomizer/GetSubmittedScenarioDetail?SubmittedId=${data.SubmittedId}`,
      data,
      setErrors
    )
    if (typeof response === "string") {
      dispatch(setErrorMessage(response))
    } else {
      dispatch(setSubmittedReviewDetailResponse(response))
    }
  }

export const {
  setReviewerCourses,
  setSubmittedReport,
  setSubmittedScenarios,
  setReviewTags,
  setReviewDetailResponse,
  setSubmittedReviewDetailResponse,
  setScenarioReviewResponse,
  setReviewerInstructionResponse,
} = reviewerCourseSlice.actions

export const reviewerCourseState = (state) => {
  return state.reviewerCourse
}

export default reviewerCourseSlice.reducer

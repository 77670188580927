import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import CustomIcons from "../../../assets/CustomIcons"
import UserAvtar from "../../../common/UserAvtar"
import VideoPlayer from "../../../common/VideoPlayer"
import clientService from "../../../services/service"
import { setLoader } from "../../../store/slices/appSlice"
import VideoQuestionComments from "./Video/QuestionComments"

const OtherStudentVideo = ({ lessonId }) => {
  const dispatch = useDispatch()
  const [videoInfo, setVideoInfo] = useState({})
  const [otherVideoInfo, setOtherVideoInfo] = useState({})
  const [showVideoUpload, setShowVideoUpload] = useState(false)

  useEffect(() => {
    getOtherStudentVideos()
  }, [lessonId])

  const getOtherStudentVideo = async (data) => {
    dispatch(setLoader(true))
    const res = await clientService.get(
      `StudentCourse/GetStudentLessionVideoDetail?studentCourseGuideLessonId=${data}`
    )
    setOtherVideoInfo(res.data)
    dispatch(setLoader(false))
  }

  const getOtherStudentVideos = async () => {
    var data = {
      courseGuideLessonId: lessonId,
      pageNo: 1,
      pageSize: 10,
    }
    dispatch(setLoader(true))
    const res = await clientService.post(
      "StudentCourse/GetStudentLessonVideos",
      data
    )
    setVideoInfo(res.data)
    dispatch(setLoader(false))
  }
  if (showVideoUpload) {
    return (
      <>
        <div id="other_video" className="tab-pane">
          <div className="resources_Content waiting-for-feedback video-upload-feedback">
            <h3
              className="pointer"
              onClick={() => {
                setShowVideoUpload(false)
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
              ></span>{" "}
              Back to All Video
            </h3>
            {/* <video
              webkit-playsinline="true"
              playsinline="true"
              src={otherVideoInfo?.studentVideoLesson?.videoAnswer}
              className="img-fluid width-100"
              controls
            /> */}
            <VideoPlayer
              className="img-fluid height-100"
              videoId={otherVideoInfo?.studentVideoLesson?.vimeoLink}
              isVideoAvailable={
                otherVideoInfo?.studentVideoLesson?.isVideoAvailable
              }
            />
            <div className="current-user">
              <UserAvtar
                name={otherVideoInfo?.studentVideoLesson?.userName}
                image={otherVideoInfo?.studentVideoLesson?.profileImage}
              />
              <div className="user-name">
                {otherVideoInfo?.studentVideoLesson?.userName}
              </div>
            </div>

            <div className="row feedback mt-2">
              <div className="col-auto">
                <UserAvtar
                  name={otherVideoInfo?.trainerName}
                  image={otherVideoInfo?.trainerImage}
                />
              </div>
              <div className="col-auto">
                <h3>{otherVideoInfo.trainerName}</h3>
                <div className="postion">Trainer</div>
              </div>
            </div>
            <div className="review">
              <p>{otherVideoInfo?.comments}</p>
            </div>
            <div className="tag-container">
              {otherVideoInfo.tags?.map((tag) => {
                return (
                  <div
                    className={
                      tag?.color == 0
                        ? "video_green"
                        : tag?.color == 1
                        ? "video_red"
                        : "video_blue"
                    }
                  >
                    {tag.tagName}
                  </div>
                )
              })}
            </div>
            {otherVideoInfo?.comments ? null  : <p className="waiting-for-feedback">
                            <span className="lorange-badge">Video Under Review</span></p> }
            <VideoQuestionComments
              studentCourseGuideLessonId={
                otherVideoInfo?.studentCourseGuideLessonId
              }
              discussionId={"otherdiscussion"}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div id="other_video" className="tab-pane">
        <div className="resources_Content">
          <h3>{videoInfo?.totalOtherVides} videos</h3>
          {videoInfo?.studentVideos && videoInfo.studentVideos?.length > 0
            ? videoInfo?.studentVideos.map((info, index) => {
                return (
                  <>
                    <a
                      onClick={() => {
                        setShowVideoUpload(true)
                        getOtherStudentVideo(info?.studentCourseGuideLessonId)
                      }}
                    >
                      <div
                        className="learning_video_section  mb-2"
                        key={`course_other_student_video${index}`}
                      >
                        <div className="video_blog">
                          <div className="video_section">
                            {/* <video
                              webkit-playsinline="true"
                              playsinline="true"
                              src={info?.videoAnswer}
                              className="img-fluid width-100"
                              controls
                            /> */}
                            <VideoPlayer
                              className="img-fluid height-100"
                              videoId={info?.vimeoLink}
                              isVideoAvailable={info?.isVideoAvailable}
                            />
                          </div>
                          <div className="training_video_info d-flex">
                            <div className="video_upload_info">
                              <UserAvtar
                                name={info?.userName}
                                image={info.profileImage}
                              />

                              <div className="video_info">
                                <h3>{info?.userName}</h3>
                                <p>{info?.days} days ago</p>
                              </div>
                            </div>
                            <div className="video_post_section">
                              <p className="light_text">
                                {info?.discussion}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: CustomIcons.message,
                                  }}
                                ></span>
                              </p>
                              <p className="light_text">
                                {info?.like}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: CustomIcons.like,
                                  }}
                                ></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </>
                )
              })
            : null}
        </div>
      </div>
    </>
  )
}

export default OtherStudentVideo

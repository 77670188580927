import { useEffect } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  authState,
  sendForgotPasswordLink,
  setSendForgotPasswordLinkResponse,
} from "../../store/slices/authSlice"

const RestorePassword = ({ onClose, userInfo }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const { forgotPasswordLink } = useSelector(authState)

  useEffect(() => {
    if (
      forgotPasswordLink.code === 500 ||
      forgotPasswordLink.status === false
    ) {
      alert.error(forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
    } else if (
      forgotPasswordLink.code === 200 ||
      forgotPasswordLink.status === true
    ) {
      onClose(true)
      alert.success(forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
    }
  }, [forgotPasswordLink])

  const handleSubmit = ({ setErrors }) => {
    var data = {
      email: userInfo?.email,
    }
    dispatch(sendForgotPasswordLink(data, setErrors))
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="delete_user"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            Restore {userInfo?.studentName}’s password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color">
            Are you sure you want to restore password for {userInfo?.studentName}? <br />
            The system will automatically send a password reset email to the
            user's email address.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Restore
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RestorePassword

import { Formik } from "formik"
import Header from "../components/Shared/Header"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "react-alert"
import { authState, sendForgotPasswordLink, setSendForgotPasswordLinkResponse } from "../store/slices/authSlice"
import { useNavigate } from "react-router"

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const navigate = useNavigate()
  const { forgotPasswordLink } = useSelector(authState)

  useEffect(() => {
    if (forgotPasswordLink.code === 500 || forgotPasswordLink.status === false) {
      alert.error(forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
    } else if (forgotPasswordLink.code === 200 || forgotPasswordLink.status === true) {

      // alert.success (forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
      navigate("/success")
    }
  }, [forgotPasswordLink])
  const handleFormSubmit = (values, { setErrors }) => {
    var data = {
      email: values.email,
      isFromAdminPortal: false
    }
    dispatch(sendForgotPasswordLink(data, setErrors))
  }
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="forgot-main-wrapper mx-auto">
                <div className="forgot-caption">
                  <h1 className="title">Forgot your Password?</h1>
                </div>
                <div className="forgot-inner-wrapper">
                  <p className="mt-4 large">
                    Enter the email you signed up with and we will
                    <br />
                    send you reset instructions.
                  </p>
                  <div className="forgot-form-area">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validate={(values) => {
                        const errors = {}
                        if (!values.email) {
                          errors.email = "Required field"
                        }
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                          errors.email = 'Invalid email address';
                        }
                        return errors
                      }}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group pb-1 mb-4">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1 required_field"
                              htmlFor="inputEmail"
                            >
                              Email
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="email"
                                className="form-control cform-control"
                                id="inputEmail"
                                name="email"
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div className="error-message">
                                {errors.email && touched.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="form-group checkbox_group mb-4 pb-1">
                            <div className="row">
                              <div className="col-sm-12">
                                <input
                                  type="submit"
                                  className="btn btn-primary btn_submit"
                                  value="Submit"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword

import { useState } from "react"
import { Link } from "react-router-dom"
import {Dropdown} from "react-bootstrap";
import CustomIcons from "../assets/CustomIcons"

const CustomDropDown = ({ currentItem, options, onChange, withIcon, label }) => {
  const [showOptions, setShowOptions] = useState(false)
  const handleClick = (item) => {
    onChange(item)
  }

  return (
    <Dropdown className="wrap-drop">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      {currentItem[label]}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((item) => {
            return (
              <Dropdown.Item onClick={() => handleClick(item)} className={item[label] == currentItem[label] ? "selected" : ""}>
                 {withIcon === true ? 
                  <span className="mr-10" dangerouslySetInnerHTML={{__html : CustomIcons[item.icon]}}></span>
                 : null}
                  {" "} {item[label]}
              </Dropdown.Item>
            )
          })}
      </Dropdown.Menu>
    </Dropdown>
  )
  return (
    <div
      className={showOptions ? "wrap-drop active" : "wrap-drop"}
      id="noble-gases"
      onClick={() => setShowOptions(!showOptions)}
    >
      <span>{currentItem[label]}</span>
      <ul className="drop">
        
      </ul>
    </div>
  )
}
export default CustomDropDown

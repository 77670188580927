import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import Select from "react-select"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import chroma from "chroma-js"

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
      ":active": {
        ...styles[":active"],
        backgroundColor: color.alpha(0.7).css(),
      },
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
  multiValueLabel: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
  multiValueRemove: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
}

const ViewFeedback = ({ onClose, evalutionInfo }) => {
  const { loading } = useSelector(appState)
  const alert = useAlert()
  const [review, setReview] = useState({})
  const [tagResp, setTagResp] = useState({})
  const [tagError, setTagError] = useState("")
  const [selectedTags, setSelectedTags] = useState(null)
  const [resultCheck, setResultCheck] = useState(-1)
  const [checkboxError, setCheckboxError] = useState("")

  useEffect(() => {
    getFeedbackDetail()
    getTags()
  }, [evalutionInfo])

  const getFeedbackDetail = async () => {
    if (loading) {
      return
    }
    const res = await clientService.get(
      `TrainerCourse/GetFinalEvaluationReview?evaluationId=${evalutionInfo?.finalEvaluationId}`
    )
    setReview(res.data)
  }

  const getTags = async () => {
    const res = await clientService.get(`TrainerCourse/GetVideoReviewTags`)
    setTagResp(res.data)
  }

  useEffect(() => {
    if (review) {
      setSelectedTags(review?.otherTags)
    } else {
      setSelectedTags({})
    }
    setResultCheck(review?.result)
  }, [review])

  const handleFormSubmit = async (values) => {
    if (selectedTags.length > 0 && resultCheck != -1) {
      const tags = []

      for (var i = 0; i < selectedTags?.length; i++) {
        tags.push({
          tagId: selectedTags[i]?.tagId,
          tagName: selectedTags[i]?.tagName,
          color: selectedTags[i]?.color,
        })
      }
      var data = {
        ...values,
        otherTags: tags,
        id: review?.id ? review.id : "",
        result: resultCheck,
        isFeedbackSent: values.isFeedbackSent,
        studentName: evalutionInfo?.studentName,
        finalEvaluationId: evalutionInfo?.finalEvaluationId,
      }

      const res = await clientService.post(
        "TrainerCourse/SaveFinalEvaluationReview",
        data
      )
      if (res.code === 200 && res.status === true) {
        alert.success(res.message)
      }
      onClose(true)
    } else {
      setTagError("Required field")
      setCheckboxError("Required field")
    }
  }
  return (
    <>
      <Modal
        aria-labelledby="create_a_course"
        id="create_a_course"
        centered
        show={true}
        className="modal-dark remove-profile sap feedback-popup"
      >
        <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
          <Modal.Title>Feedback for {evalutionInfo?.studentName}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            summary: review?.summary || "",
            goodThings: review?.goodThings || "",
            improve: review?.improve || "",
          }}
          validate={(values) => {
            const errors = {}
            if (!values.summary) {
              errors.summary = "Required field"
            }
            if (!values.goodThings) {
              errors.goodThings = "Required field"
            }
            if (!values.improve) {
              errors.improve = "Required field"
            }
            return errors
          }}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body className="form_section feedback-popup">
                <fieldset>
                  <div className="mb-4">
                    <div className="">
                      <label htmlFor="summary" className="form-label control-label">
                        Summary
                      </label>
                      <textarea
                        type="text"
                        name="summary"
                        placeholder="Type text here"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.summary}
                      ></textarea>
                      <div className="error-message">
                        {errors.summary && touched.summary && errors.summary}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="tags" className="form-label control-label">
                      Tags
                    </label>
                    <Select
                      name="tag"
                      isMulti
                      styles={colourStyles}
                      value={selectedTags}
                      className="form-control cform-control mt-1 feedback-field"
                      placeholder="Add Tag"
                      onChange={(e) => {
                        setSelectedTags(e)
                      }}
                      getOptionLabel={(e) => {
                        return e.tagName
                      }}
                      getOptionValue={(e) => {
                        return e.tagId
                      }}
                      options={tagResp}
                    />
                    <div className="error-message">
                      {selectedTags?.length > 0 ? "" : tagError}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="">
                      <label htmlFor="good" className="form-label control-label">
                        What was good
                      </label>
                      <textarea
                        name="goodThings"
                        value={values.goodThings}
                        placeholder="Type text here"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></textarea>
                      <div className="error-message">
                        {errors.goodThings &&
                          touched.goodThings &&
                          errors.goodThings}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="">
                      <label htmlFor="good" className="form-label control-label">
                        To improve
                      </label>
                      <textarea
                        name="improve"
                        value={values.improve}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Type text here"
                        className="form-control"
                      ></textarea>
                      <div className="error-message">
                        {errors.improve && touched.improve && errors.improve}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="radio_form_style">
                      <div className="radio_button_section">
                        <div className="form-check custom_radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="result"
                            checked={resultCheck == 1}
                            onChange={(e) => {
                              setResultCheck(1)
                            }}
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            {" "}
                            Passed
                          </label>
                        </div>
                      </div>
                      <div className="radio_button_section">
                        <div className="form-check custom_radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="result"
                            id="flexRadioDefault2"
                            checked={resultCheck == 2}
                            onChange={(e) => {
                              setResultCheck(2)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            {" "}
                            Not Passed
                          </label>
                        </div>
                      </div>
                      {resultCheck !== -1 ? checkboxError : ""}
                    </div>
                  </div>{" "}
                </fieldset>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer-left text-left">
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("isFeedbackSent", true)
                      submitForm()
                    }}
                    className="btn btn-submit"
                  >
                    Send feedback
                  </button>
                  {review?.id ? (
                    <button
                      type="button"
                      className="btn btn-fclose"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-fclose"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setFieldValue("isFeedbackSent", false)
                        submitForm()
                      }}
                    >
                      Save as draft
                    </button>
                  )}
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default ViewFeedback

import { useNavigate, useParams } from "react-router-dom"
import Header from "../components/Shared/Header"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  setUserDataOnInit,
} from "../store/slices/authSlice"
import jwt from "jwt-decode"

const LoginAs = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
     if(params.token){
        localStorage.setItem("token", params.token);
        try{
          const data = jwt(params.token);
          dispatch(setUserDataOnInit(data));
          navigate("/dashboard")
        }catch(e){
          navigate("/login")
        }
        
     }else{
      navigate("/login")
     }
      // 
  }, [dispatch, params])

  
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="login-main-wrapper mx-auto">
                <div className="login-caption">
                  <h1 className="title">Loading</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginAs

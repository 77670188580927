import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"
import { getAllCourceGuideLessons, getContentLessonId } from "./courseGuideLessonSlice"

export const quizInitialState = {
    quizResponse : {},
    deleteResponse : {},
    deleteOptionResponse : {},
    questions: {
      quizLessionList : []
    }
}

export const quizSlice = createSlice({
  name: "quiz",
  initialState: quizInitialState,
  reducers: {
    setQuestions:(state, action) => {
      action.payload.quizLessionList.forEach(element => {
        element.questionType = element.questionType + "";
        return element;
      });
      state.questions = action.payload;
    },
    setCreateQuizResponse:(state, action) => {
      state.quizResponse = action.payload;
    },
    setDeleteQuestionResponse:(state, action) => {
      state.deleteResponse = action.payload;
    },
    setDeleteQuestionOptionResponse:(state, action) => {
      state.deleteOptionResponse = action.payload;
    },
  },
  
})

export const SaveQuiz = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("QuizLession/SaveQuiz", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCreateQuizResponse(response))
    if(data.courseGuideId){

      dispatch(getAllCourceGuideLessons({
        courseGuideId: data.courseGuideId,
        page: 1,
        pageSize: 100,
        search: ""
      }))
    }
  }
}
export const GetAllQuestions = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("QuizLession/GetAllQuestions", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setQuestions(response.data))
  }
}
export const deleteQuestion = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("QuizLession/DeleteQuestion", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteQuestionResponse(response.data))
  }
}

export const deleteQuestionOption = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("QuizLession/DeleteQuestionOption", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteQuestionOptionResponse(response.data))
  }
}

export const {
  setQuestions,
  setCreateQuizResponse,
  setDeleteQuestionResponse,
  setDeleteQuestionOptionResponse,
} = quizSlice.actions

export const quizState = (state) => {
  return state.quiz
}

export default quizSlice.reducer;
import * as React from "react"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import UserAvtar from "../../common/UserAvtar"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import Vimeo from "@u-wave/react-vimeo"
import CalenderSort from "../models/CalenderSort"
import { authState } from "../../store/slices/authSlice"
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartValueAxisLabels,
} from "@progress/kendo-react-charts"
import SavedTimeModal from "../models/SavedTimeModal"
import TodaysActivityChart from "./TodadaysActivityChart"

const TrainerDashboard = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(3)
  const [trainerDashboard, setTrainerDashboard] = useState({})
  const [dailyTask, setDailyTask] = useState({})
  const [finalEvaluation, setFinalEvaluation] = useState({})
  const [showCalenderSort, setShowCalenderSort] = useState(false)
  const { loading } = useSelector(appState)
  const [unanswerQuestions, setUnanswerQuestions] = useState({})
  const [studentStatistic, setStudentStatistic] = useState({})
  const [todayActivity, setTodayActivity] = useState({})
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [seriesData, setSeriesData] = useState([])
  const [weekData, setWeekData] = useState([])
  const { getProfileDataResponse } = useSelector(authState)
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date().toISOString()
  )
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date().toISOString()
  )
  const [innerCircleStoke, setInnerCircleStoke] = useState("")
  const [outerCircleStoke, setOuterCircleStoke] = useState("")

  const [innerCircleCount, setInnerCircleCount] = useState("")
  const [outerCircleCount, setOuterCircleCount] = useState("")

  const current = new Date()

  const getTrainerDashboardDetail = async () => {
    current.setHours(23)
    current.setMinutes(59)
    current.setSeconds(59)
    const data = {
      toDayDateTime: current.toISOString(),
      pageNo: pageNumber,
      pageSize: perPage,
    }
    const res = await clientService.post("TrainerDashboard/GetDashboard", data)
    setTrainerDashboard(res?.data)
  }

  const closeCalenderSort = () => {
    getTrainerDashboardDetail()
    getWeeklyFinalEvaluation()
    setShowCalenderSort(false)
  }

  const closeSavedTimeModal = () => {
    getTrainerDashboardDetail()
    setShowSavedModal(false)
  }

  const getDailyReviewTask = async () => {
    const data = {
      pageNo: 1,
      pageSize: 100,
    }
    const res = await clientService.post(
      "TrainerDashboard/GetDailyReviewTasks",
      data
    )
    setDailyTask(res?.data)
  }

  const getWeeklyFinalEvaluation = async () => {
    let fromDate = new Date()
    fromDate.setDate(fromDate.getDate() - 7)
    fromDate.setHours(23)
    fromDate.setMinutes(59)
    fromDate.setSeconds(59)
    const data = {
      // fromDateTime: fromDate.toISOString(),
      // toDateTime: current.toISOString(),

      fromDateTime: localStorage.getItem("startDate") || selectedStartDate,
      toDateTime: localStorage.getItem("endDate") || selectedEndDate,
      pageNo: 1,
      pageSize: 100,
    }
    const res = await clientService.post(
      "TrainerDashboard/GetWeeklyFinalEvaluation",
      data
    )
    if (localStorage.getItem("startDate")) {
      setSelectedStartDate(localStorage.getItem("startDate"))
      setSelectedEndDate(localStorage.getItem("endDate"))
      localStorage.removeItem("startDate")
      localStorage.removeItem("endDate")
    }
    setFinalEvaluation(res?.data)
  }

  const getUnanswerQuestions = async () => {
    const data = {
      search: "",
      pageNo: 1,
      pageSize: 100,
    }

    const res = await clientService.post(
      "TrainerDashboard/GetAllUnansweredQuestions",
      data
    )
    setUnanswerQuestions(res?.data)

    const _data = {
      toDayDateTime: new Date().toISOString(),
    }

    const resp = await clientService.post(
      "TrainerDashboard/StudentStatistic",
      _data
    )
    setStudentStatistic(resp?.data)

    let _seriesData = []
    let _weekData = []

    for (let i = 0; i < resp?.data?.studentStatisticList?.length; i++) {
      const element = resp?.data?.studentStatisticList[i]
      _seriesData.push(element?.studentsCount)
      _weekData.push(`Week ${element?.weekNumber}`)
    }
    setSeriesData(_seriesData)
    setWeekData(_weekData)

    const response = await clientService.get("TrainerDashboard/TodaysActivity")
    let _reviewPendingPercent = 100 - response?.data?.reviewPendingPercent + "px"
    setInnerCircleStoke(_reviewPendingPercent)
    let _reviewedPercent = 100 - response?.data?.reviewedPercent + "px"
    setOuterCircleStoke(_reviewedPercent)
    setInnerCircleCount(response?.data?.reviewedToday)
    setOuterCircleCount(response?.data?.pendingReviewed)
    setTodayActivity(response?.data)
  }

  useEffect(() => {
    getUnanswerQuestions()
  }, [])

  useEffect(() => {
    getTrainerDashboardDetail()
    getDailyReviewTask()
    getWeeklyFinalEvaluation()
  }, [pageNumber, perPage])

  return (
    <>
      {!loading ? (
        <div className="aside-content-wrapper">
          <div className="container-fluid">
            <div className="asied-tiles-listings">
              <div className="row pt-3">
                <div className="col-xl-8 mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="relative">
                        <div className="text-center trainer-box-icon p-2">
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6ZM6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5ZM6.5 3C6.5 2.72386 6.27614 2.5 6 2.5C5.72386 2.5 5.5 2.72386 5.5 3V6C5.5 6.18939 5.607 6.36252 5.77639 6.44721L7.77639 7.44721C8.02338 7.57071 8.32372 7.4706 8.44721 7.22361C8.57071 6.97662 8.4706 6.67628 8.22361 6.55279L6.5 5.69098V3Z"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="aside-tiles-item"
                        onClick={() => setShowSavedModal(true)}
                      >
                        {trainerDashboard?.trainerRequiredMinutes == 0 ? (
                          <div className="w-100 text-center text-light mt-4">
                            <p>
                              To see your progress on the platform set up Saved
                              Time.
                            </p>
                            <button className="btn_purple">SET UP NOW</button>
                          </div>
                        ) : (
                          <div className="w-100 text-center text-light mt-4">
                            <p>You Saved</p>
                            <h4>
                              {Math.floor(trainerDashboard?.trainerSavedMinutes / 60)} H{" "}
                              {Math.floor(trainerDashboard?.trainerSavedMinutes % 60)} Min{" "}
                            </h4>
                            <p>With Combat Sales</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                      <div className="relative">
                        <div
                          className="text-center trainer-box-icon p-2"
                          style={{ backgroundColor: "#9E26FF" }}
                        >
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7ZM1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H13C14.3261 14 15.5979 14.5268 16.5355 15.4645C17.4732 16.4021 18 17.6739 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2043 15.6839 17.4413 15.1213 16.8787C14.5587 16.3161 13.7956 16 13 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2043 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6739 0.526784 16.4021 1.46447 15.4645ZM15.0312 2.88196C15.1682 2.34694 15.713 2.02426 16.248 2.16125C17.3236 2.43663 18.2768 3.06213 18.9576 3.93914C19.6383 4.81615 20.0078 5.89479 20.0078 7.005C20.0078 8.11521 19.6383 9.19385 18.9576 10.0709C18.2768 10.9479 17.3236 11.5734 16.248 11.8488C15.713 11.9857 15.1682 11.6631 15.0312 11.128C14.8943 10.593 15.2169 10.0482 15.752 9.91125C16.3973 9.74603 16.9692 9.37073 17.3777 8.84452C17.7861 8.31831 18.0078 7.67113 18.0078 7.005C18.0078 6.33887 17.7861 5.69169 17.3777 5.16548C16.9692 4.63928 16.3973 4.26398 15.752 4.09875C15.2169 3.96176 14.8943 3.41699 15.0312 2.88196ZM19.0318 14.88C19.1698 14.3453 19.7153 14.0237 20.25 14.1618C21.3227 14.4387 22.273 15.0641 22.9517 15.9397C23.6304 16.8152 23.9992 17.8914 24 18.9993V21C24 21.5523 23.5523 22 23 22C22.4477 22 22 21.5523 22 21V19.0007C21.9994 18.3361 21.7782 17.6902 21.371 17.165C20.9638 16.6396 20.3936 16.2644 19.75 16.0982C19.2153 15.9602 18.8937 15.4148 19.0318 14.88Z"
                              fill="#E4E5EA"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="aside-tiles-item">
                        <div className="w-100 text-center text-light mt-4">
                          <p>Current Students</p>
                          <h4>{trainerDashboard?.totalStudent}</h4>
                          <p>
                            on {trainerDashboard?.totalCourseCount} different
                            courses
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="aside-tiles-item">
                      <div className="w-100 text-center text-light mt-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="my-0">Students Statistic</p>
                          <div className="d-flex">
                            <p className="me-3 my-0">
                              {moment(current).format("MMM YYYY")}
                            </p>
                            <div>
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14 14C14 13.45 13.55 13 13 13C12.45 13 12 13.45 12 14C12 14.55 12.45 15 13 15C13.55 15 14 14.55 14 14ZM9 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15H9C9.55 15 10 14.55 10 14C10 13.45 9.55 13 9 13ZM3 18L15 18C15.551 18 16 17.551 16 17V11L2 11L2 17C2 17.551 2.449 18 3 18ZM15 4H14V5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V4L6 4V5C6 5.55 5.55 6 5 6C4.45 6 4 5.55 4 5V4H3C2.449 4 2 4.449 2 5V9L16 9V5C16 4.449 15.551 4 15 4ZM3 2H4V1C4 0.45 4.45 0 5 0C5.55 0 6 0.45 6 1V2L12 2V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H15C16.654 2 18 3.346 18 5L18 17C18 18.654 16.654 20 15 20L3 20C1.346 20 0 18.654 0 17L0 5C0 3.346 1.346 2 3 2Z"
                                  fill="#E4E5EA"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="text-light">
                          <Chart className="student_statistic">
                            <ChartCategoryAxis
                              majorGridLines={{
                                visible: true,
                              }}
                              labels={{
                                color: "#fff",
                              }}
                            >
                              <ChartCategoryAxisItem
                                color="#fff"
                                labels={{
                                  color: "#fff",
                                }}
                                categories={weekData}
                              />
                            </ChartCategoryAxis>
                            <ChartValueAxis>
                              <ChartValueAxisItem
                                color="#fff"
                                labels={{
                                  color: "#fff",
                                }}
                              />
                            </ChartValueAxis>
                            <ChartSeries>
                              <ChartSeriesItem
                                color={"#25c0f1"}
                                type="area"
                                data={seriesData}
                                markers={{
                                  visible: false,
                                }}
                                line={{
                                  style: "smooth",
                                }}
                              />
                            </ChartSeries>
                          </Chart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="aside-tiles-item">
                      <div className="text-light mt-2">
                        <div className="d-flex justify-content-between">
                          <h4>Student Questions ({unanswerQuestions?.notAnsweredCount})</h4>
                          {unanswerQuestions?.notAnsweredQuestions?.length > 2 && (
                            <Link to={"/trainer/studentquestion"} className="viewall text-white mb-4">VIEW ALL</Link>
                          )}
                        </div>
                        {unanswerQuestions?.notAnsweredQuestions?.length > 0 &&
                          unanswerQuestions?.notAnsweredQuestions?.map(
                            (question, index) => {
                              return (
                                <>
                                  {index < 2 &&
                                    <div className="row mb-3">
                                      <div className="col-lg-9 col-12">
                                        <h6>
                                          {question?.userName},{" "}
                                          {question?.companyName}.{" "}
                                          {question?.lessionName}{" "}
                                        </h6>
                                        <p className="p text-secondary">
                                          {question?.discussion}
                                        </p>
                                      </div>
                                      <div className="col-lg-3 col-12">
                                        <Link
                                          className="btn open-discussion-btn"
                                          to={`course/guide/${question?.courseId}/${question?.guideId}?lessonId=${question?.lessonId}&discussionId=${question?.discussionId}&studentCourseGuideLessionId=${question?.studentCourseGuideLessionId}`}
                                        >
                                          Open Discussion
                                        </Link>
                                      </div>
                                    </div>
                                  }
                                </>
                              )
                            }
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="aside-tiles-item text-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-0">
                          Daily Review Tasks ({dailyTask?.recentVideo?.length})
                        </h4>
                        {/* <div>
                          <img src="svg/shape.svg" className="me-2" />
                          <img src="svg/filter.svg" className="me-3" />
                          <button className="viewall">VIEW ALL</button>
                        </div> */}
                      </div>

                      <div className="d-flex mt-2">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14 14C14 13.45 13.55 13 13 13C12.45 13 12 13.45 12 14C12 14.55 12.45 15 13 15C13.55 15 14 14.55 14 14ZM9 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15H9C9.55 15 10 14.55 10 14C10 13.45 9.55 13 9 13ZM3 18L15 18C15.551 18 16 17.551 16 17V11L2 11L2 17C2 17.551 2.449 18 3 18ZM15 4H14V5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V4L6 4V5C6 5.55 5.55 6 5 6C4.45 6 4 5.55 4 5V4H3C2.449 4 2 4.449 2 5V9L16 9V5C16 4.449 15.551 4 15 4ZM3 2H4V1C4 0.45 4.45 0 5 0C5.55 0 6 0.45 6 1V2L12 2V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H15C16.654 2 18 3.346 18 5L18 17C18 18.654 16.654 20 15 20L3 20C1.346 20 0 18.654 0 17L0 5C0 3.346 1.346 2 3 2Z"
                            fill="#E4E5EA"
                          />
                        </svg>
                        <p className="text-secondary">
                          {moment(current).format("MM.DD.YYYY")}
                        </p>
                      </div>
                      {dailyTask?.recentVideo?.map((daily, index) => {
                        return (
                          <Link
                            className="text-light"
                            to={`/trainer/course/video-feedback/${daily?.courseId}`}
                          >
                            <div className="row mb-3">
                              <div className="col-12 col-xl-5">
                                <div className="d-flex">
                                  {/* <div className="me-3 mt-2">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    className="form-check-input"
                                  />
                                </div> */}
                                  <div>
                                    <div className="d-flex align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                                      <UserAvtar
                                        name={daily?.studentName}
                                        image={daily?.profileImage}
                                      />
                                      <p className="my-0 mb-2">
                                        {daily?.studentName}
                                      </p>
                                    </div>
                                    <p className="text-secondary text-smaller mb-2">
                                      {daily?.courseName}
                                    </p>
                                    <p className="text-secondary text-smaller">
                                      {moment(daily?.createdDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {daily?.hasVideo && (
                                <div className="col-12 col-xl-7">
                                  <div className="home-video">
                                    <Vimeo
                                      className="video"
                                      video={`${daily?.vimeoLink}`}
                                    // autoplay
                                    // width="350" height="180"s
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="col-12">
                    <div className="aside-tiles-item">
                      <div className="text-center text-light my-3">
                        <h2 className="my-0">
                          Hello, {getProfileDataResponse?.firstName}!
                        </h2>
                        {getProfileDataResponse?.profileImage ? (
                          <img
                            className="profile-image mt-4"
                            src={getProfileDataResponse?.profileImage}
                            alt="Profile Image"
                            style={{ height: '80%', width: '80%' }}
                          />
                        ) : (
                          <span className="up pointer mt-2">
                            {getProfileDataResponse?.firstName[0] +
                              getProfileDataResponse?.lastName[0]}
                          </span>
                        )}
                        <div className="mt-4">
                          <b className="text-secondary">
                            {moment(new Date()).format("MM.DD.YYYY")}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3" >
                    <div className="aside-tiles-item" style={{ height: '25%' }}>
                      <div className="w-100 text-center text-light">
                        <h4 className="my-0">Today's activity</h4>
                      </div>

                      <div className="mt-3 mb-3">
                        <TodaysActivityChart
                          outerCircleStoke={todayActivity?.reviewedPercent}
                          outerCircleCount={todayActivity?.reviewedToday}
                          innerCircleStoke={todayActivity?.reviewPendingPercent}
                          innerCircleCount={todayActivity?.pendingReviewed}
                        />
                      </div>
                      {todayActivity?.reviewedToday && todayActivity?.pendingReviewed == 0 ?
                        <div className="todaysactivity">
                          <div className="squarestyle">
                            <div class="squareblue">
                            </div>
                            <span>Video Reviewed</span>
                          </div>
                          <div className="squarestyle">
                            <div class="squarepurple">
                            </div>
                            <span>Video Pending</span>
                          </div>
                        </div>
                        : null}
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="aside-tiles-item">
                      <div className="w-100 text-light">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="my-0">
                            Final Evaluations ({finalEvaluation?.thisWeekFinalEvaluationList?.length})
                          </h4>
                          {/* <button className="viewall">VIEW ALL</button> */}
                        </div>
                        <div
                          className="d-flex mt-2"
                          onClick={() => {
                            setShowCalenderSort(true)
                          }}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14 14C14 13.45 13.55 13 13 13C12.45 13 12 13.45 12 14C12 14.55 12.45 15 13 15C13.55 15 14 14.55 14 14ZM9 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15H9C9.55 15 10 14.55 10 14C10 13.45 9.55 13 9 13ZM3 18L15 18C15.551 18 16 17.551 16 17V11L2 11L2 17C2 17.551 2.449 18 3 18ZM15 4H14V5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V4L6 4V5C6 5.55 5.55 6 5 6C4.45 6 4 5.55 4 5V4H3C2.449 4 2 4.449 2 5V9L16 9V5C16 4.449 15.551 4 15 4ZM3 2H4V1C4 0.45 4.45 0 5 0C5.55 0 6 0.45 6 1V2L12 2V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H15C16.654 2 18 3.346 18 5L18 17C18 18.654 16.654 20 15 20L3 20C1.346 20 0 18.654 0 17L0 5C0 3.346 1.346 2 3 2Z"
                              fill="#E4E5EA"
                            />
                          </svg>
                          <p className="text-secondary">
                            {moment(selectedStartDate).format("MM.DD.YYYY")} -{" "}
                            {moment(selectedEndDate).format("MM.DD.YYYY")}
                          </p>
                        </div>
                        {finalEvaluation?.thisWeekFinalEvaluationList?.map(
                          (final, index) => {
                            return (
                              <>
                                <div className="d-flex mt-3">
                                  {index == 0 ? (
                                    <h5>
                                      {moment(final?.scheduleDate).format(
                                        "MMM"
                                      )}
                                    </h5>
                                  ) : (
                                    moment(final?.scheduleDate).format(
                                      "MMM"
                                    ) !==
                                    moment(
                                      finalEvaluation
                                        ?.thisWeekFinalEvaluationList[
                                        index - 1
                                      ]?.scheduleDate
                                    ).format("MMM") && (
                                      <h5>
                                        {moment(final?.scheduleDate).format(
                                          "MMM"
                                        )}
                                      </h5>
                                    )
                                  )}
                                </div>
                                <div className="ml-3">
                                  <div className="mb-3 horizontal-line">
                                    <div className="row gx-2">
                                      <div className="col-2 mt-2">
                                        <div className="evaluationsdate">
                                          <h5>
                                            {moment(final?.scheduleDate).format(
                                              "DD"
                                            )}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="col user_avtar ml-5">
                                        <UserAvtar
                                          name={final?.studentName}
                                          image={final?.profileImage}
                                        />
                                        <div className="mt-2">
                                          <h5>{final?.studentName}</h5>
                                          <p className="text-secondary">
                                            {final?.courseName}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="evaluationstime mt-2">
                                          {moment(final?.scheduleDate).format(
                                            "h:mm A"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showCalenderSort && <CalenderSort onClose={closeCalenderSort} />}
      {showSavedModal && (
        <SavedTimeModal
          onClose={closeSavedTimeModal}
          trainerRequiredMinutes={trainerDashboard?.trainerRequiredMinutes}
        />
      )}
    </>
  )
}

export default TrainerDashboard

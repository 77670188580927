import React, { useEffect, useRef, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import CustomIcons from "../../assets/CustomIcons";
let camera_stream = null;
const VideoRecordModal = ({ onClose }) => {

    const [imageDataURL, setImageDataURL] = useState(null);
    const [currentVideoInput, setCurrentVideoInput] = useState(null);
    const [currentAudioInput, setCurrentAudioInput] = useState(null);
    const [currentAudioOutput, setCurrentAudioOutput] = useState(null);
    const [status, setStatus] = useState("idle");
    const player = useRef(null);
    const [videoInputs, setVideoInputs] = useState([]);
    const [audioInputs, setAudioInputs] = useState([]);
    const [audioOutputs, setAudioOutputs] = useState([]);
    const [showSettings, setShowSettings] = useState(false);



    const getListOfVideoInputs = async (type) => {
        const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
        return enumerateDevices.filter((device) => device.kind === type);
    };



    const InitializeMedia = async () => {

        if (!("mediaDevices" in navigator)) {
            navigator.mediaDevices = {};
        }
        if (!("getUserMedia" in navigator.mediaDevices)) {
            navigator.mediaDevices.getUserMedia = function (constraints) {
                var getUserMedia =
                    navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia Not Implemented"));
                }

                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        }

        const videoInputs = await getListOfVideoInputs("videoinput");
        if (videoInputs.length) {
            setVideoInputs(videoInputs);
            setCurrentVideoInput(videoInputs[0]);
        } else {
            alert("The device does not have a camera");
        }
        const audioInputs = await getListOfVideoInputs("audioinput");
        if (audioInputs.length) {
            setAudioInputs(audioInputs);
            setCurrentAudioInput(audioInputs[0])
        } else {
            alert("The device does not have a mic");
        }
        const audioOutput = await getListOfVideoInputs("audiooutput");

        if (audioOutput.length) {
            setAudioOutputs(audioOutput);
            setCurrentAudioOutput(audioOutput[0])
        } else {
            alert("The device does not have a speeker");
        }


    }

    useEffect(() => {
        showStreamData();
    }, [currentVideoInput, currentAudioInput])

    const showStreamData = async () => {
        if (currentVideoInput && currentAudioInput) {
            await navigator.mediaDevices
                .getUserMedia({
                    video: {
                        deviceId: {
                            exact: currentVideoInput.deviceId,
                        },
                    },
                    audio: {
                        deviceId: {
                            exact: currentAudioInput.deviceId,
                        },
                    }
                })
                .then((stream) => {
                    camera_stream = stream  
                    player.current.srcObject = stream;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    useEffect( () => {
        navigator.getUserMedia({ audio: true, video: true }, function (stream) {
            if (stream.getVideoTracks().length == 0) {
                alert("The device does not have a camera")
            } else if (stream.getAudioTracks().length == 0) {
                alert("The device does not have a mic")
            } else {
                
                InitializeMedia();
            }
        }, function (error) {
            alert("Please give camera and/or microphone permisson")
        });

    }, [])
    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="create_a_guide"
                id="create_a_guide"
                centered
            
                show={true}
                className="modal-dark remove-profile create-guide-popup sap video-audio-popup"
            >
                <Modal.Header onHide={() =>{
                        camera_stream?.getTracks()[0]?.stop();
                        onClose()
                }} closeButton={true} closeVariant="white">
                    <Modal.Title>Configure Video and Audio</Modal.Title>
                    <p>If everything looks correct, press the “Ready” button below to be brought to Recording Page</p>
                </Modal.Header>
                <Modal.Body>
                        <video
                            style={{ width: "100%" }}
                            ref={player}
                            autoPlay
                            muted={true}
                            className="image-fluid video-preview"
                            webkit-playsinline="true" playsinline="true"
                        ></video>
                       
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer-left text-left">
                        <button type="submit"
                            onClick={() => {
                                camera_stream?.getTracks()[0]?.stop();
                                onClose({
                                    audioInput: currentAudioInput,
                                    videoInput: currentVideoInput
                                })
                            }}
                            className="btn btn-submit">
                            Ready
                        </button>
                        <button
                            type="button"
                            className="btn btn-fclose"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                camera_stream?.getTracks()[0]?.stop();
                                onClose()
                            }}>
                            Close
                        </button>

                        <button
                            type="button"
                            className="btn btn-fclose"
                            onClick={() => {
                                setShowSettings(true)
                            }}
                        >
                            <span dangerouslySetInnerHTML={{ __html: CustomIcons.setting_icon }}></span>
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                size="md"
                centered
                show={showSettings}
                className="modal-dark remove-profile create-guide-popup video-setting-popup sap video-popup"
            >
                <Modal.Body className="white_text p-3">
                    <div className="mb-3">
                        Video Input
                        <Dropdown className="wrap-drop">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {currentVideoInput?.label}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {videoInputs.map((item) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setCurrentVideoInput(item)
                                        }}>
                                            {item.label}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="mb-3">
                        Microphone
                        <Dropdown className="wrap-drop">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {currentAudioInput?.label}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {audioInputs.map((item) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setCurrentAudioInput(item)
                                        }}>
                                            {item.label}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="mb-3">
                        Speakers
                        <Dropdown className="wrap-drop">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {currentAudioOutput?.label}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {audioOutputs.map((item) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setCurrentAudioOutput(item)
                                        }}>
                                            {item.label}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="">

                        <button
                            type="button"
                            className="btn btn-submit"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setShowSettings(false)
                            }}
                        >
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default VideoRecordModal;
import React from "react"
import Header from "../components/Shared/Header"

const PageNotFound = () => {
  return (
    <>
    <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="register-main-wrapper mx-auto">
                <div className="register-caption">
                  <h1 className="title">
                    <b>
                      404
                    </b>
                  </h1>
                  <h1 className="title"><b>Page Not Found</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PageNotFound

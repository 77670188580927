import config from "../config"
import { setLoader } from "../store/slices/appSlice"
import store from "./store"
import axios from "axios"


const axiosInterceptors = axios.create({
  baseURL: config.apiUrl,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    'www-authenticate': "Bearer",
    'Access-Control-Allow-Origin' : "*",
    'Content-Type': 'application/json',
    "CompanyId" : localStorage.getItem("companyId")
  },
});

axiosInterceptors.interceptors.request.use((config) => {
  axios.defaults.timeout = 35000;
  const token = "Bearer " + localStorage.getItem('token');
  if (token) {
    config.headers.Authorization =  token;
  } 
  const { dispatch } = store;
  dispatch(setLoader(true))
  return config;
});

axiosInterceptors.interceptors.response.use(
  function onSuccess(response) {
    const { dispatch } = store;
    dispatch(setLoader(false))
    return response.data;
  },
  function onError(error) {
    const { dispatch } = store;
    dispatch(setLoader(false))
    if (!error.response) {
      // return window.location.assign('/something-went-wrong')
    }

    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        return window.location.assign('/login')
        if (error.response.data.message === "Unauthenticated.") {
          // localStorage.removeItem('token')
          //  localStorage.removeItem('expires_at')
          //  
        }
      } else if (error.response.status === 404) {
        // return window.location.assign('/404')
      } else if (error.response.status === 500) {
        // Sentry.captureException(error)
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInterceptors;

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FlashCardItem = ({ week }) => {
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">{week?.guideName}</Tooltip>}
        >
            {({ ref, ...triggerHandler }) => (
                <div
                    variant="light"
                    ref={ref}
                    {...triggerHandler}
                    className={week?.isCompleted ? "active pointer" : "pointer"}
                >
                    {week?.guideName.length < 20 ? week?.guideName : week?.guideName?.substring(0, 20) + "..."}
                </div>
            )}
        </OverlayTrigger>
    )
}

export default FlashCardItem;
import moment from "moment"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useSelector } from "react-redux"
import CustomIcons from "../../assets/CustomIcons"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import CustomPagination from "../../utils/CustomPagination"

const CourseApproval = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState("")
  const [searchTemp, setSearchTemp] = useState("")
  const [perPage, setPerPage] = useState(10)
  const [sortBy, setSortBy] = useState("studentName")
  const [sortOrder, setSortOrder] = useState("ASC")
  const [courseInfo, setCourseInfo] = useState({})
  const { loading } = useSelector(appState)
  const alert = useAlert()

  useEffect(() => {
    getCourseList()
  }, [pageNumber, perPage, sortBy, sortOrder])

  useEffect(() => {
    if (pageNumber == 1) {
      getCourseList()
    } else {
      setPageNumber(1)
    }
  }, [search])

  const updateSortOrder = (sBy) => {
    if (sBy === sortBy) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
    } else {
      setSortBy(sBy)
      setSortOrder("ASC")
    }
  }

  const getOrderClass = (columnName) => {
    if (columnName === sortBy) {
      if (sortOrder === "ASC") {
        return "pointer down"
      } else {
        return "pointer up"
      }
    } else {
      return "pointer up-down"
    }
  }

  const getCourseList = async () => {
    const data = {
      pageNo: pageNumber,
      pageSize: perPage,
      search: search,
      sortingColunm: sortBy,
      sortingOrder: sortOrder,
    }
    const res = await clientService.post(
      "TrainerCourse/GetPendingCourseApprovalsList",
      data
    )
    setCourseInfo(res)
  }

  const approveRejectCourse = async (studentId, e) => {
    if (studentId) {
      const data = {
        studentCourseId: studentId,
        approvalStatus: e,
      }
      const res = await clientService.post(
        "TrainerCourse/PendingApproveCourse",
        data
      )
      if (res.code === 200 && res.status === true) {
        alert.success(res.message)
      } else {
        alert.error(res.message)
      }
      getCourseList()
    }
  }

  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area h-100">
            <div className="row">
              <div className="col-7">
                <div className="aside-title-area">
                  <h2 className="title title-lg pt-2">Course Approval</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="searchbar_main form_section mt-1">
                  <div className="search_bar">
                    <fieldset>
                      <input
                        className="form-control cform-control white_text"
                        type="text"
                        value={searchTemp}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setSearch("")
                          }
                          setSearchTemp(e.target.value)
                        }}
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            setSearch(searchTemp)
                          }
                        }}
                        name="search"
                        placeholder="Search by name"
                        
                      />
                      <button>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: CustomIcons.search,
                          }}
                        ></span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-4 course-approval-table">
                <div className="responsive-table">
                  <table className="table default_table_theme">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className={getOrderClass("studentName")}
                          onClick={() => updateSortOrder("studentName")}
                        >
                          Student Name
                        </th>
                        <th
                          scope="col"
                          className={getOrderClass("courseName")}
                          onClick={() => updateSortOrder("courseName")}
                        >
                          Course Name
                        </th>
                        <th scope="col">Requested Date</th>
                        <th> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseInfo?.data &&
                        courseInfo?.data?.pendingCourses?.length > 0 ? (
                        courseInfo.data.pendingCourses.map((user, index) => {
                          return (
                            user?.courseslist?.map((course, i) => {
                              return (
                                <tr key={`courseInfo__approval_list_${index}`}>
                                  {i == 0 &&
                                    <th rowSpan={user?.courseslist?.length}>
                                      {user?.profileImage ? (
                                        <img
                                          alt=""
                                          src={user?.profileImage}
                                          className="img-fluid width-50"
                                        />
                                      ) : (
                                        <span className="up">
                                          {user?.studentName?.[0]}
                                        </span>
                                      )}{" "}
                                      <b>{user?.studentName}</b>
                                    </th>
                                  }
                                  <td>{course?.courseName}</td>
                                  <td>
                                    {" "}
                                    {moment(course?.enrollmentDate).format(
                                      "MM/DD/yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="green_button ml-1"
                                      onClick={() => {
                                        approveRejectCourse(
                                          course?.studentCourseId,
                                          1
                                        )
                                      }}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="red_button"
                                      onClick={() => {
                                        approveRejectCourse(
                                          course?.studentCourseId,
                                          2
                                        )
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </td>
                                </tr>
                              )
                            })
                          )
                        })
                      ) : !loading ? (
                        <tr>
                          <td className="light_color" colSpan={9}>
                            There are no courses to approve.
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
              {courseInfo?.data?.total === 0 ? null : (
                <CustomPagination
                  total={courseInfo?.data?.total}
                  limit={perPage}
                  curretnPage={pageNumber}
                  setPage={setPageNumber}
                  setLimit={setPerPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourseApproval

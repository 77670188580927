import { useState } from "react"
import { Link } from "react-router-dom"

const TestYourSelfResult = ({ questions, courseId, correctCount }) => {
  return (
    <>
      <div className="aside-content-wrapper white_body random_test">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/course/test-yourself/${courseId}`}>
                        Random test
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Test
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-12 col-lg-4 text-left">
              <Link
                to={`/course/test-yourself/${courseId}`}
                className="main_btn_default blue_color float-end float-sm-right"
              >
                Confirm
              </Link>
            </div>
            <div className="col-lg-12 text-center">
              <img
                src={
                  Number((correctCount * 100) / questions.length) > 80
                    ? "/img/good_job_icon.png"
                    : "/img/not_good_job.png"
                }
                className="mb-3 mt-3"
                alt="#"
              />
              <h3 className="mb-3">
                {Number((correctCount * 100) / questions.length) >= 90
                  ? "Good job!"
                  : "Whoops!"}
                <br />
                Your score is{" "}
                {Number((correctCount * 100) / questions.length).toFixed(2)}%
              </h3>
              <p className="light_text">
                {Number((correctCount * 100) / questions.length) <= 40
                  ? "Study some more and try again."
                  : Number((correctCount * 100) / questions.length) <= 60
                  ? "You’re getting there! Study some more and try again"
                  : Number((correctCount * 100) / questions.length) <= 80
                  ? "So close! Study some more and try again."
                  : ""}
              </p>
            </div>{" "}
            <div className="col-lg-12">
              <div className="full">
                <div className="radio_button_section">
                  {questions?.map((answers, index) => {
                    return (
                      <div
                        className={
                          answers.quizState == 1
                            ? "form-check custom_radio right_ans"
                            : answers.quizState == 2
                            ? "form-check custom_radio false_ans"
                            : "form-check custom_radio"
                        }
                      >
                        <h4>
                          {index + 1}. {answers.question}
                        </h4>
                        <p>
                          {answers.quizState == 1 ? (
                            <b className="green-text">Correct: </b>
                          ) : answers.quizState == 2 ? (
                            <b className="red-text">Incorrect: </b>
                          ) : (
                            ""
                          )}
                          {answers.options?.map((option, index) => {
                            if (
                              answers?.selectedOptions?.indexOf(
                                option.quizLessionOptionId
                              ) >= 0
                            ) {
                              return <p>{option.optionName}</p>
                            }
                            return ""
                          })}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestYourSelfResult

import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
// import DeleteQuestion from "../../models/DeleteQuestion"
// import EvalutionInfo from "../../models/EvalutionInfo"
import InputMask from "react-input-mask"
import CustomIcons from "../../assets/CustomIcons"
import { appState, setLoader } from "../../store/slices/appSlice"
import clientService from "../../services/service"
import {
  authState,
  sendForgotPasswordLink,
  setSendForgotPasswordLinkResponse,
} from "../../store/slices/authSlice"
import EvalutionInfo from "./EvalutionInfo"
import DeleteQuestion from "./DeleteQuestion"

const branchTypes = [
  { value: "1", label: "Pest Control" },
  { value: "2", label: "Security" },
  { value: "3", label: "Solar" },
  { value: "4", label: "Roofing" },
]
const StudentProfileEdit = () => {
  const { studentId } = useParams()
  const { loading } = useSelector(appState)
  const alert = useAlert()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [studentDetail, setStudentDetail] = useState({})
  const [certificationType, setCertificationType] = useState([])
  const [companyType, setCompanyType] = useState([])
  const [showRestorePasswordModel, setShowRestorePasswordModel] =
    useState(false)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [showEvalutionModel, setShowEvalutionModel] = useState(false)
  const [evalutionId, setEvalutionId] = useState("")
  const { forgotPasswordLink } = useSelector(authState)
  const [teamOptions, setTeamOptions] = useState([])

  useEffect(() => {
    getStudentDetail()
    getCertificationType()
    getCompanyType()
  }, [studentId])

  useEffect(() => {
    if (studentDetail?.companyId) {
      getTeamOptions()
    }
  }, [studentDetail])

  const getStudentDetail = async () => {
    if (loading) {
      return <> </>
    }
    const res = await clientService.get(
      `StudentManagement/GetStudentDetails?studentId=${studentId}`
    )
    if (res?.code == 200 && res?.status == true) {
      setStudentDetail(res.data)
    } else {
      navigate("/admin/student")
    }
  }

  const getCertificationType = async () => {
    const res = await clientService.get(
      `StudentManagement/GetCertificationEnum`
    )
    setCertificationType(res.data)
  }

  const getCompanyType = async () => {
    const data = {
      pageNo: 1,
      pageSize: 40000,
      search: "",
      sortingColunm: "",
      sortingOrder: "",
    }
    const res = await clientService.post(`Company/GetCompanyList`, data)
    setCompanyType(res?.data?.companyList)
  }

  const handleFormSubmit = async (values) => {
    const data = {
      ...values,
      id: studentDetail?.id,
      branch: Number(values?.branch?.value)
        ? Number(values?.branch?.value)
        : studentDetail?.branch,
      teamId: values?.teamId?.id ? values?.teamId?.id : studentDetail?.teamId,
      certification: Number(values?.certification?.value)
        ? Number(values?.certification?.value)
        : studentDetail?.certification,
      companyId: studentDetail?.companyId,
    }
    const res = await clientService.post(
      "StudentManagement/UpdateStudentDetail",
      data
    )
    if (res.code == 200 && res.status == true) {
      alert.success(res.message)
    }
    getStudentDetail()
  }

  useEffect(() => {
    if (
      forgotPasswordLink.code === 500 ||
      forgotPasswordLink.status === false
    ) {
      alert.error(forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
    } else if (
      forgotPasswordLink.code === 200 ||
      forgotPasswordLink.status === true
    ) {
      setShowRestorePasswordModel(false)
      getStudentDetail()
      alert.success(forgotPasswordLink.message)
      dispatch(setSendForgotPasswordLinkResponse({}))
    }
  }, [forgotPasswordLink])

  const handleRestore = async (e) => {
    if (e === true) {
      if (studentDetail?.id) {
        var data = {
          email: studentDetail?.email,
          isFromAdminPortal: false,
        }
        dispatch(sendForgotPasswordLink(data))
      }
    } else {
      setShowRestorePasswordModel(false)
    }
  }

  const handleDelete = async (e) => {
    if (e === true) {
      if (studentDetail?.id) {
        dispatch(setLoader(true))
        var res = await clientService.post(
          "StudentManagement/DeleteUserAccount",
          {
            id: studentDetail.id,
          }
        )
        dispatch(setLoader(false))
      }
      setShowDeleteModel(false)
      if (res.code == 200 && res.status == true) {
        alert.success(res.message)
      }
      navigate("/trainer/student")
    } else {
      setShowDeleteModel(false)
    }
  }

  const getTeamOptions = async () => {
    let data = {
      companyId: studentDetail?.companyId,
    }
    const res = await clientService.post(`Team/GetTeamDropdownList?companyId=${data?.companyId}`, {})
    setTeamOptions(res.data)
  }

  return (
    <>
      <div class="aside-content-wrapper scrollbarstyle">
        {!loading ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="backtopage">
                  <Link
                    to=""
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                    ></span>{" "}
                    Back to All Students
                  </Link>
                </h3>
              </div>
            </div>
            {studentDetail ? (
              <div className="row pb-4">
                <div className="col-lg-12 col-xl-10 offset-xl-1">
                  <div className="profile-information-blog profile_information_box">
                    <div className="form_section">
                      <Formik
                        initialValues={{
                          firstName: studentDetail?.firstName,
                          lastName: studentDetail?.lastName,
                          phoneNumber: studentDetail?.phoneNumber,
                          email: studentDetail?.email,
                          sales: studentDetail?.sales,
                          teamId: studentDetail?.teamId
                            ? teamOptions.filter((item) => {
                                return item.id == studentDetail?.teamId
                              })
                            : "",
                          certification: studentDetail?.certification
                            ? certificationType.filter((item) => {
                                return (
                                  item.value == studentDetail?.certification
                                )
                              })
                            : "",
                          // branch: studentDetail?.branch
                          //   ? branchTypes.filter((item) => {
                          //       return item.value == studentDetail?.branch
                          //     })
                          //   : "",
                          companyId: studentDetail?.companyId
                            ? companyType.filter((item) => {
                                return item.id == studentDetail?.companyId
                              })
                            : "",
                        }}
                        enableReinitialize
                        validate={(values) => {
                          const errors = {}
                          if (!values.firstName) {
                            errors.firstName = "Required field"
                          } else if (!/^[aA-zZ\s]+$/i.test(values.firstName)) {
                            errors.firstName = "Please enter valid details"
                          }
                          if (!values.lastName) {
                            errors.lastName = "Required field"
                          } else if (!/^[aA-zZ\s]+$/i.test(values.lastName)) {
                            errors.lastName = "Please enter valid details"
                          }
                          if (values.phoneNumber) {
                            if (
                              !/\([0-9]{3}\)\s[0-9]{3}\s[0-9]{4}$/i.test(
                                values.phoneNumber
                              ) &&
                              !/[0-9]{3}[0-9]{3}[0-9]{4}$/i.test(
                                values.phoneNumber
                              )
                            ) {
                              errors.phoneNumber =
                                "Please enter valid Phone Number"
                            }
                          }
                          if (values.sales && !/[0-9]$/i.test(values.sales)) {
                            errors.sales = "Please enter valid sales number"
                          }

                          return errors
                        }}
                        onSubmit={handleFormSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="top_profile_section">
                              <div className="row">
                                <div className="col-9">
                                  {studentDetail?.profileImage ? (
                                    <img
                                      className="img-fluid width-50"
                                      src={studentDetail?.profileImage}
                                    />
                                  ) : (
                                    <span className="up">
                                      {studentDetail?.firstName?.[0]}
                                      {"" + studentDetail?.lastName?.[0]}
                                    </span>
                                  )}
                                  <h3 className="profile_title">
                                    {studentDetail?.firstName}{" "}
                                    {studentDetail?.lastName}
                                  </h3>
                                </div>
                                <div className="col-3 text-right">
                                  <button
                                    className="main_btn_default"
                                    type="submit"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </div>
                            </div>
                            <h5>General info</h5>

                            <fieldset>
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group pb-1">
                                    <label
                                      className="control-label col-xs-3 mb-1 required_field"
                                      htmlFor="name"
                                    >
                                      First Name
                                    </label>
                                    <div className="col-xs-9">
                                      <input
                                        type="text"
                                        className={
                                          errors.firstName && touched.firstName
                                            ? "form-control cform-control error-field"
                                            : "form-control cform-control"
                                        }
                                        name="firstName"
                                        placeholder="First Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                      />
                                      <div className="error-message">
                                        {errors.firstName &&
                                          touched.firstName &&
                                          errors.firstName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group pb-1">
                                    <label
                                      className="control-label col-xs-3 mb-1 required_field"
                                      htmlFor="name"
                                    >
                                      Last Name
                                    </label>
                                    <div className="col-xs-9">
                                      <input
                                        type="text"
                                        className={
                                          errors.lastName && touched.lastName
                                            ? "form-control cform-control error-field"
                                            : "form-control cform-control"
                                        }
                                        name="lastName"
                                        placeholder="Last Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                      />
                                      <div className="error-message">
                                        {errors.lastName &&
                                          touched.lastName &&
                                          errors.lastName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mb-4 pb-1">
                                <label
                                  className="control-label col-xs-3 mb-1 required_field"
                                  htmlFor="email"
                                >
                                  Email
                                </label>
                                <div className="col-xs-9">
                                  <div className="disable_field">
                                    <input
                                      type="email"
                                      className="form-control cform-control lock_icon"
                                      name="email"
                                      id="email"
                                      placeholder="Email"
                                      disabled
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                    />
                                    <span
                                      className="pointer"
                                      dangerouslySetInnerHTML={{
                                        __html: CustomIcons.lock,
                                      }}
                                    ></span>
                                  </div>
                                  <div className="error"></div>
                                </div>
                              </div>
                              <div className="form-group mb-4 pb-1">
                                <label
                                  className="control-label col-xs-3 mb-1 required_field"
                                  htmlFor="number"
                                >
                                  Phone number
                                </label>
                                <div className="col-xs-9">
                                  <InputMask
                                    mask="(999) 999 9999"
                                    className={
                                      errors.phoneNumber && touched.phoneNumber
                                        ? "form-control cform-control error-field"
                                        : "form-control cform-control flag"
                                    }
                                    value={values.phoneNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  >
                                    {(inputProps) => (
                                      <input
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="(XXX) XXX XXXX"
                                        // maxLength={15}
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                  <div className="error-message">
                                    {errors.phoneNumber &&
                                      touched.phoneNumber &&
                                      errors.phoneNumber}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mt-4 mb-4 pb-1">
                                <label
                                  className="control-label col-xs-3 mb-1"
                                  htmlFor="certification"
                                >
                                  Certification
                                </label>
                                <div className="col-xs-9">
                                  <div className="disable_field">
                                    <Select
                                      className="form-control cform-control form_field_box"
                                      name="certification"
                                      onChange={(e) => {
                                        setFieldValue("certification", e)
                                      }}
                                      onBlur={handleBlur}
                                      value={values?.certification}
                                      options={certificationType}
                                      getOptionLabel={(e) => {
                                        return e.text
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="form-group mb-4 pb-1"
                                key={`teamId`}
                              >
                                <label
                                  className="control-label col-xs-3 mb-1"
                                  htmlFor="team"
                                >
                                  Team
                                </label>
                                <div className="col-xs-9">
                                  <div className="disable_field">
                                    <Select
                                      className="form-control cform-control form_field_box"
                                      name="teamId"
                                      onChange={(e) => {
                                        setFieldValue("teamId", e)
                                      }}
                                      getOptionLabel={(e) => {
                                        return (
                                          <div
                                            className="list-item"
                                            key={`team_option_${e?.teamId}`}
                                          >
                                            {e?.teamName}
                                          </div>
                                        )
                                      }}
                                      value={values?.teamId}
                                      onBlur={handleBlur}
                                      options={teamOptions}
                                    />
                                  </div>
                                  <div className="error"></div>
                                </div>
                              </div>
                              <div className="form-group mb-4 pb-1">
                                <label
                                  className="control-label col-xs-3 mb-1 required_field"
                                  htmlFor="sales"
                                >
                                  Sales data
                                </label>
                                <div className="col-xs-9">
                                  <div className="disable_field">
                                    <span className="doller_sign">
                                      <span
                                        className="pointer"
                                        dangerouslySetInnerHTML={{
                                          __html: CustomIcons.dollar,
                                        }}
                                      ></span>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control cform-control dollor"
                                      name="sales"
                                      value={values.sales}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  <div className="error-message">
                                    {errors.sales &&
                                      touched.sales &&
                                      errors.sales}
                                  </div>{" "}
                                </div>

                                <div
                                  className="form-group mb-4 pb-1"
                                  key="companyId"
                                >
                                  <label
                                    className="control-label col-xs-3 mb-1 mt-3 required_field"
                                    htmlFor="company"
                                  >
                                    Company
                                  </label>
                                  <div className="col-xs-9">
                                    <div className="disable_field">
                                      <Select
                                        className="form-control cform-control form_field_box company-select"
                                        name="companyId"
                                        disabled={true}
                                        onChange={(e) => {
                                          setFieldValue("companyId", e)
                                        }}
                                        isDisabled={true}
                                        onBlur={handleBlur}
                                        value={values?.companyId}
                                        options={companyType}
                                        getOptionLabel={(e) => {
                                          return (
                                            <div
                                              className="list-item"
                                              key={`company_option_${e?.companyId}`}
                                            >
                                              {e.companyLogo ? (
                                                <img src={e.companyLogo} />
                                              ) : (
                                                <span className="up mr-10">
                                                  {e?.companyName[0]}
                                                </span>
                                              )}

                                              {e.companyName}
                                            </div>
                                          )
                                        }}
                                      />
                                    </div>
                                    <div className="error"></div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                      <hr />
                      <h5>Learning info</h5>
                      {studentDetail?.course && studentDetail?.course.length > 0
                        ? studentDetail?.course.map((course, index) => {
                            return (
                              <>
                                <div className="white_text mb-1 mt-4">
                                  {course?.courseName}
                                </div>
                                <div
                                  key={`learning_info${index}`}
                                  className="row"
                                >
                                  <div className="col-12 col-xl-3">
                                    <div className="progress_student mt-1">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: course?.progress + "%",
                                          }}
                                          aria-valuenow="100"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {course?.progress}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-xl-2 start_question">
                                    <span
                                      className="white_color"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          course?.courseState === 1
                                            ? CustomIcons.active
                                            : CustomIcons.frozen,
                                      }}
                                    ></span>
                                    &ensp;
                                    <span className="white_color">
                                      {course?.courseStateName}
                                    </span>
                                  </div>

                                  <div className="col-12 col-xl-3 start_question">
                                    <span className="light_text">
                                      Pre Evaluation:
                                    </span>{" "}
                                    <span className="white_color">
                                      {/* {course?.startQuiz}% */}
                                      {course.isEvaluationEnabled
                                        ? course?.startQuiz
                                          ? course?.startQuiz + "%"
                                          : "-"
                                        : "NA"}
                                    </span>
                                  </div>

                                  <div className="col-12 col-xl-3 start_question">
                                    <span className="light_text">
                                      Post Evaluation:
                                    </span>{" "}
                                    <span className="white_color">
                                      {/* {course?.finishQuiz}% */}
                                      {course.isEvaluationEnabled
                                        ? course?.finishQuiz
                                          ? course?.finishQuiz + "%"
                                          : "-"
                                        : "NA"}
                                    </span>
                                  </div>

                                  <div className="col-12 col-xl-1 start_question">
                                    {course?.isFinalEvaluationEnabled ? (
                                      <>
                                        {course?.isEvaluationCompleted ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                CustomIcons.document_student,
                                            }}
                                            onClick={() => {
                                              setShowEvalutionModel(true)
                                              setEvalutionId(
                                                course?.finalEvaluationId
                                              )
                                            }}
                                          ></span>
                                        ) : (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                CustomIcons.document_student_light,
                                            }}
                                          ></span>
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )
                          })
                        : null}
                      {studentDetail?.allowPwdChange ? (
                        <>
                          <hr />
                          <h5>Password</h5>
                          <div className="buttons profiles">
                            <Link
                              className="default_btn"
                              data-bs-toggle="modal"
                              to=""
                              onClick={() => {
                                setShowRestorePasswordModel(true)
                              }}
                              role="button"
                            >
                              Restore password
                            </Link>
                          </div>{" "}
                        </>
                      ) : null}
                      <hr />
                      <h5>Danger zone</h5>
                      <div className="buttons profiles">
                        <Link
                          className="delete_btn"
                          data-bs-toggle="modal"
                          to=""
                          onClick={() => {
                            setShowDeleteModel(true)
                          }}
                          role="button"
                        >
                          Delete User
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {showRestorePasswordModel && (
        <DeleteQuestion
          onClose={handleRestore}
          onDelete={handleRestore}
          title={`Restore ${studentDetail?.firstName} ${studentDetail?.lastName} password`}
          desciption={`Are you sure you want to restore password for <b>${studentDetail?.firstName} ${studentDetail?.lastName} </b>? The system will automatically send a password reset email to the user's email address.`}
          okBtn={"Restore"}
        />
      )}
      {showDeleteModel && (
        <DeleteQuestion
          onClose={handleDelete}
          onDelete={handleDelete}
          title={`Delete This User`}
          desciption={`Are you sure you want to delete ${studentDetail?.firstName} ${studentDetail?.lastName} account?`}
          okBtn={"Yes, I'm sure"}
        />
      )}
      {showEvalutionModel && (
        <EvalutionInfo
          studentDetail={studentDetail?.firstName}
          evalutionId={evalutionId}
          onClose={() => {
            setShowEvalutionModel(false)
            setEvalutionId("")
          }}
        />
      )}
    </>
  )
}
export default StudentProfileEdit

import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const courceInitialState = {
    newCourcesResponse: {},
    getCourcesResponse: {},
    getCourceByIdResponse: {},
    updatedCourcesResponse: {},
    publishedCourcesResponse: {},
    duplicateCourcesResponse: {},
    deleteCourcesResponse: {},
}

export const courceSlice = createSlice({
  name: "cource",
  initialState: courceInitialState,
  reducers: {
    setCreateCourceResponse: (state, action) => {
      state.newCourcesResponse = action.payload
    },
    setGetCourceResponse: (state, action) => {
      state.getCourcesResponse = action.payload
    },
    setGetCourceByIdResponse: (state, action) => {
      state.getCourceByIdResponse = action.payload
    },
    setUpdateCourceResponse: (state, action) => {
      state.updatedCourcesResponse = action.payload
    },
    setPublishCourceResponse: (state, action) => {
      state.publishedCourcesResponse = action.payload
    },
    setDuplicateCourceResponse: (state, action) => {
      state.duplicateCourcesResponse = action.payload
    },
    setDeleteCourceResponse: (state, action) => {
      state.deleteCourcesResponse = action.payload
    },
  },
  
})

export const createCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/CreateCourse", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setCreateCourceResponse(response))
  }
}

export const getAllCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/GetCourses", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetCourceResponse(response))
  }
}

export const getCourceById = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(`Course/GetCourseById?Id=${data.Id}`, data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setGetCourceByIdResponse(response))
  }
}

export const updateCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/UpdateCourse", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setUpdateCourceResponse(response))
  }
}

export const publishCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/CoursePublished", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPublishCourceResponse(response))
  }
}

export const duplicateCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/Duplicatecoursecreate", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDuplicateCourceResponse(response))
  }
}

export const deleteCource = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("Course/DeleteCourse", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteCourceResponse(response))
  }
}



export const {
    setCreateCourceResponse,
    setGetCourceResponse,
    setGetCourceByIdResponse,
    setUpdateCourceResponse,
    setPublishCourceResponse,
    setDuplicateCourceResponse,
    setDeleteCourceResponse
} = courceSlice.actions

export const courceState = (state) => {
  return state.cource
}

export default courceSlice.reducer

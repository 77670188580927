import React from "react"
import CustomIcons from "../../assets/CustomIcons"

const LearningGuideSchedule = ({ evalutionInfo }) => {
  return (
    <>
      <div className="col-md-12 minus_blank_space mt-3">
        <div className="full text-center mt-3">
          <div className="icon">
            <span
              dangerouslySetInnerHTML={{
                __html: CustomIcons.big_calendar,
              }}
            ></span>
          </div>
        </div>
        <div className="full text-center">
          <h3 className="white_color mt-4">
            <b className="white_color">Your Evaluation has been scheduled.</b>
          </h3>
          <p className="light_color mt-3">
            Remember to check your email for the time and date.
          </p>
        </div>

        
        <div className="evaluation_section visible_all_line">
        <p className="white_color large large-text">
            <b>Steps to Complete your Final Evaluation</b>
          </p>
          <ul className="list_step">
            {evalutionInfo?.stepInfosView.length > 0
              ? evalutionInfo?.stepInfosView.map((info, index) => {
                  return (
                    <>
                      <li key={`course_info${index}`}>
                        <span>{index + 1}</span>
                        <div
                          dangerouslySetInnerHTML={{ __html: info.stepInfo }}
                        ></div>
                      </li>
                    </>
                  )
                })
              : null}
          </ul>
          <p className="feedback_status dark_blue">
            {evalutionInfo?.finishText}
          </p>
        </div>
      </div>
    </>
  )
}

export default LearningGuideSchedule

const NoData = ({ title }) => {
    return (
        <>
            <div className="row">
                <div className="col-12 text-center mt-5">
                    <img className="mt-5" src="/img/unlock_vendor.png" />
                </div>
                <div className="col-12 text-center mt-5">
                    <p className="light_text">{title}</p>
                </div>
            </div>
        </>
    )

}

export default NoData;
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import clientService from "../../../services/service"

const StudentTextResource = () => {
  const [resourceInfo, setResourceInfo] = useState({})
  const { courseId, resourceId } = useParams()
  useEffect(() => {
    if (resourceId) {
      getDetailofResource()
    }
  }, [resourceId])

  const getDetailofResource = async () => {
    const res = await clientService.get(
      `StudentResource/GetTextorVideoByResourceId?resourceId=${resourceId}`
    )
    setResourceInfo(res.data)
  }
  return (
    <>
      <div className="aside-content-wrapper white_body">
        <div className="container-fluid">
          <div className="model_content_fit_screen">
            <div className="modal-content">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/course/resources/${courseId}`}>
                        Resources
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {resourceInfo?.name}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="modal-body">
                <div className="resources_Content">
                  {resourceInfo && resourceInfo?.video ? (
                    <video controls className="img-fluid">
                      <source src={resourceInfo?.video}></source>
                    </video>
                  ) : null}
                  <div
                    className="note"
                    dangerouslySetInnerHTML={{
                      __html: resourceInfo?.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentTextResource

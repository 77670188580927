import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import clientService from "../../services/service";

const Header = ({ handleGetData }) => {

  const [domainInfo, setDomainInfo] = useState([])

  function extractDomain(url) {
    let domain;
    // Find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    // Find & remove port number
    domain = domain.split(':')[0];
    // Find & remove "?"
    domain = domain.split('?')[0];

    // Removing 'www.' if present
    if (domain.startsWith('www.')) {
      domain = domain.substring(4);
    }
    return domain;
  }

  const getDomainInfo = async () => {
    const domain = extractDomain(window.location.href);
    const res = await clientService.get("Auth/GetCompanyDomainInfo?domain=" + domain)
    if (res.code == 200 && res.status) {
      setDomainInfo(res.data)
      document.title = res?.data?.companyName || "Combat Sales";
      let _data = {
        companyTagLine: res?.data?.companyTagLine,
        companyName: res?.data?.companyName || "Combat Sales"
      }
      handleGetData(_data)
    }
  }

  useEffect(() => {
    getDomainInfo()
  }, [])
  return (
    <header className="header-main-inner">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/dashboard">
              {domainInfo?.companyLogo ?
                <div className="company_img p-3">
                  <img
                    src={domainInfo?.companyLogo}
                    // src="/img/logo.png"
                    className="img-fluid"
                    alt="Combat Sales"
                  />
                </div>
                :
                <div className="">
                  <img
                    // src={domainInfo?.companyLogo}
                    src=""
                    className="img-fluid"
                    alt="Combat Sales"
                  />
                </div>
              }
            </Link>
            <div className="navbar-right navbar-right-custom d-flex align-items-center justify-content-flex-end">
              <div className="navbar-links ms-auto">
                {/* <Link to="/register" className="navr-links">
                  Create Account
                </Link>
                <Link to="/login" className="navr-links">
                  Log In
                </Link> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header

import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { authState, getProfile } from "../../store/slices/authSlice"
import { ConvertToWords } from "../../utils/CommonFunctions"
import ChooseDate from "./ChooseDate"
import clientService from "../../services/service"
import { useAlert } from "react-alert"

const NewEnrollCourse = ({ onClose, course, closeModel }) => {
  const { getProfileDataResponse } = useSelector(authState)
  const alert = useAlert()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    onClose(true)
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="delete_user"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Start a New Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="white_color">
            You already have{" "}
            {ConvertToWords(getProfileDataResponse?.enrolledCourses)} courses in
            progress. Are you sure you want to start another one?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              // onClick={handleSubmit}
              onClick={async () => {
                let startDate = new Date()
                const dt = startDate.toISOString().replace(".000", "")
                const postData = {
                  courseId: course?.data?.id,
                  startDate: dt,
                }
                try {
                  const res = await clientService.post(
                    "StudentCourse/EnrollToCourse",
                    postData
                  )
                  if (res.code === 200 && res.status) {
                    alert.success(res.message)
                    closeModel(true)
                    onClose(false)
                  }
                } catch (e) {}
              }}
            >
              Start
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewEnrollCourse

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import UserAvtar from "../../common/UserAvtar"
import VideoPlayer from "../../common/VideoPlayer"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"

const ReviewerHome = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(6)
  const [reviewerDashboard, setReviewerDashboard] = useState({})
  const current = new Date()
  const { loading } = useSelector(appState)

  const getReviewerDashboardDetail = async () => {
    const data = {
      toDayDateTime: current.toISOString(),
      pageNo: pageNumber,
      pageSize: perPage,
    }

    const res = await clientService.post(
      "ReviewerDashboard/GetReviewerDashboardDetails",
      data
    )
    setReviewerDashboard(res?.data)
  }

  useEffect(() => {
    getReviewerDashboardDetail()
  }, [pageNumber, perPage])

  return (
    <>
      {!loading ? (
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">Overview</h2>
                </div>
              </div>
            </div>
            <div className="asied-tiles-listings">
              <div className="row">
                <div className="col-12 col-xl-3 col-sm-6 mb-3">
                  <div className="aside-tiles-item">
                    <div className="shadow-shape">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_f_1502_40280)">
                          <circle cx="38" cy="38" r="8" fill="#26CBFF" />
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_1502_40280"
                            x="0"
                            y="0"
                            width="76"
                            height="76"
                            filterUnits="userSpaceOnUse"
                            ccolorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="15"
                              result="effect1_foregroundBlur_1502_40280"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-start">
                      <div className="tile-icon">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="48"
                            height="48"
                            rx="24"
                            fill="white"
                            fillOpacity="0.1"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.8182 16.8909C15.8182 16.2985 16.2985 15.8182 16.8909 15.8182H18.5455V18.5455H15.8182V16.8909ZM15.8182 20.3636H18.5455V23.0909H15.8182V20.3636ZM14 16.8909V19.4545V24V28.5455V31.1091C14 32.7057 15.2943 34 16.8909 34H19.4545H28.5455H31.1091C32.7057 34 34 32.7057 34 31.1091V28.5455V24V19.4545V16.8909C34 15.2943 32.7057 14 31.1091 14H28.5455H19.4545H16.8909C15.2943 14 14 15.2943 14 16.8909ZM32.1818 18.5455V16.8909C32.1818 16.2985 31.7015 15.8182 31.1091 15.8182H29.4545V18.5455H32.1818ZM29.4545 20.3636H32.1818V23.0909H29.4545V20.3636ZM27.6364 19.4545V15.8182H20.3636V19.4545V23.0909H27.6364V19.4545ZM32.1818 24.9091V27.6364H29.4545V24.9091H32.1818ZM32.1818 29.4545H29.4545V32.1818H31.1091C31.7015 32.1818 32.1818 31.7015 32.1818 31.1091V29.4545ZM27.6364 24.9091V28.5455V32.1818H20.3636V28.5455V24.9091H27.6364ZM15.8182 29.4545V31.1091C15.8182 31.7015 16.2985 32.1818 16.8909 32.1818H18.5455V29.4545H15.8182ZM18.5455 27.6364H15.8182V24.9091H18.5455V27.6364Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="tile-caption">
                        <div className="tile-title">
                          <p>Videos to Review</p>
                        </div>
                        <div className="tile-value">
                          <h4>{reviewerDashboard?.totalVideoToReview}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-3 col-sm-6 mb-3">
                  <div className="aside-tiles-item">
                    <div className="shadow-shape">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_f_1502_40280)">
                          <circle cx="38" cy="38" r="8" fill="#26CBFF" />
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_1502_40280"
                            x="0"
                            y="0"
                            width="76"
                            height="76"
                            filterUnits="userSpaceOnUse"
                            ccolorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="15"
                              result="effect1_foregroundBlur_1502_40280"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                    <div className="aside-tiles-inner d-flex align-items-center justify-content-flex-start">
                      <div className="tile-icon">
                        <svg
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="48"
                            height="48"
                            rx="24"
                            fill="white"
                            fillOpacity="0.1"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21 16C19.3431 16 18 17.3431 18 19C18 20.6569 19.3431 22 21 22C22.6569 22 24 20.6569 24 19C24 17.3431 22.6569 16 21 16ZM16 19C16 16.2386 18.2386 14 21 14C23.7614 14 26 16.2386 26 19C26 21.7614 23.7614 24 21 24C18.2386 24 16 21.7614 16 19ZM13.4645 27.4645C14.4021 26.5268 15.6739 26 17 26H25C26.3261 26 27.5979 26.5268 28.5355 27.4645C29.4732 28.4021 30 29.6739 30 31V33C30 33.5523 29.5523 34 29 34C28.4477 34 28 33.5523 28 33V31C28 30.2043 27.6839 29.4413 27.1213 28.8787C26.5587 28.3161 25.7956 28 25 28H17C16.2044 28 15.4413 28.3161 14.8787 28.8787C14.3161 29.4413 14 30.2043 14 31V33C14 33.5523 13.5523 34 13 34C12.4477 34 12 33.5523 12 33V31C12 29.6739 12.5268 28.4021 13.4645 27.4645ZM27.0312 14.882C27.1682 14.3469 27.713 14.0243 28.248 14.1613C29.3236 14.4366 30.2768 15.0621 30.9576 15.9391C31.6383 16.8162 32.0078 17.8948 32.0078 19.005C32.0078 20.1152 31.6383 21.1939 30.9576 22.0709C30.2768 22.9479 29.3236 23.5734 28.248 23.8488C27.713 23.9857 27.1682 23.6631 27.0312 23.128C26.8943 22.593 27.2169 22.0482 27.752 21.9113C28.3973 21.746 28.9692 21.3707 29.3777 20.8445C29.7861 20.3183 30.0078 19.6711 30.0078 19.005C30.0078 18.3389 29.7861 17.6917 29.3777 17.1655C28.9692 16.6393 28.3973 16.264 27.752 16.0988C27.2169 15.9618 26.8943 15.417 27.0312 14.882ZM31.0318 26.88C31.1698 26.3453 31.7153 26.0237 32.25 26.1618C33.3227 26.4387 34.273 27.0641 34.9517 27.9397C35.6304 28.8152 35.9992 29.8914 36 30.9993V33C36 33.5523 35.5523 34 35 34C34.4477 34 34 33.5523 34 33V31.0007C33.9994 30.3361 33.7782 29.6902 33.371 29.165C32.9638 28.6396 32.3936 28.2644 31.75 28.0982C31.2153 27.9602 30.8937 27.4148 31.0318 26.88Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="tile-caption">
                        <div className="tile-title">
                          <p>My Students</p>
                        </div>
                        <div className="tile-value">
                          <h4>{reviewerDashboard?.totalStudent}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="recent-video-area recent-videos-blog mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">Recent Videos</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {reviewerDashboard?.recentVideoList &&
              reviewerDashboard?.recentVideoList.length > 0 ? (
                reviewerDashboard?.recentVideoList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="col-lg-6 col-xl-4"
                        key={`reviewer_video_${index}`}
                      >
                        <Link
                          to={`/reviewer/randomiser/detail/${item?.courseId}/${item?.id}`}
                        >
                          <div className="video_blog">
                            <div className="video_thumb">
                              {/* <video
                              webkit-playsinline="true"
                              playsinline="true"
                              className="img-fluid width-100 height-100"
                              controls
                              src={item?.video}
                            ></video> */}
                              <VideoPlayer
                                className="img-fluid height-100"
                                videoId={item?.vimeoLink}
                                isVideoAvailable={item?.isVideoAvailable}
                              />
                            </div>
                            <div className="video_detail">
                              <div className="d-flex position-relative">
                                <div className="user_avtar">
                                  <UserAvtar
                                    name={item?.studentName}
                                    image={item?.profileImage}
                                  />
                                  <div>
                                    <h5 className="mt-0">
                                      {item?.studentName}
                                    </h5>
                                    <div className="video-bottom-reviewer">
                                    <p>
                                      {item?.courseName}{" "}
                                    </p>
                                    <span>{item?.days} days ago</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  )
                })
              ) : !loading ? (
                <div className="row">
                  <div className="col-12">
                    <div className="empty-block-area mt-5 pt-5 mb-5">
                      <div className="text-center">
                        <div className="empty-block-icon mb-3">
                          <svg
                            width="128"
                            height="128"
                            viewBox="0 0 128 128"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M97.7866 64.7762C97.7866 83.4358 82.66 98.5624 64.0004 98.5624C45.3408 98.5624 30.2142 83.4358 30.2142 64.7762C30.2142 46.1166 45.3408 30.9901 64.0004 30.9901C82.66 30.9901 97.7866 46.1166 97.7866 64.7762ZM64.0004 96.5624C81.5555 96.5624 95.7866 82.3313 95.7866 64.7762C95.7866 47.2212 81.5555 32.9901 64.0004 32.9901C46.4454 32.9901 32.2142 47.2212 32.2142 64.7762C32.2142 82.3313 46.4454 96.5624 64.0004 96.5624Z"
                              fill="url(#paint0_linear_1602_43853)"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M59.9996 94.5627C77.5546 94.5627 91.7858 80.3315 91.7858 62.7765C91.7858 51.1212 85.5127 40.9311 76.1585 35.3983C87.6809 40.1723 95.7862 51.5277 95.7862 64.7762C95.7862 82.3312 81.5551 96.5624 64.0001 96.5624C58.1003 96.5624 52.576 94.9551 47.8411 92.1544C51.5866 93.7063 55.6931 94.5627 59.9996 94.5627Z"
                              fill="url(#paint1_linear_1602_43853)"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M27.0126 43.028C30.2155 53.378 36.8895 65.7613 46.2239 77.7921C55.5582 89.8228 65.8951 99.3642 75.1248 105.038C79.733 107.871 84.1595 109.798 88.1142 110.535C92.0664 111.27 95.7876 110.851 98.6361 108.641C101.485 106.431 102.815 102.931 103.084 98.9196C103.354 94.9059 102.587 90.1395 100.987 84.9719C97.7846 74.6219 91.1106 62.2386 81.7762 50.2078C72.4418 38.1771 62.105 28.6357 52.8753 22.9618C48.267 20.1289 43.8406 18.2017 39.8858 17.4653C35.9337 16.7294 32.2125 17.1486 29.3639 19.3587C26.5154 21.5688 25.1848 25.0692 24.9158 29.0803C24.6465 33.0939 25.4135 37.8604 27.0126 43.028ZM47.804 76.5661C29.3521 52.7841 21.6451 27.8789 30.59 20.9389C39.5348 13.9988 61.7442 27.6519 80.196 51.4338C98.6479 75.2158 106.355 100.121 97.4101 107.061C88.4653 114.001 66.2559 100.348 47.804 76.5661Z"
                              fill="url(#paint2_linear_1602_43853)"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M105.565 52.1883C100.087 61.7074 90.7608 72.4049 78.9376 82.1406C67.1143 91.8762 54.8261 98.9767 44.4329 102.526C39.2474 104.297 34.3764 105.239 30.1907 105.08C26.0089 104.921 22.1483 103.634 19.6102 100.552C17.0721 97.4696 16.55 93.4338 17.1964 89.2992C17.8434 85.1608 19.702 80.561 22.4351 75.8116C27.9129 66.2925 37.2392 55.5949 49.0624 45.8593C60.8857 36.1237 73.1739 29.0231 83.5671 25.4735C88.7527 23.7024 93.6236 22.761 97.8093 22.92C101.991 23.0789 105.852 24.3656 108.39 27.448C110.928 30.5303 111.45 34.5661 110.804 38.7006C110.157 42.8391 108.298 47.4389 105.565 52.1883ZM77.0306 79.8247C100.267 60.6908 113.271 38.0947 106.074 29.3549C98.8773 20.6152 74.2062 29.0413 50.9694 48.1752C27.7327 67.3091 14.7295 89.9052 21.9261 98.6449C29.1227 107.385 53.7938 98.9586 77.0306 79.8247Z"
                              fill="url(#paint3_linear_1602_43853)"
                            />
                            <path
                              d="M111.601 42.9932C111.601 45.1017 109.892 46.8109 107.783 46.8109C105.675 46.8109 103.965 45.1017 103.965 42.9932C103.965 40.8847 105.675 39.1754 107.783 39.1754C109.892 39.1754 111.601 40.8847 111.601 42.9932Z"
                              fill="#FF6915"
                            />
                            <path
                              d="M55.5698 46.8109C55.5698 48.9194 53.8606 50.6287 51.7521 50.6287C49.6436 50.6287 47.9344 48.9194 47.9344 46.8109C47.9344 44.7025 49.6436 42.9932 51.7521 42.9932C53.8606 42.9932 55.5698 44.7025 55.5698 46.8109Z"
                              fill="#FF6915"
                            />
                            <path
                              d="M54.9523 97.2837C54.9523 100.539 52.313 103.179 49.0572 103.179C45.8015 103.179 43.1622 100.539 43.1622 97.2837C43.1622 94.0279 45.8015 91.3886 49.0572 91.3886C52.313 91.3886 54.9523 94.0279 54.9523 97.2837Z"
                              fill="#FF6915"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1602_43853"
                                x1="64.0011"
                                y1="83.3586"
                                x2="63.9987"
                                y2="17.4756"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#EF37FF" />
                                <stop offset="1" stop-color="#9E26FF" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_1602_43853"
                                x1="71.8141"
                                y1="82.8004"
                                x2="71.8114"
                                y2="23.1654"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#FF6915" />
                                <stop offset="1" stop-color="#9E26FF" />
                              </linearGradient>
                              <linearGradient
                                id="paint2_linear_1602_43853"
                                x1="64.0008"
                                y1="89.7774"
                                x2="63.9968"
                                y2="-1.61528"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#EF37FF" />
                                <stop offset="1" stop-color="#9E26FF" />
                              </linearGradient>
                              <linearGradient
                                id="paint3_linear_1602_43853"
                                x1="64.0009"
                                y1="86.6035"
                                x2="63.9984"
                                y2="6.46373"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#EF37FF" />
                                <stop offset="1" stop-color="#9E26FF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="empty-block-title">
                          <h6 className="title">
                            There is no data available yet.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ReviewerHome

import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import Select from "react-select"
import CustomIcons from "../../assets/CustomIcons"
import StatusOptions from "../../consts/StatusOptions"
import { editCourseStudentStatus, setEditedCourseStudentStatusResponse, studentCourseState } from "../../store/slices/studentCourseSlice"



const EditCourseStudentStatus = ({ onClose, selectedStudent }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const { courseId } = useParams()
  const { editedStudentStatus } = useSelector(studentCourseState)
  const [status, setStatus] = useState({})

  useEffect(() => {
    if (
      editedStudentStatus.code === 500 ||
      editedStudentStatus.status === false
    ) {
      alert.error(editedStudentStatus.message)
      dispatch(setEditedCourseStudentStatusResponse({}))
    } else if (
      editedStudentStatus.code === 200 ||
      editedStudentStatus.status === true
    ) {
      onClose(true)
      alert.success(editedStudentStatus.message)
      dispatch(setEditedCourseStudentStatusResponse({}))
    }
  }, [alert, editedStudentStatus])

  const handleSubmit = () => {
    const students = []
    for (var i = 0; i < selectedStudent.length; i++) {
      students.push({
        studentId: selectedStudent[i]
      })
    }
    if (selectedStudent.length > 0 && status.userId !== "") {
      var data = {
          students:students,
          courseState: status.value,
          courseId: courseId
      }
    }
    dispatch(editCourseStudentStatus(data))
  }
  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap"
        aria-labelledby="edit_status"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">
            Edit status for selected {selectedStudent.length} users
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 form_section">
              <form>
                <fieldset>
                  <div className="form-group">
                    <label className="control-label col-xs-3 mb-1">Status</label>
                      <Select
                      name="status"
                      options={StatusOptions}
                      getOptionLabel={(e) => {
                        return (
                          <span><span dangerouslySetInnerHTML={{__html : CustomIcons[e.symbol]}}></span>  {e.label}</span> 
                        )
                      }}
                      onChange={(e) => {
                        setStatus(e)
                      }}
                      id="sources"
                      className="custom-select sources mt-2"
                      placeholder="Select status"
                      
                      />
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <button
              className="btn btn-submit"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              className="btn btn-fclose"
              onClick={onClose}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditCourseStudentStatus

import { Modal } from "react-bootstrap"
import { Formik } from "formik"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import StatusOptions from "../../consts/StatusOptions"
import {
  editTrainerCourseStudent,
  getCourseTrainerReviwer,
  studentCourseState,
  setEditedCourseStudentResponse
} from "../../store/slices/studentCourseSlice"
import { useParams } from "react-router"
import { useEffect } from "react"
import CustomIcons from "../../assets/CustomIcons"

const EditTrainerStudent = ({ onClose, studentInfo }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const { courseId } = useParams()
  const { editedStudent } = useSelector(studentCourseState)

  useEffect(() => {
    dispatch(getCourseTrainerReviwer({ courseId: courseId }))
  }, [dispatch, courseId])

  useEffect(() => {
    if (editedStudent.code === 500 || editedStudent.status === false) {
      alert.error(editedStudent.message)
      dispatch(setEditedCourseStudentResponse({}))
    } else if (editedStudent.code === 200 || editedStudent.status === true) {
      onClose(true)
      alert.success(editedStudent.message)
      dispatch(setEditedCourseStudentResponse({}))
    }
  },[alert, editedStudent])

  const handleFormSubmit = (values) => {
    var data = {
      studentId: studentInfo.studentId,
      courseId: courseId,
      courseState: Number(values.status.value),
    }
    dispatch(editTrainerCourseStudent(data))
  }

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="create_a_guide"
        id="create_a_guide"
        centered
        show={true}
        className="modal-dark remove-profile create-guide-popup sap"
      >
        <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
          <Modal.Title>Edit {studentInfo.studentName} status</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            status: studentInfo?.studentName
              ? {
                  value: studentInfo?.courseState,
                  label: studentInfo?.courseStateText,
                }
              : StatusOptions[0],
          }}
          onSubmit={handleFormSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body className="form_section">
                <div className="row">
                  <div className="col-sm-12 form_section">
                  <fieldset>
                      <div className="form-group">
                        <label className="control-label col-xs-3 mb-1">
                          Status
                        </label>
                        <Select
                          name="status"
                          options={StatusOptions}
                          getOptionLabel={(e) => {
                            return (
                              <span><span dangerouslySetInnerHTML={{__html : CustomIcons[e.symbol]}}></span>  {e.label}</span> 
                            )
                          }}
                          id="sources"
                          className="custom-select sources"
                          placeholder="Select status"
                          onChange={(e) => {
                            setFieldValue("status", e)
                          }}
                          value={values.status}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer-left text-left">
                  <button type="submit" className="btn btn-submit">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-fclose"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default EditTrainerStudent

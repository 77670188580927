const config = {
    apiUrl : "https://combatsalesapi.alliancetek.net/api/",
    loginAsUrl: "https://combatstudentuat.alliancetek.net/login-as/",
    GoogleClientId : "1069475190849-9c0t4aie2c8hqb7klr2s506eum55tgg7.apps.googleusercontent.com",
    StorageAccount : "combatsalesqa",
    AccessKey : "Yv3bXxxEkBJOBc2NJCHxtfxjyoeLbIjYRCWhnL27yxBT87/TCxV2CZt34i4XVEVSvrNtMToaYD11+AStmJS31w==",
    sasToken : "?sv=2020-08-04&ss=b&srt=co&sp=rwlacitfx&se=2032-05-11T18:14:51Z&st=2022-05-11T10:14:51Z&spr=https&sig=QbLL4w6vb3tQjSUiApi%2F%2FrHtUvpc0yCoe%2BaXAqn8m9I%3D"

 }
 export default config;

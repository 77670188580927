import { useEffect, useState } from "react"
import clientService from "../../../services/service"
import { useParams } from "react-router"
import moment from "moment"
import { appState } from "../../../store/slices/appSlice"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const Answered = ({ searchKeyWord }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [questionList, setQuestionList] = useState([])
  const { courseId } = useParams()
  const [total, setTotal] = useState(0)
  const { loading } = useSelector(appState)

  const getQuestiontList = async () => {
    const data = {
      courseId: courseId,
      search: searchKeyWord,
      type: 0,
      pageNo: pageNumber,
      pageSize: perPage,
    }
    if (loading) {
      return <></>
    }
    const res = await clientService.post("TrainerCourse/GetAllQuestion", data)
    setQuestionList(
      pageNumber == 1
        ? res.data.answeredQuestions
        : [...questionList, ...res.data.answeredQuestions]
    )
    setTotal(res.data.answeredCount)
  }

  useEffect(() => {
    getQuestiontList()
  }, [pageNumber, perPage, searchKeyWord])

  useEffect(() => {
    if (pageNumber == 1) {
      getQuestiontList()
    } else {
      setPageNumber(1)
    }
  }, [searchKeyWord])

  return (
    <>
      <div className="answer-list">
        <div className="row">
          <div className="col-12 mt-4">
            <p className="white_color mb-0">
              <b>Answered ({total})</b>
            </p>
          </div>
        </div>
        {total > 0 ? (
          <div className="row">
            {questionList?.map((item, index) => {
              return (
                <>
                  <Link
                    key={`answered_question${index}`}
                    to={`/trainer/course/guide/${courseId}/${item?.guideId}?lessonId=${item?.lessonId}&discussionId=${item?.discussionId}`}
                  >
                    <div className="col-12 mt-4">
                      <div className="question_blog_full">
                        <div className="d-flex position-relative">
                          <span className="up">{item?.userName[0]}</span>
                          <div>
                            <p className="mt-0 white_color mb-2">
                              <b>{item?.userName}</b>{" "}
                              <span className="light_color small">
                                {moment(item?.createdDate).format(
                                  "M/DD/YYYY h:mm A"
                                )}
                              </span>
                            </p>
                            <p className="mb-0">{item?.discussion}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              )
            })}
            {total > questionList.length ? (
              <button
                className="btn btn-primary"
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                Load More
              </button>
            ) : null}
          </div>
        ) : !loading ? (
          <div className="row">
            <div className="col-12 text-center mt-5">
              <img className="mt-0" src="/img/unlock_vendor.png" />
            </div>
            <div className="col-12 text-center mt-3">
              <p className="light_text">No data available.</p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Answered

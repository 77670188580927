import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const flashcardInitialState = {
    flashcards : {},
    newFlashcard : {},
    duplicateResponse : {},
    publishResponse : {},
    deleteOptionResponse : {},
}

export const flashcardSlice = createSlice({
  name: "flashcard",
  initialState: flashcardInitialState,
  reducers: {
    setFlashcardResponse:(state, action) => {
      state.flashcards = action.payload;
    },
    setNewFlashcardResponse:(state, action) => {
      state.newFlashcard = action.payload;
    },
    setPublishFlashcardResponse:(state, action) => {
      state.publishResponse = action.payload;
    },
    setDuplicateFlashcardResponse:(state, action) => {
      state.duplicateResponse = action.payload;
    },
    setDeleteFlashcardResponse:(state, action) => {
      state.deleteResponse = action.payload;
    },
  },
  
})

export const saveFlashcard = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("CourseGuide/AddFlashcard", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setNewFlashcardResponse(response))
  }
}
export const getFlashcard = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(`CourseGuide/GetFlashCard?CourseGuideId=${data.CourseGuideId}`, data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setFlashcardResponse(response.data))
  }
}
export const duplicateFlashcard = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("CourseGuide/DuplicateFlashcard", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDuplicateFlashcardResponse(response.data))
  }
}
export const publishFlashcard = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("CourseGuide/PublishedUnPublishedCourseGuideFlashcard", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setPublishFlashcardResponse(response.data))
  }
}

export const deleteFlashcard = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post("CourseGuide/DeleteFlashcard", data, setErrors)
  if (typeof response === 'string') {
    dispatch(setErrorMessage(response));
  } else {
    dispatch(setDeleteFlashcardResponse(response.data))
  }
}

export const {
  setNewFlashcardResponse,
  setDuplicateFlashcardResponse,
  setDeleteFlashcardResponse,
  setPublishFlashcardResponse,
  setFlashcardResponse
} = flashcardSlice.actions

export const flashcardState = (state) => {
  return state.flashcard
}

export default flashcardSlice.reducer;
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import CustomIcons from "../../../assets/CustomIcons"
import CustomDropDown from "../../../common/CustomDropDown"
import ResourceFilterType from "../../../consts/ResourceFilterType"
import clientService from "../../../services/service"
import DownloadFile from "../../../utils/DownloadFile"
import StudentTextLessonPreview from "./TextLessonPreview"
import StudentVideoLessonPreview from "./VideoLessonPreview"
import TextLessonPreview from "./TextLessonPreview"

const StudentResourceList = () => {
  const { courseId } = useParams()
  const alert = useAlert()
  const [search, setSearch] = useState("")
  const [resourceInfo, setResourceInfo] = useState({})
  const [selectedFilter, setSelectedFilter] = useState(ResourceFilterType[0])
  const [previewModel, setPreviewModel] = useState(false)
  const [previewVideoModel, setPreviewVideoModel] = useState(false)
  const [resourceId, setResourceId] = useState("")

  const closeModel = () => {
    setPreviewModel(false)
    setResourceId("")
  }
  const closeVideoModel = () => {
    setPreviewVideoModel(false)
    setResourceId("")
  }
  useEffect(() => {
    getAllResources()
  }, [courseId, selectedFilter])

  useEffect(() => {
    if (search === "") {
      getAllResources()
    }
  }, [search])

  const getAllResources = async () => {
    var data = {
      search: search,
      courseId: courseId,
      sourceType: Number(selectedFilter.value),
    }
    const res = await clientService.post(
      "StudentResource/GetAllResourcesByCourseId",
      data
    )
    setResourceInfo(res.data)
  }

  const getSearchForm = () => {
    return (
      <div className="row">
        <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-7">
          <div className="searchbar_main form_section">
            <div className="search_bar">
              <fieldset>
                <input
                  className="form-control cform-control mb-15"
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  onKeyUp={(e) => {
                    if (e.key == "Enter") {
                      getAllResources()
                    }
                  }}
                />
                <button>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: CustomIcons.search,
                    }}
                  ></span>
                </button>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-5 resource-dropdown">
          <CustomDropDown
            options={ResourceFilterType}
            currentItem={selectedFilter}
            onChange={setSelectedFilter}
            label={"label"}
            withIcon={true}
          />
        </div>
      </div>
    )
  }
  if (
    resourceInfo?.collections &&
    resourceInfo?.collections.length == 0 &&
    resourceInfo?.textResources &&
    resourceInfo?.textResources.length == 0 &&
    resourceInfo?.videoResources &&
    resourceInfo?.videoResources.length == 0 &&
    resourceInfo?.downloadFileResources &&
    resourceInfo?.downloadFileResources.length == 0 &&
    resourceInfo?.usefullLinkResources &&
    resourceInfo?.usefullLinkResources.length == 0
  ) {
    return (
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area resources-content mb-5">
            {getSearchForm()}
            <div className="col-md-12 minus_blank_space mt-5">
              <div className="full text-center mt-5">
                <img src="/img/ic_faq_empty.png" alt="" />
              </div>
              <div className="full text-center">
                <p className="light_text mb-0 mt-4">
                  No results for selected filters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area resources-content mb-5">
            {getSearchForm()}
            {resourceInfo?.collections &&
              resourceInfo?.collections.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-2 mb-0">Collections</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.collections && resourceInfo?.collections.length > 0
                ? resourceInfo?.collections.map((collection, index) => {
                  return (
                    <>
                      <div
                        key={`collection_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link
                            to={`/course/collections/${courseId}/${collection?.collectionId}`}
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: CustomIcons.folder,
                                  }}
                                ></span>
                              </div>
                              <div>
                                <p>{collection?.collectionName}</p>
                              </div>
                            </div>
                          </Link>
                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
            </div>

            {resourceInfo?.textResources &&
              resourceInfo?.textResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">Text Resources</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.textResources &&
                resourceInfo?.textResources.length > 0
                ? resourceInfo?.textResources.map((text, index) => {
                  return (
                    <>
                      <div
                        key={`text_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link
                            to=""
                            onClick={() => {
                              setPreviewModel(true)
                              setResourceId(text?.resourseId)
                            }}
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor orange">
                                {text?.image ? (
                                  <img src={text?.image} />
                                ) : (
                                  <span className="up">
                                    {text?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{text?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
              {previewModel && (
                <TextLessonPreview
                  content={resourceId}
                  onClose={closeModel}
                  previewbtn={false}
                />
              )}
            </div>

            {resourceInfo?.videoResources &&
              resourceInfo?.videoResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">
                      Video Resources
                    </h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.videoResources &&
                resourceInfo?.videoResources.length > 0
                ? resourceInfo?.videoResources.map((video, index) => {
                  return (
                    <>
                      <div
                        key={`video_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link
                            to=""
                            onClick={() => {
                              setPreviewVideoModel(true)
                              setResourceId(video?.resourseId)
                            }}
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor orange">
                                {video?.image ? (
                                  <img src={video?.image} />
                                ) : (
                                  <span className="up">
                                    {video?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{video?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}

              {previewVideoModel && (
                <StudentVideoLessonPreview
                  content={resourceId}
                  onClose={closeVideoModel}
                  previewbtn={false}
                />
              )}
            </div>

            {resourceInfo?.downloadFileResources &&
              resourceInfo?.downloadFileResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">
                      Files to Download
                    </h2>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row">
              {resourceInfo?.downloadFileResources &&
                resourceInfo?.downloadFileResources.length > 0
                ? resourceInfo?.downloadFileResources.map((file, index) => {
                  return (
                    <>
                      <div
                        key={`file_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <Link to="">
                            <div className="d-flex position-relative" onClick={() => {
                              DownloadFile(
                                file.attachment,
                                file.attachmentName
                              )
                            }}>
                              <div
                                className="resources_vendor blue"
                              >
                                {file?.image ? (
                                  <img src={file?.image} />
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: CustomIcons.download,
                                    }}
                                  ></span>
                                )}
                              </div>
                              <div>
                                <p>{file?.resourceName}</p>
                              </div>
                            </div>
                          </Link>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
            </div>

            {resourceInfo?.usefullLinkResources &&
              resourceInfo?.usefullLinkResources.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="aside-title-area mt-4 mb-0">
                    <h2 className="title title-lg pt-3 mb-0">Useful Links</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {resourceInfo?.usefullLinkResources &&
                resourceInfo?.usefullLinkResources.length > 0
                ? resourceInfo?.usefullLinkResources.map((link, index) => {
                  return (
                    <>
                      <div
                        key={`link_res_${index}`}
                        className="col-lg-6 col-xl-4"
                      >
                        <div className="resources_blog collection-listbox">
                          <a
                            href={
                              link?.link.indexOf("http") === 0
                                ? link?.link
                                : `http://${link?.link}`
                            }
                            target="_blank"
                          >
                            <div className="d-flex position-relative">
                              <div className="resources_vendor">
                                {link?.image ? (
                                  <img src={link?.image} />
                                ) : (
                                  <span className="up">
                                    {link?.resourceName[0]}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p>{link?.resourceName}</p>
                                <label className="youtube">
                                  {link?.linkType}
                                </label>
                              </div>
                            </div>
                          </a>

                          <Link
                            to=""
                            className="weblink"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigator.clipboard.writeText(
                                window.location.href
                              )
                              alert.success("Link Copied Successfully")
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: CustomIcons.copy_link,
                              }}
                            ></span>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentResourceList

import { useState } from "react"
import Answered from "./Answered"
import NotAnswered from "./NotAnswered"
import CustomIcons from "../../../assets/CustomIcons"

const TrainerQuestion = () => {
  const [search, setSearch] = useState("")
  const [searchKeyWord, setSearchKeyWord] = useState("")

  return (
    <>
      <div className="aside-content-wrapper">
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">Questions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="searchbar_main form_section mt-2 mb-4">
                  <div className="search_bar">
                    <fieldset>
                      <input
                        className="form-control cform-control"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setSearchKeyWord("")
                          }
                          setSearch(e.target.value)
                        }}
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            setSearchKeyWord(search)
                          }
                        }}
                        name="search"
                        placeholder="Search by name"
                      />
                      <button>
                      <span
                        dangerouslySetInnerHTML={{ __html: CustomIcons.search }}
                      ></span>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <NotAnswered searchKeyWord={searchKeyWord} />
            <Answered searchKeyWord={searchKeyWord} />
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainerQuestion

import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import clientService from "../../../services/service"
import { useAlert } from "react-alert"
import { useDispatch, useSelector } from "react-redux"
import { authState, getProfile } from "../../../store/slices/authSlice"
import CustomIcons from "../../../assets/CustomIcons"
import TrainerVideoUploadDiscussionInfo from "../TrainerVideoUploadDiscussionInfo"
import VideoPlayer from "../../../common/VideoPlayer"
import UserAvtar from "../../../common/UserAvtar"

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const TrainerVideoUploadLessonDetail = ({ studentGuideId, setShowDetail }) => {
  const { getProfileDataResponse } = useSelector(authState)
  const [lessonInfo, setLessonInfo] = useState({})
  const [comment, setComment] = useState("")
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [discussions, setDiscussions] = useState([])
  const alert = useAlert()
  const dispatch = useDispatch()
  const [showVideoUpload, setShowVideoUpload] = useState(false)

  const query = useQuery()
  const guideLessonId = query.get("lessonId")
  const discussionId = query.get("discussionId")
  const studentCourseGuideLessionId = query.get("studentCourseGuideLessionId")

  useEffect(() => {
    getGuideLessons()
    dispatch(getProfile())
  }, [])

  useEffect(() => {
    if (pageNo == 1) {
      getDiscussion()
    } else {
      setPageNo(1)
    }
  }, [studentGuideId])

  const getGuideLessons = async () => {
    const res = await clientService.get(
      `TrainerCourse/GetVideoDetail?StudentCourseGuideLessonId=${studentGuideId}`
    )
    setLessonInfo(res.data)
  }

  const getDiscussion = async () => {
    if (studentCourseGuideLessionId == studentGuideId && discussionId) {
      const data = {
        studentCourseGuideLessionId: studentGuideId,
        discussionId: discussionId,
        pageNo: pageNo,
        pageSize: 10,
      }
      const res = await clientService.post(
        "TrainerVideoDiscussion/GetAllDiscussion",
        data
      )
      setTotal(res.data.discussionCount)
      setDiscussions(res.data.discusstionList)
    } else if (studentGuideId) {
      const data = {
        studentCourseGuideLessionId: studentGuideId,
        pageNo: pageNo,
        pageSize: 10,
      }
      const res = await clientService.post(
        "TrainerVideoDiscussion/GetAllDiscussion",
        data
      )
      setTotal(res.data.discussionCount)
      if (pageNo == 1) {
        setDiscussions(res.data.discusstionList)
      } else {
        setDiscussions([...discussions, res.data.discusstionList])
      }
    }
  }

  const addDiscussion = async () => {
    if (comment) {
      const data = {
        studentCourseGuideLessionId: studentGuideId,
        discussion: comment,
      }
      const res = await clientService.post(
        "TrainerVideoDiscussion/AddDiscussion",
        data
      )
      if (res.status) {
        alert.success(res.message)
        setComment("")
        if (pageNo == 1) {
          getDiscussion()
        } else {
          setPageNo(1)
        }
      } else {
        alert.error(res.message)
      }
    }
  }
  return (
    <>
      <div className="resources_Content">
        <h3
          className="pointer"
          onClick={() => {
            setShowDetail(false)
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: CustomIcons.back }}></span>{" "}
          Back to All Video
        </h3>

        <div className="">
          <VideoPlayer
            className="img-fluid height-100"
            videoId={lessonInfo?.vimeoLink}
            isVideoAvailable={lessonInfo?.isVideoAvailable}
          />
        </div>
        <p
          className="note"
          dangerouslySetInnerHTML={{ __html: lessonInfo.discription }}
        ></p>
        <div className="row feedback">
          <div className="review">
            <span>Comment : </span>
            <span>{lessonInfo?.comments}</span>
          </div>
        </div>
        {lessonInfo?.tags && lessonInfo?.tags.length > 0 ? (
          <div className="tag-container mt-3">
            <span className="me-2">Tags :</span>
            {lessonInfo?.tags?.map((tag) => {
              return (
                <div
                  className={
                    tag?.color == 0
                      ? "video_green"
                      : tag?.color == 1
                        ? "video_red"
                        : "video_blue"
                  }
                >
                  {tag.tagName}
                </div>
              )
            })}
          </div>
        ) : (
          <p className="waiting-for-feedback">
            <span className="lorange-badge">Video Under Review</span>
          </p>
        )}

        <div className="mt-5 d-flex space_between align-items-center" id="mydiscussion">
          {total > 0 && <h3>Discussions </h3>}
          <p className="light_text">{total} Conversation</p>
        </div>
        <div className="comment_section">
          <div className="default_comment_field">
            <div className="user_avtar">
              <UserAvtar
                name={
                  getProfileDataResponse?.firstName +
                  " " +
                  getProfileDataResponse?.lastName
                }
                image={getProfileDataResponse.profileImage}
              />
              <input
                type="text"
                placeholder="Write a comment or question…"
                name="comment"
                onKeyUp={(e) => {
                  if (e.key == "Enter") {
                    addDiscussion()
                  }
                }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button onClick={addDiscussion}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: CustomIcons.purple_send,
                  }}
                ></span>
              </button>
            </div>
          </div>
          {discussions.map((discussion) => (
            <TrainerVideoUploadDiscussionInfo discussion={discussion} />
          ))}
        </div>
      </div>
    </>
  )
}

export default TrainerVideoUploadLessonDetail

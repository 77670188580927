import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import clientService from "../../services/service"

const EvalutionInfo = ({ evalutionId, onClose, studentDetail }) => {
  const [evalutionDetail, setEvalutionDetail] = useState([])

  const getEvalution = async () => {
    const res = await clientService.get(
      `StudentManagement/GetFinalEvaluation?FinalEvaluationId=${evalutionId}`
    )
    setEvalutionDetail(res.data)
  }
  useEffect(() => {
    getEvalution()
  }, [evalutionId])
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="create_a_guide"
        id="create_a_guide"
        centered
        show={true}
        className="modal-dark remove-profile sap"
      >
        <Modal.Header onHide={onClose} closeButton={true} closeVariant="white">
          <Modal.Title>{studentDetail}'s Final Evaluation Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form_section">
          <div className="evaluation_section">
            <p className="light_color">
              <b className="white_color">Summary:</b>
              <br />
              {evalutionDetail?.summary}
            </p>
            <p>
              {evalutionDetail?.tags && evalutionDetail?.tags.length > 0
                ? evalutionDetail?.tags.map((tag, index) => {
                    return (
                      <label
                        key={`tag_info${index}`}
                        className={tag?.color == 0 ? "green" : "red"}
                      >
                        {tag?.tagName}
                      </label>
                    )
                  })
                : null}
            </p>
            <p className="light_color">
              <b className="white_color">What was Good:</b>
              <br />
              {evalutionDetail?.goodThings}
            </p>
            <p className="light_color">
              <b className="white_color">To Improve:</b>
              <br />
              {evalutionDetail?.toImprove}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-fclose"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EvalutionInfo

import React, { useEffect, useState } from "react"
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom"
import clientService from "../../services/service"
import QuestionProgress from "./QuestionTypes/QuestionProgress"
import VideoQuestion from "./QuestionTypes/VideoQuestion"
import TextQuestion from "./QuestionTypes/TextQuestion"
import QuizLessonContent from "./QuestionTypes/QuizLessonContent"
import VideoUpload from "./QuestionTypes/VideoUpload"
import CustomIcons from "../../assets/CustomIcons"
import QuestionComments from "./QuestionTypes/QuestionComments"
import { useDispatch } from "react-redux"
import { setReloadTopBar } from "../../store/slices/courceGuideSlice"
import WalkThrough from "./QuestionTypes/WalkThrough"

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const LearningGuideQuizCorrect = (props) => {
  const { setExtraClass } = useOutletContext()
  const { courseId, guidId } = useParams()
  const [courseInfo, setCourseInfo] = useState({})
  const dispatch = useDispatch()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [tabType, setTabType] = useState("my")

  const query = useQuery()
  const guideLessonId = query.get("lessonId")

  useEffect(() => {
    getCourseInfo()
  }, [guidId])

  useEffect(() => {
    setTabType("my")
  }, [currentIndex])

  const getCourseInfo = async () => {
    dispatch(setReloadTopBar())
    const res = await clientService.get(
      `StudentCourse/GetAllCourseGuideLessons?CourseGuideId=${guidId}`
    )
    setCourseInfo(res.data)

    for (var i = 0; i < res.data.studentCourseLessions.length; i++) {
      if (
        res.data.studentCourseLessions[i].courseGuideLessonId == guideLessonId
      ) {
        setCurrentIndex(i)
      }
    }
  }
  useEffect(() => {
    setExtraClass(props.extraClass)
    return () => {
      setExtraClass("")
    }
  }, [])

  const getQuestion = () => {
    if (courseInfo?.studentCourseLessions) {
      if (
        courseInfo?.studentCourseLessions &&
        courseInfo?.studentCourseLessions[currentIndex]?.type == 1
      ) {
        return (
          <TextQuestion
            lessonId={
              courseInfo?.studentCourseLessions[currentIndex]
                .courseGuideLessonId
            }
          />
        )
      } else if (
        courseInfo?.studentCourseLessions &&
        courseInfo?.studentCourseLessions[currentIndex]?.type == 2
      ) {
        return (
          <VideoQuestion
            lessonId={
              courseInfo?.studentCourseLessions[currentIndex]
                .courseGuideLessonId
            }
          />
        )
      } else if (
        courseInfo?.studentCourseLessions &&
        courseInfo?.studentCourseLessions[currentIndex]?.type == 3
      ) {
        return (
          <QuizLessonContent
            getCourseInfo={getCourseInfo}
            currentIndex = {currentIndex}
            lesson={courseInfo?.studentCourseLessions[currentIndex]}
            lessonId={
              courseInfo?.studentCourseLessions[currentIndex]
                .courseGuideLessonId
            }
            lessonCount={courseInfo?.studentCourseLessions.length}
            moveNext={() => {

              if (
                currentIndex + 1 <
                courseInfo?.studentCourseLessions.length
              ) {
                setCurrentIndex(currentIndex + 1)
              }
            }}
          />
        )
      } else if (
        courseInfo?.studentCourseLessions &&
        courseInfo?.studentCourseLessions[currentIndex]?.type == 4
      ) {
        return (
          <VideoUpload
            getCourseInfo={getCourseInfo}
            lessonId={
              courseInfo?.studentCourseLessions[currentIndex]
                .courseGuideLessonId
            }
            setTabType={setTabType}
          />
        )
      } else if (
        courseInfo?.studentCourseLessions &&
        courseInfo?.studentCourseLessions[currentIndex]?.type == 6
      ) {
        return (
          <WalkThrough
            lessonId={
              courseInfo?.studentCourseLessions[currentIndex]
                .courseGuideLessonId
            }
          />
        )
      }
      return ""
    }
  }
  return (
    <>
      <div className="lesson_sidebar">
        <ul>
          {courseInfo?.studentCourseLessions?.map((lesson, index) => {
            const time = lesson.time.split(":")
            return (
              <li
                onClick={() => setCurrentIndex(index)}
                className={lesson.iscompleted ? "" : "hide_check"}
                key={`lesson_${index}`}
              >
                <div className="lesson_sidebar_info">
                  {lesson.type == 1 || lesson?.type == 6 ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: CustomIcons.small_document,
                      }}
                    ></span>
                  ) : lesson.type == 2 || lesson.type == 4 ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: CustomIcons.small_video,
                      }}
                    ></span>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.quiz }}
                    ></span>
                  )}
                  {lesson.name} <br />
                  <small>
                    {time[0]}h {time[1]}m
                  </small>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="aside-content-wrapper white_body random_test">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrum">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Learning</li>
                    <li className="breadcrumb-item">
                      <Link to={`/course/learning-guide/${courseId}`}>
                        {courseInfo?.course?.courseName}
                      </Link>{" "}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {courseInfo?.guideName}
                    </li>
                  </ol>
                </nav>
                {courseInfo?.studentCourseLessions &&
                  courseInfo?.studentCourseLessions[currentIndex]?.type ===
                  3 ? null : (
                  <a
                    className="pointer"
                    href={
                      tabType == "other" ? "#otherdiscussion" : "#mydiscussion"
                    }
                  >
                    <span
                      className="icon_message"
                      dangerouslySetInnerHTML={{
                        __html: CustomIcons.message,
                      }}
                    ></span>
                  </a>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              {getQuestion()}
              {courseInfo?.studentCourseLessions &&
                courseInfo?.studentCourseLessions[currentIndex]?.type != 4 &&
                courseInfo?.studentCourseLessions[currentIndex]?.type != 3 ? (
                <>
                  <QuestionProgress
                    lessonInfo={
                      courseInfo?.studentCourseLessions
                        ? courseInfo?.studentCourseLessions[currentIndex]
                        : {}
                    }
                    lessonId={
                      courseInfo?.studentCourseLessions
                        ? courseInfo?.studentCourseLessions[currentIndex]
                          .courseGuideLessonId
                        : ""
                    }
                    getCourseInfo={getCourseInfo}
                    prev={() => {
                      if (currentIndex > 0) {
                        setCurrentIndex(currentIndex - 1)
                      }
                    }}
                    next={() => {
                      if (
                        currentIndex + 1 <
                        courseInfo?.studentCourseLessions.length
                      ) {
                        setCurrentIndex(currentIndex + 1)
                      }
                    }}
                    currentIndex={currentIndex}
                    totalLength={courseInfo?.studentCourseLessions.length}
                  />

                  <QuestionComments
                    lessonInfo={
                      courseInfo?.studentCourseLessions
                        ? courseInfo?.studentCourseLessions[currentIndex]
                        : {}
                    }
                    lessonId={
                      courseInfo?.studentCourseLessions
                        ? courseInfo?.studentCourseLessions[currentIndex]
                          .courseGuideLessonId
                        : ""
                    }
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LearningGuideQuizCorrect

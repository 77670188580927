import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const resourceInitialState = {
  collectionsByCourseId: {},
  resourceBycourseId: [],
  newResourse: {},
  editedStudentStatus: {},
  editedStudentTrainer: {},
  editedStudentReviewer: {},
}

export const resourceSlice = createSlice({
  name: "resource",
  initialState: resourceInitialState,
  reducers: {
    setCollectionByCourseIdResponse: (state, action) => {
      state.collectionsByCourseId = action.payload.data
    },
    setResoursesByCourseIdResponse: (state, action) => {
      state.resourceBycourseId = action.payload.data
    },
    setNewResourseResponse: (state, action) => {
      state.newResourse = action.payload
    },
    setEditedCourseStudentStatusResponse: (state, action) => {
      state.editedStudentStatus = action.payload
    },
    setEditedCourseStudentTrainerResponse: (state, action) => {
      state.editedStudentTrainer = action.payload
    },
    setEditedCourseStudentReviewerResponse: (state, action) => {
      state.editedStudentReviewer = action.payload
    },
  },
})

export const getCollectionbycourseId = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `ResourceManagement/GetCollectionByCourseId?courseId=${data.courseId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCollectionByCourseIdResponse(response))
  }
}

export const getAllResourcebycourseId = (setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ResourceManagement/GetAllResourcesByCourseId",
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setResoursesByCourseIdResponse(response))
  }
}

export const createResourse = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ResourceManagement/AddResourceAndCollection",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setNewResourseResponse(response))
  }
}

export const editCourseStudentStatus = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ResourceManagement/UpdateStudentStatus",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentStatusResponse(response))
  }
}
export const editCourseStudentTrainer = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ResourceManagement/UpdateStudentTrainer",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentTrainerResponse(response))
  }
}

export const editCourseStudentReviewer = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "ResourceManagement/UpdateStudentReviewer",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentReviewerResponse(response))
  }
}


export const {
  setCollectionByCourseIdResponse,
  setResoursesByCourseIdResponse,
  setNewResourseResponse,
  setEditedCourseStudentReviewerResponse,
  setEditedCourseStudentTrainerResponse,
  setEditedCourseStudentStatusResponse
} = resourceSlice.actions

export const resourceState = (state) => {
  return state.resource
}

export default resourceSlice.reducer

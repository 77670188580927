import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"

const TrainerCourseGuide = () => {
  const { loading } = useSelector(appState)
  const { courseId } = useParams()
  const [courseGuide, setCourseGuide] = useState([])

  useEffect(() => {
    getCourseGuides()
  }, [courseId])

  const getCourseGuides = async () => {
    if (loading) {
      return
    }
    const res = await clientService.get(
      `TrainerCourse/GetCourseGuide?CourseId=${courseId}`
    )
    setCourseGuide(res)
  }
  return (
    <div className="aside-content-wrapper">
      <div className="container-fluid">
        <div className="overview-area mb-5">
          <div className="row">
            <div className="col-12">
              <div className="aside-title-area">
                <h2 className="title title-lg">
                  {courseGuide?.data?.courseName}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {courseGuide?.data?.courseGuides.length > 0
              ? courseGuide?.data?.courseGuides.map((guide, index) => {
                return (
                  <>
                    <div
                      className="col-md-6 col-xl-4 col-12"
                      key={`trainer_guide${index}`}
                    >
                      <Link
                        to={`/trainer/course/guide/${courseId}/${guide.courseGuideId}`}
                      >
                        <div className="course_blog">
                          <div className="course_thumb">
                            <img src={guide?.image} alt="#" />
                          </div>
                          <div className="course_detail">
                            <div className="d-flex position-relative">
                              <div>
                                <label>
                                  {guide?.courseGuideName}{" "}
                                  <span>{guide?.noOfLesson} Lessons</span>
                                </label>
                                <h5>{guide?.course}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                )
              })
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainerCourseGuide

import { useEffect, useState } from "react"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import clientService from "../../services/service"

const CousreDeadline = ({ onClose, courseId }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [detail, setDetail] = useState([])
  const [highlightDates, setHighlightDates] = useState([])
  const [dueLessons, setDueLessons] = useState([])

  const getDetailsofCalendar = async () => {
    const res = await clientService.get(
      `StudentHome/GetStudentCourseCalendar?CourseId=${courseId}`
    )
    setDetail(res.data)
    const dt = [];
    const dl = [];
    for(var i = 0; i < res.data.weeks.length; i++) {
        const dts = new Date(res.data.weeks[i].dueDate);
        dts.setSeconds(0)
        dts.setMinutes(0)
        dts.setHours(0)
        dt.push(dts)
        dl.push(res.data.weeks[i].dueLessons)
    }
    setHighlightDates(dt);
    setDueLessons(dl);
  }
  useEffect(() => {
    getDetailsofCalendar()
  }, [courseId])

  const renderDayContents = (day, date) => {

    let haveToolTip = false;
    let tooltipText = "";
    for(var i = 0; i < highlightDates.length; i++){
      if(date.getMonth() == highlightDates[i].getMonth() &&
      date.getFullYear() == highlightDates[i].getFullYear() &&
      date.getDate() == highlightDates[i].getDate()){
        haveToolTip = true;
        tooltipText = `Lesson ${dueLessons[i]} Due`;
      }
    }
    
    if(haveToolTip) {
      return (
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => {
          return <Tooltip show={true}  id="button-tooltip" {...props}>
          {tooltipText}
        </Tooltip>
        }}
      ><span>{day}</span>
      </OverlayTrigger>
      )
    }
    return <span>{day}</span>
  };
  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap calendar_style_2"
        aria-labelledby="create_a_lesson"
        size="lg"
        id="text_lesson_preview"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">{detail?.courseName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calendar_section">
            <DatePicker
            //   selected={startDate}
            //   onChange={(date) => setStartDate(date)}
              inline
              monthsShown={2}
              highlightDates={highlightDates}
              renderDayContents={renderDayContents}
              dayClassName={(date) =>{
               
                if(highlightDates.length >= 1) {
                  if(
                    date.getMonth() == highlightDates[0].getMonth() &&
                    date.getFullYear() == highlightDates[0].getFullYear() &&
                    date.getDate() == highlightDates[0].getDate()
                  )
                  return "first-hight-light"
                }
                return "";
              }
                // getDate(date) < Math.random() * 31 ? "random" : undefined
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-left text-left">
            <p className="tooltip-purple">Start of Lesson</p>
            <p className="tooltip-blue">Next Assignment Date</p>
            <button
              onClick={onClose}
              className="btn btn-fclose"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CousreDeadline

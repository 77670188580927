import { useState } from "react"
import { Link } from "react-router-dom"
import AvailablePrize from "./AvailablePrize"
import LockedPrize from "./LockedPrize"
import RequestedPrize from "./RequestedPrize"

const StudentPrizeLocker = () => {

  const [totalScore, setTotalScore] = useState(0)
  return (
    <>
      <div className="aside-content-wrapper prize-locker-box">
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">Prize Locker</h2>
                </div>
              </div>
            </div>
            <div className="asied-tiles-listings prize-listing">
              <div className="row">
                <div className="col-12 col-xl-3 col-lg-3 col-sm-12 mb-3">
                  <div className="aside-tiles-item">
                    <div className="shadow-shape">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_f_1502_40280)">
                          <circle cx="38" cy="38" r="8" fill="#26CBFF" />
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_1502_40280"
                            x="0"
                            y="0"
                            width="76"
                            height="76"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="`"
                            />
                            <feGaussianBlur
                              stdDeviation="15"
                              result="effect1_foregroundBlur_1502_40280"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                    <div className="d-flex align-items-center justify-content-flex-start">
                      <div className="tile-caption">
                        <div className="tile-title">
                          <p>Available Points</p>
                        </div>
                        <div className="tile-value">
                          <h4>{totalScore}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-9 col-lg-9 col-sm-12 mb-3">
                  <div className="aside-tiles-item">
                    <div className="shadow-shape">
                      <svg
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_f_1502_40280)">
                          <circle cx="38" cy="38" r="8" fill="#26CBFF" />
                        </g>
                        <defs>
                          <filter
                            id="filter0_f_1502_40280"
                            x="0"
                            y="0"
                            width="76"
                            height="76"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feGaussianBlur
                              stdDeviation="15"
                              result="effect1_foregroundBlur_1502_40280"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                    <div className="aside-tiles-inner d-flex align-items-center justify-content-flex-start">
                      <div className="tile-caption">
                        <div className="tile-title">
                          <p>Planner</p>
                        </div>
                        <div className="tile-value">
                          <h4>
                            To get more points play more with the Randomizer.
                          </h4>
                        </div>
                      </div>
                      <div className="aside-tile-btn-area">
                        <div className="aside-tile-btn">
                          <Link to="/courses" className="btn btn-go">
                            Go
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AvailablePrize setTotalScore={setTotalScore} />
              <LockedPrize totalScore={totalScore} />
              <RequestedPrize totalScore={totalScore} />
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StudentPrizeLocker

import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Formik } from "formik"
import Header from "../components/Shared/Header"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "react-alert"
import {
  authState,
  setInvitationResponse,
  setValidateInvitationResponse,
  validateInvitation,
  acceptInvitation,
} from "../store/slices/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

const SignUpInvite = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { validateInviteResponse, inviteResp } = useSelector(authState)
  const [passwordType, setPasswordType] = useState("password")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [companyDetails, setCompanyDetails] = useState({})

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  useEffect(() => {
    if (inviteResp.code === 500 || inviteResp.status === false) {
      alert.error(inviteResp.message)
      dispatch(setInvitationResponse({}))
    } else if (inviteResp.code === 200 || inviteResp.status === true) {
      alert.success(inviteResp.message)
      dispatch(setInvitationResponse({}))
      navigate("/login")
    }
  }, [inviteResp])

  useEffect(() => {
    if (
      validateInviteResponse.code === 500 ||
      validateInviteResponse.status === false
    ) {
      alert.error(validateInviteResponse.message)
      dispatch(setValidateInvitationResponse({}))
    } else if (
      validateInviteResponse.code === 200 ||
      validateInviteResponse.status === true
    ) {
      dispatch(setValidateInvitationResponse({}))
      setFirstName(validateInviteResponse?.data?.firstName)
      setLastName(validateInviteResponse?.data?.lastName)
    }
  }, [validateInviteResponse])

  useEffect(() => {
    var data = {
      activationCode: searchParams.get("activationCode"),
    }
    dispatch(validateInvitation(data))
  }, [dispatch])

  const handleFormSubmit = (values, { setErrors }) => {
    var data = {
      ...values,
      firstName: firstName,
      lastName: lastName,
      activationCode: searchParams.get("activationCode"),
    }
    dispatch(acceptInvitation(data, setErrors))
  }

  const handleGetData = (data) => {
    setCompanyDetails(data)
  }


  return (
    <>
      <Header handleGetData={handleGetData} />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-6">
              <div className="register-main-wrapper mx-auto">
                <div className="register-caption">
                  <h1 className="title">
                    {companyDetails?.companyTagLine}
                    {/* <b>
                      <span>®</span>
                    </b> */}
                  </h1>
                </div>
                <div className="register-inner-wrapper">
                  <p className="mt-4 large2">
                    You've been invited to join {companyDetails?.companyName}.
                    <br />
                    Let's finish your setup before moving forward.
                  </p>
                  <div className="register-form-area">
                    <Formik
                      initialValues={{
                        firstName: firstName,
                        lastName: lastName,
                        password: "",
                        acceptsTerms: false,
                      }}
                      validate={(values) => {
                        const errors = {}
                        if (!values.password) {
                          errors.password = "Required field"
                        }
                        if (
                          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*)(?=.{8,})/.test(
                            values.password
                          )
                        ) {
                          errors.password =
                            "Password must be at least 8 characters long with both letters and numbers."
                        }
                        if (!values.acceptsTerms) {
                          errors.acceptsTerms =
                            "You must agree to the Terms of Service."
                        }
                        return errors
                      }}
                      onSubmit={handleFormSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit} method="post">
                          <div className="form-group pb-1">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1"
                              htmlFor="first_name"
                            >
                              First name
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="text"
                                className={
                                  errors.firstName && touched.firstName
                                    ? "form-control cform-control error-field"
                                    : "form-control cform-control"
                                }
                                readOnly
                                placeholder="First name"
                                name="firstName"
                                value={firstName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <div className="error-message">
                                {errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName}
                              </div>
                            </div>
                          </div>
                          <div className="form-group pb-1">
                            <label
                              className="control-label col-xs-3 mb-1 pb-1"
                              htmlFor="last_name"
                            >
                              Last name
                            </label>
                            <div className="col-xs-9">
                              <input
                                type="text"
                                className={
                                  errors.lastName && touched.lastName
                                    ? "form-control cform-control error-field"
                                    : "form-control cform-control"
                                }
                                placeholder="Last name"
                                name="lastName"
                                readOnly
                                value={lastName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <div className="error-message">
                                {errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName}
                              </div>
                            </div>
                          </div>
                          <div className="form-group pb-2">
                            <div className="row">
                              <div className="col-12">
                                <label
                                  className="control-label col-lg-12 col-xs-12 mb-1 pb-1"
                                  htmlFor="inputPassword"
                                >
                                  Password
                                </label>
                              </div>
                            </div>
                            <div className="col-xs-9">
                              <div className="input-group">
                                <input
                                  type={passwordType}
                                  className={
                                    errors.password && touched.password
                                      ? "form-control cform-control error-field"
                                      : "form-control cform-control border-right-0"
                                  }
                                  placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                  name="password"
                                  value={values.password}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />

                                <span className="input-group-text white-icon">
                                  <span
                                    toggle="#password-field"
                                    className="fa fa-fw fa-eye field-icon toggle-password"
                                  >
                                    <FontAwesomeIcon
                                      className="pointer"
                                      onClick={togglePassword}
                                      icon={
                                        passwordType === "password"
                                          ? faEyeSlash
                                          : faEye
                                      }
                                    />
                                  </span>
                                </span>
                              </div>

                              <div className="error-message">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </div>
                            </div>
                          </div>
                          <div className="form-group checkbox_group mb-4 pb-1">
                            <div className="row">
                              <div className="col-sm-9">
                                <input
                                  id="term_use"
                                  className="form-check-input"
                                  type="checkbox"
                                  name="acceptsTerms"
                                  value={values.acceptsTerms}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label white_color"
                                  htmlFor="term_use"
                                >
                                  I have read and agree to the
                                  <br />
                                  <Link to="/terms-condition">
                                    Terms of Use
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/privacy-policy">
                                    Customer Privacy Policy
                                  </Link>
                                  .
                                </label>
                                <div className="error-message">
                                  {errors.acceptsTerms &&
                                    touched.acceptsTerms &&
                                    errors.acceptsTerms}
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <input
                                  type="submit"
                                  className="btn btn-primary btn_submit"
                                  value="Register"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="register-footer-wrapper mt-4">
                  <div className="loing-footer-caption">
                    <h5 className="login-footer-links pt-4 pb-4">
                      Already have an account!?{" "}
                      <Link to="/login" className="text-link">
                        Log in
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpInvite

const RandomiserOverViewEmpty = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="overview-area">
          <div className="row">
            <div className="col-12">
              <div className="aside-title-area">
                <h2 className="title title-lg">Courses</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mt-5">
              <div className="full text-center">
                <img src="/img/unlock_vendor.png" alt="" />
              </div>
              <div className="full text-center">
                <p className="light_text mb-0 mt-5">
                  There is no data available yet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RandomiserOverViewEmpty

import React from "react"

const LearningGuideResultGoodJob = ({ evalutionInfo }) => {
  return (
    <>
      <div className="col-md-12 minus_blank_space mt-3">
        <div className="full text-center mt-3">
          <div className="icon">
            <img src="/img/good_job.png" alt="#" />
          </div>
        </div>
        <div className="full text-center">
          <h3 className="white_color mt-4">
            <b>Good job!</b>
          </h3>
          <p className="light_color mt-4">See your results below:</p>
        </div>

        <div className="evaluation_section">
          <p className="light_color">
            <b className="white_color">Summary:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.summary}
          </p>
          <p>
            {evalutionInfo?.finalEvaluationTags &&
            evalutionInfo?.finalEvaluationTags.length > 0
              ? evalutionInfo?.finalEvaluationTags.map((tag, index) => {
                  return <label className="dark_red">{tag?.tagName}</label>
                })
              : null}
          </p>
          <p className="light_color">
            <b className="white_color">What was good:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.goodThings}
          </p>
          <p className="light_color">
            <b className="white_color">To improve:</b>
            <br />
            {evalutionInfo?.finalEvaluationResult?.toImprove}
          </p>
        </div>
      </div>
    </>
  )
}

export default LearningGuideResultGoodJob

import React, { useEffect, useState } from "react"
import clientService from "../../../services/service"
import { Link } from "react-router-dom"
import ExpandedWalkThroughModal from "../../models/ExpandedWalkThroughModal"

const WalkThrough = ({ lessonId }) => {

    const [lessonInfo, setLessonInfo] = useState("")
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        getCourseLession()
    }, [lessonId])

    const getCourseLession = async () => {
        const res = await clientService.get(
            `StudentCourse/GetLessionContent?courseGuideLessonId=${lessonId}`
        )
        setLessonInfo(res.data)
    }

    const onClose = () => {
        setShowModal(false)
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-lg-12 mt-2">
                    <div className="tabbar_head video-tabs">
                        {/* <iframe
                            width="100%"
                            src={lessonInfo?.walkthroughUrl}
                            title="Combat Sales Roleplay"
                            // style={{ height: "-webkit-fill-available" }}
                            style={{ minHeight: "500px" }}
                            allow="camera;microphone"
                        ></iframe> */}
                        <div>
                            <script src="https://js.storylane.io/js/v1/storylane.js"></script>
                            <div
                                className="sl-embed"
                                style={{
                                    position: 'relative',
                                    paddingBottom: 'calc(46.34% + 27px)',
                                    width: '100%',
                                    height: '100%',
                                    transform: 'scale(1)'
                                }}
                            >
                                <iframe
                                    width={'100%'}
                                    className="sl-demo"
                                    // src={"https://app.storylane.io/share/orub9lz4ppfv"}
                                    src={lessonInfo?.walkthroughUrl}
                                    name="sl-embed"
                                    allow="fullscreen"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: '1px solid rgba(63, 95, 172, 0.35)',
                                        boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
                                        borderRadius: '10px',
                                        boxSizing: 'border-box'
                                    }}>
                                </iframe>
                            </div>
                        </div>
                    </div>
                    {/* <div className="right_btn mt-2">
                        <button className="main_btn_default blue_color float-end" onClick={() => {
                            setShowModal(true)
                        }}>
                            Expand
                        </button>
                    </div> */}
                </div>
            </div>

            {showModal &&
                <ExpandedWalkThroughModal onClose={onClose} showModal={showModal} url={lessonInfo?.walkthroughUrl} />
            }
        </>
    )
}
export default WalkThrough

import React from 'react';

const Randomiser = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="overview-area randomister_content">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="aside-title-area mt-0 mb-0">
                                <h2 className="title title-lg line-height_48 mt-0 mb-0">Randomiser</h2>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="aside-title-area mt-0 mb-0 d-flex justify-content-end">
                                <a className="border_btn_default" href="randomiser_leaderboard.html">Leaderboard</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt-5">
                            <div className="randomister_blog text-center mt-5">
                                <img src="../img/unlock_vendor.png" alt="#" />
                                <h3 className="mt-4">Congrats, you've unlocked Randomizer!</h3>
                                <p className="mb-4">Here you can read a scenario and then record yourself answering to objections.</p>
                                <div className="buttons">
                                    <a href="randomiser_scenario.html" className="main_btn_default">Start new attempt</a>
                                    <a data-bs-toggle="modal" href="#view_instruction" className="border_btn_default">View Instruction</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Randomiser;
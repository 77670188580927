import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import {
  getSubmittedScenarios,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice"
import { useEffect } from "react"
import { useParams } from "react-router"
import { appState } from "../../store/slices/appSlice"
import { Link } from "react-router-dom"
import moment from "moment"
import VideoPlayer from "../../common/VideoPlayer"
import UserAvtar from "../../common/UserAvtar"

const ToReview = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const { scenarios } = useSelector(reviewerCourseState)
  const { loading } = useSelector(appState)

  useEffect(() => {
    dispatch(getSubmittedScenarios({ courseId: courseId }))
  }, [dispatch])

  return (
    <>
      <div className="mt-4">
        <div id="to_review" className="container tab-pane active">
          <div className="row">
            {scenarios?.pendingReview &&
            scenarios?.pendingReview?.length > 0 ? (
              scenarios.pendingReview.map((scene, index) => {
                return (
                  <>
                    {scene?.isReviewed == false ? (
                      <div className="col-12">
                        <div className="video_post_section d-block single_video video-box-list">
                          <div className="video_blog">
                            <div className="">
                              <label className="video_time">
                                {scene?.secondsPerQuestion}
                              </label>
                              <label className="video_time video_date">
                                {moment(scene?.createdDate).format("MM/DD/YY")}
                              </label>
                              <Link
                                to={`/reviewer/randomiser/detail/${courseId}/${scene?.submittedId}`}
                              >
                                <div className="play_button">
                                  <VideoPlayer
                                    className="img-fluid height-100"
                                    videoId={scene?.vimeoLink}
                                    isVideoAvailable={scene?.isVideoAvailable}
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                          <Link
                            to={`/reviewer/randomiser/detail/${courseId}/${scene?.submittedId}`}
                          >
                            <div className="video_post_info">
                              <div className="video_upload_info">
                                <UserAvtar
                                  name={scene?.studentName}
                                  image={scene?.profileImage}
                                />

                                <div className="video_info offwhite_color">
                                  <h3>{scene?.studentName}</h3>
                                  <p>{scene?.days} days ago</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </>
                )
              })
            ) : !loading ? (
              <div className="col-md-12 minus_blank_space mt-5">
                <div className="full text-center mt-5">
                  <img src="/img/unlock_vendor.png" alt="" />
                </div>
                <div className="full text-center">
                  <p className="light_text mb-0 mt-5">
                    There is no data available yet.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
export default ToReview

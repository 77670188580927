import React, { useEffect, useState } from "react"

const VideoPreview = ({ videoLink, questionTimes, questions }) => {
  const playerRef = React.useRef(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  useEffect(() => {
    var sec = questionTimes[questionTimes.length - 1] / 1000
    // for(var i = 0; i < questionTimes.length; i++){
    //     sec += questionTimes[i] / 1000
    // }

    setSeconds(Math.ceil(sec))
  }, [])

  const updateQuestion = (e) => {
    var newIndex = currentQuestionIndex
    for (var i = 0; i < questionTimes.length; i++) {
      if (questionTimes[i] / 1000 < e.target.currentTime) {
        newIndex = i
      }
    }
    if (currentQuestionIndex != newIndex) {
      setCurrentQuestionIndex(newIndex)
    }
  }
  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.addEventListener("timeupdate", updateQuestion)
    }
  }, [playerRef])

  const getScondsToTime = () => {
    // if (seconds > 60) {
    //     if (seconds - 60 > 10) {
    //         return `01:${seconds - 60}`;
    //     } else {
    //         return `01:0${seconds - 60}`;
    //     }
    // } else if (seconds < 10) {
    //     return `00:0${seconds}`;
    // } else {
    //     return `00:${seconds}`;
    // }
    if (seconds >= 60) {
      const min = Math.floor(seconds / 60)
      const sec = seconds % 60
      var str = ""

      if (min < 10) {
        str = `0${min}`
      } else {
        str = `${min}`
      }
      if (sec < 10) {
        return `${str}:0${sec}`
      } else {
        return `${str}:${sec}`
      }
    } else if (seconds < 10) {
      return `00:0${seconds}`
    } else {
      return `00:${seconds}`
    }
  }
  return (
    <div className="popup_content">
      <p class={"m-0"}>
        <video
          style={{ width: "100%" }}
          src={videoLink}
          autoPlay
          ref={playerRef}
          controls
          className="image-fluid video-preview"
          webkit-playsinline="true"
          playsinline="true"
        />

        <span className="media_player_controls_timer">
          <div className="control-video">
            <span className="controls_player left_timer">
              <label>Timer</label>
              <p className="large_number">{getScondsToTime()}</p>
            </span>
            <span className="controls_player right_content">
              <span className="left_plyer_control">
                <label>
                  {currentQuestionIndex + 1} / {questions.length} Objections
                </label>
                <p>{questions[currentQuestionIndex]?.question}</p>
              </span>

              {/* <span className="right_plyer_control">
                            <a className="player_round_btn" href="#">
                                {currentQuestionIndex + 1 < questions.length ?
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.47139 0.86201C6.21104 0.601661 5.78893 0.601661 5.52858 0.86201C5.26824 1.12236 5.26824 1.54447 5.52858 1.80482L9.05718 5.33341H1.29166C0.946479 5.33341 0.666656 5.63189 0.666656 6.00008C0.666656 6.36827 0.946479 6.66675 1.29166 6.66675H9.05718L5.52858 10.1953C5.26824 10.4557 5.26824 10.8778 5.52858 11.1382C5.78893 11.3985 6.21104 11.3985 6.47139 11.1382L11.1381 6.47149C11.3984 6.21114 11.3984 5.78903 11.1381 5.52868L6.47139 0.86201Z" fill="white"></path>
                                    </svg>
                                    :
                                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.8047 0.528758C12.0651 0.789108 12.0651 1.21122 11.8047 1.47157L4.4714 8.8049C4.21106 9.06525 3.78894 9.06525 3.5286 8.8049L0.195262 5.47157C-0.0650874 5.21122 -0.0650874 4.78911 0.195262 4.52876C0.455612 4.26841 0.877722 4.26841 1.13807 4.52876L4 7.39069L10.8619 0.528758C11.1223 0.268409 11.5444 0.268409 11.8047 0.528758Z" fill="white" />
                                    </svg>
                                }
                            </a>
                        </span> */}
            </span>
          </div>
        </span>
      </p>
    </div>
  )
}

export default VideoPreview

import moment from "moment"
import { useEffect } from "react"
import { useState } from "react"
import { useAlert } from "react-alert"
import { useDispatch } from "react-redux"
import CustomIcons from "../../assets/CustomIcons"
import UserAvtar from "../../common/UserAvtar"
import clientService from "../../services/service"
import { setReloadSideBar } from "../../store/slices/appSlice"

const TrainerDiscussionInfo = ({ discussion }) => {
  const alert = useAlert()
  const [likeCount, setLikeCount] = useState(discussion.likeCount)
  const [commnetCount, setCommnetCount] = useState(discussion.commnetCount)
  const [showSendReply, setShowSendReply] = useState(false)
  const [showReplies, setShowReplies] = useState(false)
  const [comment, setComment] = useState("")
  const [replies, setReplies] = useState([])
  const [pageNO, setPageNO] = useState(1)
  const dispatch = useDispatch();
  useEffect(() => {
    if (showReplies && replies.length == 0) {
      getReplies()
    }
  }, [showReplies])

  useEffect(() => {
    if (pageNO != 1) {
      getReplies()
    }
  }, [pageNO])
  const likeDiscussion = async () => {
    const res = await clientService.post("TrainerCourse/LikeDiscussion", {
      discussionId: discussion.discussionId,
    })
    alert.success(res.message)
    setLikeCount(res.data.likeCount)
  }
  const addComment = async () => {
    if (comment) {
      const data = {
        discussionId: discussion.discussionId,
        discussion: comment,
      }
      const res = await clientService.post(
        "TrainerCourse/AddCommentForTrainer",
        data
      )
      alert.success(res.message)
      setComment("")
      setReplies([
        {
          areYouTrainer: false,
          commentId: res.data.id,
          createdDate: moment.utc(),
          discussion: res.data.discussion,
          likeCount: 0,
          profileImage: res.data.profileImage,
          userName: res.data.userName,
        },
        ...replies,
      ])
      setCommnetCount(commnetCount + 1)

      dispatch(setReloadSideBar({}))
    }
  }
  const getReplies = async () => {
    const data = {
      discussionId: discussion.discussionId,
      pageNo: pageNO,
      pageSize: 5,
    }
    const res = await clientService.post("TrainerCourse/GetAllComments", data)
    if (pageNO == 1) {
      setReplies(res.data.commentList)
    } else {
      setReplies([...replies, ...res.data.commentList])
    }
  }
  const time = moment.utc(discussion.createdDate).local().format("M/D/Y h:mm A")

  const likeComment = async (index, id) => {
    const res = await clientService.post("TrainerCourse/LikeComment", {
      commentId: id,
    })
    const resplies = [...replies]
    resplies[index].likeCount = res.data.likeCount
    setReplies(resplies)
  }
  return (
    <div className="comment_blog d-flex">
      <UserAvtar name={discussion?.userName} image={discussion?.profileImage} />
      <div className="comment_inner_section">
        <div className="top_section d-flex space_between">
          <h3 className="comment_name">
            {discussion.userName}
            {discussion?.isForTrainer ? (
              <label className="trainer_label">Ask Trainer</label>
            ) : null}
            <span className="date_time">{time}</span>
          </h3>
          <p>
            {likeCount}
            <span
              onClick={likeDiscussion}
              dangerouslySetInnerHTML={{ __html: CustomIcons.like }}
            ></span>
          </p>
        </div>
        <div
          className="comment_cont pointer"
          onClick={() => setShowReplies(!showReplies)}
        >
          <p className="mt-3">{discussion.discussion}</p>
          <p>
            {commnetCount} replies
            <span dangerouslySetInnerHTML={{
              __html:
                showReplies ? CustomIcons.arrow_up : CustomIcons.arrow_down
            }}></span>
          </p>

        </div>

        {showReplies && replies.length >= 1 && (
          <div className="comment_child">
            {replies.map((reply, index) => {
              const timeString = moment
                .utc(reply.createdDate)
                .local()
                .format("M/D/Y h:mm A")

              return (
                <div key={reply.commentId} className="comment_blog d-flex">
                  <UserAvtar name={reply?.userName} image={reply?.profileImage} />
                  <div className="comment_inner_section">
                    <div className="top_section d-flex space_between">
                      <h3 className="comment_name">
                        {reply.userName}{" "}
                        <span className="date_time">{timeString}</span>
                      </h3>
                      <p
                        onClick={() => {
                          likeComment(index, reply.commentId)
                        }}
                      >
                        {reply.likeCount}
                        <span
                          onClick={likeDiscussion}
                          dangerouslySetInnerHTML={{ __html: CustomIcons.like }}
                        ></span>
                      </p>
                    </div>
                    <div className="comment_cont">
                      <p className="mt-2">{reply.discussion}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        <div className="comment_footer">
          {showSendReply ? (
            <div className="default_comment_field">
              <input
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                name="message"
                placeholder="Enter message"
                onKeyUp={(e) => {
                  if (e.key == "Enter") {
                    addComment()
                  }
                }}
              />
              <button onClick={addComment}>
                <span
                  dangerouslySetInnerHTML={{ __html: CustomIcons.purple_send }}
                ></span>
              </button>
            </div>
          ) : (
            <p>
              <a onClick={() => setShowSendReply(true)}>
                <span
                  dangerouslySetInnerHTML={{ __html: CustomIcons.reply }}
                ></span>
                Reply
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrainerDiscussionInfo

import { createSlice } from "@reduxjs/toolkit"
import clientService from "../../services/service"
import { setErrorMessage } from "./appSlice"

export const studentCourseInitialState = {
  courseTrainerReviewers: {
    trainers : [],
    reviewers:[]
  },
  courseStudents: [],
  editedStudent: {},
  editedStudentStatus: {},
  editedStudentTrainer: {},
  editedStudentReviewer: {},
}

export const studentCourseSlice = createSlice({
  name: "studentCourse",
  initialState: studentCourseInitialState,
  reducers: {
    setCourseTrainerReviwerResponse: (state, action) => {
      state.courseTrainerReviewers = action.payload.data
    },
    setCourseStudentsResponse: (state, action) => {
      state.courseStudents = action.payload.data
    },
    setEditedCourseStudentResponse: (state, action) => {
      state.editedStudent = action.payload
    },
    setEditedCourseStudentStatusResponse: (state, action) => {
      state.editedStudentStatus = action.payload
    },
    setEditedCourseStudentTrainerResponse: (state, action) => {
      state.editedStudentTrainer = action.payload
    },
    setEditedCourseStudentReviewerResponse: (state, action) => {
      state.editedStudentReviewer = action.payload
    },
  },
})

export const getCourseTrainerReviwer = (data, setErrors) => async (dispatch) => {
  const response = await clientService.get(
    `CourseStudents/GetCourseTrainerAndReviewerList?courseId=${data.courseId}`,
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCourseTrainerReviwerResponse(response))
  }
}

export const getCourseStudents = (setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseStudents/GetCourseStudentList",
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setCourseStudentsResponse(response))
  }
}

export const editTrainerCourseStudent = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TrainerCourse/UpdateStudentStatus",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentResponse(response))
  }
}

export const editCourseStudentStatus = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "TrainerCourse/UpdateStudentStatusForTrainer",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentStatusResponse(response))
  }
}
export const editCourseStudentTrainer = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseStudents/UpdateStudentTrainer",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentTrainerResponse(response))
  }
}

export const editCourseStudentReviewer = (data, setErrors) => async (dispatch) => {
  const response = await clientService.post(
    "CourseStudents/UpdateStudentReviewer",
    data,
    setErrors
  )
  if (typeof response === "string") {
    dispatch(setErrorMessage(response))
  } else {
    dispatch(setEditedCourseStudentReviewerResponse(response))
  }
}


export const {
  setCourseTrainerReviwerResponse,
  setCourseStudentsResponse,
  setEditedCourseStudentResponse,
  setEditedCourseStudentReviewerResponse,
  setEditedCourseStudentTrainerResponse,
  setEditedCourseStudentStatusResponse
} = studentCourseSlice.actions

export const studentCourseState = (state) => {
  return state.studentCourse
}

export default studentCourseSlice.reducer

import { Formik } from "formik"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
// import {
//   companyState,
//   getCompanyList,
// } from "../../store/slices/admin/companySlice"
import clientService from "../../services/service"
import InputMask from "react-input-mask"

const InviteStudent = ({ onClose }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const [defaultCompany, setDefaultCompany] = useState({
    companyName: "Select...",
    id: ""
  })
  const [companyList, setCompanyList] = useState([])
  const [isShow, setIsShow] = useState(false)
  const selectedCompany = localStorage.getItem("companyId") || ""

  const getCompanyList = async () => {
    const data = {
      pageNo: 1,
      pageSize: 100,
      status: 1,
    }
    const response = await clientService.post("Company/GetCompanyList", data)
    if (response?.data?.total == 0) {
      alert.error("You are not associated with any courses of company so you cannot invite students.")
      onClose()
    }
    setCompanyList(response?.data?.companyList)
  }

  useEffect(() => {
    getCompanyList()
  }, [])

  const handleFormSubmit = async (values, { setErrors }) => {
    let companyId
    if (values?.companyId?.id == "") {
      companyId = defaultCompany?.id
    } else {
      companyId = values.companyId?.id
    }
    var data = {
      ...values,
      companyId: companyId,
    }
    const res = await clientService.post(
      "StudentManagement/SendInvitationToStudent", data
    )
    if (res?.code == 200 || res?.code == 201) {
      alert.success(res?.message)
      onClose()
    }
  }

  const getDefaultCompany = () => {
    if (companyList.length > 0) {
      for (let index = 0; index < companyList?.length; index++) {
        if (companyList[index]?.id == selectedCompany) {
          setDefaultCompany(companyList[index])
        }
      }
      if (companyList.length == 1) {
        setDefaultCompany(companyList[0])
      }
      setIsShow(true)
    }
  }

  useEffect(() => {
    getDefaultCompany()
  }, [companyList])

  return (
    <>
      {isShow ? (
        <Modal
          size="md"
          aria-labelledby="create_a_guide"
          id="create_a_guide"
          centered
          show={true}
          className="modal-dark remove-profile sap"
        >
          <Modal.Header
            onHide={onClose}
            closeButton={true}
            closeVariant="white"
          >
            <Modal.Title>Invite a Student</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              companyId: defaultCompany,
              email: "",
            }}
            validate={(values) => {
              const errors = {}
              const isEmail = /^[\w+\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
                values.email
              )
              if (companyList.length > 1 && (values.companyId?.id == null || values.companyId?.id == "" || values.companyId?.id == undefined)) {
                errors.companyId = "Required field"
              }
              if (!values.email) {
                errors.email = "Required field"
              } else if (!isEmail) {
                errors.email = "Please enter valid email"
              }
              if (!values.firstName) {
                errors.firstName = "Required field"
              }
              if (!values.lastName) {
                errors.lastName = "Required field"
              }
              return errors
            }}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body className="form_section">
                  <fieldset>
                    <div className="form-group mb-3 pb-1">
                      <label className="control-label col-xs-3 mb-1 required_field">
                        First Name
                      </label>
                      <input
                        className="form-control cform-control"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      <div className="error-message">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </div>
                    </div>
                    <div className="form-group mb-3 pb-1">
                      <label className="control-label col-xs-3 mb-1 required_field">
                        Last Name
                      </label>
                      <input
                        className="form-control cform-control"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      <div className="error-message">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </div>
                    </div>
                    <div className="form-group mb-3 pb-1">
                      <label className="control-label col-xs-3 mb-1" htmlFor="number">
                        Phone number
                      </label>
                      <InputMask
                        mask="(999) 999 9999"
                        className={
                          errors.phoneNumber && touched.phoneNumber
                            ? "form-control cform-control error-field"
                            : "form-control cform-control flag"
                        }
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {(inputProps) => (
                          <input
                            type="text"
                            name="phoneNumber"
                            placeholder="(XXX) XXX XXXX"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </div>

                    <div className="form-group mb-3 pb-1">
                      <label className="control-label col-xs-3 mb-1 required_field">
                        Email
                      </label>
                      <input
                        className="form-control cform-control"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div className="error-message">
                        {errors.email && touched.email && errors.email}
                      </div>
                    </div>
                    {companyList.length > 1 &&
                      <div className="form-group mb-1">
                        <label className="control-label col-xs-3 mb-1 required_field">
                          Company Name
                        </label>
                        <Select
                          name="companyId"
                          id="sources"
                          // defaultValue={defaultCompany}
                          className="custom-select sources"
                          placeholder="Select an option"
                          getOptionLabel={(op) => {
                            return op.companyName
                          }}
                          getOptionValue={(e) => {
                            return e.id
                          }}
                          onChange={(e) => {
                            setFieldValue("companyId", e)
                            setDefaultCompany(e)
                          }}
                          onBlur={handleBlur}
                          defaultMenuIsOpen={false}
                          value={defaultCompany}
                          options={companyList}
                        />

                        <div className="error-message">
                          {errors.companyId &&
                            touched.companyId &&
                            errors.companyId}
                        </div>
                      </div>
                    }
                  </fieldset>
                </Modal.Body>
                <Modal.Footer>
                  <div className="modal-footer-left text-left">
                    <button type="submit" className="btn btn-submit">
                      Invite
                    </button>
                    <button
                      type="button"
                      className="btn btn-fclose"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal>
      ) : null}
    </>
  )
}

export default InviteStudent

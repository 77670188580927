import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom"
import DateConfirm from "./DateConfirm"
import clientService from "../../services/service";
import { useAlert } from "react-alert";

var moment = require('moment-timezone');

const Resume = ({ onClose, course }) => {

    const alert = useAlert()

    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(new Date());
    const [highlightDates, setHighlightDates] = useState([]);
    const [dateConfirmModel, setDateConfirmModel] = useState(false)

    useEffect(() => {
        const pendingDats = startDate.getDate() + (((1 + 7 - startDate.getDay()) % 7) || 7);
        const dates = [];
        for (var i = startDate.getDate(); i <= pendingDats; i++) {
            dates.push(addDays(startDate, i - startDate.getDate()));
        }
        setHighlightDates(dates);
    }, [startDate])

    const addDays = (date, days) => {
        var dt = new Date(date);
        dt.setDate(dt.getDate() + days);
        return dt;
    }

    const saveData = async () => {
        const currentDate = new Date();
        const postData = {
            "courseId": course?.data?.id,
            "startDate": currentDate,
            isResume: true
        }
        try {
            const res = await clientService.post("StudentCourse/EnrollToCourse", postData);
            if (res.code === 200 && res.status) {
                alert.success(res.message);
                onClose(true)
            }
        } catch (e) {

        }
    }

    return (
        <>
            <Modal
                show={true}
                className="modal-dark sap"
                aria-labelledby="create_a_lesson"
                size="lg"
                id="text_lesson_preview"
                centered
            >
                <Modal.Header
                    onHide={onClose}
                    closeButton={true}
                    closeVariant="white"
                    className="modal-header"
                >
                    <Modal.Title className="modal-title">
                        Are you sure want to resume your course ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="white_color">
                        Your course remaining lessons start date will be adjust and calulate based on today's date.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer-left text-left">
                        <Link
                            className="main_btn_default"
                            data-bs-toggle="modal"
                            role="button"
                            to=""
                            onClick={saveData}
                        >
                            Yes
                        </Link>
                        <button
                            onClick={onClose}
                            className="btn btn-fclose"
                            data-bs-target="new_courses"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Resume

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import clientService from "../../../services/service";

const RandomiserVideoReportModel = ({ onClose, submittedId }) => {
  const dispatch = useDispatch();
  const [report, setReport] = useState({});

  useEffect(() => {
    getFeedbackReport();
  }, [submittedId]);

  const getFeedbackReport = async () => {
    const res = await clientService.get(
      `StudentRandomizer/GetScenarioFeedback?submittedId=${submittedId}`
    );
    setReport(res.data);
  };

  return (
    <>
      <Modal
        show={true}
        className="modal-dark remove-profile sap evaluation-session-popup video_report_popup"
        aria-labelledby="evaluation_session"
        size="lg"
        id="evaluation_session"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Video Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video_report_details">
            <p className="score_total mb-2">
              Total Score: <b className="green-text">{report?.score}</b> / max
              15
            </p>
            <div className="verbals">
              <p>
                Nonverbals:{" "}
                <b className="green-text">
                  {report?.isNonVerbalNA ? "NA" : report?.nonVerbalsScore}
                </b>
              </p>
              <div className="progress_bar_verbals">
                <div className="progress">
                  {[0, 1, 2, 3, 4].map((item) => {
                    return (
                      <div
                        className={
                          item < report?.nonVerbalsScore
                            ? "progress-bar active"
                            : "progress-bar"
                        }
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    );
                  })}
                </div>
                <ul className="process_number">
                  {[0, 1, 2, 3, 4, 5].map((item) => {
                    return (
                      <li
                        className={
                          item == report?.nonVerbalsScore
                            ? "active fixed_trag"
                            : item < report?.nonVerbalsScore
                            ? "active"
                            : ""
                        }
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <ul className="labels">
                  {report?.nonVerbalsScoreTags &&
                  report?.nonVerbalsScoreTags.length > 0
                    ? report?.nonVerbalsScoreTags.map((tag, index) => {
                        return (
                          <li
                            className={
                              tag?.color == 0
                                ? "green"
                                : tag?.color == 1
                                ? "red"
                                : "blue"
                            }
                          >
                            {tag?.tagName}
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
            <div className="verbals mt-2">
              <p>
                Verbals: <b className="green-text">
                  {report?.isVerbalNA ? "NA" : report?.verbalsScore}</b>
              </p>
              <div className="progress_bar_verbals">
                <div className="progress">
                  {[0, 1, 2, 3, 4].map((item) => {
                    return (
                      <div
                        className={
                          item < report?.verbalsScore
                            ? "progress-bar active"
                            : "progress-bar"
                        }
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    );
                  })}
                </div>
                <ul className="process_number">
                  {[0, 1, 2, 3, 4, 5].map((item) => {
                    return (
                      <li
                        className={
                          item == report?.verbalsScore
                            ? "active fixed_trag"
                            : item < report?.verbalsScore
                            ? "active"
                            : ""
                        }
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <ul className="labels">
                  {report?.verbalsScoreTags &&
                  report?.verbalsScoreTags.length > 0
                    ? report?.verbalsScoreTags.map((tag, index) => {
                        return (
                          <li
                            className={
                              tag?.color == 0
                                ? "green"
                                : tag?.color == 1
                                ? "red"
                                : "blue"
                            }
                          >
                            {tag?.tagName}
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
            <div className="verbals mt-2">
              <p>
                Paraverbals:{" "}
                <b className="green-text">{report?.isPeraVerbalNA ? "NA" : report?.peraVerbalsScore}</b>
              </p>
              <div className="progress_bar_verbals">
                <div className="progress">
                  {[0, 1, 2, 3, 4].map((item) => {
                    return (
                      <div
                        className={
                          item < report?.peraVerbalsScore
                            ? "progress-bar active"
                            : "progress-bar"
                        }
                        role="progressbar"
                        style={{ width: "20%" }}
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    );
                  })}
                </div>
                <ul className="process_number">
                  {[0, 1, 2, 3, 4, 5].map((item) => {
                    return (
                      <li
                        className={
                          item == report?.peraVerbalsScore
                            ? "active fixed_trag"
                            : item < report?.peraVerbalsScore
                            ? "active"
                            : ""
                        }
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <ul className="labels">
                  {report?.peraVerbalsTags && report?.peraVerbalsTags.length > 0
                    ? report?.peraVerbalsTags.map((tag, index) => {
                        return (
                          <li
                            className={
                              tag?.color == 0
                                ? "green"
                                : tag?.color == 1
                                ? "red"
                                : "blue"
                            }
                          >
                            {tag?.tagName}
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
            <p className="mt-4 mb-0">
              <a
                className="border_btn_default"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                Close
              </a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RandomiserVideoReportModel;

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import clientService from "../../services/service"
import { appState } from "../../store/slices/appSlice"
import CustomIcons from "../../assets/CustomIcons"

const TrainerCousrseList = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const { loading } = useSelector(appState)
  const [courseList, setCourseList] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    getCourseList()

  }, [pageNumber])

  useEffect(() => {
    if (search === "") {
      getCourseList()
    }
  }, [search])

  const getCourseList = async () => {
    if (loading) {
      return
    }
    const requestParams = {
      search: search,
      pageNo: pageNumber,
      pageSize: 100,
    }
    const res = await clientService.post(
      "TrainerCourse/GetAllTrainerCourses",
      requestParams
    )
    setCourseList(res)
  }

  return (
    <div className="aside-content-wrapper">
      <div className="container-fluid">
        <div className="overview-area mb-5">
          <div className="row">
            <div className="col-12">
              <div className="aside-title-area">
                <h2 className="title title-lg">Courses</h2>
              </div>
              <div className="searchbar_main form_section">
                <div className="search_bar">
                  <fieldset>
                    <input
                      className="form-control cform-control text-white"
                      type="text"
                      name="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                      onKeyUp={(e) => {
                        if (e.key == "Enter") {
                          getCourseList()
                        }
                      }}
                    />
                    <button>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: CustomIcons.search,
                        }}
                      ></span>
                    </button>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {courseList?.data?.trainerCourseList.length > 0 ? (
              courseList?.data?.trainerCourseList.map((course, index) => {
                return (
                  <>
                    <div className="col-xl-6 col-12" key={`trainer_course${index}`}>
                      <Link
                        className="text-decoration_none"
                        to={`/trainer/course/detail/${course?.courseId}`}
                      >
                        <div className="courses_blog small">
                          {/* <div className=""> */}
                            <img
                            className="course_image"
                              src={course?.courseImage}
                              height="116"
                              alt=""
                            />
                          {/* </div> */}
                          {/* <div className="course_content"> */}
                            <div className="left_conent desc">
                              <h3>{course?.courseName}</h3>
                              <p>{course?.noOfVideo} new videos</p>
                              <p>{course?.companyName}</p>
                            </div>
                          {/* </div> */}
                        </div>
                      </Link>
                    </div>
                  </>
                )
              })
            ) : !loading ? (
              <div className="row mt-5">
                <div className="col-md-12 mt-5">
                  <div className="full text-center">
                    <img src="/img/unlock_vendor.png" alt="#" />
                  </div>
                  <div className="full text-center">
                    <p className="light_text mb-0 mt-5">
                      There are no courses yet.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainerCousrseList

import { useEffect, useState } from "react";
import clientService from "../../../services/service";

const TextQuestion = ({ lessonId }) => {

    const [lessonInfo, setLessonInfo] = useState({});
    useEffect(() => {
        getCouseLession();
    }, [lessonId])
    const getCouseLession = async () => {
        const res = await clientService.get(`StudentCourse/GetLessionContent?courseGuideLessonId=${lessonId}`)
        setLessonInfo(res.data);
    }
   
    return (
        <div className="modal-body">
            <div className="resources_Content">
                <div dangerouslySetInnerHTML={{ __html : lessonInfo.description}}></div>
            </div>
        </div>
    )

}
export default TextQuestion;
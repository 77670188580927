import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { appState } from "../../store/slices/appSlice"
import {
  getReviewerCourses,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice"
import RandomiserOverViewEmpty from "./RandomiserOverViewEmpty"

const RandomiserOverView = () => {
  const dispatch = useDispatch()
  const { reviewerCourses } = useSelector(reviewerCourseState)
  const { loading } = useSelector(appState)

  useEffect(() => {
    var data = {
      pageNo: 1,
      pageSize: 100,
    }
    dispatch(getReviewerCourses(data))
  }, [dispatch])

  return (
    <>
      {reviewerCourses?.reviewerCourseList?.length > 0 ? (
        <div className="container-fluid">
          <div className="overview-area mb-5">
            <div className="row">
              <div className="col-12">
                <div className="aside-title-area">
                  <h2 className="title title-lg">Courses</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {reviewerCourses?.reviewerCourseList?.length > 0
                ? reviewerCourses.reviewerCourseList.map((course, index) => {
                    return (
                      <>
                        <div className="col-lg-6">
                          <Link
                            className="text-decoration_none"
                            to={`/reviewer/randomiser/detail/${course?.courseId}`}
                          >
                            <div className="courses_blog small">
                              <div className="course_image">
                                <img
                                  src={course?.courseImage}
                                  height="116"
                                  alt=""
                                />
                              </div>
                              <div className="course_content">
                                <div className="left_conent">
                                  <h3>{course?.courseName}</h3>
                                  <p>{course?.noOfVideo}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    )
                  })
                : null}
            </div>
          </div>
        </div>
      ) : !loading ? (
        <RandomiserOverViewEmpty />
      ) : null}
    </>
  )
}
export default RandomiserOverView

const UserAvtar = ({ image, name }) => {
  const U = name?.split(" ")
  return (
    <div>
      <div className="user_avtar mb-2">
        {image ? (
          <img src={image} className="user-av" />
        ) : (
          <span className="up">
            {U && U[0] && U[0][0] ? U[0][0] : ""}
            {U && U[1] && U[1][0]  ? U[1][0] : ""} 
          </span>
        )}
      </div>
    </div>
  )
}

export default UserAvtar

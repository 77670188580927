import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"

import store from "./store/store"
import { Provider } from "react-redux"
import { Provider as AlertProvider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import App from "./App"
import Loader from "./common/Loader"
const alertOptions = {
  timeout: 8000,
  position: "top right",
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
      <Loader />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import Select from "react-select"
import CustomIcons from "../../../assets/CustomIcons"
import clientService from "../../../services/service"
import chroma from "chroma-js"
import { Modal } from "react-bootstrap"
import VideoPlayer from "../../../common/VideoPlayer"
import UserAvtar from "../../../common/UserAvtar"
import VideoRecordModal from "../../models/VideoRecordModal"
import { useDispatch } from "react-redux"
import { setLoader } from "../../../store/slices/appSlice"
import uploadFileToBlob from "../../../components/azure-storage-blob"
import { FileUploader } from "react-drag-drop-files"
import VideoTypes from "../../../consts/VideoTypes"
import DeleteQuestion from "../../models/DeleteQuestion"
import Prompt from "../../models/Prompt"

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
      ":active": {
        ...styles[":active"],
        backgroundColor: color.alpha(0.7).css(),
      },
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
  multiValueLabel: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
  multiValueRemove: (styles, { data }) => {
    const color = chroma(
      data.color == 0 ? "#61b487" : data.color == 1 ? "#e36868" : "#1B8EB3"
    )
    return {
      ...styles,
      backgroundColor: color.alpha(0.7).css(),
    }
  },
}

let timeInt = null;
let media_recorder = null
let camera_stream = null
let blobs_recorded = []

const VideoFeedbackDetail = () => {
  const { guideLessonId, courseId, type } = useParams()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [videoDetailResp, setVideoDetailResp] = useState([])
  const [tagResp, setTagResp] = useState({})
  const [selectedTags, setSelectedTags] = useState(null)
  const [nextPreData, setNextPreData] = useState({})
  const [nextPre, setNextPre] = useState("next")
  const [comment, setComment] = useState("")
  const alert = useAlert()
  const navigate = useNavigate()
  const [isRedo, setIsRedo] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedTime, setSelectedTime] = useState({
    // text: "Choose correct item",
    value: "",
  })
  const [errorMessage, setErrorMessage] = useState("")

  const dispatch = useDispatch()
  const videoRef = React.useRef(null)
  const [enableVideoRecording, setEnableVideoRecording] = useState(false)
  const [enableVideoUploading, setEnableVideoUploading] = useState(false)
  const [showTestModal, setShowTestModal] = useState(false)
  const [audioInput, setAudioInput] = useState(null)
  const [vidioInput, setVideoInput] = useState(null)
  const [recordingStatus, setRecordingStatus] = useState("idel")
  const [cameraStream, setCameraStream] = useState(null)
  const [localLink, setLocalLink] = useState(null)
  const [videoBlob, setVideoBlob] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const [showCancelModel, setShowCancelModel] = useState(false)
  const [currentTab, setCurrentTab] = useState("my_video")
  const [seconds, setSeconds] = useState(0);
  const [videoReply, setVideoReply] = useState("")
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [isVideoRecorded, setIsVideoRecorded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPromptModel, setShowPromptModel] = useState(false)


  const timeDuration = [
    // { text: "Choose correct item", value: "" },
    { text: "15 Minutes", value: 15 },
    { text: "30 Minutes", value: 30 },
    { text: "45 Minutes", value: 45 },
    { text: "1 Hour", value: 60 },
    { text: "NA", value: 0 },
  ]

  const startTimer = () => {
    if (timeInt) {
      clearInterval(timeInt)
    }
    timeInt = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
  }

  const stopTimer = () => {
    if (timeInt) {
      clearInterval(timeInt)
    }
  }

  const onCloseModel = (e) => {
    if (e && e.audioInput && e.videoInput) {
      setAudioInput(e.audioInput)
      setVideoInput(e.videoInput)
      setEnableVideoRecording(true)
    }
    setShowTestModal(false)
  }

  useEffect(() => {
    if (audioInput && vidioInput && enableVideoRecording) {
      initRecording()
    }
  }, [audioInput, vidioInput, enableVideoRecording])

  const initRecording = async () => {
    setRecordingStatus("idel")
    camera_stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: {
          exact: vidioInput.deviceId,
        },
      },
      audio: {
        deviceId: {
          exact: audioInput.deviceId,
        },
      },
    })
    setCameraStream(camera_stream)
    videoRef.current.srcObject = camera_stream
  }

  const startRecording = async () => {
    setRecordingStatus("start")
    startTimer();
    media_recorder = new MediaRecorder(camera_stream, {
      mimeType: "video/webm",
    })
    media_recorder.addEventListener("dataavailable", (e) => {
      blobs_recorded.push(e.data);
    })
    media_recorder.addEventListener("stop", () => {
      let video_local = URL.createObjectURL(
        new Blob(blobs_recorded, { type: "video/webm" })
      )
      setLocalLink(video_local)
      const blob = new Blob(blobs_recorded, { type: "video/mp4" })
      blob.name = "student_video_" + guideLessonId + ".mp4"
      setVideoBlob(blob)
    })
    media_recorder.start(1000)
  }

  const stopRecording = () => {
    setLocalLink("")
    setRecordingStatus("stop");
    stopTimer();
    media_recorder.stop()
  }

  useEffect(() => {
    getVideoDetail()
    getTags()
  }, [guideLessonId])

  const getVideoDetail = async () => {
    try {
      const res = await clientService.get(
        `TrainerCourse/GetVideoDetail?StudentCourseGuideLessonId=${guideLessonId}`
      )
      setVideoDetailResp(res?.data)
    } catch (error) {
      alert.error(error)
    }
  }

  const nextPrevious = async () => {
    var data = {
      studentCourseGuideLessonId: guideLessonId,
      isReviewed: type === "reviewed" ? true : false,
      isNext: nextPre === "next" ? true : false,
    }
    const res = await clientService.post(
      "TrainerCourse/GetNextOrPreviousVideoDetail",
      data
    )
    setNextPreData(res)
    if (res?.status === true && res?.code === 200) {
      {
        type === "reviewed"
          ? navigate(
            `/trainer/course/video-feedback/deatil/${res?.data?.courseId}/${res?.data?.studentCourseGuideLessonId}/reviewed`
          )
          : navigate(
            `/trainer/course/video-feedback/deatil/${res?.data?.courseId}/${res?.data?.studentCourseGuideLessonId}`
          )
      }
    }
  }

  useEffect(() => {
    if (videoDetailResp) {
      setComment(videoDetailResp?.comments)
      setSelectedTags(videoDetailResp?.tags)
    } else {
      setComment("")
      setSelectedTags({})
    }
  }, [videoDetailResp])

  const handleFormSubmit = async () => {
    const tags = []
    for (var i = 0; i < selectedTags?.length; i++) {
      tags.push({ tagId: selectedTags[i]?.tagId })
    }
    var data = {
      reviewTag: tags,
      comment: comment,
      studentCourseGuideLessonId: guideLessonId,
      minutes: selectedTime?.value,
      videoReply: videoReply
    }
    const res = await clientService.post("TrainerCourse/AddVideoFeedback", data)
    if (res.code === 200) {
      alert.success(res.message)
    }
    navigate(`/trainer/course/video-feedback/${courseId}`)
  }

  const handleChange = (res) => {
    const localUrl = URL.createObjectURL(res[0])
    setSelectedFile(res[0])
    setLocalLink(localUrl)
  }

  const handleRedoSubmit = async () => {
    const tags = []
    for (var i = 0; i < selectedTags?.length; i++) {
      tags.push({ tagId: selectedTags[i]?.tagId })
    }
    var data = {
      reviewTag: tags,
      comment: comment,
      studentCourseGuideLessonId: guideLessonId,
      redo: true,
      videoReply: videoReply
    }
    const res = await clientService.post("TrainerCourse/AddVideoFeedback", data)
    if (res.code === 200) {
      alert.success(res.message)
    }
    navigate(`/trainer/course/video-feedback/${courseId}`)
  }

  const getTags = async () => {
    const res = await clientService.get(`TrainerCourse/GetVideoReviewTags`)
    setTagResp(res?.data)
  }

  const onClose = () => {
    setShowModal(false)
    setIsRedo(null)
  }

  const getScondsToTime = () => {
    if (seconds >= 60) {
      const min = Math.floor(seconds / 60);
      const sec = seconds % 60;
      var str = "";

      if (min < 10) {
        str = `0${min}`;
      } else {
        str = `${min}`;
      }
      if (sec < 10) {
        return `${str}:0${sec}`;
      } else {
        return `${str}:${sec}`;
      }
    } else if (seconds < 10) {
      return `00:0${seconds}`;
    } else {
      return `00:${seconds}`;
    }
  }

  const sendToReview = async () => {
    dispatch(setLoader(true))
    var res = null
    if (enableVideoUploading) {
      res = await uploadFileToBlob(selectedFile, "trainervideoresponse", guideLessonId)
    } else {
      res = await uploadFileToBlob(videoBlob, "trainervideoresponse", guideLessonId)
    }
    setIsVideoUploaded(true)
    setIsVideoRecorded(true)
    alert.success("Video was Successfully Recorded.")
    if (res) {
      setVideoReply(res)
    }
    dispatch(setLoader(false))
  }

  useEffect(() => {
    setEnableVideoUploading(false);
    setEnableVideoRecording(false);
    setRecordingStatus("idel");
    setAudioInput(null);
    setVideoInput(null);
    setLocalLink(null);
    setVideoBlob(null);
    setSelectedFile(null);
    setCurrentTab("my_video")
  }, [guideLessonId])

  const handleDelete = async (e) => {
    if (e === true) {
      setLocalLink("")
      setEnableVideoUploading(false)
      setEnableVideoRecording(false)
      setIsVideoUploaded(false);
      setIsVideoRecorded(false)
      setVideoReply("")
    }
    setShowDeleteModel(false)
  }

  const handleCancel = async (e) => {
    if (e === true) {
      if (media_recorder) {
        media_recorder.stop()
      }
      setLocalLink("")
      stopTimer();
      setEnableVideoUploading(false)
      setEnableVideoRecording(false)
      setSeconds(0);
      blobs_recorded = [];
      setRecordingStatus("idel")
    }
    setShowCancelModel(false)
  }

  const closeModel = () => {
    setShowPromptModel(false)
  }

  return (
    <>
      <div className="aside-content-wrapper white_body with_footer">
        <div className="container-fluid">
          <div className="model_content_fit_screen">
            <div className="modal-content">
              <div className="tabbar_head">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="pill"
                      to={`/trainer/course/video-feedback/${courseId}`}
                    >
                      To review
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="pill"
                      to={`/trainer/course/video-feedback/${courseId}/reviewed`}
                    >
                      Reviewed
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div id="to_review" className="tab-pane active">
                  <div className="resources_Content mt-4">
                    <div className="learning_video_section full_section">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center justify-content-between">
                            <h3 className="backtopage">
                              <Link
                                to={
                                  videoDetailResp?.isReviewed
                                    ? `/trainer/course/video-feedback/${courseId}/reviewed`
                                    : `/trainer/course/video-feedback/${courseId}`
                                }
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: CustomIcons.back,
                                  }}
                                ></span>
                                Back to All Videos
                              </Link>
                            </h3>
                            {videoDetailResp?.promptText &&
                              < Link
                                to=""
                                className="btnprompt"
                                data-bs-toggle="modal"
                                onClick={() => {
                                  setShowPromptModel(true)
                                }}
                                role="button"
                              >
                                <img src="/img/icons/test-yourself.png" />
                              </Link>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="single_video_feedback single_video_reviewed">
                            <div className="video_blog">
                              <div className="video_section">
                                <VideoPlayer
                                  className="img-fluid width-100 height-100"
                                  videoId={videoDetailResp?.vimeoLink}
                                  isVideoAvailable={
                                    videoDetailResp?.isVideoAvailable
                                  }
                                />
                              </div>
                              <div className="training_video_info d-flex align-items-center">
                                <div className="video_upload_info">
                                  <UserAvtar
                                    name={videoDetailResp?.userName}
                                    image={videoDetailResp?.profileImage}
                                  />
                                  <div className="video_info">
                                    <h3>{videoDetailResp?.userName}</h3>
                                    <p>{videoDetailResp?.days} days ago</p>
                                  </div>
                                </div>
                                <div>
                                  {videoDetailResp?.isSequence &&
                                    <img src="/img/sequence1.png" height={25} width={25} />
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="video_details_form form_section mt-4">
                              <form>
                                <fieldset>
                                  <div className="form-group mb-4 pb-1">

                                    <div className="resources_Content">
                                      {videoDetailResp?.trainerVideoResponse?.isVideoAvailable ?
                                        <>
                                          <label className="control-label col-xs-3 mb-2 mt-3">
                                            Video Feedback By Trainer
                                          </label>
                                          <VideoPlayer
                                            className="img-fluid width-100 height-100"
                                            videoId={videoDetailResp?.trainerVideoResponse?.vimeoLink}
                                            isVideoAvailable={videoDetailResp?.trainerVideoResponse?.isVideoAvailable}
                                          />
                                        </>
                                        :
                                        videoDetailResp?.isReviewed ?
                                          <>
                                            <VideoPlayer
                                              className="img-fluid width-100 height-100"
                                              videoId={videoDetailResp?.trainerVideoResponse?.vimeoLink}
                                              isVideoAvailable={videoDetailResp?.trainerVideoResponse?.isVideoAvailable}
                                            />
                                          </> :
                                          <>
                                            <label className="control-label col-xs-3 mb-2 mt-3 required_field">
                                              Upload/Record video to respond this student's video
                                            </label>
                                            {!enableVideoRecording && !enableVideoUploading ? (
                                              <div className="row mb-4">
                                                <div className="col-lg-6">
                                                  <div
                                                    onClick={() => { setShowTestModal(true) }}
                                                    className="video_upload_section record_video">
                                                    <p className="text-white">
                                                      <svg
                                                        width="16"
                                                        height="12"
                                                        viewBox="0 0 16 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          clipRule="evenodd"
                                                          d="M1.33333 2.66602C1.33333 2.29783 1.63181 1.99935 2 1.99935H9.33333C9.70152 1.99935 10 2.29783 10 2.66602V5.99935V9.33268C10 9.70087 9.70152 9.99935 9.33333 9.99935H2C1.63181 9.99935 1.33333 9.70087 1.33333 9.33268V2.66602ZM11.3333 4.70389V2.66602C11.3333 1.56145 10.4379 0.666016 9.33333 0.666016H2C0.895431 0.666016 0 1.56145 0 2.66602V9.33268C0 10.4373 0.895431 11.3327 2 11.3327H9.33333C10.4379 11.3327 11.3333 10.4373 11.3333 9.33268V7.29481L14.9458 9.87517C15.1491 10.0203 15.4163 10.0397 15.6384 9.92546C15.8604 9.81119 16 9.58241 16 9.33268V2.66602C16 2.41629 15.8604 2.18751 15.6384 2.07324C15.4163 1.95897 15.1491 1.97838 14.9458 2.12353L11.3333 4.70389ZM14.6667 8.03723L11.8136 5.99935L14.6667 3.96148V8.03723Z"
                                                          fill="#E4E5EA"
                                                        ></path>
                                                      </svg>
                                                      Record video
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div
                                                    onClick={() => { setEnableVideoUploading(true) }}
                                                    className="video_upload_section record_video"
                                                  >
                                                    <p className="text-white">
                                                      <svg
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          clipRule="evenodd"
                                                          d="M7.00065 0.333984C7.36884 0.333984 7.66732 0.611141 7.66732 0.953032V7.39118L9.86258 5.19591C10.1229 4.93556 10.545 4.93556 10.8054 5.19591C11.0657 5.45626 11.0657 5.87837 10.8054 6.13872L7.47206 9.47206C7.21171 9.73241 6.7896 9.73241 6.52925 9.47206L3.19591 6.13872C2.93556 5.87837 2.93556 5.45626 3.19591 5.19591C3.45626 4.93556 3.87837 4.93556 4.13872 5.19591L6.33398 7.39118V0.953032C6.33398 0.611141 6.63246 0.333984 7.00065 0.333984ZM1.66732 9.00065C1.66732 8.63246 1.36884 8.33398 1.00065 8.33398C0.632461 8.33398 0.333984 8.63246 0.333984 9.00065V11.6673C0.333984 12.1978 0.544698 12.7065 0.919771 13.0815C1.29484 13.4566 1.80355 13.6673 2.33398 13.6673H11.6673C12.1978 13.6673 12.7065 13.4566 13.0815 13.0815C13.4566 12.7065 13.6673 12.1978 13.6673 11.6673V9.00065C13.6673 8.63246 13.3688 8.33398 13.0007 8.33398C12.6325 8.33398 12.334 8.63246 12.334 9.00065V11.6673C12.334 11.8441 12.2637 12.0137 12.1387 12.1387C12.0137 12.2637 11.8441 12.334 11.6673 12.334H2.33398C2.15717 12.334 1.9876 12.2637 1.86258 12.1387C1.73756 12.0137 1.66732 11.8441 1.66732 11.6673V9.00065Z"
                                                          fill="#E4E5EA"
                                                        ></path>
                                                      </svg>
                                                      Upload video
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                      }

                                      {enableVideoRecording ? (
                                        <>
                                          <div>Video Record</div>
                                          {localLink && recordingStatus === "stop" ? (
                                            <>
                                              {" "}
                                              <video
                                                webkit-playsinline="true"
                                                playsinline="true"
                                                src={localLink}
                                                muted={true}
                                                className="img-fluid"
                                                controls
                                              />
                                              {!isVideoRecorded &&
                                                <button
                                                  type="button"
                                                  className="btn btn-submit mr-16 dark-purple"
                                                  onClick={sendToReview}
                                                >
                                                  Upload
                                                </button>
                                              }
                                              {/* <button
                                                type="button"
                                                className="btn btn-fclose btn-puple-outline"
                                                onClick={() => {
                                                  setLocalLink("")
                                                  setEnableVideoUploading(false)
                                                  setEnableVideoRecording(false)
                                                  setRecordingStatus("idel")
                                                  setSeconds(0);
                                                  blobs_recorded = []
                                                }}
                                              >
                                                Retry
                                              </button> */}
                                              <button
                                                type="button"
                                                className="btn btn- float-right del-button"
                                                onClick={() => {
                                                  setShowDeleteModel(true)
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: CustomIcons.delete,
                                                }}
                                              ></button>
                                              {formSubmitted && !isVideoUploaded &&
                                                <div className="error-message">
                                                  Please upload video
                                                </div>
                                              }
                                            </>
                                          ) : (
                                            <div className="video-container-new">
                                              <div className="video postion-relative">
                                                <video
                                                  style={{ width: "100%" }}
                                                  ref={videoRef}
                                                  autoPlay
                                                  muted={true}
                                                  className="image-fluid video-preview"
                                                  webkit-playsinline="true"
                                                  playsinline="true"
                                                ></video>
                                                <div className="video-timer">{getScondsToTime()}</div>
                                              </div>

                                              {recordingStatus !== "stop" ? (
                                                <>
                                                  {recordingStatus === "idel" ? (
                                                    <button
                                                      className="btn btn-submit dark-purple"
                                                      type="button"
                                                      onClick={startRecording}
                                                    >
                                                      Start Recording
                                                    </button>
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="btn btn-submit dark-purple"
                                                      onClick={stopRecording}
                                                    >
                                                      Stop Recording
                                                    </button>
                                                  )}

                                                  <button
                                                    type="button"
                                                    className="btn btn-fclose del-button ml-15"
                                                    onClick={() => {
                                                      setShowCancelModel(true)
                                                    }}
                                                  >
                                                    CANCEL
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn btn-submit mr-16 dark-purple"
                                                    onClick={stopRecording}
                                                  >
                                                    Send to review
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-fclose btn-puple-outline"
                                                    onClick={stopRecording}
                                                  >
                                                    Retry
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-fclose float-right del-button"
                                                    onClick={() => {
                                                      setShowDeleteModel(true)
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html: CustomIcons.delete,
                                                    }}
                                                  ></button>
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </>
                                      ) : null}

                                      {enableVideoUploading ? (
                                        <>
                                          {localLink ? (
                                            <>
                                              {" "}
                                              <div>
                                                <video
                                                  webkit-playsinline="true"
                                                  playsinline="true"
                                                  src={localLink}
                                                  className="img-fluid"
                                                  controls
                                                />
                                              </div>
                                              {!isVideoUploaded &&
                                                <button
                                                  type="button"
                                                  className="btn btn-submit mr-16 dark-purple"
                                                  onClick={sendToReview}
                                                >
                                                  Upload
                                                </button>
                                              }
                                              {/* <button
                                                type="button"
                                                className="btn btn-fclose btn-puple-outline"
                                                onClick={() => {
                                                  setLocalLink("")
                                                  setEnableVideoUploading(false)
                                                  setEnableVideoRecording(false)
                                                }}
                                              >
                                                Retry
                                              </button> */}
                                              <button
                                                type="button"
                                                className="btn btn-fclose float-right del-button"
                                                onClick={() => {
                                                  setShowDeleteModel(true)
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: CustomIcons.delete,
                                                }}
                                              ></button>
                                              {formSubmitted && !isVideoUploaded &&
                                                <div className="error-message">
                                                  Please upload video
                                                </div>
                                              }
                                            </>
                                          ) : (
                                            <>
                                              <div className="row mt-3">
                                                <div className="col-12">Video Upload</div>
                                              </div>
                                              <div className="row">
                                                <FileUploader
                                                  multiple={true}
                                                  handleChange={handleChange}
                                                  name="file"
                                                  types={VideoTypes}
                                                  onTypeError={() => {
                                                    alert.success("File not support")
                                                  }}
                                                  onSizeError={() => {
                                                    alert.error(
                                                      "You Can't Upload File with More than 500MB Size"
                                                    )
                                                  }}
                                                >
                                                  <div className="file_upload_section">
                                                    <div className="file_upload_inner text-center">
                                                      <p>
                                                        <svg
                                                          width="27"
                                                          height="27"
                                                          viewBox="0 0 27 27"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M2.59123 4.02105C2.59123 3.23111 3.2316 2.59075 4.02154 2.59075H6.2276V6.22711H2.59123V4.02105ZM2.59123 8.65135H6.2276V12.2877H2.59123V8.65135ZM0.166992 4.02105V7.43923V13.4998V19.5604V22.9786C0.166992 25.1074 1.89273 26.8332 4.02154 26.8332H7.43972H19.5609H22.9791C25.1079 26.8332 26.8337 25.1074 26.8337 22.9786V19.5604V13.4998V7.43923V4.02105C26.8337 1.89224 25.1079 0.166504 22.9791 0.166504H19.5609H7.43972H4.02154C1.89273 0.166504 0.166992 1.89224 0.166992 4.02105ZM24.4094 6.22711V4.02105C24.4094 3.23112 23.7691 2.59075 22.9791 2.59075H20.7731V6.22711H24.4094ZM20.7731 8.65135H24.4094V12.2877H20.7731V8.65135ZM18.3488 7.43923V2.59075H8.65184V7.43923V12.2877H18.3488V7.43923ZM24.4094 14.712V18.3483H20.7731V14.712H24.4094ZM24.4094 20.7726H20.7731V24.4089H22.9791C23.769 24.4089 24.4094 23.7686 24.4094 22.9786V20.7726ZM18.3488 14.712V19.5604V24.4089H8.65184V19.5604V14.712H18.3488ZM2.59123 20.7726V22.9786C2.59123 23.7686 3.2316 24.4089 4.02154 24.4089H6.2276V20.7726H2.59123ZM6.2276 18.3483H2.59123V14.712H6.2276V18.3483Z"
                                                            fill="#ACAFBF"
                                                          />
                                                        </svg>
                                                      </p>
                                                      <p>
                                                        <b>
                                                          Drop video to attach or{" "}
                                                          <label>
                                                            <input
                                                              className="d-none"
                                                              type="file"
                                                              value=""
                                                            />
                                                            browse
                                                          </label>
                                                        </b>
                                                      </p>
                                                      <p>
                                                        Supported file type - MP4, MOV, AVI,
                                                        FLV, MKV, WMV, AVCHD, WEBM, H.264,
                                                        MPEG-4
                                                        <br />
                                                        Maximum size - 500MB,{" "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </FileUploader>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                    {!videoDetailResp?.isReviewed &&
                                      <div className="horizontal-line_or">
                                        <span className="line"></span>
                                        <span className="text">OR</span>
                                        <span className="line"></span>
                                      </div>
                                    }
                                    <label className={videoDetailResp?.isReviewed ? "control-label col-xs-3 mb-1 mt-3" : "control-label col-xs-3 mb-1 mt-3 required_field"}>
                                      {videoDetailResp?.isReviewed ?
                                        comment == null || comment == undefined || comment == "" ? "" : "Comment"
                                        : "Comment"}
                                    </label>
                                    <div className="col-xs-9">
                                      {!videoDetailResp?.isReviewed ?
                                        <>
                                          <textarea
                                            name="comment"
                                            value={comment}
                                            className="form-control cform-control mt-1"
                                            placeholder="Type comment to video"
                                            onChange={(e) => {
                                              setComment(e.target.value)
                                            }}
                                            readOnly={videoDetailResp?.isReviewed}
                                          ></textarea>
                                          {formSubmitted && (!comment && !videoReply) ? (
                                            <div className="error-message">
                                              Please enter comment
                                            </div>) : null}
                                        </>
                                        :
                                        <span>{comment}</span>
                                      }

                                      {videoDetailResp?.isReviewed ?
                                        <label className="control-label col-xs-3 mt-4 mb-1 ">
                                          Tag
                                        </label> :
                                        <label className="control-label col-xs-3 mt-4 mb-1 required_field">
                                          Add Tag
                                        </label>
                                      }
                                      {!videoDetailResp?.isReviewed ?
                                        <>
                                          <Select
                                            name="tag"
                                            isMulti
                                            isDisabled={videoDetailResp?.isReviewed}
                                            styles={colourStyles}
                                            value={selectedTags}
                                            className="form-control cform-control mt-1 reviewed-add-comment text-capitalize"
                                            placeholder="Add Tag"
                                            onChange={(e) => {
                                              setSelectedTags(e)
                                            }}
                                            getOptionLabel={(e) => {
                                              return e.tagName
                                            }}
                                            getOptionValue={(e) => {
                                              return e.tagId
                                            }}
                                            options={tagResp}
                                          />
                                          {formSubmitted &&
                                            selectedTags.length == 0 ? (
                                            <div className="error-message">
                                              Please select tag
                                            </div>
                                          ) : null}
                                        </>
                                        :
                                        <div className="video-upload-feedback">
                                          <div className="tag-container mt-2">
                                            {selectedTags?.map((tag) => {
                                              return (
                                                <div
                                                  className={tag?.color == 0 ? "video_green" : tag?.color == 1 ? "video_red" : "video_blue"}>
                                                  <span>{tag?.tagName}</span>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      }

                                      {showTestModal ? <VideoRecordModal onClose={onCloseModel} /> : null}
                                      {showDeleteModel ? (
                                        <DeleteQuestion
                                          onClose={handleDelete}
                                          title={"Delete This Video"}
                                          desciption="Are you sure you want to delete this video?"
                                        />
                                      ) : null}
                                      {showCancelModel ? (
                                        <DeleteQuestion
                                          onClose={handleCancel}
                                          title={"Cancelling This Recording"}
                                          desciption="Are you sure you want to cancel?<br /> 
                                                      The video will be not saved and you'll go back to the first step."
                                        />
                                      ) : null}

                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="dark_footer video-feedback-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="bottom_control_video">
                  <Link
                    className="video_btn_control prev"
                    to=""
                    onClick={() => {
                      setNextPre("previous")
                      nextPrevious()
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.previous }}
                    ></span>
                    Previous
                  </Link>
                  {videoDetailResp?.isReviewed ? null : (
                    <div>
                      <button
                        className="main_btn_default blue_color dark-purple"
                        type="submit"
                        onClick={() => {
                          setFormSubmitted(true)
                          const tags = []
                          if (!comment && !videoReply) {
                            return
                          }
                          for (var i = 0; i < selectedTags?.length; i++) {
                            tags.push({ tagId: selectedTags[i]?.tagId })
                          }
                          if (tags.length <= 0) {
                            return
                          }
                          setIsRedo(false)
                          setShowModal(true)
                        }}
                      >
                        Complete Review
                      </button>
                      <button
                        className="main_btn_default blue_color dark-purple ml-10"
                        type="submit"
                        onClick={() => {
                          setFormSubmitted(true)
                          setIsRedo(true)
                          const tags = []
                          if (!comment && !videoReply) {
                            return
                          }
                          for (var i = 0; i < selectedTags?.length; i++) {
                            tags.push({ tagId: selectedTags[i]?.tagId })
                          }
                          if (tags.length <= 0) {
                            return
                          }
                          handleRedoSubmit()
                        }}
                      >
                        Redo
                      </button>
                    </div>
                  )}

                  <Link
                    className="video_btn_control next"
                    to=""
                    onClick={() => {
                      setNextPre("next")
                      nextPrevious()
                    }}
                  >
                    Next
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.next }}
                    ></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div >

      {
        showModal ? <Modal
          show={true}
          className="modal-dark sap calendar_style_2"
          aria- labelledby="create_a_guide"
          size="md"
          id="text_lesson_preview"
          centered
        >
          <Modal.Header
            onHide={onClose}
            closeButton={true}
            closeVariant="white"
            className="modal-header"
          >
            <Modal.Title className="modal-title"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-light bold text-center">
              How long does it usually take you to train someone per session?
            </p>
            <Select
              name="sources"
              className="custom-select sources"
              placeholder=""
              getOptionLabel={(e) => {
                return e.text
              }}
              onChange={(e) => {
                setSelectedTime(e)
                setErrorMessage("")
              }}
              value={selectedTime}
              options={timeDuration}
            />
            <p className="text-danger">{errorMessage}</p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              onClick={async () => {
                if (isRedo) {
                  handleRedoSubmit()
                } else if (isRedo == false) {
                  handleFormSubmit()
                }

              }}
              className="btn btn-fclose btn_thankyou"
              data-bs-target="new_courses"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Thank You !
            </button>
          </Modal.Footer>
        </Modal >
          : null
      }

      {showPromptModel && <Prompt onClose={closeModel} videoDetailResp={videoDetailResp} />}
    </>
  )
}

export default VideoFeedbackDetail

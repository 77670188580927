import Header from "../components/Shared/Header"

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="main-wrapper d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col col-lg-12">
              <div className="sucess_msg_full forgot-main-wrapper mx-auto privacy-policy">
                <div className="forgot-caption">
                  <h1 className="title">PRIVACY POLICY</h1>
                </div>
                <div className="forgot-inner-wrapper">
                  <p className="mt-4 text-left">
                    <b>
                      This privacy policy for Combat Sales LLC ("Company," "we,"
                      "us," or "our"), describes how and why we might collect,
                      store, use, and/or share ("process") your information when
                      you use our services ("Services").{" "}
                    </b> <br />
                    <br />   <br />
                    <b>WHAT INFORMATION DO WE COLLECT?</b> <br />
                    <br />   <br />
                    <b>1.1 Personal information you disclose to us</b> <br />
                    <br /> 
                    We collect personal information that you voluntarily provide
                    to us when you register on the Services, express an interest
                    in obtaining information about us or our products and
                    Services, when you participate in activities on the
                    Services, or otherwise when you contact us. All personal
                    information that you provide to us must be true, complete,
                    and accurate, and you must notify us of any changes to such
                    personal information.
                    <br />    <br />
                    <b>1.2 Information automatically collected</b> <br />
                    <br />
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity but may include device and
                    usage information, such as your IP address, browser and
                    device characteristics, operating system, language
                    preferences, referring URLSs, device name, country,
                    location, information about how and when you use our
                    Services, and other technical information. This information
                    is primarily needed to maintain the security and operation
                    of our Services, and for our internal analytics and
                    reporting purposes. You may additionally submit personally
                    identifying information, such as your name, contact details,
                    and employment history, by filling out a web form, getting
                    in touch with us by email, or using another method.
                    <br />    <br />
                    <b>1.3 Cookies</b> <br />
                    <br />
                    As per business standards, we make use of a range of third-
                    party service providers to design, protect, enhance,
                    maintain, track, and advertise our website. Third parties
                    (like Google) may set cookies on your computer when you
                    visit our website in order to display you more relevant
                    advertisements. By changing your browser settings, you can
                    choose not to be tracked by cookies and advertisements.
                    <br />    <br />
                    <b>1.4 Content from other websites</b> <br />
                    <br />
                    Content in this site may include embedded content from other
                    websites. These websites may track your interaction with the
                    embedded content, gather information about you, utilise
                    cookies, embed additional third-party tracking, and monitor
                    your activity. This includes tracking your activity if you
                    have an account and are logged in to the website.
                    <br />    <br />
                    <b>How do we process your information?</b> <br />
                    <br />
                    We use your information to react to your questions,
                    communicate with you about our services, and/or decide
                    whether to invite you to take part in our service offerings.
                    This privacy statement governs the collection of the
                    information you provide and ensures its security. We also
                    process your information to provide, improve, and administer
                    our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your
                    information for other purposes with your consent
                    <br />    <br /> 
                    <b>How long do we keep your data?</b> <br />
                    <br />
                    Unless a longer retention time is required or permitted by
                    law, we will only keep your personal information for as long
                    as it is necessary for the reasons outlined in this privacy
                    notice (such as tax, accounting, or other legal
                    requirements). If we no longer need to process your personal
                    information for legitimate business purposes, we will either
                    delete it or make it anonymous. If this is not possible (for
                    instance, if your personal information is stored in backup
                    archives), we will securely store your personal information
                    and keep it separate from any other processing until
                    deletion is possible.
                    <br />    <br />
                    <b>Where is your data sent?</b> <br />
                    <br />
                    Your submissions might be subject to automated spam
                    screening, internal email distribution, and storage in one
                    or more cloud-based systems, some of whose servers might be
                    located outside of your country of origin.
                    <br />    <br />
                    <b>What are your privacy rights?</b> <br />
                    <br />
                    On your request to terminate your account, we shall
                    deactivate or remove your account and information from our
                    active databases. If you would want to review or amend the
                    information in your account at any time, you can. To stop
                    fraud, address issues, help with any investigations, enforce
                    our legal responsibilities, and/or comply with relevant
                    legal requirements, we might keep some information in our
                    records.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy

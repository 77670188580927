import moment from "moment"
import { useEffect } from "react"
import { Accordion } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import VideoPlayer from "../../common/VideoPlayer"
import {
  getSubmittedReviewScenarioDetail,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice"

const ReviewerVideo = () => {
  const { submittedId, courseId } = useParams()
  const { submittedReviewDetail } = useSelector(reviewerCourseState)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSubmittedReviewScenarioDetail({ SubmittedId: submittedId }))
  }, [dispatch])
  return (
    <>
      <div className="container-fluid">
        <div className="overview-area mb-5">
          <div className="row">
            <div className="col-12">
              <div className="aside-title-area">
                <h3 className="backtopage">
                  <Link to={`/reviewer/randomiser/detail/${courseId}/reviewed`}>
                    <span
                      dangerouslySetInnerHTML={{ __html: CustomIcons.back }}
                    ></span>
                    Back to home
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="recent-video-area single_video">
          <div className="row">
            <div className="col-lg-12 col-xl-10 offset-xl-1">
              <div className="video_content">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <Accordion className="faq-item-list">
                    <Accordion.Item className="accordion-item">
                      <Accordion.Header>
                        <h4>Scenario</h4>
                        <span
                        >
                          How to score the video?
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{submittedReviewDetail?.scenario}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-xl-10 offset-xl-1">
              <div className="video_blog mt-4">
                <div className="video_thumb">
                  <label className="video_time">
                    {submittedReviewDetail?.secondsPerQuestion}
                  </label>
                  <label className="video_time video_date">
                    {moment
                      .utc(submittedReviewDetail?.createdDate)
                      .local()
                      .format("MM/DD/YY")}
                  </label>
                  <Link className="play_button" to="">
                    <div className="play_button">
                      {/* <video className="img-fluid height-100" controls>
                        <source
                          src={submittedReviewDetail?.video}
                          type="video/mp4"
                        ></source>
                      </video> */}
                       <VideoPlayer
                                  className="img-fluid height-100"
                                  videoId={submittedReviewDetail?.vimeoLink}
                                  isVideoAvailable={submittedReviewDetail?.isVideoAvailable}

                                />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewerVideo

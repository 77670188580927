import React from "react"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import clientService from "../../services/service"
import { useAlert } from "react-alert"

const CalenderSort = ({ onClose }) => {
  const [startDate, setStartDate] = useState("")
  const [highlightDates, setHighlightDates] = useState([])
  const [endDate, setEndDate] = useState("")
  const alert = useAlert()

  const onChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate)
    setEndDate(endDate)
  };

  return (
    <>
      <Modal
        show={true}
        className="modal-dark sap calendar_style_2"
        aria-labelledby="create_a_guide"
        size="md"
        id="text_lesson_preview"
        centered
      >
        <Modal.Header
          onHide={onClose}
          closeButton={true}
          closeVariant="white"
          className="modal-header"
        >
          <Modal.Title className="modal-title">Calender Sort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calendar_section">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            {/* <DatePicker
              inline
              monthsShown={1}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              highlightDates={highlightDates}
              renderDayContents={""}
              dayClassName={""}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            onClick={
              () => {
                if (startDate != null && endDate != null) {
                  let _startDate = (new Date(startDate)).toISOString()
                  let _endDate = (new Date(endDate)).toISOString()
                  localStorage.setItem("startDate", _startDate)
                  localStorage.setItem("endDate", _endDate)
                  onClose()
                } else {
                  alert.error("Please Select Date Range")
                }

              }
            }
            className="btn btn-fclose"
            data-bs-target="new_courses"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            Submit Sort
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CalenderSort

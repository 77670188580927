import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import CustomIcons from "../../assets/CustomIcons"
import UserAvtar from "../../common/UserAvtar"
import VideoPlayer from "../../common/VideoPlayer"
import { getBlogUrl } from "../../consts/CommonFunction"
import { appState } from "../../store/slices/appSlice"
import {
  getSubmittedScenarios,
  reviewerCourseState,
} from "../../store/slices/reviewer/reviewerCourseSlice"
import VideoReportModel from "./VideoReportModel"

const Reviewed = () => {
  const [showViewReport, setShowViewReport] = useState(false)
  const [submittedId, setSubmittedId] = useState("")
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const { scenarios } = useSelector(reviewerCourseState)
  const { loading } = useSelector(appState)

  useEffect(() => {
    dispatch(getSubmittedScenarios({ courseId: courseId }))
  }, [dispatch])

  const closeModel = () => {
    setShowViewReport(false)
    setSubmittedId("")
  }
  return (
    <>
      <div className="mt-4">
        <div id="reviewed" className="container tab-pane">
          <div className="row">
            {scenarios?.videoIsReviewed &&
            scenarios?.videoIsReviewed?.length > 0 ? (
              scenarios?.videoIsReviewed.map((scene, index) => {
                return (
                  <>
                    {scene?.isReviewed == true ? (
                      <div className="col-12">
                        <div className="video_post_section d-block single_video">
                          <div className="video_blog">
                            <div className="">
                              <label className="video_time">
                                {scene?.secondsPerQuestion}
                              </label>
                              <label className="video_time video_date">
                                {moment(scene?.createdDate).format("MM/DD/YY")}
                              </label>
                              <div className="play_button">
                                <VideoPlayer
                                  className="img-fluid height-100"
                                  videoId={scene?.vimeoLink}
                                  isVideoAvailable={scene?.isVideoAvailable}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="video_post_info">
                            <div className="video_upload_info">
                              <UserAvtar
                                name={scene?.studentName}
                                image={scene?.userImage}
                              />
                              <div className="video_info offwhite_color">
                                <h3>{scene?.studentName}</h3>
                                <p>{scene?.days} days ago</p>
                              </div>
                            </div>
                            <div className="score_info">
                              <p className="light_color">
                                <b>Total Score:</b>{" "}
                                <b className="green-text">{scene?.score}</b>
                              </p>
                              <Link
                                className="report_btn"
                                data-bs-toggle="modal"
                                to=""
                                onClick={() => {
                                  setShowViewReport(true)
                                  setSubmittedId(scene?.submittedId)
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: CustomIcons.document,
                                  }}
                                ></span>{" "}
                                View report
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )
              })
            ) : !loading ? (
              <div className="col-md-12 minus_blank_space mt-5">
                <div className="full text-center mt-5">
                  <img src="/img/unlock_vendor.png" alt="" />
                </div>
                <div className="full text-center">
                  <p className="light_text mb-0 mt-5">
                    There is no data available yet.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showViewReport && (
        <VideoReportModel onClose={closeModel} submittedId={submittedId} />
      )}
    </>
  )
}
export default Reviewed

import React, { useEffect, useState } from 'react'
import clientService from '../../services/service'
import { Link } from 'react-router-dom'
import CustomIcons from '../../assets/CustomIcons'

const StudentQuestion = () => {

    const [unanswerQuestions, setUnanswerQuestions] = useState({})
    const [search, setSearch] = useState("")
    const [searchKeyWord, setSearchKeyWord] = useState("")

    const getUnanswerQuestions = async () => {
        const data = {
            search: search,
            pageNo: 1,
            pageSize: 100,
        }

        const res = await clientService.post(
            "TrainerDashboard/GetAllUnansweredQuestions",
            data
        )
        setUnanswerQuestions(res?.data)
    }

    useEffect(() => {
        getUnanswerQuestions()
    }, [search])

    return (
        <div className="aside-content-wrapper">
            <div className="container-fluid">
                <h4 className='text-light'>Student Questions ({unanswerQuestions?.notAnsweredCount})</h4>
                <div className="searchbar_main form_section mt-4 mb-4">
                    <div className="search_bar">
                        <fieldset>
                            <input
                                className="form-control cform-control text-white"
                                type="text"
                                value={search}
                                onChange={(e) => {

                                    setSearch(e.target.value)
                                }}
                                onKeyUp={(e) => {
                                    if (e.key == "Enter") {
                                        getUnanswerQuestions()
                                    }
                                }}
                                name="search"
                                placeholder="Search by name"
                            />
                            <button>
                                <span
                                    dangerouslySetInnerHTML={{ __html: CustomIcons.search }}
                                ></span>
                            </button>
                        </fieldset>
                    </div>
                </div>
                <div className="overview-area mb-5">
                    <div className="">
                        <div className="text-light mt-2">
                            {unanswerQuestions?.notAnsweredQuestions?.length > 0 ?
                                unanswerQuestions?.notAnsweredQuestions?.map((question) => {
                                    return (
                                        <>
                                            <div className="mt-4">
                                                <div className="question_blog_full">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <h6>
                                                                {question?.userName},{" "}
                                                                {question?.companyName}.{" "}
                                                                {question?.lessionName}{" "}
                                                            </h6>
                                                            <p className="p text-secondary">
                                                                {question?.discussion}
                                                            </p>
                                                        </div>
                                                        <div className="col-2">
                                                            <Link
                                                                className="btn open-discussion-btn"
                                                                to={`/trainer/course/guide/${question?.courseId}/${question?.guideId}?lessonId=${question?.lessonId}&discussionId=${question?.discussionId}&studentCourseGuideLessionId=${question?.studentCourseGuideLessionId}`}
                                                            >
                                                                Open Discussion
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                :
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <img className="mt-5" src="/img/unlock_vendor.png" />
                                    </div>
                                    <div className="col-12 text-center">
                                        <p className="light_text">There are no student questions.</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div >
                </div>
            </div>
        </div >
    )
}

export default StudentQuestion

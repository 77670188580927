import React, { useEffect, useState } from "react";
import VideoRecordModal from "../../models/VideoRecordModal";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import clientService from "../../../services/service";
import VideoPreview from "../../../common/VideoPreview";
import VideoPlayer from "../../../common/VideoPlayer";
import uploadFileToBlob from "../../../components/azure-storage-blob";
import { useAlert } from "react-alert";
import { Accordion, Modal } from "react-bootstrap";
import { setLoader } from "../../../store/slices/appSlice";
import ImageTypes from "../../../consts/ImageTypes";

let media_recorder = null;
let camera_stream = null;
let blobs_recorded = [];
let timeInt = null;
let startTime = null;
const Scenario = ({ setShowInstructionModal, onCancel, scenarioId }) => {
  const { courseId } = useParams();

  const [showVideoConfirmModel, setShowVideoConfirmModel] = useState(false);
  const [recodConfig, setRecodConfig] = useState({});
  const [readyForRecording, setReadyForRecording] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("idel");
  const [cameraStream, setCameraStream] = useState(null);
  const [localLink, setLocalLink] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [scenario, setScenario] = useState({});
  const [seconds, setSeconds] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionSecond, setQuestionSecond] = useState(0);
  const [questionInfo, setQuestionInfo] = useState([0]);
  const [congressModel, setCongressModel] = useState(false);
  const [attempt, setAttempt] = useState(0);

  const alert = useAlert();
  const videoRef = React.useRef(null);
  const player = React.useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (recodConfig.audioInput && recodConfig.videoInput) {
      initRecording();
    }
  }, [recodConfig]);

  useEffect(() => {
    if (questionSecond > 0) {
      if (questionSecond >= scenario.secondsPerQuestion) {
        if (timeInt) {
          clearInterval(timeInt);
        }
        setQuestionSecond(0);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        timeInt = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
          setQuestionSecond((questionSecond) => questionSecond + 1);
        }, 1000);
      }
    }
  }, [questionSecond]);

  useEffect(() => {
    if (timeInt && seconds < 0) {
      if (currentQuestionIndex < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        stopRecording();
      }
    }
  }, [seconds]);

  useEffect(() => {
    if (currentQuestionIndex < questions.length) {
      let getCurrentMilis = new Date().getTime();
      setQuestionInfo([...questionInfo, getCurrentMilis - startTime]);
      // setSeconds((questions.length - currentQuestionIndex) * scenario.secondsPerQuestion);
      setSeconds(scenario.secondsPerQuestion);
      setQuestionSecond(0);
    } else if (questions.length >= 1) {
      stopRecording();
      if (timeInt) {
        clearInterval(timeInt);
      }
    }
  }, [currentQuestionIndex]);

  const getInfo = async () => {
    const res = await clientService.get(
      `StudentRandomizer/GetQuestions?courseId=${courseId}&ScenarioId=${scenarioId}`
    );
    setQuestions(res.data.questions);
    setScenario(res.data.scenario);
    setSeconds(
      res.data.questions.length * res.data.scenario.secondsPerQuestion
    );
    setSeconds(res.data.scenario.secondsPerQuestion);
    setCurrentQuestionIndex(0);
  };

  const startRecording = async (e) => {
    if (e && e.audioInput && e.videoInput) {
      setRecodConfig(e);
      setReadyForRecording(true);
    }
    setShowVideoConfirmModel(false);
  };

  const startTimer = () => {
    if (timeInt) {
      clearInterval(timeInt);
    }
    timeInt = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
      setQuestionSecond((questionSecond) => questionSecond + 1);
    }, 1000);
  };

  const initRecording = async () => {
    setRecordingStatus("idel");
    camera_stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: {
          exact: recodConfig.videoInput.deviceId,
        },
      },
      audio: {
        deviceId: {
          exact: recodConfig.audioInput.deviceId,
        },
      },
    });
    setCameraStream(camera_stream);
    videoRef.current.srcObject = camera_stream;
  };
  const recordingStart = async () => {
    setRecordingStatus("start");
    setQuestionInfo([0]);
    startTimer();
    startTime = new Date().getTime();
    media_recorder = new MediaRecorder(camera_stream, {
      mimeType: "video/webm",
    });
    media_recorder.addEventListener("dataavailable", (e) => {
      blobs_recorded.push(e.data);
    });
    media_recorder.addEventListener("stop", () => {
      let video_local = URL.createObjectURL(
        new Blob(blobs_recorded, { type: "video/webm" })
      );
      setLocalLink(video_local);
      const blob = new Blob(blobs_recorded, { type: "video/mp4" });
      blob.name = "randomizer_" + courseId + ".mp4";
      setVideoBlob(blob);
      blobs_recorded = [];
    });
    media_recorder.start(1000);
  };
  const stopRecording = () => {
    setRecordingStatus("stop");
    if (timeInt) {
      clearInterval(timeInt);
    }
    if (media_recorder && media_recorder.stop) {
      camera_stream?.getTracks().forEach((track) => track.stop());
      media_recorder.stop();
    }
  };

  const getScondsToTime = () => {
    if (seconds >= 60) {
      const min = Math.floor(seconds / 60);
      const sec = seconds % 60;
      var str = "";

      if (min < 10) {
        str = `0${min}`;
      } else {
        str = `${min}`;
      }
      if (sec < 10) {
        return `${str}:0${sec}`;
      } else {
        return `${str}:${sec}`;
      }
    } else if (seconds < 10) {
      return `00:0${seconds}`;
    } else {
      return `00:${seconds}`;
    }
  };

  const sendToReview = async () => {
    dispatch(setLoader(true));
    const videoUrl = await uploadFileToBlob(videoBlob, "course", courseId);
    const questionTimes = [];
    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      questionTimes.push({
        questionId: element.questionId,
        seconds: questionInfo[index],
      });
    }
    const data = {
      scenarioId: scenario.scenarioId,
      video: videoUrl,
      question: questionTimes,
    };
    const res = await clientService.post(
      "StudentRandomizer/SubmitScenario",
      data
    );
    if (res.status) {
      alert.success(res.message);
      setCongressModel(true);
      setAttempt(res.data.attempt);
    }
  };
  if (readyForRecording) {
    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <div className="aside-title-area mt-0 mb-0">
              <h2 className="title title-lg line-height_48 mt-0 mb-0">
                Video objections recording
              </h2>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="aside-title-area mt-0 mb-0 d-flex justify-content-end">
              {localLink ? (
                <a
                  className="btn btn-submit pointer mr-16"
                  onClick={sendToReview}
                >
                  send to review
                </a>
              ) : null}
              <a className="border_btn_default pointer" onClick={onCancel}>
                Cancel
              </a>
            </div>
          </div>
        </div>
        <div className="row" id="video_audio_popup">
          <div className="col-lg-12">
            <div className="randomister_blog">
              <div
                className="static_form  randomister_popup mt-4"
                id="video_audio_popup"
              >
                <div className="center">
                  <div className="popup_section p-0">
                    {localLink ? (
                      <VideoPreview
                        videoLink={localLink}
                        questionTimes={questionInfo}
                        questions={questions}
                      />
                    ) : (
                      <div className="popup_content">
                        <p
                          class={
                            recordingStatus == "idel"
                              ? "video_audio_section m-0"
                              : "m-0"
                          }
                        >
                          <video
                            style={{ width: "100%" }}
                            ref={videoRef}
                            autoPlay
                            muted={true}
                            className="image-fluid video-preview"
                            webkit-playsinline="true"
                            playsinline="true"
                          />
                          <span className="media_player_controls_timer">
                            <div className="control-video">
                              <span className="controls_player left_timer">
                                <label>Timer</label>
                                <p className="large_number">
                                  {getScondsToTime()}
                                </p>
                              </span>
                              <span className="controls_player right_content">
                                <span className="left_plyer_control">
                                  <label>
                                    {currentQuestionIndex + 1} /{" "}
                                    {questions.length} Objections
                                  </label>
                                  <p>
                                    {questions[currentQuestionIndex]?.question}
                                  </p>
                                </span>

                                <span
                                  onClick={() => {
                                    if (
                                      currentQuestionIndex < questions.length
                                    ) {
                                      setCurrentQuestionIndex(
                                        currentQuestionIndex + 1
                                      );
                                    } else {
                                      stopRecording();
                                    }
                                  }}
                                  className="right_plyer_control"
                                >
                                  <a className="player_round_btn" href="#">
                                    {currentQuestionIndex + 1 <
                                    questions.length ? (
                                      <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M6.47139 0.86201C6.21104 0.601661 5.78893 0.601661 5.52858 0.86201C5.26824 1.12236 5.26824 1.54447 5.52858 1.80482L9.05718 5.33341H1.29166C0.946479 5.33341 0.666656 5.63189 0.666656 6.00008C0.666656 6.36827 0.946479 6.66675 1.29166 6.66675H9.05718L5.52858 10.1953C5.26824 10.4557 5.26824 10.8778 5.52858 11.1382C5.78893 11.3985 6.21104 11.3985 6.47139 11.1382L11.1381 6.47149C11.3984 6.21114 11.3984 5.78903 11.1381 5.52868L6.47139 0.86201Z"
                                          fill="white"
                                        ></path>
                                      </svg>
                                    ) : (
                                      <svg
                                        width="12"
                                        height="9"
                                        viewBox="0 0 12 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M11.8047 0.528758C12.0651 0.789108 12.0651 1.21122 11.8047 1.47157L4.4714 8.8049C4.21106 9.06525 3.78894 9.06525 3.5286 8.8049L0.195262 5.47157C-0.0650874 5.21122 -0.0650874 4.78911 0.195262 4.52876C0.455612 4.26841 0.877722 4.26841 1.13807 4.52876L4 7.39069L10.8619 0.528758C11.1223 0.268409 11.5444 0.268409 11.8047 0.528758Z"
                                          fill="white"
                                        />
                                      </svg>
                                    )}
                                  </a>
                                </span>
                              </span>
                            </div>
                          </span>
                          {recordingStatus == "idel" ? (
                            <span className="player_recording_btn">
                              <a
                                className="main_btn_default no_hover"
                                onClick={recordingStart}
                              >
                                <svg
                                  width="9"
                                  height="12"
                                  viewBox="0 0 9 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.9173 6.27734L0.851306 10.988C0.801109 11.0214 0.742792 11.0406 0.682566 11.0434C0.62234 11.0463 0.56246 11.0328 0.509305 11.0044C0.456149 10.9759 0.411708 10.9336 0.380714 10.8818C0.349721 10.8301 0.333337 10.771 0.333305 10.7107V1.28934C0.333337 1.22904 0.349721 1.16989 0.380714 1.11817C0.411708 1.06645 0.456149 1.0241 0.509305 0.995644C0.56246 0.967185 0.62234 0.953677 0.682566 0.956558C0.742792 0.959439 0.801109 0.978602 0.851306 1.012L7.9173 5.72267C7.96296 5.75311 8.00039 5.79435 8.02628 5.84273C8.05217 5.89111 8.06571 5.94513 8.06571 6C8.06571 6.05487 8.05217 6.1089 8.02628 6.15728C8.00039 6.20566 7.96296 6.2469 7.9173 6.27734Z"
                                    fill="#F9F9FB"
                                  ></path>
                                </svg>{" "}
                                Start recording
                              </a>
                            </span>
                          ) : null}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="video_content mt-3">
                    <Accordion
                      className="accordion accordion-flush"
                      defaultActiveKey="0"
                    >
                      <Accordion.Item className="accordion-item" eventKey="0">
                        <Accordion.Header className="accordion-header">
                          {scenario.name}
                        </Accordion.Header>
                        <Accordion.Body className="white_text">
                          <p>{scenario.scenario}</p>
                          {scenario.attachedFile ? (
                            <p>
                              {scenario.isVideo ? (
                                <VideoPlayer
                                  videoId={scenario.vimeoLink}
                                  autoplay={false}
                                  isVideoAvailable={scenario.isVideoAvailable}
                                />
                              ) : (
                                // <video
                                //   className="img-fluid"
                                //   src={scenario.attachedFile}
                                //   alt="#"
                                // />
                                <img
                                  className="img-fluid"
                                  src={scenario.attachedFile}
                                  alt="#"
                                />
                              )}
                            </p>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modal-dark remove-profile create-guide-popup sap "
          show={congressModel}
        >
          <Modal.Header
            closeButton={true}
            closeVariant="white"
            onHide={() => {
              setCongressModel(false);
              onCancel(false);
            }}
          ></Modal.Header>
          <Modal.Body>
            <div className="resources_content white-font">
              <p className="text-center mt-5">
                <img width="59" src="/img/unlock_vendor.png" alt="#" />
              </p>
              <h5 className="modal-title text-center text-light mb-3">
                Congrats!
              </h5>
              <p className="text-center">
                Your video was successfully submitted for review.
                <br />
                You used {attempt} / 10 attempts per day.{" "}
              </p>
              <div className="center d-flex justify-content-center">
                <Link
                  to=""
                  onClick={() => {
                    setCongressModel(false);
                    onCancel(true);
                  }}
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="main_btn_default"
                >
                  Try another
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="row aic">
            <div className="col-auto">
              <div className="aside-title-area">
                <h2 className="title title-lg line-height_48">Randomiser</h2>
              </div>
            </div>
            <div className="col">
              <div className="popup_header al1">
                <h4>Scenario</h4>
                <a
                  className="pointer ml-10"
                  onClick={() => setShowInstructionModal(true)}
                >
                  View Instruction
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-right">
          <div className="buttons">
            <a
              onClick={() => setShowVideoConfirmModel(true)}
              className="main_btn_default pointer"
            >
              Start recording
            </a>
            <a
              onClick={onCancel}
              data-bs-toggle="modal"
              href="#view_instruction"
              className="border_btn_default"
            >
              Cancel
            </a>
          </div>
        </div>
        <div className="col-12">
          <p class="color-white">{scenario.scenario}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="randomister_blog">
            <div className="static_form1 randomister_popup" id="scenario_popup">
              <div className="row h-100">
                <div className="col-md-12 h-100">
                  {scenario.attachedFile ? (
                    <>
                      {scenario.isVideo ? (
                        <VideoPlayer
                          videoId={scenario.vimeoLink}
                          autoplay={true}
                          isVideoAvailable={scenario.isVideoAvailable}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={scenario.attachedFile}
                          alt="#"
                        />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideoConfirmModel ? (
        <VideoRecordModal onClose={startRecording} />
      ) : null}
    </>
  );
};
export default Scenario;
